import { Grid2 as Grid } from '@mui/material'
import { PimLandField } from '@one/components/common/PimLandField'
import {
  PimArtikelVersionLieferantJson,
  PimArtikelVersionLieferantLandJson,
  PimLandDisplayJson,
  SeArtikelJson
} from '@one/typings/apiTypings'
import { ChangeFn, ModelAction, onChangeWrapper, ValueChangeFn } from '@utils/modelmgr'
import { NumberField } from '@utils/ui/fields/NumberField'
import { RowForm } from '@utils/ui/fields/RowForm'
import { aidOf, resolveObjectField } from '@utils/utils'
import React from 'react'
import { EigenlistungsArtikelUseCase } from './model/EigenlistungsArtikelUseCase'

export interface PimArtikelLieferantFrachkostenProps {
  pimArtikelVersionLieferant: PimArtikelVersionLieferantJson
  laender: PimLandDisplayJson[]
  onChange: ValueChangeFn<SeArtikelJson>
  dispatch: (action: ModelAction) => void
  errors: any
  idx: number
  readonly?: boolean
}

export const PimArtikelLieferantFrachkosten = ({
  pimArtikelVersionLieferant,
  laender,
  onChange,
  dispatch,
  errors,
  idx,
  readonly
}: PimArtikelLieferantFrachkostenProps) => {
  return !readonly || pimArtikelVersionLieferant.laender?.length > 0 ? (
    <RowForm
      title="Frachtkosten"
      name="frachtkosten"
      borderless
      rows={pimArtikelVersionLieferant.laender}
      onAdd={
        readonly
          ? null
          : () =>
              dispatch({
                type: EigenlistungsArtikelUseCase.ADDLIEFERANTLAND,
                lieferant: pimArtikelVersionLieferant
              })
      }
      onRemove={
        readonly
          ? null
          : (land) =>
              dispatch({
                type: EigenlistungsArtikelUseCase.REMOVELIEFERANTLAND,
                lieferant: pimArtikelVersionLieferant,
                land
              })
      }
      body={(land, idx2) => (
        <PimArtikelLieferantFrachkostenItemMemo
          land={land}
          laender={laender}
          onChange={onChangeWrapper(
            onChange,
            `pimArtikel.version.lieferanten.[__aid=${aidOf(pimArtikelVersionLieferant)}].laender.[__aid=${aidOf(land)}]`
          )}
          errors={errors}
          errorsPath={`pimArtikel.version.lieferanten.[${idx}].laender.[${idx2}]`}
          readonly={readonly}
        />
      )}
    />
  ) : null
}

const PimArtikelLieferantFrachkostenMemo = React.memo(PimArtikelLieferantFrachkosten)

export interface PimArtikelLieferantFrachkostenItemProps {
  land: PimArtikelVersionLieferantLandJson
  laender: PimLandDisplayJson[]
  onChange: ChangeFn
  errors: any
  errorsPath: string
  readonly?: boolean
}

export const PimArtikelLieferantFrachkostenItem = ({
  land,
  laender,
  onChange,
  errors,
  errorsPath,
  readonly
}: PimArtikelLieferantFrachkostenItemProps) => {
  return (
    <Grid container spacing={2}>
      <Grid size={{xs:12,md:6,lg:3,xl:1}}>
        <PimLandField
          label="Land"
          name="pimLand"
          value={land.pimLand}
          values={laender}
          onChange={onChange}
          error={resolveObjectField(errors, errorsPath + '.pimLand')}
          long
          fullWidth
          disabled={readonly}
        />
      </Grid>
      <Grid size={{xs:12,md:6,lg:3,xl:2}}>
        <NumberField
          label="Frachtkosten"
          name="frachtkosten"
          value={land.frachtkosten}
          onChange={onChange}
          error={resolveObjectField(errors, errorsPath + '.frachtkosten')}
          fraction={2}
          fullWidth
          disabled={readonly}
        />
      </Grid>
    </Grid>
  )
}

const PimArtikelLieferantFrachkostenItemMemo = React.memo(PimArtikelLieferantFrachkostenItem)
