import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Grid2 as Grid } from '@mui/material'
import { HkmEnum } from '@one/enums/HkmEnum'
import { ErpSystemJson } from '@one/typings/apiTypings'
import { useFormState } from '@utils/formstate'
import { ApplyButton } from '@utils/ui/Buttons/ApplyButton'
import { ButtonRow } from '@utils/ui/Buttons/ButtonRow'
import { CancelButton } from '@utils/ui/Buttons/CancelButton'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { SelectEnumField } from '@utils/ui/fields/SelectEnumField'
import { TextField } from '@utils/ui/fields/TextField'
import { useCallback, useEffect, useMemo, useState } from 'react'

export interface ErpSystemFormProps {
  readonly?: boolean
  erpSystem?: ErpSystemJson
  onSaveErp?: (erp: ErpSystemJson) => void
  onCancel?: () => void
}

export const ErpSystemForm = ({ readonly, erpSystem, onSaveErp, onCancel }: ErpSystemFormProps) => {
  const [showToken, setShowToken] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const initState = useMemo(
    () => ({
      id: null,
      type: null,
      intercompanyEinstellungen: null,
      name: '',
      importDir: '',
      exportDir: '',
      communicationType: null,
      serverLink: '',
      synchronTimeOut: null,
      token: '',
      zielGln: '',
      user: '',
      password: '',
      jmsQue: '',
      hkmQue: '',
      preiseOnDemand: false,
      skipConfirmImport: false,
      jmsMaxSendSize: null,
      jmsMaxSendConut: null,
      supportsEanGruppen: false
    }),
    []
  )
  const [state, onChange] = useFormState<ErpSystemJson>(erpSystem ? { ...erpSystem } : initState)

  useEffect(() => {
    onChange(() => erpSystem || initState)
  }, [erpSystem, onChange, initState])

  const handleSave = () => {
    onSaveErp(state)
    handleCancel()
  }

  const handleCancel = () => {
    onChange(() => erpSystem || initState)
    onCancel && onCancel()
  }

  const toggleTokenVisibility = useCallback(() => {
    setShowToken(!showToken)
  }, [showToken])

  const togglePasswordVisibility = useCallback(() => {
    setShowPassword(!showPassword)
  }, [showPassword])

  return (
    <>
      <Grid container spacing={2} direction="row">
        <Grid size={{xs:12,sm:6,lg:3}}>
          <TextField
            name="name"
            label="Name"
            // error={errors.name}
            value={state.name}
            onChange={onChange}
            fullWidth
            required
            disabled={readonly}
          />
        </Grid>
        <Grid size={{xs:12,sm:6,lg:3}}>
          <SelectEnumField
            name="type"
            label="Typ"
            // error={errors.type}
            value={state.type}
            onChange={onChange}
            enumType={HkmEnum.ErpSystemEinstellungen}
            fullWidth
            required
            disabled={readonly}
          />
        </Grid>
        <Grid size={{xs:12,sm:6,lg:3}}>
          <SelectEnumField
            name="intercompanyEinstellungen"
            label="Intercompany Einstellung"
            // error={errors.intercompanyEinstellungen}
            value={state.intercompanyEinstellungen}
            onChange={onChange}
            enumType={HkmEnum.IntercompanyEinstellungen}
            fullWidth
            required
            disabled={readonly}
          />
        </Grid>
        <Grid size={{xs:12,sm:6,lg:3}}>
          <TextField
            name="importDir"
            label="Import-Directory"
            // error={errors.importDir}
            value={state.importDir}
            onChange={onChange}
            fullWidth
            required
            disabled={readonly}
          />
        </Grid>
        <Grid size={{xs:12,sm:6,lg:3}}>
          <TextField
            name="exportDir"
            label="Export-Directory"
            // error={errors.exportDir}
            value={state.exportDir}
            onChange={onChange}
            fullWidth
            required
            disabled={readonly}
          />
        </Grid>
        <Grid size={{xs:12,sm:6,lg:3}}>
          <SelectEnumField
            name="communicationType"
            label="Kommunikations-Typ"
            // error={errors.communicationType}
            value={state.communicationType}
            onChange={onChange}
            enumType={HkmEnum.ErpCommunticationType}
            fullWidth
            required
            disabled={readonly}
          />
        </Grid>
        <Grid size={{xs:12,sm:6,lg:3}}>
          <TextField
            name="serverLink"
            label="Serverlink"
            // error={errors.serverLink}
            value={state.serverLink}
            onChange={onChange}
            fullWidth
            required
            disabled={readonly}
          />
        </Grid>
        <Grid size={{xs:12,sm:6,lg:3}}>
          <TextField
            name="synchronTimeOut"
            label="Sync-Timeout"
            // error={errors.synchronTimeOut}
            value={state.synchronTimeOut}
            onChange={onChange}
            fullWidth
            required
            disabled={readonly}
            type="number"
          />
        </Grid>
        <Grid size={{xs:12,sm:6,lg:3}}>
          <TextField
            name="token"
            label="Token"
            // error={errors.token}
            value={state.token}
            onChange={onChange}
            fullWidth
            required
            disabled={readonly}
            type={showToken ? 'text' : 'password'}
            actions={[
              {
                onClick: toggleTokenVisibility,
                icon: showToken ? <VisibilityOff /> : <Visibility />
              }
            ]}
          />
        </Grid>
        <Grid size={{xs:12,sm:6,lg:3}}>
          <TextField
            name="zielGln"
            label="Ziel-GLN"
            // error={errors.zielGln}
            value={state.zielGln}
            onChange={onChange}
            fullWidth
            required
            disabled={readonly}
          />
        </Grid>
        <Grid size={{xs:12,sm:6,lg:3}}>
          <TextField
            name="user"
            label="User"
            // error={errors.user}
            value={state.user}
            onChange={onChange}
            fullWidth
            required
            disabled={readonly}
          />
        </Grid>
        <Grid size={{xs:12,sm:6,lg:3}}>
          <TextField
            name="password"
            label="Password"
            // error={errors.password}
            value={state.password}
            onChange={onChange}
            fullWidth
            required
            disabled={readonly}
            type={showPassword ? 'text' : 'password'}
            actions={[
              {
                onClick: togglePasswordVisibility,
                icon: showPassword ? <VisibilityOff /> : <Visibility />
              }
            ]}
          />
        </Grid>
        <Grid size={{xs:12,sm:6,lg:3}}>
          <TextField
            name="jmsQueue"
            label="jms-Queue"
            // error={errors.jmsQueue}
            value={state.jmsQueue}
            onChange={onChange}
            fullWidth
            required
            disabled={readonly}
          />
        </Grid>
        <Grid size={{xs:12,sm:6,lg:3}}>
          <TextField
            name="hkmQueue"
            label="HkM-Queue"
            // error={errors.hkmQueue}
            value={state.hkmQueue}
            onChange={onChange}
            fullWidth
            required
            disabled={readonly}
          />
        </Grid>
        <Grid size={{xs:12,sm:6,lg:3}}>
          <Checkbox
            disabled={readonly}
            label="Preise-On-Demand"
            name="preiseOnDemand"
            checked={state.preiseOnDemand}
            onChange={onChange}
          />
        </Grid>
        <Grid size={{xs:12,sm:6,lg:3}}>
          <Checkbox
            disabled={readonly}
            label="Import-Bestätigung überspringen"
            name="skipConfirmImport"
            checked={state.skipConfirmImport}
            onChange={onChange}
          />
        </Grid>
        <Grid size={{xs:12,sm:6,lg:3}}>
          <TextField
            name="jmsMaxSendSize"
            label="JMS maximale Sendungsgröße"
            // error={errors.jmsMaxSendSize}
            value={state.jmsMaxSendSize}
            onChange={onChange}
            fullWidth
            required
            disabled={readonly}
            type="number"
          />
        </Grid>
        <Grid size={{xs:12,sm:6,lg:3}}>
          <TextField
            name="jmsMaxSendCount"
            label="JMS maximale Sendungsanzahl"
            // error={errors.jmsMaxSendCount}
            value={state.jmsMaxSendCount}
            onChange={onChange}
            fullWidth
            required
            disabled={readonly}
            type="number"
          />
        </Grid>
        <Grid size={{xs:12,sm:6,lg:3}}>
          <Checkbox
            disabled={readonly}
            label="EAN-Gruppen unterstützen"
            name="supportsEanGruppen"
            checked={state.supportsEanGruppen}
            onChange={onChange}
          />
        </Grid>
      </Grid>
      {!readonly && (
        <Grid container spacing={2} direction="row" justifyContent="flex-end">
          <Grid size={{xs:12}}>
            <ButtonRow>
              <ApplyButton size="small" onClick={handleSave} />
              <CancelButton size="small" onClick={handleCancel} />
            </ButtonRow>
          </Grid>
        </Grid>
      )}
    </>
  )
}
