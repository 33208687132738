import { Grid2 as Grid, Icon, Typography } from '@mui/material'
import { brown, indigo } from '@mui/material/colors'
import { TreeItem } from '@mui/x-tree-view/TreeItem'
import { formatValue } from '@utils/ui/DataTable/DataTableUtils'
import { ScrollPanel } from '@utils/ui/ScrollPanel'
import { counter, resolveObjectField } from '@utils/utils'
import clsx from 'clsx'
import { useMemo } from 'react'
import { makeStyles } from 'tss-react/mui'
import { SimpleTreeView } from '@mui/x-tree-view'

const useTreeStyles = makeStyles()((theme: any) => ({
  root: {
    // height: 264,
    // flexGrow: 1,
    width: '100%'
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: '2px',
    paddingBottom: '2px',
    paddingRight: '12px',
    marginLeft: -24
  },
  labelRoot2: {
    '& > p': {
      paddingLeft: '12px'
    }
  },
  labelIcon: {
    marginRight: theme.spacing(1)
  },
  labelIcon2: {
    marginRight: theme.spacing(1),
    fontSize: '1em'
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1
  },
  attrValue: {
    fontWeight: 'inherit'
  },
  attrLabel: {
    width: '3em',
    fontWeight: 'inherit'
  }
}))

const useItemStyles = makeStyles()((theme: any) => ({
  root: {
    color: theme.palette.text.secondary
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1)
    // fontWeight: theme.typography.fontWeightNormal
  },
  headLine: {
    '&>div': {
      marginTop: 6,
      backgroundColor: theme.darkMode ? brown[800] : indigo[50]
    }
  },
  group: {},
  expanded: {},
  selected: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit'
  }
}))

export const CompositeTreeItem = ({
  nodeId,
  labelText,
  labelIcon,
  labelInfo = null,
  color = null,
  bgColor = null,
  children = null,
  path = null,
  itemClasses,
  treeClasses
}) => (
  <TreeItem
    itemId={nodeId}
    data-name={`${path || ''}${labelText}${labelInfo ? `-${labelInfo}` : ''}`}
    label={
      <div className={treeClasses.labelRoot}>
        <Icon color="inherit" className={treeClasses.labelIcon}>
          {labelIcon}
        </Icon>
        <Typography variant="body2" className={treeClasses.labelText}>
          {labelText}
        </Typography>
        <Typography variant="caption" color="inherit">
          {labelInfo}
        </Typography>
      </div>
    }
    classes={itemClasses}
    style={{
      // @ts-ignore
      '--tree-view-color': color,
      '--tree-view-bg-color': bgColor
    }}
  >
    {children}
  </TreeItem>
)

export const AttributeTreeItem = ({
  nodeId,
  labelText,
  labelInfo,
  labelIcon,
  treeClasses,
  itemClasses,
  simple = false,
  name = null,
  path = null,
  children = null
}) => (
  <TreeItem
    className={simple ? undefined : itemClasses.headLine}
    itemId={nodeId}
    label={
      <div className={treeClasses.labelRoot}>
        <Icon color="inherit" className={treeClasses.labelIcon}>
          {labelIcon}
        </Icon>
        <Typography
          variant="body2"
          className={treeClasses.labelText}
          data-name={name ? name : `${path || ''}${labelText}${labelInfo ? `-${labelInfo}` : ''}`}
        >
          {labelText}
        </Typography>
        <Typography variant="caption" color="inherit">
          {labelInfo}
        </Typography>
      </div>
    }
    classes={itemClasses}
    // style={{
    //   '--tree-view-color': color,
    //   '--tree-view-bg-color': bgColor
    // }}
  >
    {children}
  </TreeItem>
)

export const ChangeTreeItem = ({
  nodeId,
  labelText,
  value,
  flag,
  path,
  treeClasses,
  itemClasses
}) => (
  <TreeItem
    itemId={nodeId}
    label={
      <div className={clsx(treeClasses.labelRoot, treeClasses.labelRoot2)}>
        {/* <Icon color="inherit" className={treeClasses.labelIcon2}>
            {(flag == false && <RemoveCircleOutline />) ||
            (flag == true && <AddCircleOutline />) || <ChangeCircleOutlined />}
          </Icon> */}
        <Typography variant="body2" className={treeClasses.attrLabel}>
          {labelText}
        </Typography>
        <Typography
          variant="body2"
          className={treeClasses.attrValue}
          data-name={`${path || ''}${labelText}`}
        >
          {value == null ? (
            <small style={{ fontStyle: 'italic' }}>leer</small>
          ) : (
            <>&apos;{value}&apos;</>
          )}
        </Typography>
      </div>
    }
    classes={itemClasses}
    // style={{
    //   '--tree-view-color': color,
    //   '--tree-view-bg-color': bgColor
    // }}
  />
)

export const DiffTreeItem = ({
  idgen = null,
  nodeId = null,
  labelText,
  alt,
  neu,
  path,
  name = null,
  type = null,
  valueAlt = null,
  valueNeu = null,
  children = null,
  hidden = false
}) => {
  if (hidden) {
    return null
  }
  let va = resolveObjectField(alt, name)
  let vn = resolveObjectField(neu, name)
  if (va == null && vn == null && valueAlt == null && valueNeu == null) {
    return null
  }

  const formattedAlt = valueAlt != null ? valueAlt : formatValue(va, type)
  const formattedNeu = valueNeu != null ? valueNeu : formatValue(vn, type)
  const textAlign = 'right' //isNumber(vn) || isNumber(va) ? 'right' : 'left'

  return (
    <TreeItem
      itemId={nodeId || idgen()}
      label={
        <Grid container direction="row" spacing={1}>
          {/* <Icon color="inherit" className={treeClasses.labelIcon2}>
            {(flag == false && <RemoveCircleOutline />) ||
            (flag == true && <AddCircleOutline />) || <ChangeCircleOutlined />}
          </Icon> */}
          <Grid size={{md:3}}>
            <Typography variant="body2">{labelText}:</Typography>
          </Grid>
          {alt && (
            <Grid size={{md:3}} textAlign={textAlign}>
              <Typography variant="body2" data-name={`${path || ''}${labelText}-old`}>
                {formattedAlt}
              </Typography>
            </Grid>
          )}
          {neu && (
            <Grid size={{md:3}} textAlign={textAlign}>
              <Typography variant="body2" data-name={`${path || ''}${labelText}-new`}>
                {formattedNeu}
              </Typography>
            </Grid>
          )}
        </Grid>
      }
      // classes={itemClasses}
      // style={{
      //   '--tree-view-color': color,
      //   '--tree-view-bg-color': bgColor
      // }}
    >
      {children}
    </TreeItem>
  )
}

export type ChangesTreeViewProps = {
  generateItems: (
    nextId: () => string,
    expandedIds: string[],
    treeClasses: any,
    itemClasses: any
  ) => React.ReactNode
}

export const ChangesTreeView = ({ generateItems }: ChangesTreeViewProps) => {
  const { classes: treeClasses } = useTreeStyles()
  const { classes: itemClasses } = useItemStyles()

  const state = useMemo(() => {
    const expandedIds = []
    const generator = counter(0)
    const items = generateItems(
      () => `treeitem-${generator.next().value}`,
      expandedIds,
      treeClasses,
      itemClasses
    )
    return { items, expandedIds }
  }, [generateItems, itemClasses, treeClasses])

  return (
    <ScrollPanel>
      <SimpleTreeView
        className={treeClasses.root}
        expandedItems={state.expandedIds}
        // defaultCollapseIcon={null}
        // defaultExpandIcon={null}
        // defaultEndIcon={<div style={{ width: 24 }} />}
        selectedItems={[]}
      >
        {state.items}
      </SimpleTreeView>
    </ScrollPanel>
  )
}
