import { Grid2 as Grid } from '@mui/material'
import { AppPaths } from '@one/AppPaths'
import { LieferantField } from '@one/components/Lieferant/LieferantField'
import { EkRabattgruppenSearcherCriteriaJson } from '@one/typings/apiTypings'
import { UserRoles } from '@one/UserRoles'
import { ChangeFunction } from '@utils/formstate'
import { Action } from '@utils/ui/Action'
import { CardEx } from '@utils/ui/CardEx'
import { useMemo } from 'react'

export interface EkRabattgruppenFormProps {
  value: EkRabattgruppenSearcherCriteriaJson
  onChange: ChangeFunction<EkRabattgruppenSearcherCriteriaJson>
  onSearch: VoidFunction
}

export const EkRabattgruppenForm = ({ value, onChange, onSearch }: EkRabattgruppenFormProps) => {
  const topActions = useMemo(
    () =>
      [
        {
          role: UserRoles.STAMMDATEN_EDITOR,
          tooltip: 'Rabattgruppe Massenpflege',
          navlink: value?.lieferant
            ? AppPaths.EkRabattgruppenMassenPflegeFn({ lieferantId: value?.lieferant?.id })
            : null,
          text: 'Massenpflege',
          enabled: value.lieferant != null
        }
      ] as Action[],
    [value.lieferant]
  )

  const bottomActions = useMemo<Action[]>(
    () => [
      {
        role: UserRoles.STAMMDATEN_EDITOR,
        text: 'Suchen',
        onClick: onSearch,
        enabled: value.lieferant != null
      }
    ],
    [onSearch, value.lieferant]
  )

  return (
    <CardEx
      backLink
      title="EK-Rabattgruppenübersicht"
      topActions={topActions}
      bottomActions={bottomActions}
    >
      <Grid container spacing={3}>
        <Grid size={{xs:12,sm:12,md:8,lg:6,xl:5}}>
          <LieferantField
            value={value.lieferant}
            label="Lieferant"
            name="lieferant"
            onChange={onChange}
            fullWidth
            modus="dezentral"
            asLink
          />
        </Grid>
      </Grid>
    </CardEx>
  )
}
