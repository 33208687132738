import { Box, Tooltip, TooltipProps } from '@mui/material'
import { ReactElement, ReactNode } from 'react'

export interface TooltipExProps {
  help?: boolean
  popperClassName?: string
  placement?: TooltipProps['placement']
  children: ReactElement<unknown, any>
  arrow?: boolean
  classes?: TooltipProps['classes']
  title: ReactNode
}

/**
 * Slightly extended Tooltip component.
 *
 * @param arrow show arrow
 * @param help show help cursor
 * @param title the tool
 * @param popperClassName
 * @param placement
 * @param classes
 * @param children
 * @constructor
 */
export const TooltipEx = ({
  arrow,
  help,
  title,
  popperClassName,
  placement,
  classes,
  children
}: TooltipExProps) => {
  return (
    <Tooltip
      arrow={arrow}
      title={title}
      placement={placement}
      classes={classes}
      style={help ? { cursor: 'help' } : undefined}
      slotProps={{
        popper: { className: popperClassName }
      }}
    >
      {children}
    </Tooltip>
  )
}

export type TooltipWrapperProps = {
  title: ReactNode
  arrow?: boolean
  help?: boolean
  placement?: TooltipProps['placement']
  children: ReactNode
}

/**
 * Wraps children with a Tooltip if title is provided to overcome disabled tooltips in disabled elements.
 * @param title
 * @param children
 * @param arrow show arrow
 * @param placement
 * @param help show help cursor
 * @constructor
 */
export const TooltipWrapper = ({
  title,
  children,
  arrow,
  placement,
  help
}: TooltipWrapperProps) => {
  if (title) {
    return (
      <TooltipEx title={title} arrow={arrow} help={help} placement={placement}>
        <Box>{children}</Box>
      </TooltipEx>
    )
  }
  return children
}
