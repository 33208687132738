import { Grid2 as Grid } from '@mui/material'
import { api } from '@one/api'
import {
  BetriebsTypenJson,
  BetriebstypJson,
  GesellschafterDisplayJson
} from '@one/typings/apiTypings'
import { useModelMgr } from '@utils/modelmgr'
import { CancelButton } from '@utils/ui/Buttons/CancelButton'
import { SaveButton } from '@utils/ui/Buttons/SaveButton'
import { Column } from '@utils/ui/DataTable/DataTable'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { DialogEx } from '@utils/ui/DialogEx'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { TooltipEx } from '@utils/ui/TooltipWrapper'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { NumberField } from '@utils/ui/fields/NumberField'
import { Radio } from '@utils/ui/fields/Radio'
import { StaticField } from '@utils/ui/fields/StaticField'
import { StatePlane } from '@utils/ui/planes/StatePlane'
import { aidOf, nameOf, zeroPad } from '@utils/utils'
import { useCallback, useMemo } from 'react'

const validate = (model: BetriebsTypenJson) => {
  const errors = {} as any
  let hasDefault = false
  for (let betriebstTyp of model.betriebsTypen) {
    if (betriebstTyp.default && betriebstTyp.zugeordnet) {
      hasDefault = true
      break
    }
  }

  if (!hasDefault) {
    errors.default = 'Bitte ein Default-Betriebstyp festlegen.'
  }

  return errors
}

export interface GesellschafterBetriebstypenDialogProps {
  gesellschafter: GesellschafterDisplayJson
  open: boolean
  onClose: (selected?: any | any[]) => void
}

export const GesellschafterBetriebstypenDialog = ({
  gesellschafter,
  open,
  onClose
}: GesellschafterBetriebstypenDialogProps) => {
  const handleCancel = () => {
    onClose()
  }

  const { model, isChanged, isNew, save, updModel, uiLock, onValueChange } =
    useModelMgr<BetriebsTypenJson>({
      id: gesellschafter.stammEbeneId,
      api,
      title: 'Betriebstypenpflege',
      rest: 'gesellschafter/betriebstypen',
      unwrapField: 'betriebsTypen',
      onSave: onClose,
      validate
    })

  const setDefault = useCallback(
    (row: BetriebstypJson, checked: boolean) => {
      updModel((old: BetriebsTypenJson) => {
        const copy = {
          ...old,
          betriebsTypen: old.betriebsTypen.map((bt) => {
            const dft = bt == row ? checked : !checked
            if (bt.default !== dft) {
              return {
                ...bt,
                default: dft
              }
            }
            return bt
          })
        }
        return copy
      })
    },
    [updModel]
  )

  const columns = useMemo(
    () =>
      [
        {
          field: nameOf<BetriebstypJson>('zugeordnet'),
          header: 'Zugeordnet',
          headerTip: 'Zugeordnet',
          type: 'boolean',
          align: 'center',
          width: '1em',
          body: (row) => (
            <Grid container spacing={1} justifyContent="center">
              <Grid>
                <Checkbox
                  compact
                  checked={row.zugeordnet}
                  name={`betriebsTypen.[__aid=${aidOf(row)}].zugeordnet`}
                  onChange={onValueChange}
                />
              </Grid>

              {row.zugeordnet && (
                <Grid>
                  <TooltipEx title="Standardauswahl">
                    <Radio
                      compact
                      checked={row.default}
                      onChange={() => setDefault(row, !row.default)}
                    />
                  </TooltipEx>
                </Grid>
              )}
            </Grid>
          )
        },
        {
          field: nameOf<BetriebstypJson>('kontextName'),
          off: !model.pimMode,
          header: 'Kontext',
          valueGetter: (row) => row.kontextNr && `${row.kontextNr} ${row.kontextName}`
        },
        {
          field: nameOf<BetriebstypJson>('handelTyp'),
          off: !model.pimMode,
          header: 'Typ',
          type: 'string',
          align: 'center',
          width: '1em'
        },
        {
          field: 'btNr',
          header: 'B-Typ',
          headerTip: 'Betriebstyp',
          align: 'right',
          width: '1em',
          valueGetter: (row) => zeroPad(row.btNr, 3)
        },
        {
          field: nameOf<BetriebstypJson>('bezeichnung'),
          hideIfEmpty: true,
          off: model.pimMode,
          header: 'Bezeichnung'
        }
      ] as Column<BetriebstypJson>[],
    [model.pimMode, onValueChange, setDefault]
  )

  const actions = (
    <>
      <SaveButton onClickVoid={save} isNew={isNew} isChanged={isChanged} />
      <CancelButton onClick={handleCancel} />
    </>
  )

  return (
    <DialogEx
      open={open}
      title={model.pimMode ? 'Zuordnung Neon-Kontexte' : 'Zuordung Betriebstypen'}
      onClose={handleCancel}
      fullWidth
      maxWidth="md"
      actions={actions}
      height="550px"
    >
      <StatePlane uiLock={uiLock}>
        <Frame space>
          <FrameRow>
            <Grid container padding={2} spacing={2}>
              <Grid size={{xs:3}}>
                <NumberField label="GLN" value={gesellschafter.gln} fraction={0} />
              </Grid>
              <Grid size={{xs:6}}>
                <StaticField label="Gesellschafter" value={gesellschafter.name} />
              </Grid>
            </Grid>
          </FrameRow>
          <FrameBody>
            <DataTableCard
              title={model.pimMode ? 'Kontexte' : 'Betriebstypen'}
              name="ZugeordneteBetriebstypen"
              columns={columns}
              value={model.betriebsTypen}
              dense
              borderless
              rowFiller
              filterMode="both"
              selectMode="single"
            />
          </FrameBody>
        </Frame>
      </StatePlane>
    </DialogEx>
  )
}
