import { Grid2 as Grid } from '@mui/material'
import { SparteDisplayJson, WarengruppeDisplayJson } from '@one/typings/apiTypings'
import { useFormState } from '@utils/formstate'
import { formatNumber } from '@utils/numberutils'
import { ApplyButton } from '@utils/ui/Buttons/ApplyButton'
import { CancelButton } from '@utils/ui/Buttons/CancelButton'
import { DialogEx } from '@utils/ui/DialogEx'
import { AutocompleteEx } from '@utils/ui/fields/AutocompleteEx'
import { useMemo } from 'react'

export interface ArtikelAboSelektionDefaultsDialogProps {
  open: boolean
  onClose: (fn?: any) => void
  warengruppen: WarengruppeDisplayJson[]
  warengruppenGlobal: WarengruppeDisplayJson[]
  sparten: SparteDisplayJson[]
  spartenGlobal: SparteDisplayJson[]
  setDefaults: (wg: WarengruppeDisplayJson, sparte: SparteDisplayJson) => void
}

export const ArtikelAboSelektionDefaultsDialog = ({
  open,
  onClose,
  warengruppen,
  warengruppenGlobal,
  sparten,
  spartenGlobal,
  setDefaults
}: ArtikelAboSelektionDefaultsDialogProps) => {
  const { spartenEx, warengruppenEx, hatSparten } = useMemo(
    () => ({
      spartenEx: sparten.concat(
        spartenGlobal
          .filter((sp) => sparten.find((s0) => s0.id === sp.id) == null)
          .map((wg) => ({ ...wg, global: true }))
      ),
      warengruppenEx: warengruppen.concat(
        warengruppenGlobal
          .filter((sp) => warengruppen.find((s0) => s0.id === sp.id) == null)
          .map((wg) => ({ ...wg, global: true }))
      ),
      hatSparten: sparten?.length > 0 || spartenGlobal?.length > 0
    }),
    [sparten, spartenGlobal, warengruppen, warengruppenGlobal]
  )

  const [state, onChange] = useFormState(() => {
    const sparte = spartenEx?.length > 0 ? spartenEx[0] : null
    const warengruppe = warengruppenEx?.length > 0 ? warengruppenEx[0] : null
    return { sparte, warengruppe }
  })

  const save = () => {
    setDefaults(state.warengruppe, state.sparte)
    onClose()
  }

  return (
    <DialogEx
      open={open}
      onClose={onClose}
      title="Defaultwerte für neue Artikel"
      actions={
        <>
          <ApplyButton
            onClickVoid={save}
            disabled={(hatSparten && state?.sparte == null) || state?.warengruppe == null}
          />
          <CancelButton onClick={onClose} />
        </>
      }
    >
      <Grid container spacing={2} padding={1}>
        <Grid size={{xs:12,sm:12}}>
          <AutocompleteEx
            label="Sparte"
            required={hatSparten}
            value={state.sparte}
            name="sparte"
            onChange={onChange}
            fullWidth
            options={spartenEx}
            groupBy={(option: any) => (option?.global ? 'Global verfügbare' : undefined)}
            renderItem={(option: SparteDisplayJson) => (
              <Grid container spacing={1} width="100%">
                <Grid flexGrow={1}>
                  {option.bezeichnung}
                </Grid>
                {option.anzahlArtikel > 0 && (
                  <Grid textAlign="right">
                    <small title="Anzahl Verwendungen in Artikeln">~{option.anzahlArtikel}</small>
                  </Grid>
                )}
              </Grid>
            )}
            optionLabel={(opt: SparteDisplayJson) => opt.nr + ' ' + (opt.bezeichnung || '')}
          />
        </Grid>
        <Grid size={{xs:12,sm:12}}>
          <AutocompleteEx
            label="Warengruppe"
            required
            value={state.warengruppe}
            name="warengruppe"
            onChange={onChange}
            fullWidth
            groupBy={(option: any) => (option?.global ? 'Global verfügbare' : undefined)}
            options={warengruppenEx}
            renderItem={(option: WarengruppeDisplayJson) => (
              <Grid container spacing={1} width="100%">
                <Grid width="5em" textAlign="right">
                  {option.nummer}
                </Grid>
                <Grid flexGrow={1}>
                  {option.name}
                </Grid>
                {option.anzahlArtikel > 0 && (
                  <Grid textAlign="right">
                    <small title="Anzahl Verwendungen in Artikeln">
                      ~{formatNumber(option.anzahlArtikel)}
                    </small>
                  </Grid>
                )}
              </Grid>
            )}
            optionLabel={(opt: WarengruppeDisplayJson) => opt.nummer + ' ' + (opt.name || '')}
          />
        </Grid>
      </Grid>
    </DialogEx>
  )
}
