import { Grid2 as Grid } from '@mui/material'
import { PimArtikelVersionDisplayJson, SeArtikelJson } from '@one/typings/apiTypings'
import { ModelAction, onChangeWrapper, ValueChangeFn } from '@utils/modelmgr'
import { NumberField } from '@utils/ui/fields/NumberField'
import { RowForm } from '@utils/ui/fields/RowForm'
import { aidOf, dataFromEvent, resolveObjectField } from '@utils/utils'
import React, { SyntheticEvent } from 'react'
import { EigenlistungsArtikelUseCase } from './model/EigenlistungsArtikelUseCase'
import { ArtikelField } from '@one/components/Artikel/ArtikelField'

export interface PimArtikelDisplayArtikelProps {
  displayArtikel: PimArtikelVersionDisplayJson[]
  onChange: ValueChangeFn<SeArtikelJson>
  dispatch: (action: ModelAction) => void
  errors: any
  readonly?: boolean
}

const PimArtikelDisplayArtikel = ({
  displayArtikel,
  onChange,
  dispatch,
  errors,
  readonly
}: PimArtikelDisplayArtikelProps) => {
  return (
    <RowForm
      title="Display-Artikel"
      name="displayartikel"
      rows={displayArtikel}
      addLabel="Display hinzufügen"
      onAdd={
        readonly
          ? null
          : () =>
              dispatch({
                type: EigenlistungsArtikelUseCase.ADDDISPLAYARTIKEL
              })
      }
      onRemove={
        readonly
          ? null
          : (display) =>
              dispatch({ type: EigenlistungsArtikelUseCase.REMOVEDISPLAYARTIKEL, display })
      }
      body={(display, idx) => (
        <PimArtikelDisplayMemo
          display={display}
          onChange={onChangeWrapper(
            onChange,
            `pimArtikel.version.displayArtikel.[__aid=${aidOf(display)}]`
          )}
          errors={resolveObjectField(
            errors,
            'model.pimArtikel?.version.displayArtikel.[' + idx + '].hageNummer'
          )}
          readonly={readonly}
        />
      )}
    />
  )
}

export const PimArtikelDisplayArtikelMemo = React.memo(PimArtikelDisplayArtikel)

type PimArtikelDisplayProps = {
  display: PimArtikelVersionDisplayJson
  onChange: (e: SyntheticEvent) => void
  errors: any
  readonly?: boolean
}

const PimArtikelDisplay = ({ display, onChange, errors, readonly }: PimArtikelDisplayProps) => {
  const onArtikelChange = (e) => {
    const { value } = dataFromEvent(e)
    // TODO extend onChangeWrapper so it can use more values instead of one
    display.hageNummer = value.hageNummer
    onChange(e)
  }
  return (
    <Grid container spacing={2}>
      <Grid size={{ xs: 12, md: 6, lg: 3, xl: 2 }}>
        <ArtikelField
          name="artikel"
          label="hageNummer"
          value={display.artikel}
          onChange={onArtikelChange}
          error={errors}
          fullWidth
          modus="alle"
          required
          disabled={readonly}
        />
      </Grid>
      <Grid size={{ xs: 12, md: 6, lg: 3, xl: 2 }}>
        <NumberField
          label="Menge"
          value={display.menge}
          onChange={onChange}
          fraction={0}
          name="menge"
          fullWidth
          disabled={readonly}
        />
      </Grid>
      <Grid size={{ xs: 12, sm: 6, md: 2, lg: 2, xl: 2 }}>
        <NumberField
          label="EK"
          value={display.ek}
          onChange={onChange}
          fraction={2}
          name="ek"
          disabled={readonly}
        />
      </Grid>
    </Grid>
  )
}

export const PimArtikelDisplayMemo = React.memo(PimArtikelDisplay)
