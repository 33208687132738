import { PimProduktJson, SeArtikelJson } from '@one/typings/apiTypings'
import { ValueChangeFn } from '@utils/modelmgr'
import { CardEx } from '@utils/ui/CardEx'
import { TextField } from '@utils/ui/fields/TextField'
import { Grid2 as Grid } from '@mui/material'
import React from 'react'

export interface PimArtikelProduktProps {
  produkt: PimProduktJson
  onChange?: ValueChangeFn<SeArtikelJson>
  errors?: any
  readonly?: boolean
}

/**
 * PimArtikelProdukt wird im EigenlistungsArtikelPflegeView editierbar
 * und im PimArtikelView als readonly genutzt.
 * @param produkt
 * @param onChange
 * @param errors
 * @param readonly
 * @constructor
 */
export const PimArtikelProdukt = ({
  produkt,
  onChange,
  errors,
  readonly
}: PimArtikelProduktProps) => {
  return (
    <CardEx title="Produkt" name="Pimprodukt">
      <Grid container spacing={2}>
        {
          <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
            <TextField
              label="Hage-ProduktNummer:"
              value={produkt.version.hageProduktNummer}
              onChange={onChange}
              name="pimArtikel.version.pimProdukt.version.hageProduktNummer"
              disabled={readonly}
              fullWidth
            />
          </Grid>
        }
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
          <TextField
            label="Zolltarif-Nummer"
            value={produkt.version?.zolltarifnummer}
            name="pimArtikel.version.pimProdukt.version.zolltarifnummer"
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
          <TextField
            label="Statistische Warennummer"
            value={produkt.version?.statistischeWarennummer}
            name="pimArtikel.version.pimProdukt.version.statistischeWarennummer"
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
          <TextField
            label="Marke"
            value={produkt.version?.marke}
            name="pimArtikel.version.pimProdukt.version.marke"
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
          <TextField
            label="Vertriebskanal"
            value={produkt.version?.vertriebskanal}
            name="pimArtikel.version.pimProdukt.version.vertriebskanal"
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
          <TextField
            label="Primärklassifikation"
            value={produkt.version?.sprache?.primaerklassifikation}
            name="pimArtikel.version.pimProdukt.version.sprache.primaerklassifikation"
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
      </Grid>
    </CardEx>
  )
}

export const PimArtikelProduktMemo = React.memo(PimArtikelProdukt)
