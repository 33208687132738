import { Grid2 as Grid, Typography } from '@mui/material'
import { DzLieferantJson, ZeLieferantJson } from '@one/typings/apiTypings'
import { CardEx } from '@utils/ui/CardEx'
import { StaticField } from '@utils/ui/fields/StaticField'

export type LieferantenLieferbedingungProps = {
  lieferant: DzLieferantJson | ZeLieferantJson
}

export const LieferantenLieferbedingung = ({ lieferant }: LieferantenLieferbedingungProps) => (
  <CardEx title="Lieferbedingungen">
    <Grid container spacing={2}>
      <Grid size={{xs:4}}>
        <StaticField label="EDI" value={lieferant.edi ? 'Ja' : 'Nein'} />
      </Grid>
      <Grid size={{xs:4}}>
        <StaticField label="EDI-Dsp" value={lieferant.ediDsp} empty />
      </Grid>
      <Grid size={{xs:4}}/>
      <Grid size={{xs:4}}>
        <StaticField label="Lieferbedingung" value={lieferant.lieferbedingung} empty />
      </Grid>
      <Grid size={{xs:4}}>
        <StaticField label="Mindestbestellwert" value={lieferant.mindestBestellwert} empty />
      </Grid>
      <Grid size={{xs:4}}/>
      <Grid size={{xs:4}}>
        <StaticField label="Zahlungsziel" value={lieferant.zahlungsZiel} empty />
      </Grid>
      <Grid size={{xs:4}}>
        <StaticField label="Währung" value={lieferant.waehrung} empty />
      </Grid>
      <Grid size={{xs:4}}/>
      <Grid size={{xs:4}}>
        <StaticField label="Freihausgrenze" value={lieferant.freihausgrenze} empty />
      </Grid>
      <Grid size={{xs:4}}>
        <StaticField label="Freihausmenge" value={lieferant.freihausmenge} empty />
      </Grid>
      <Grid size={{xs:4}}/>
      {lieferant.abholAdresse && (
        <>
          <Grid size={{xs:6}}>
            <StaticField label="Abhol-Adresse">
              <Typography>{lieferant.abholAdresse?.strasse || ''}</Typography>
              <Typography>{`${lieferant.abholAdresse?.plz || ''} ${
                lieferant.abholAdresse?.ort || ''
              }`}</Typography>
            </StaticField>
          </Grid>
          <Grid size={{xs:6}}/>
        </>
      )}
    </Grid>
  </CardEx>
)
