import { api } from '@one/api'
import { WarengruppeDisplayJson, WarengruppeSearcherCriteriaJson } from '@one/typings/apiTypings'
import { useSearcherState } from '@utils/searcher'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { WarengruppenUebersichtParams } from './WarengruppenUebersichtParams'
import { WarengruppenUebersichtTable } from './WarengruppenUebersichtTable'

export const WarengruppenUebersichtView = () => {
  const { criteria, onCriteriaChange, result, search } = useSearcherState<
    WarengruppeSearcherCriteriaJson,
    WarengruppeDisplayJson
  >({
    api,
    url: '/warengruppe'
  })

  return (
    <Frame space>
      <FrameRow>
        <WarengruppenUebersichtParams
          onSearch={search}
          value={criteria}
          onChange={onCriteriaChange}
        />
      </FrameRow>
      <FrameBody>
        <WarengruppenUebersichtTable result={result} reload={search} />
      </FrameBody>
    </Frame>
  )
}
