import { alpha, Grid, Icon, Paper, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { hagebauGreen } from '@utils/ui/styles'

// Styling definitions
const useStyles = makeStyles()((theme: any) => {
  return {
    box: {
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      whiteSpace: 'nowrap',
      overflow: 'hidden'
    },
    left: {
      width: '44%',
      height: '100%',
      textAlign: 'center',
      padding: '1em 1em',
      textTransform: 'capitalize',
      alignItems: 'center',
      flexDirection: 'column',
      display: 'flex',
      backgroundColor: theme.darkMode ? alpha(hagebauGreen, 0.42) : alpha(hagebauGreen, 1.0),
      color: '#fff'
    },
    right: {
      height: '100%',
      backgroundColor: theme.darkMode ? alpha(hagebauGreen, 0.1) : alpha(hagebauGreen, 0.3),
      padding: '1em',
      flexDirection: 'column',
      display: 'flex',
      alignItems: 'flex-end',
      flexGrow: 1
    },
    title: {
      display: 'block'
    },
    icon: {
      verticalAlign: 'bottom',
      fontSize: '2em',
      display: 'block',
      color: 'rgba(255,255,255,0.8)'
    }
  }
})

export interface StatusItemWrapperProps {
  name?: string
  title: string
  count: string | number
  icon: string
  children: React.ReactNode
}

export const StatusItemWrapper = ({
  children,
  title,
  count,
  name,
  icon
}: StatusItemWrapperProps) => {
  const { classes } = useStyles()
  if (count == null || count === 0 || count === '') {
    return null
  }

  return (
    <Grid item xl={2} lg={3} md={4} sm={6} xs={12}>
      <Paper data-name={name} className={classes.box} elevation={5}>
        <div className={classes.left}>
          <div className={classes.title}>
            <Typography fontSize="12px">{title}</Typography>
          </div>
          <div>
            <Icon className={classes.icon}>{icon}</Icon>
          </div>
        </div>
        <div className={classes.right}>{children}</div>
      </Paper>
    </Grid>
  )
}
