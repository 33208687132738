import { Grid2 as Grid } from '@mui/material'
import { HkmEnum } from '@one/enums/HkmEnum'
import { DzLieferantJson, ZeLieferantJson } from '@one/typings/apiTypings'
import { useEnums } from '@utils/enums'
import { CardEx } from '@utils/ui/CardEx'
import { StaticField } from '@utils/ui/fields/StaticField'

export type LieferantBezeichnungProps = {
  lieferant: DzLieferantJson | ZeLieferantJson
  type: 'dz' | 'ze'
}

export const LieferantBezeichnung = ({ lieferant, type }: LieferantBezeichnungProps) => {
  const { et } = useEnums()
  const dzLieferant = lieferant as DzLieferantJson
  const landKategorie = dzLieferant.landKategorie
  return (
    <CardEx title="Lieferant">
      <Grid container spacing={2}>
        <Grid size={{xs:4}}>
          <StaticField label="Name 1" value={lieferant.hauptAdresse?.name1} empty />
        </Grid>
        <Grid size={{xs:4}}>
          <StaticField label="Name 2" value={lieferant.hauptAdresse?.name2} empty />
        </Grid>
        <Grid size={{xs:4}}>
          <StaticField label="Name 3" value={lieferant.hauptAdresse?.name3} empty />
        </Grid>
        <Grid size={{xs:4}}>
          <StaticField label="Nummer" value={lieferant.nummer} empty />
        </Grid>
        <Grid size={{xs:4}}>
          <StaticField label="ILN" value={lieferant.iln} empty />
        </Grid>
        <Grid size={{xs:4}} />
        {type == 'dz' && (
          <Grid size={{xs:4}}>
            <StaticField label="Unsere Kundennummer" value={dzLieferant.unsereKundennummer} empty />
          </Grid>
        )}
        <Grid size={{xs:4}}>
          <StaticField label="Ust ID Nummer" value={lieferant.ustIDNummer} empty />
        </Grid>
        {type == 'dz' && (
          <Grid size={{xs:4}}>
            <StaticField label="Steuernummer" value={dzLieferant.steuernummer} empty />
          </Grid>
        )}
        <Grid size={{xs:4}}>
          <StaticField label="Branche" value={lieferant.branche} empty />
        </Grid>
        <Grid size={{xs:4}}>
          <StaticField label="Suchbegriff" value={lieferant.suchbegriff} empty />
        </Grid>
        <Grid size={{xs:4}}>
          <StaticField
            label="Lieferantenart"
            value={et(HkmEnum.LieferantArt, lieferant.lieferantArt)}
            empty
          />
        </Grid>
        <Grid size={{xs:4}}>
          <StaticField label="Eigenlistung" value={lieferant.eigenlistung ? 'Ja' : 'Nein'} empty />
        </Grid>
        {type == 'dz' && (
          <Grid size={{xs:4}}>
            <StaticField
              label="Länderkategorie"
              value={et(HkmEnum.LieferantLandKategorie, landKategorie)}
              empty
            />
          </Grid>
        )}
      </Grid>
    </CardEx>
  )
}
