import { Grid2 as Grid, Typography } from '@mui/material'
import { AppPaths } from '@one/AppPaths'
import { BetriebstypKontextField } from '@one/components/common/BetriebstypKontextField'
import { PreisEbeneField } from '@one/components/common/PreisEbeneField'
import { StandortField } from '@one/components/common/StandortField'
import { AppContext } from '@utils/ui/App/AppContext'
import { CardEx } from '@utils/ui/CardEx'
import { StaticField } from '@utils/ui/fields/StaticField'
import { useContext } from 'react'
import { makeStyles } from 'tss-react/mui'
import { VkPreiseMasseBearbeitenKopfJsonEx } from './model/VkPreisMassenKopfModel'

export interface VkPreisMassenPflegeKopfProps {
  model: VkPreiseMasseBearbeitenKopfJsonEx
  setStandortId: (id: any) => void
}

// Styling definitions
const useStyles = makeStyles()({
  fields: {
    marginTop: '-32px'
  },
  title: {
    flexGrow: 1
  }
})

export const VkPreisMassenPflegeKopf = ({ model, setStandortId }: VkPreisMassenPflegeKopfProps) => {
  const { classes } = useStyles()
  const { isPIMModel } = useContext(AppContext)

  const header = (
    <Grid container>
      <Grid className={classes.title}>
        <Typography variant="h6">
          {model.forEinzelhandel
            ? 'Einzelhandelverkaufspreisliste'
            : 'Fachhandelverkaufspreisliste'}
        </Typography>
      </Grid>
    </Grid>
  )

  const actions = null

  return (
    <CardEx
      backLink
      overviewLink={AppPaths.VkPreisUebersicht[0]}
      header={header}
      bottomActions={actions}
    >
      <Grid container spacing={3} direction="row" className={classes.fields}>
        <Grid size={{xs:12,md:5,lg:3}}>
          <StaticField
            label="Name der Preisliste"
            // name="name"
            value={model.name || ''}
            // size="small"
            fullWidth
          />
        </Grid>
        <Grid size={{xs:12,md:5,lg:3}}>
          <StandortField
            label="Standort"
            value={model.standortId}
            onChange={setStandortId}
            options={model.standorte}
            emptyText="Alle Preise"
            withButtons
            fullWidth
          />
        </Grid>
        <Grid size={{xs:12,lg:3}}>
          <BetriebstypKontextField owner={model} />
        </Grid>
        {isPIMModel && (
          <Grid size={{xs:12,lg:3}}>
            <PreisEbeneField
              label="Preisebene"
              name="preisEbeneId"
              value={model.preisEbeneId}
              kontext={model.kontext}
              disabled
            />
          </Grid>
        )}
      </Grid>
    </CardEx>
  )
}
