import { Add, CalendarViewDay, Dehaze } from '@mui/icons-material'
import { Grid2 as Grid } from '@mui/material'
import { AppPaths } from '@one/AppPaths'
import { UserRoles } from '@one/UserRoles'
import { api } from '@one/api'
import { LieferantCell } from '@one/components/common/LieferantCell'
import { WarengruppeNameCell, WarengruppeNrCell } from '@one/components/common/WarengruppeCell'
import { formatLieferant } from '@one/components/common/formatters'
import {
  HandelTyp,
  VkPreisGruppeJson,
  VkPreisgruppeRegelJson,
  VkPreisgruppeRegelKopfJson,
  VkPreisgruppeRegelnBearbeitenJson
} from '@one/typings/apiTypings'
import { useLocalState } from '@utils/localState'
import { useModelMgr } from '@utils/modelmgr'
import { Action } from '@utils/ui/Action'
import { RouteContext } from '@utils/ui/App/AppRoute'
import { Button } from '@utils/ui/Buttons/Button'
import { ButtonRow } from '@utils/ui/Buttons/ButtonRow'
import { ReloadButton } from '@utils/ui/Buttons/ReloadButton'
import { SaveButton } from '@utils/ui/Buttons/SaveButton'
import { CardEx } from '@utils/ui/CardEx'
import { Column, RowGrouper } from '@utils/ui/DataTable/DataTable'
import { DataTableAction } from '@utils/ui/DataTable/DataTableBody'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { useDialogAnker } from '@utils/ui/DialogAnker'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { useMessageDialog } from '@utils/ui/MessageDialog'
import { SelectField } from '@utils/ui/fields/SelectField'
import { StaticField } from '@utils/ui/fields/StaticField'
import { StatePlane } from '@utils/ui/planes/StatePlane'
import { useSnackbarEx } from '@utils/ui/snackbarex'
import { aidOf, buildMap } from '@utils/utils'
import { useCallback, useContext, useMemo } from 'react'
import { VkPreisgruppeRegelViewDialog } from './VkPreisgruppeRegelDialog'
import { UploadButton } from '@utils/ui/Buttons/UploadButton'

export interface VkPreisgruppeRegelKopfJsonTempId extends VkPreisgruppeRegelKopfJson {
  regeln: (VkPreisgruppeRegelJson & { tmpId: string })[]
}

export const VkPreisgruppenRegelnView = () => {
  const { standortgruppeId, standortId } = useContext(RouteContext) as any
  const [DlgAnker, showDlg] = useDialogAnker()
  const [grouping, setGrouping] = useLocalState('VkStandardPreisgruppenView.grouping', false)

  const { askToConfirm } = useMessageDialog()
  const { enqueError } = useSnackbarEx()

  const { model, isChanged, envelope, isNew, save, reload, updModel, uiLock, onValueChange } =
    useModelMgr<
      VkPreisgruppeRegelKopfJson,
      VkPreisgruppeRegelKopfJsonTempId,
      VkPreisgruppeRegelnBearbeitenJson
    >({
      restps: { standortgruppeId, standortId },
      noid: true,
      api,
      title: 'VK-Preisgruppenpflege',
      rest: '/vkpreisgrupperegel',
      unwrapField: 'kopf'
    })

  const preisgruppenMap = buildMap(envelope.preisgruppen, (p) => p.id)

  const columns = useMemo<Column<VkPreisgruppeRegelJson>[]>(
    () => [
      {
        header: 'Lieferant',
        field: ['lieferant.name1'],
        valueGetter: (row) => formatLieferant(row.lieferant),
        body: (row) => <LieferantCell lieferant={row.lieferant} asLink />,
        sortable: true
      },
      {
        field: 'warengruppe.nummer',
        header: 'WG-Nr',
        off: grouping,
        body: (row) =>
          row.warengruppe ? (
            <WarengruppeNrCell warengruppe={row.warengruppe} />
          ) : (
            <small>übergreifend</small>
          )
      },
      {
        field: 'warengruppe.name',
        header: 'WG-Name',
        body: (row) =>
          row.warengruppe ? (
            <WarengruppeNameCell warengruppe={row.warengruppe} />
          ) : (
            <small>übergreifend</small>
          )
      },
      {
        field: 'defaultPreisgruppeId',
        header: 'Preisgruppe',
        tooltip: (row) => {
          const preisgruppe = preisgruppenMap.get(row.preisgruppeId)
          return preisgruppe.bezeichnung
        },
        valueGetter: (row) => {
          const preisgruppe = preisgruppenMap.get(row.preisgruppeId)
          return preisgruppe?.name ?? ''
        }
      },
      {
        field: 'audit.createdBy',
        header: 'Erstellt von'
      },
      {
        field: 'audit.createdOn',
        header: 'Erstellungsdatum',
        type: 'datetime'
      },
      {
        field: 'audit.updatedBy',
        header: 'Aktualisiert von'
      },
      {
        field: 'audit.updatedOn',
        header: 'Aktualisierungsdatum',
        type: 'datetime'
      }
    ],
    [grouping, preisgruppenMap]
  )

  const onDefaultRule = useCallback(
    (prevRule = null) => {
      showDlg((visible, onClose) => (
        <VkPreisgruppeRegelViewDialog
          open={visible}
          value={prevRule}
          preisGruppen={envelope.preisgruppen}
          onClose={onClose((newRule) => {
            if (newRule) {
              updModel((m) => {
                const dups = m.regeln.filter(
                  (r) =>
                    r.lieferant.id === newRule.lieferant.id &&
                    r.warengruppe?.id === newRule.warengruppe?.id
                ).length
                if (prevRule ? dups > 1 : dups > 0) {
                  enqueError('Diese Regel gibt es schon!')
                  return m
                }
                if (prevRule) {
                  return {
                    ...m,
                    regeln: m.regeln.map((r) => (aidOf(r) === aidOf(newRule) ? newRule : r))
                  }
                }
                return {
                  ...m,
                  regeln: [...m.regeln, newRule]
                }
              })
            }
          })}
        />
      ))
    },
    [showDlg, envelope.preisgruppen, updModel, enqueError]
  )

  const onDeleteRule = useCallback(
    (rule) => {
      askToConfirm({
        title: 'Löschen einer VK-Standard Preisgruppen Regel',
        confirmLabel: 'Ja',
        message:
          'Sind Sie sicher, dass diese Regel gelöscht werden soll? Die Regel wird mit dem nächsten Speichern gelöscht.',
        onConfirm: () =>
          updModel((m) => {
            return {
              ...m,
              regeln: m.regeln.filter((r) => {
                return r.id !== rule.id || r.tmpId !== rule.tmpId
              })
            }
          })
      })
    },
    [askToConfirm, updModel]
  )

  const actions = useMemo<Action[]>(
    () => [
      {
        tooltip: 'Gruppieren an/aus',
        icon: grouping ? <CalendarViewDay /> : <Dehaze />,
        onClick: () => setGrouping(!grouping)
      },
      {
        role: UserRoles.STANDORT_EDITOR,
        tooltip: 'Neu Regel anlegen',
        name: 'Standard-Preisgruppen-Regel-neu',
        icon: <Add />,
        onClick: () => onDefaultRule()
      }
    ],
    [onDefaultRule, grouping, setGrouping]
  )

  const tableActions = useMemo(
    (): DataTableAction[] => [
      {
        role: UserRoles.STANDORT_EDITOR,
        icon: 'edit',
        direct: true,
        handler: (row) => onDefaultRule(row)
      },
      {
        role: UserRoles.STANDORT_EDITOR,
        icon: 'delete',
        direct: true,
        handler: (row) => onDeleteRule(row)
      }
    ],
    [onDefaultRule, onDeleteRule]
  )

  const groupBy = useMemo(
    (): RowGrouper =>
      grouping
        ? {
            field: 'warengruppe.name',
            header: 'WG-Name',
            groupEmpty: true,
            body: (row) =>
              row.warengruppe ? (
                <span>{row.warengruppe.name} </span>
              ) : (
                <span>Warengruppen übergreifend</span>
              )
          }
        : null,
    [grouping]
  )

  const owner =
    (envelope?.standortgruppe &&
      envelope.standortgruppe.nr + ': ' + envelope.standortgruppe.name) ||
    (envelope?.standort && envelope.standort.nr + ': ' + envelope.standort.name) ||
    'Gesellschafter'

  const label =
    (envelope?.standort && 'Ebene Standort') ||
    (envelope?.standortgruppe && 'Ebene Standortgruppe') ||
    'Ebene'

  const global = envelope.standort == null && envelope.standortgruppe == null

  const eh = envelope.handelTyp === HandelTyp.EH

  const topactions = [
    <UploadButton
      key="vk-Preisgruppenregel-upload"
      label="Vk-Preisgruppenregel import"
      tooltip="Eine Datei mit Vk-Preisgruppenregel hochladen und einspielen"
      api={api}
      accept="application/json"
      path="vkpreisgrupperegel/upload"
      // brauchen wir einen refresh?
      //            onComplete={refresh}
    />
  ] as any

  return (
    <StatePlane uiLock={uiLock}>
      <Frame space>
        <FrameRow>
          <CardEx backLink title="VK-Preisgruppen-Regeln" topActions={topactions}>
            <Grid container spacing={2} direction="row">
              <Grid size={{xs:12,sm:4,md:3,lg:2}}>
                <StaticField label={label} value={owner} />
              </Grid>
              {eh && (
                <Grid size={{xs:12,sm:5,md:5,lg:3}}>
                  <SelectField
                    name="defaultEinzelhandelPreisgruppeId"
                    label="Standard VK-Preisgruppe"
                    // error={errors.defaultPreisgruppeId}
                    value={model.defaultEinzelhandelPreisgruppeId}
                    optionValue="id"
                    options={envelope.preisgruppen}
                    onChange={onValueChange}
                    renderItem={(pg: VkPreisGruppeJson) => pg.name + ' - ' + pg.bezeichnung}
                    fullWidth
                    required={global}
                    disabled={!global}
                  />
                </Grid>
              )}
              {eh && !global && (
                <Grid size={{xs:12,sm:5,md:4,lg:3}} alignSelf="flex-end">
                  <Button
                    name="gesellschafterregeln"
                    label="Gesellschafterweite Regeln"
                    variant="text"
                    to={AppPaths.VkPreisgruppenRegelnByGesellschafterFn()}
                  />
                </Grid>
              )}
            </Grid>
          </CardEx>
        </FrameRow>
        <FrameBody>
          <DataTableCard
            name="VkPreisgruppenRegeln"
            title="Regelübersicht"
            columns={columns}
            actions={tableActions}
            topActions={actions}
            value={model?.regeln ?? []}
            emptyMessage="Es sind noch keine Regeln gepflegt"
            initialOrderBy={['lieferant.name1', 'warengruppe.nummer']}
            dense
            filterMode="both"
            groupBy={groupBy}
          />
        </FrameBody>
        <FrameRow>
          <ButtonRow>
            <SaveButton onClickVoid={save} isNew={isNew} isChanged={isChanged} />
            <ReloadButton onClick={reload} isNew={isNew} isChanged={isChanged} />
          </ButtonRow>
        </FrameRow>
      </Frame>
      <DlgAnker />
    </StatePlane>
  )
}
