import { Grid2 as Grid } from '@mui/material'
import { ArtikelBetriebstypField } from '@one/components/common/ArtikelBetriebstypField'
import { NeonKontextField } from '@one/components/common/NeonKontextField'
import { StandortField } from '@one/components/common/StandortField'
import {
  ArtikelBetriebstyp,
  LieferantDisplayJson,
  NeonKontext,
  PreisEbeneDisplayJson
} from '@one/typings/apiTypings'
import { AppContext } from '@utils/ui/App/AppContext'
import { CardEx } from '@utils/ui/CardEx'
import { AutocompleteEx } from '@utils/ui/fields/AutocompleteEx'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { compareStrings } from '@utils/utils'
import { useCallback, useContext, useState } from 'react'

export interface HauptlieferantPflegeKopfProps {
  lieferanten: LieferantDisplayJson[]
  preisEbenen: PreisEbeneDisplayJson[]
  onSearch: (lieferant: any, preisEbeneId: string) => void
  onFilterChange: (e: any) => void
  filters: any
  kontext?: NeonKontext
  betriebstyp?: ArtikelBetriebstyp
  onChangeKontext: (kontext: NeonKontext) => void
  onChangeBetriebstyp: (betriebstyp: ArtikelBetriebstyp) => void
  isChanged: boolean
}

export const HauptlieferantPflegeKopf = ({
  lieferanten,
  preisEbenen,
  onSearch,
  onFilterChange,
  filters,
  kontext,
  betriebstyp,
  onChangeKontext,
  onChangeBetriebstyp,
  isChanged
}: HauptlieferantPflegeKopfProps) => {
  const { isPIMModel } = useContext(AppContext)
  const [lieferant, onChangeLieferant] = useState()
  const [preisEbene, onChangePreisEbene] = useState()

  const handleLieferantChange = useCallback(
    (item) => {
      onChangeLieferant(item.target.value)
      onSearch(item.target.value, preisEbene)
    },
    [preisEbene, onSearch]
  )

  const handlePreisEbeneChange = useCallback(
    ({ value }) => {
      onChangePreisEbene(value)
      onSearch(lieferant, value)
    },
    [lieferant, onSearch]
  )

  return (
    <CardEx title="Hauptlieferanten-Pflege" backLink>
      <Grid container spacing={3} paddingBottom={2}>
        <Grid size={{xs:12,sm:6,lg:3}}>
          <AutocompleteEx
            fullWidth
            label="Lieferanten Filter"
            name="id"
            value={lieferant}
            options={
              lieferanten ? lieferanten.sort((a, b) => compareStrings(a.nummer, b.nummer)) : []
            }
            onChange={handleLieferantChange}
            emptyText="Alle"
            optionValue="id"
            optionLabel={(i) => `(${i.nummer}) ${i.name1}`}
          />
        </Grid>
        <Grid size={{xs:12,sm:6,lg:3}}>
          {isPIMModel ? (
            <NeonKontextField
              label="Kontext"
              value={kontext}
              onChange={(e) => onChangeKontext(e.target.value as NeonKontext)}
              required
              disabled={isChanged}
            />
          ) : (
            <ArtikelBetriebstypField
              label="Betriebstyp"
              value={betriebstyp}
              onChange={(e) => onChangeBetriebstyp(e.target.value as ArtikelBetriebstyp)}
              required
              disabled={isChanged}
            />
          )}
        </Grid>
        <Grid size={{xs:12,sm:6,lg:3}}>
          <StandortField
            label="Standort"
            value={preisEbene}
            onChange={handlePreisEbeneChange}
            options={preisEbenen}
            readonly={false}
            emptyText="Übergreifend"
            withButtons
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} direction="row">
        <Grid>
          <Checkbox
            label="Artikel ohne Hauptlieferant"
            size="small"
            name="filterWithoutHauptlieferant"
            checked={filters.filterWithoutHauptlieferant}
            onChange={onFilterChange}
            color="default"
          />
        </Grid>
        <Grid>
          <Checkbox
            label="Artikel mit standortspezifischen Lieferanten"
            size="small"
            name="filterWithAltHauptlieferant"
            checked={filters.filterWithAltHauptlieferant}
            onChange={onFilterChange}
            color="default"
          />
        </Grid>
        <Grid>
          <Checkbox
            label="Artikel mit Lieferanten am gewählten Standort"
            size="small"
            name="filterPreisebene"
            checked={filters.filterPreisebene}
            onChange={onFilterChange}
            color="default"
            disabled={!preisEbene}
          />
        </Grid>
      </Grid>
    </CardEx>
  )
}
