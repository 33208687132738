import { Paper } from '@mui/material'
import { useServerIdent } from '@one/ServerIdent'
import { makeStyles } from 'tss-react/mui'
import packageJson from '../package.json'
import { ReactNode } from 'react'

export interface DefaultFrameProps {
  children: ReactNode
}

// Styling definitions
const useStyles = makeStyles()((theme: any) => ({
  container: {
    overflow: 'auto',
    position: 'relative',
    width: '100%',
    height: '100%',
    margin: 0,
    padding: 0,
    backgroundColor: theme.darkMode ? null : 'white'
  },
  background: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
    '& >div:first-of-type': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '20px 30px'
      // '& >img:last-child': {
      //   filter: theme.darkMode ? 'hue-rotate(180deg) invert(87%)' : null
      // }
    },
    '& >div:nth-of-type(2)': {
      flexGrow: 1
    },
    '& >div:last-child': {
      fontSize: '54px',
      fontWeight: 'bold',
      padding: '50px',
      textAlign: 'right'
    }
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%'
  },
  ident: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}))

/**
 * Hintergrund
 *
 */
export const DefaultFrame = ({ children }: DefaultFrameProps) => {
  const { classes } = useStyles()

  const [ident] = useServerIdent()

  return (
    <div className={classes.container}>
      <Paper sx={{ display: { xs: 'none', md: 'block' } }}>
        <div className={classes.background}>
          <div>
            <img src="logo.png" alt="hagebau" width="153" />
            <img src="NEON_logo.png" height="120" alt="hagebau neon" />
          </div>
          <div />
          <div className={classes.ident}>
            <div>
              <div style={{ fontSize: '40%', lineHeight: 1 }}>
                <div style={{ fontSize: '60%' }}>{ident?.envName || 'Unbekannt'}</div>
                <span>Version </span> {ident?.version || packageJson.version}
              </div>
            </div>
            <div>ONE Frontend</div>
          </div>
        </div>
      </Paper>
      <div className={classes.content}>{children}</div>
    </div>
  )
}
