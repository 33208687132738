import { Grid2 as Grid } from '@mui/material'
import { LieferantenVerteilungDefinitionSearcherCriteriaJson } from '@one/typings/apiTypings'
import { ChangeFunction } from '@utils/formstate'
import { SearchButton } from '@utils/ui/Buttons/SearchButton'
import { CardEx } from '@utils/ui/CardEx'
import { ShortCutHandler } from '@utils/ui/ShortCutHandler'
import { TextField } from '@utils/ui/fields/TextField'

export interface LieferantenVerteilerUebersichtParamsProps {
  value: LieferantenVerteilungDefinitionSearcherCriteriaJson
  onSearch: VoidFunction
  onChange: ChangeFunction<LieferantenVerteilungDefinitionSearcherCriteriaJson>
}

export const LieferantenVerteilerUebersichtParams = ({
  value,
  onSearch,
  onChange
}: LieferantenVerteilerUebersichtParamsProps) => {
  const shortcuts = {
    Enter: onSearch
  }

  return (
    <ShortCutHandler shortcuts={shortcuts}>
      <CardEx
        backLink
        collapsiable
        title="Lieferantenverteiler"
        bottomActions={<SearchButton onClick={onSearch} />}
      >
        <Grid container spacing={3}>
          <Grid size={{xs:12,sm:6,lg:3}}>
            <TextField fullWidth label="Name" name="name" value={value.name} onChange={onChange} />
          </Grid>
        </Grid>
      </CardEx>
    </ShortCutHandler>
  )
}
