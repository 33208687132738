import { TableRows } from '@mui/icons-material'
import { Grid2 as Grid, Typography } from '@mui/material'
import { AppPaths } from '@one/AppPaths'
import { ArtikelPager } from '@one/components/common/ArtikelPager'
import { BetriebstypKontextField } from '@one/components/common/BetriebstypKontextField'
import { formatWarengruppe } from '@one/components/common/WarengruppeCell'
import {
  ArtikelBetriebstyp,
  ArtikelDisplayJson,
  EkKonditionenListeEintragDisplayJson,
  EkKonditionenRabattgruppenViewJson,
  LieferantDisplayJson,
  NeonKontext
} from '@one/typings/apiTypings'
import { Action } from '@utils/ui/Action'
import { AppContext } from '@utils/ui/App/AppContext'
import { Button } from '@utils/ui/Buttons/Button'
import { CardEx } from '@utils/ui/CardEx'
import { StaticField } from '@utils/ui/fields/StaticField'
import { formatValues } from '@utils/utils'
import { memo, useContext } from 'react'
import { RabattgruppeField } from './fields/RabattgruppeField'
import { EkPreiseUsecase } from './model/EkPreiseUsecase'

export interface EkPreisPflegeKopfProps {
  id?: number
  dispatch: (action: any) => void
  isChanged: boolean
  lieferant: LieferantDisplayJson
  preislistenName?: string
  artikel: ArtikelDisplayJson
  artikelList: EkKonditionenListeEintragDisplayJson[]
  artikelListLoading: boolean
  artikelPos: number
  onSwitchArtikel: (id: number) => void
  ekRabattgruppen?: EkKonditionenRabattgruppenViewJson[]
  artikelRabattgruppeId?: number
  allianzModus: boolean
  abgeschlossen?: boolean
  kontext?: NeonKontext
  betriebstyp?: ArtikelBetriebstyp
}

export const EkPreisPflegeKopf = ({
  id,
  dispatch,
  isChanged,
  lieferant,
  preislistenName,
  artikel,
  artikelList,
  artikelListLoading,
  artikelPos,
  onSwitchArtikel,
  ekRabattgruppen,
  artikelRabattgruppeId,
  allianzModus,
  abgeschlossen,
  kontext,
  betriebstyp
}: EkPreisPflegeKopfProps) => {
  const { isPIMModel } = useContext(AppContext)
  const artikelBez = artikel?.bez1 && formatValues(', ', artikel?.bez1, artikel?.bez2)

  const lieferantName =
    lieferant?.name1 &&
    formatValues(' ', lieferant?.name1, lieferant?.name2, `(${lieferant?.nummer})`)

  const header = (
    <>
      <Typography variant="h6">{`Einkaufspreise für Artikel '${artikelBez}'`}</Typography>
      {artikel?.archiviert && (
        <Typography variant="subtitle2" color="#ff0000" marginTop={-1}>
          Archivierter Artikel
        </Typography>
      )}
    </>
  )

  const topActions = [
    <Button
      key="artikel"
      label="Artikelansicht"
      variant="outlined"
      size="small"
      to={artikel?.id ? AppPaths.ArtikelFn(artikel.id) : null}
      disabled={artikel == null}
    />,
    <ArtikelPager
      key="pager"
      artikelList={artikelList}
      artikelListLoading={artikelListLoading}
      artikelPos={artikelPos}
      onSwitchArtikel={onSwitchArtikel}
      disabled={isChanged}
    />
  ] as Action[]

  return (
    <CardEx
      backLink
      overviewLink={AppPaths.EkPreisUebersicht}
      navLinks={[
        {
          name: 'nav-massenpflege',
          icon: <TableRows />,
          label: 'Zur Massenpflege',
          to: AppPaths.EkPreisMassenPflegeFn(id)
        }
      ]}
      header={header}
      topActions={topActions}
    >
      <Grid container spacing={2} direction="row">
        <Grid container direction="row" spacing={1} size={{ xs: 12 }}>
          <Grid size={{ xs: 12, sm: isPIMModel ? 4 : 6 }}>
            <StaticField label="Name der Preisliste" value={preislistenName} fullWidth />
          </Grid>
          <Grid size={{ xs: 12, sm: 2 }}>
            <BetriebstypKontextField betriebsTyp={betriebstyp} kontext={kontext} />
          </Grid>
          <Grid size={{ xs: 12, sm: 4 }}>
            <StaticField label="Lieferant" value={lieferantName} fullWidth />
          </Grid>
        </Grid>

        <Grid container direction="row" spacing={1} size={{ xs: 12 }}>
          <Grid size={{ xs: 6, sm: 3, lg: 2 }}>
            <StaticField
              label="hage-Nr."
              value={artikel?.hageNummer}
              fullWidth
              placeholder="keine"
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 3, lg: 2 }}>
            <StaticField label="Artikel-Nr." value={artikel?.btNummer} fullWidth />
          </Grid>
          <Grid size={{ xs: 6, sm: 3, lg: 2 }}>
            <StaticField label="EAN" value={artikel?.ean} fullWidth />
          </Grid>
          <Grid size={{ xs: 6, sm: 3, lg: 2 }}>
            <StaticField label="IAN" value={artikel?.industrieArtikelNummer} fullWidth />
          </Grid>
          <Grid size={{ xs: 6, md: 3, lg: 2 }}>
            <StaticField
              label="Warengruppe"
              value={formatWarengruppe(artikel?.warengruppe)}
              fullWidth
            />
          </Grid>
          <Grid size={{ xs: 6, md: 3, lg: 2 }}>
            <RabattgruppeField
              label="Artikel EK-Rabattgruppe"
              options={ekRabattgruppen}
              value={artikelRabattgruppeId}
              name="artikelRabattgruppeId"
              onChange={(value) =>
                dispatch({ type: EkPreiseUsecase.SETARTIKELEKRABATTGRUPPE, value })
              }
              readonly={abgeschlossen || artikel == null}
              // allianzModus={allianzModus}
            />
          </Grid>
        </Grid>
      </Grid>
    </CardEx>
  )
}

export const EkPreisPflegeKopfMemo = memo(EkPreisPflegeKopf)
