import { Column } from '@utils/ui/DataTable/DataTable'
import { StandortArtikelRegalplatzJson } from '@one/typings/apiTypings'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { nameOf } from '@utils/utils'

const columns: Column<StandortArtikelRegalplatzJson>[] = [
  {
    field: nameOf<StandortArtikelRegalplatzJson>('standort'),
    header: 'Standort',
    sortable: true,
    valueGetter: (data) => `${data.standort?.nr} - ${data.standort?.name}`
  },
  {
    field: nameOf<StandortArtikelRegalplatzJson>('anzahl'),
    header: 'Anzahl',
    sortable: true
  },
  {
    field: nameOf<StandortArtikelRegalplatzJson>('etikettentyp'),
    header: 'Etikettentyp',
    sortable: true
  },
  {
    field: nameOf<StandortArtikelRegalplatzJson>('lagerbereich'),
    header: 'Lagerbereich',
    sortable: true
  },
  {
    field: nameOf<StandortArtikelRegalplatzJson>('lagerort'),
    header: 'Lagerort',
    sortable: true
  },
  {
    field: nameOf<StandortArtikelRegalplatzJson>('lagerplatz'),
    header: 'Lagerplatz',
    sortable: true
  },
  {
    field: nameOf<StandortArtikelRegalplatzJson>('lagerplatztyp'),
    header: 'Lagerplatztyp',
    sortable: true
  },
  {
    field: nameOf<StandortArtikelRegalplatzJson>('abDatum'),
    header: 'Datum ab',
    type: 'string',
    sortable: true
  }
]

export interface StandortArtikelRegalplatzProps {
  regalplaetze: StandortArtikelRegalplatzJson[]
}

export const StandortArtikelRegalplatz = ({ regalplaetze }: StandortArtikelRegalplatzProps) => (
  <DataTableCard
    name="StandortArtikelRegalplaetze"
    value={regalplaetze}
    title="Regalplätze"
    columns={columns}
    dense
    autoHeight
    autoHeightMax={300}
  />
)
