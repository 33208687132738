/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable react/jsx-props-no-spreading */
import {Button, Dialog, DialogActions, DialogContent, Grid, Typography} from '@mui/material'
import {lightBlue} from '@mui/material/colors'
import {CancelButton} from '@utils/ui/Buttons/CancelButton'
import {Checkbox} from '@utils/ui/fields/Checkbox'
import {useState} from 'react'
import {makeStyles} from 'tss-react/mui'

export interface EkPreisVorgangErstellenDialogProps {
  open: boolean
  onClose: (fn?: () => void) => () => void
  onSave: (callback?: any) => void
  onCheck: (checked: boolean) => void
  onNavigate?: () => void
  onCancel?: () => void
  hatPreisaenderungen: boolean
  confirmLabel?: string
  navigateLabel?: string
}

const useStyles = makeStyles()({
  checkme: {
    display: 'flex',
    width: '100%',
    flexFlow: 'column',
    marginTop: '12px'
  },
  dialogContent: {
    padding: '0 !important'
  },

  paperInfo: {
    borderLeft: `solid 1em ${lightBlue[600]}`
  },

  buttonSevere: {},

  buttons: {
    columnGap: '8px'
  }
})

export const EkPreisVorgangErstellenDialog = ({
  open,
  onSave,
  onClose,
  onCheck,
  onNavigate,
  onCancel = null,
  confirmLabel = 'Speichern',
  navigateLabel = 'Speichern und zur Liste',
  hatPreisaenderungen
}: EkPreisVorgangErstellenDialogProps) => {
  const { classes } = useStyles()
  const [createEkPreisliste, setCreateEkPreisliste] = useState(false)

  const _onCancel = onCancel
  const _confirmColor = classes.buttonSevere

  const paperProps = {
    className: classes.paperInfo
  }

  const message = hatPreisaenderungen
    ? 'Es sind Artikel mit Preisänderungen enthalten. Soll ein EK-Preisvorgang erstellt werden?' // Nur ArtikelAenderung liefert hinweis auf Preisänderung.
    : 'Soll ein EK-Preisvorgang erzeugt werden, wenn Artikel von EK-Preisänderungen betroffen sind?' // Betrifft Artikelselektion und EK-Rabattgruppe!
  const title = 'EK-Vorgang erstellen'

  const onCheckboxClick = () => {
    setCreateEkPreisliste(!createEkPreisliste)
    onCheck(!createEkPreisliste)
  }

  const handleSave = () => {
    onSave()
    onClose()()
  }

  const handleNavigate = () => {
    onNavigate()
    onSave()
    onClose()()
  }

  return (
    <Dialog open={open} PaperProps={paperProps} maxWidth="md">
      <DialogContent className={classes.dialogContent}>
        <Grid
          container
          direction="row"
          wrap="nowrap"
          alignContent="flex-start"
          spacing={3}
          padding="1rem"
        >
          <Grid item container direction="column">
            <Grid item>
              <Typography fontSize="1.4rem">{title}</Typography>
              <Typography>{message}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions className={classes.buttons} disableSpacing>
        <Checkbox
          label="EK Preisvorgang anlegen"
          size="small"
          name="EkPreisvorgang"
          checked={createEkPreisliste}
          onChange={onCheckboxClick}
          color="default"
        />
        <Button variant="contained" className={_confirmColor} onClick={handleSave}>
          {confirmLabel}
        </Button>
        {onNavigate && (
          <Button
            variant="contained"
            className={_confirmColor}
            onClick={handleNavigate}
            disabled={!createEkPreisliste}
          >
            {navigateLabel}
          </Button>
        )}
        <CancelButton autoFocus onClick={onClose(_onCancel)} />
      </DialogActions>
    </Dialog>
  )
}
