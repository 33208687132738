/* eslint-disable react/jsx-props-no-spreading */
import {api} from '@one/api'
import {LieferantField} from '@one/components/Lieferant/LieferantField'
import {EventNames} from '@one/EventNames'
import {CacheStatus, useDataCache} from '@utils/datacache'

export interface LieferantAboFieldProps {
  name?: string
  label?: string
  placeholder?: string
  value?: any
  valueAsId?: boolean
  onChange?: (e: any) => void
  disabled?: boolean
  error?: string | boolean
  required?: boolean
}

export const LieferantAboField = ({
  value,
  valueAsId,
  label,
  name,
  onChange,
  placeholder,
  disabled = false,
  error = '',
  required
}: LieferantAboFieldProps) => {
  const lieferantCache = useDataCache({
    api,
    refreshEvents: EventNames.ARTIKELABO,
    datakey: `lieferanten`,
    rest: '/lieferant/uebersichtAnzeigen/abonnierte',
    method: 'POST',
    // data: { abonnierte: true },
    field: 'searcher.result',
    decorator: (m: any) => ({ lieferant: m, info: 'Abonnierte (Vorschläge)' })
  })

  return (
    <LieferantField
      fullWidth
      loading={lieferantCache.status === CacheStatus.loading}
      label={label}
      placeholder={placeholder}
      defaultSugg={lieferantCache.data}
      value={value}
      name={name}
      valueAsId={valueAsId}
      onChange={onChange}
      disabled={disabled}
      error={error}
      modus="dezentral"
      required={required}
    />
  )
}
