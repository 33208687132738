import { EventNames } from '@one/EventNames'
import { api } from '@one/api'
import {
  VkPreiseBearbeitenJson,
  VkPreisListeDisplayJson,
  VkPreisListeSearcherCriteriaJson
} from '@one/typings/apiTypings'
import { useApiCaller } from '@utils/apicaller'
import { useObserver } from '@utils/observer'
import { useSearcherState } from '@utils/searcher'
import { RouteContext } from '@utils/ui/App/AppRoute'
import { AppRouteCtx } from '@utils/ui/App/AppRouteCtx'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { useMessageDialog } from '@utils/ui/MessageDialog'
import { arrayItemReplace, asNumberArray } from '@utils/utils'
import { useCallback, useContext, useEffect } from 'react'
import { VkPreisUebersichtParams } from './VkPreisUebersichtParams'
import { VkPreisUebersichtTable } from './VkPreisUebersichtTable'

export const VkPreisUebersichtView = () => {
  const { preisListenIds } = useContext(RouteContext) as any

  const { visible } = useContext(AppRouteCtx)

  const { criteria, result, search, searchEx, setResult, onCriteriaChange } = useSearcherState<
    VkPreisListeSearcherCriteriaJson,
    VkPreisListeDisplayJson
  >({
    api,
    url: '/vkpreisliste',
    doOpen: preisListenIds == null
  })

  const { askToConfirm, askToDelete } = useMessageDialog()

  const [apiCall, apiBusy] = useApiCaller(api)

  const onFreigeben = useCallback(
    (row: any) => () => {
      askToConfirm({
        title: 'Freigabe',
        message: 'Diese Preisliste freigeben. Eine Bearbeitung ist danach nicht mehr möglich.',
        onConfirm: () =>
          apiCall<VkPreiseBearbeitenJson>({
            method: 'POST',
            rest: '/vkpreisliste/alleFreigeben',
            params: { id: row.id },
            onErrorMsg: 'Die Preisliste konnte nicht freigegeben werden',
            onSuccessMsg: 'Die Preisliste wurde erfolgreich freigegeben.',
            onSuccess: (data) =>
              setResult((old) => arrayItemReplace(old, (r) => r.id === row.id, data.listeDisplay))
          })
      })
    },
    [apiCall, askToConfirm, setResult]
  )

  const onAbschliessen = useCallback(
    (row: any) => () => {
      askToConfirm({
        title: 'Soll die Preisliste abgeschlossen werden?',
        message: 'Es werden alle Preise abgeschlossen und können dann freigegeben werden.',
        onConfirm: () => {
          apiCall<any>({
            method: 'POST',
            rest: '/vkpreisliste/alleAbschliessen',
            params: { id: row.id },
            onErrorMsg: 'Die Preisliste konnte nicht abgeschlossen werden',
            onSuccessMsg: 'Die Preisliste wurde erfolgreich abgeschlossen.',
            onSuccess: (data) =>
              setResult(arrayItemReplace(result.rows, (r) => r.id === row.id, data.listeDisplay))
          })
        }
      })
    },
    [apiCall, askToConfirm, result, setResult]
  )

  const onDelete = useCallback(
    (row: any) => () => {
      askToDelete({
        title: 'Soll die Preisliste wirklich gelöscht werden?',
        onConfirm: () => {
          apiCall<any>({
            method: 'POST',
            rest: '/vkpreisliste/delete',
            params: { id: row.id },
            onErrorMsg: 'Die Preisliste konnte nicht gelöscht werden.',
            onSuccessMsg: 'Die Preisliste wurde gelöscht.',
            onSuccess: () => setResult(arrayItemReplace(result.rows, (r) => r.id === row.id, null))
          })
        }
      })
    },
    [apiCall, askToDelete, result, setResult]
  )

  useEffect(() => {
    if (preisListenIds) {
      searchEx({ preisListenIds: asNumberArray(preisListenIds) })
    }
  }, [preisListenIds, searchEx])

  useObserver(
    EventNames.VKPREISLISTE,
    () => {
      search()
    },
    visible
  )

  return (
    <Frame space>
      <FrameRow>
        <VkPreisUebersichtParams
          value={criteria}
          onSearch={search}
          onValueChange={onCriteriaChange}
          preislistenIdMode={preisListenIds != null}
        />
      </FrameRow>
      <FrameBody>
        <VkPreisUebersichtTable
          onFreigeben={onFreigeben}
          onAbschliessen={onAbschliessen}
          onDelete={onDelete}
          result={result}
        />
      </FrameBody>
    </Frame>
  )
}
