/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/jsx-props-no-spreading */
import {ArrowDropDown, ArrowDropUp} from '@mui/icons-material'
import {Grid2 as Grid, IconButton, InputAdornment, Popover, TextField} from '@mui/material'
import {TreeItem} from '@mui/x-tree-view/TreeItem'
import {propsShrink} from '@utils/ui/styles'
import {stopPropagation} from '@utils/ui/uiutils'
import {distinctItems, flattenArray, isString, oidOf} from '@utils/utils'
import {useEffect, useRef, useState} from 'react'
import {makeStyles} from 'tss-react/mui'
import {Checkbox} from './Checkbox'
import {ChipContainer} from './ChipContainer'
import {SimpleTreeView} from '@mui/x-tree-view'

export interface TreeSelectFieldProps<T = any> {
  label?: string
  name?: string
  placeholder?: string
  options?: T[]
  getOptionIcon?: (option: T) => any
  getOptionValue?: (option: T) => any
  getOptionLabel?: (option: T) => any
  getOptionSelectable?: (option: T) => any
  getOptionItems?: (option: T) => any
  getOptionTip?: (option: T) => any
  value?: object | any[]
  onChange?: (changed: any) => any
  disabled?: any
  multiSelect?: any
  fullWidth?: boolean
  required?: boolean
  error?: boolean | string
}

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles()((theme: any) => ({
  treeItem: {
    '& .marker svg': {
      fontSize: '18px',
      paddingTop: '2px'
    }
  },
  treeItemLabelWrapper: {
    display: 'flex'
  },
  treeItemLabel: {
    paddingLeft: '5px'
  },
  subTreeItem: {
    '& .marker svg': {
      fontSize: '18px',
      paddingTop: '2px'
    }
    // marginLeft: -10,
    // paddingLeft: 18
    // borderLeft: `1px dashed ${theme.palette.text.primary}`
  }
}))

const collectDefaultExpanded = (options, getOptionItems) => {
  const result = []
  if (options != null) {
    const proc = (o) => {
      if (o != null) {
        if (o.defaultExpanded) {
          result.push(`node-${oidOf(o)}`)
        }
        const items = getOptionItems(o)
        if (items != null) {
          items.forEach(proc)
        }
      }
    }
    options.forEach(proc)
  }
  return result
}

const smallIcon = { padding: 2 }

const TreeSelectItem = ({
  nodeId,
  label,
  icon,
  title,
  className,
  selectable,
  selected,
  onSelect,
  children
}) => {
  const { classes } = useStyles()
  return (
    <TreeItem
      //@ts-ignore
      className={className}
      itemId={nodeId}
      title={title}
      label={
        <Grid container alignItems="center">
          {selectable && (
            <Grid paddingBottom="4px">
              <Checkbox
                size="small"
                style={smallIcon}
                name={nodeId}
                checked={selected}
                // onClick={stopPropagation}
                onChange={onSelect}
                label={
                  <div className={classes.treeItemLabelWrapper}>
                    {icon}
                    <span className={classes.treeItemLabel}>{label}</span>
                  </div>
                }
              />
            </Grid>
          )}
        </Grid>
      }
      // collapseIcon={<ExpandMore />}
      // expandIcon={<ChevronRight />}
    >
      {children}
    </TreeItem>
  )
}

const getOptionItemsFn = (f) => f || ((o) => o.items)

const init = {
  defaultExpanded: [],
  index: new Map(),
  valueIndex: new Map()
}

/**
 * Select one or more items
 *
 * @param {*} label Field label
 * @param {*} name Value name
 * @param {*} placeholder Placeholer
 * @param {*} options Array with Objects to choose from
 * @param {*} value Selected option(s)
 * @param {*} onChange Selection change
 * @param {*} disabled Disabled
 * @param {*} multiSelect Allow multi select
 * @param {*} getOptionIcon Get icon from option, default: undefined
 * @param {*} getOptionValue Get value from option, default: "option"
 * @param {*} getOptionLabel Get label of option, default: "option.label"
 * @param {*} getOptionSelectable Check if option should be selectable, default: "option.selectable". An item is selectable if null/undefined/!==0/!==false
 * @param {*} getOptionItems Get Sub items of option, Default: "option.items"
 * @param getOptionTip
 * @param error
 * @param fullWidth
 * @param required
 * @returns
 */
export const TreeSelectField = ({
  label,
  name,
  placeholder,
  options,
  value,
  onChange,
  disabled,
  multiSelect,
  getOptionIcon,
  getOptionValue,
  getOptionLabel,
  getOptionSelectable,
  getOptionItems,
  getOptionTip,
  error,
  fullWidth = false,
  required = false
}: TreeSelectFieldProps) => {
  const { classes } = useStyles()
  const anchorRef = useRef(undefined)
  const treeRef = useRef(undefined)
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState([])
  const [state, setState] = useState(init)
  const lastValue = useRef(null)
  const [focusNodeId, setFocusNodeId] = useState()

  useEffect(() => {
    const itemsFn = getOptionItemsFn(getOptionItems)
    const so = options || []
    const all = flattenArray(so, itemsFn)
    const fnValue = getOptionValue || ((o) => o)
    setState({
      defaultExpanded: collectDefaultExpanded(so, itemsFn),
      index: new Map(all.map((o) => [`node-${oidOf(o)}`, o])),
      valueIndex: new Map(all.map((o) => [fnValue(o), o]))
    })
    setFocusNodeId(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options])

  useEffect(() => {
    if (lastValue.current !== value && state.valueIndex.size > 0) {
      let sel
      if (multiSelect) {
        sel = Array.isArray(value) ? value : []
      } else {
        sel = value != null ? [value] : []
      }
      sel = distinctItems(sel)
      const nsel = sel.map((v) => state.valueIndex.get(v)).filter((v) => v != null)
      setSelected(nsel)
    }
  }, [multiSelect, state.valueIndex, value])

  const onFocus = () => {
    setOpen(true)
  }

  const onToggle = () => {
    setOpen(!open)
  }

  const onPopupClose = () => {
    setOpen(false)
    // if (anchorRef.current) {
    //   setTimeout(() => {
    //     anchorRef.current.focus()
    //   }, 250)
    // }
  }

  const onKeyDown = (e) => {
    if (!open && e.key === 'Enter') {
      stopPropagation(e)
      setOpen(true)
    }
  }

  const onClick = (e) => {
    stopPropagation(e)
    setOpen(true)
  }

  // const onPopupOpen = () => {
  //   if (treeRef.current && treeRef.current.firstChild) {
  //     treeRef.current.firstChild.focus()
  //   }
  // }

  const labelFn = getOptionLabel || ((o) => o.label)
  const selectableFn = getOptionSelectable || ((o) => o.selectable == null || o.selectable)
  const itemsFn = getOptionItemsFn(getOptionItems)
  const isSelected = (o) => selected.includes(o)
  const infoTipFn = getOptionTip || (() => undefined)

  const select = (option, checked) => {
    let next = []
    if (multiSelect) {
      if (checked) {
        next = [...selected, option]
      } else {
        next = selected.filter((sel) => option !== sel)
      }
    } else if (checked) {
      next = [option]
    }
    setSelected(next)
    if (onChange) {
      const valueFn = getOptionValue || ((o) => o)
      let n
      if (multiSelect) {
        n = next.map((opt) => valueFn(opt))
      } else {
        n = next.length > 0 ? valueFn(next[0]) : null
      }
      lastValue.current = n
      onChange({ name, value: n })
    }
  }

  const onSelect = (e) => {
    const { name: nodeId, checked } = e.target
    const option = state.index.get(nodeId)
    if (option != null) {
      select(option, checked)
    }
  }

  const onDeselect = (e, option) => select(option, false)

  const onTreeKeyDown = (e) => {
    if (e.code === 'Space') {
      stopPropagation(e)
      if (focusNodeId && onSelect) {
        const opt = state.index.get(focusNodeId)
        if (opt != null) {
          if (selectableFn(opt)) {
            onSelect({
              ...e,
              target: { ...e.target, name: focusNodeId, checked: !isSelected(opt) }
            })
          }
        }
      }
    }
  }

  const onNodeFocus = (e, nodeId) => {
    setFocusNodeId(nodeId)
  }

  const addOptions = (items, cn) => {
    return (
      items &&
      items.map((option) => {
        const nodeId = `node-${oidOf(option)}`
        return (
          <TreeSelectItem
            className={cn}
            key={nodeId}
            nodeId={nodeId}
            title={infoTipFn(option)}
            icon={getOptionIcon(option)}
            label={labelFn(option)}
            selectable={selectableFn(option)}
            selected={isSelected(option)}
            onSelect={onSelect}
          >
            {addOptions(itemsFn(option), classes.subTreeItem)}
          </TreeSelectItem>
        )
      })
    )
  }

  const expandButton = !disabled && (
    <InputAdornment position="end">
      <IconButton style={{ padding: '0' }} size="small" onClick={onToggle}>
        {(open && <ArrowDropUp />) || <ArrowDropDown />}
      </IconButton>
    </InputAdornment>
  )

  return (
    <>
      <TextField
        variant="standard"
        label={label}
        name={name}
        error={!!error}
        helperText={isString(error) ? error : null}
        value={selected}
        disabled={disabled}
        onClick={onClick}
        InputProps={{
          // @ts-ignore
          inputComponent: ChipContainer,
          endAdornment: expandButton
        }}
        inputProps={{
          placeholder,
          getValueTip: getOptionTip,
          getValueIcon: getOptionIcon,
          getValueLabel: getOptionLabel,
          getValueKey: getOptionValue,
          onDelete: onDeselect,
          footSpace: true,
          fullWidth,
          anchorRef
        }}
        slotProps={{
          inputLabel: propsShrink
        }}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        fullWidth={fullWidth}
        required={required}
      />
      <Popover
        open={open}
        anchorEl={anchorRef.current}
        onClose={onPopupClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <div style={{ padding: 12, minHeight: '5rem', maxHeight: '15rem', overflow: 'auto' }}>
          <SimpleTreeView
            ref={treeRef}
            defaultExpandedItems={state.defaultExpanded}
            onKeyDown={onTreeKeyDown}
            onItemFocus={onNodeFocus}
          >
            {addOptions(options, classes.treeItem)}
          </SimpleTreeView>
        </div>
      </Popover>
    </>
  )
}
