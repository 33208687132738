/* eslint-disable react/jsx-props-no-spreading */
import {LetterIcon, LetterIconProps} from '@utils/ui/LetterIcon'

export type FilterModeType = 'I' | 'E'

export type FilterModeIconProps = {
  value: FilterModeType
  onChange?: (v: FilterModeType) => void
  size?: LetterIconProps['size']
}

export const FilterModeIcon = ({ value, onChange, size }: FilterModeIconProps) => {
  switch (value) {
    case 'E':
      return (
        <LetterIcon
          letter="A"
          title="ausschließen, klicken zum einzuschließen"
          onClick={onChange ? () => onChange('I') : null}
          size={size}
        />
      )
    case 'I':
      return (
        <LetterIcon
          letter="E"
          title="einschließen, klicken zum auszuschließen"
          onClick={onChange ? () => onChange('E') : null}
          size={size}
        />
      )
    default:
      return null
  }
}
