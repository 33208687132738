import { Step, StepButton, StepLabel, Stepper } from '@mui/material'
import {
  EkPreisListeImportStatus,
  EkPreisListImportAssistentStepDisplayJson,
  EkPreisListImportStepId
} from '@one/typings/apiTypings'
import { TooltipEx } from '@utils/ui/TooltipWrapper'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme: any) => ({
  headFree: {
    // paddingTop: 20,
    overflow: 'hidden'
  },
  akt: {
    '& svg.MuiStepIcon-root.Mui-active': {
      fontSize: '200%'
    },
    '& span.MuiStepLabel-label': {
      fontSize: '100%!important'
    }
  },
  inakt: {
    '& svg': {
      fontSize: '120%'
    },
    '& span.MuiStepLabel-label.Mui-completed': {
      // fontWeight: "600"
    }
  },
  tooltip: {
    [theme.breakpoints.up('smallDesktop')]: {
      display: 'none'
    }
  }
}))

export type EkPreisImportStepperProps = {
  status: EkPreisListeImportStatus
  aktStepId: EkPreisListImportStepId
  allSteps: EkPreisListImportAssistentStepDisplayJson[]
  onClick?: (stepId: any) => void
}

export const EkPreisImportStepper = ({
  status,
  aktStepId,
  allSteps,
  onClick
}: EkPreisImportStepperProps) => {
  const { classes } = useStyles()

  const aktStepIdEx = aktStepId == null ? EkPreisListImportStepId.STEP2FORMAT : aktStepId

  const step1 = {
    stepId: EkPreisListImportStepId.STEP1PARS,
    stepName: 'Datei-Upload',
    ok: true,
    executed: true,
    lastStep: false
  }

  const step2 = {
    stepId: EkPreisListImportStepId.STEP2FORMAT,
    stepName: 'Prüfung Importdaten',
    ok:
      status === EkPreisListeImportStatus.VALID ||
      status === EkPreisListeImportStatus.IMPORTIERT ||
      status === EkPreisListeImportStatus.ERLEDIGT,
    executed:
      aktStepIdEx !== EkPreisListImportStepId.STEP2FORMAT ||
      status !== EkPreisListeImportStatus.NEU,
    lastStep: false
  }

  const allStepsWithValidate = [step1, step2, ...(allSteps || [])]

  const aktStepIdx = allStepsWithValidate.findIndex((sd) => sd.stepId === aktStepIdEx)

  const stepperProps = {
    'akt-step-id': aktStepIdEx
  }

  const hideLabelsOnMobile = {
    '& .MuiStepLabel-label': {
      display: { xs: 'none', smallDesktop: 'block' }
    }
  }

  const steps = allStepsWithValidate.map((sd, idx) => {
    const stepProps = { 'step-id': sd.stepId }
    return (
      <Step
        {...stepProps}
        key={sd.stepId}
        completed={sd.executed && sd.ok}
        last={sd.lastStep}
        active={sd.stepId === aktStepIdEx}
        className={sd.stepId === aktStepIdEx ? classes.akt : classes.inakt}
      >
        {status === EkPreisListeImportStatus.ERLEDIGT ||
        (sd.executed && !sd.ok) ||
        aktStepIdx <= idx ||
        sd.stepId === EkPreisListImportStepId.STEP1PARS ||
        onClick == null ? (
          <TooltipEx title={sd.stepName} classes={{ tooltip: classes.tooltip }}>
            <StepLabel sx={hideLabelsOnMobile}>{sd.stepName}</StepLabel>
          </TooltipEx>
        ) : (
          <TooltipEx title={sd.stepName} classes={{ tooltip: classes.tooltip }}>
            <StepButton onClick={() => onClick(sd.stepId)} sx={hideLabelsOnMobile}>
              {sd.stepName}
            </StepButton>
          </TooltipEx>
        )}
      </Step>
    )
  })

  return (
    <Stepper {...stepperProps} activeStep={aktStepIdx} className={classes.headFree}>
      {steps}
    </Stepper>
  )
}
