import { CalendarViewDay, Dehaze } from '@mui/icons-material'
import { blue, red } from '@mui/material/colors'
import { AppPaths, VirtualArtikelListIds } from '@one/AppPaths'
import { EventNames } from '@one/EventNames'
import { UserRoles } from '@one/UserRoles'
import { api } from '@one/api'
import { HkmEnum } from '@one/enums/HkmEnum'
import {
  ArtikelAenderungTyp,
  DashboardJson,
  DashboardVorgangJson,
  DashboardVorgangTyp,
  LieferantAenderungTyp,
  SyncInfoJson
} from '@one/typings/apiTypings'
import { ApiExclusive, useApiCaller } from '@utils/apicaller'
import { useEnums } from '@utils/enums'
import { useLocalState } from '@utils/localState'
import { useObserver } from '@utils/observer'
import { useQuery } from '@utils/routing'
import { AppContext } from '@utils/ui/App/AppContext'
import { Column, RowGrouper } from '@utils/ui/DataTable/DataTable'
import { DataTableCard, DataTableFilterRef } from '@utils/ui/DataTable/DataTableCard'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { Medal } from '@utils/ui/fields/Medal'
import { distinct, nameOf } from '@utils/utils'
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { StatusHeader } from './StatusHeader'
import { AutocompleteEx } from '@utils/ui/fields/AutocompleteEx'
import { useFormState } from '@utils/formstate'
import { Grid2 as Grid } from '@mui/material'

interface DashboardData extends DashboardJson {
  lieferantCounts?: string
  artikelCounts?: string
}

export const Dashboard = () => {
  const { isAllianz } = useContext(AppContext)

  const [data, setData] = useState<DashboardData>({ vorgaenge: [] })
  const [duration, setDuration] = useState<number | null>()

  const [{ [AppPaths.DashboardQueries._key]: filterParam }, setQuery] = useQuery()

  const [grouping, setGrouping] = useLocalState('dashboard.grouping', false)

  const [syncStatus, setSyncStatus] = useState<SyncInfoJson>(null)

  const filterRef = useRef<DataTableFilterRef>(undefined)

  const [apiCall, apiBusy] = useApiCaller(api)
  const [apiPoll] = useApiCaller(api)

  const { et, en } = useEnums()

  const refresh = useCallback(
    (force?: boolean) => {
      apiCall<DashboardJson>({
        method: 'GET',
        exclusive: ApiExclusive.BLOCK,
        rest: '/dashboard',
        params: { noCache: !!force },
        onSuccess: (data, _r, d) => {
          setData(data)
          setDuration(d)
        }
      })
    },
    [apiCall]
  )

  const initSyncState = () => {
    apiPoll<SyncInfoJson>({
      method: 'GET',
      rest: '/exportstate/poll',
      onSuccess: (data) => setSyncStatus(data)
    })
  }

  const getLink = useCallback(
    (data: DashboardVorgangJson): string => {
      switch (data.type) {
        case DashboardVorgangTyp.NEUE_LIEFERANTEN_ARTIKEL:
          return AppPaths.ArtikelAboSelektionPflegeFn(data.artikelSelektionId, {
            filter: ArtikelAenderungTyp.NEU
          })

        case DashboardVorgangTyp.GEANDERTE_LIEFERANTEN_ARTIKEL:
          return AppPaths.ArtikelAboSelektionPflegeFn(data.artikelSelektionId, {
            filter: ArtikelAenderungTyp.GEAENDERT
          })

        case DashboardVorgangTyp.KONFLIKT_DZ_LIEFERANTEN_ARTIKEL:
          return AppPaths.ArtikelAboSelektionPflegeFn(data.artikelSelektionId, {
            filter: ArtikelAenderungTyp.KONFLIKT_DZ
          })

        case DashboardVorgangTyp.GELOESCHTE_LIEFERANTEN_ARTIKEL:
          return AppPaths.ArtikelAboSelektionPflegeFn(data.artikelSelektionId, {
            filter: ArtikelAenderungTyp.AUSGELISTET
          })

        case DashboardVorgangTyp.KONFLIKT_DZ_LIEFERANT:
        case DashboardVorgangTyp.LIEFERANT_ABO_UNBESTIMMT:
          return AppPaths.LieferantAboPflegeFn(data.lieferantAboId)

        case DashboardVorgangTyp.LIEFERANTEN_NEU:
          return AppPaths.LieferantAboUebersichtFn(LieferantAenderungTyp.NEU)

        case DashboardVorgangTyp.LIEFERANTEN_GEAENDERT:
          return AppPaths.LieferantAboUebersichtFn(LieferantAenderungTyp.GEAENDERT)

        case DashboardVorgangTyp.HAUPTLIEFERANT_GEAENDERT_EK:
        case DashboardVorgangTyp.EK_PREISPFLEGE:
          return AppPaths.EkPreisAnlageFn({ artikelListeRef: data.referenz })

        case DashboardVorgangTyp.HAUPTLIEFERANT_GEAENDERT_VK:
        case DashboardVorgangTyp.VK_PREISPFLEGE:
          if (isAllianz) {
            return null
          }
          return AppPaths.VkPreisAnlageFn({ artikelListeRef: data.referenz })

        case DashboardVorgangTyp.ARTIKEL_MIT_GESPERRTEN_HAUPTLIEFERANTEN:
          return AppPaths.HauptlieferantPflegeFn(
            VirtualArtikelListIds.GesperrteHauptlieferantArtikelListe,
            data.kontext
          )

        case DashboardVorgangTyp.ARTIKEL_OHNE_HAUPTLIEFERANTEN:
          return AppPaths.HauptlieferantPflegeFn(
            VirtualArtikelListIds.HauptlieferantArtikelListe,
            data.kontext,
            data.betriebstyp
          )

        case DashboardVorgangTyp.ARTIKEL_ANDERER_HAUPTLIEFERANT:
          return AppPaths.HauptlieferantPflegeFn(
            VirtualArtikelListIds.NichtBeforzugterHauptlieferant,
            data.kontext
          )

        case DashboardVorgangTyp.EXPORT_ERP_ERROR_ARTIKEL:
          return AppPaths.ExportArtikelFehler

        case DashboardVorgangTyp.EXPORT_ERP_ERROR_LIEFERANT:
          return AppPaths.ExportLieferantFehler

        case DashboardVorgangTyp.EXPORT_ERP_ERROR_EKPREIS:
          return AppPaths.ExportEkPreisFehler

        case DashboardVorgangTyp.EXPORT_ERP_ERROR_VKPREIS:
          return AppPaths.ExportVkPreisFehler

        case DashboardVorgangTyp.EXPORT_ERP_ERROR_EKKONDITION:
          return AppPaths.ExportEkKonditionenFehler

        case DashboardVorgangTyp.IMPORT_ERP_ERROR_ARTIKEL:
          return AppPaths.ImportArtikelFehler

        case DashboardVorgangTyp.IMPORT_ERP_ERROR_EKKONDITION:
          return AppPaths.ImportEkKonditionenFehler

        case DashboardVorgangTyp.IMPORT_ERP_ERROR_EKPREIS:
          return AppPaths.ImportEkPreisFehler

        case DashboardVorgangTyp.IMPORT_ERP_ERROR_VKPREIS:
          return AppPaths.ImportVkPreisFehler

        case DashboardVorgangTyp.IMPORT_ERP_ERROR_LIEFERANT:
          return AppPaths.ImportLieferantFehler

        case DashboardVorgangTyp.ARTIKEL_ETIKETTEN_DRUCK:
          return AppPaths.ArtikelEtikettenDruckFn(data.standortId)

        case DashboardVorgangTyp.EK_UND_VK_PREISAENDERUNG:
          return AppPaths.PreisaenderungenFn({
            artikelListeRef: data.referenz,
            betriebstyp: data.betriebstyp,
            kontext: data.kontext
          })

        default:
          return null
      }
    },
    [isAllianz]
  )

  useEffect(() => {
    if (filterParam === AppPaths.DashboardQueries.error) {
      filterRef.current.setColumnFilters([{ field: 'error', value: 'Fehler' }])
      setQuery({ [AppPaths.DashboardQueries._key]: '' })
      // } else if (filterParam !== '') {
      //   filterRef.current.setTriggerFilter(null)
    }
  }, [filterParam, setQuery])

  useEffect(() => {
    refresh()
    initSyncState()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useObserver(EventNames.DASHBOARD, () => {
    refresh(true)
  })

  useObserver(EventNames.PULSE_EXPORTSTATUS, ({ data }) => {
    setSyncStatus(data as SyncInfoJson)
  })

  const header = useMemo(
    () => <StatusHeader data={data} syncStatus={syncStatus} />,
    [data, syncStatus]
  )

  const columns = useMemo<Column<DashboardVorgangJson>[]>(
    () => [
      {
        field: nameOf<DashboardVorgangJson>('datum'),
        header: 'Datum',
        type: 'date'
      },
      {
        field: nameOf<DashboardVorgangJson>('type'),
        header: 'Typ',
        valueGetter: (row) => {
          return et(HkmEnum.DashboardVorgangTyp, row.type)
        }
      },
      {
        field: nameOf<DashboardVorgangJson>('error'),
        header: 'Art',
        align: 'center',
        valueGetter: (row) => (row.error ? 'Fehler' : 'Aufgabe'),
        body: (row) => (
          <Medal
            text={row.error ? 'Fehler' : 'Aufgabe'}
            backgroundColor={row.error ? red[200] : blue[200]}
          />
        )
      },
      {
        field: nameOf<DashboardVorgangJson>('anzahl'),
        type: 'number',
        header: 'Anzahl',
        valueGetter: (row) => {
          return row.anzahl || row.detailCount || null
        }
      },
      {
        field: nameOf<DashboardVorgangJson>('anzahlEskaliert'),
        type: 'number',
        header: 'Eskaliert'
      },
      {
        field: nameOf<DashboardVorgangJson>('text'),
        header: 'Details',
        width: '40%'
      },
      {
        field: nameOf<DashboardVorgangJson>('lieferant'),
        header: 'Lieferant'
      }
    ],
    [et]
  )

  const tableActions = useMemo(
    () => [
      {
        icon: 'edit',
        tooltip: 'Bearbeiten',
        getLink: getLink
      }
    ],
    [getLink]
  )

  const actions = useMemo(
    () => [
      {
        role: UserRoles.STAMMDATEN_EDITOR,
        tooltip: 'Gruppieren an/aus',
        icon: grouping ? <CalendarViewDay /> : <Dehaze />,
        onClick: () => setGrouping(!grouping)
      },
      {
        role: UserRoles.STAMMDATEN_EDITOR,
        tooltip: 'Dashboard neuladen',
        icon: 'refresh',
        onClick: () => refresh()
      }
    ],
    [grouping, refresh, setGrouping]
  )

  const grouper =
    grouping &&
    ({
      field: 'type',
      valueGetter: (row: any) => et(HkmEnum.DashboardVorgangTyp, row.type)
    } as RowGrouper)

  const [form, onFormChange] = useFormState<any>({ quickfilter: [] })

  const quickfilter = (
    <Grid container justifyContent="end" width="100%">
      <Grid size={{ xs: 6 }} paddingTop="3px">
        <AutocompleteEx
          emptyText="Typfilter..."
          options={data.vorgaenge
            .map((v) => v.type)
            .filter(distinct)
            .map((v) => en(HkmEnum.DashboardVorgangTyp, v))
            .sort((a, b) => a.text.localeCompare(b.text))}
          multiple
          name="quickFilter"
          value={form.quickFilter}
          onChange={onFormChange}
          optionLabel="text"
          optionValue="id"
          fullWidth
        />
      </Grid>
    </Grid>
  )

  const vorgaengeFiltered = useMemo(() => {
    if (!form.quickFilter || form.quickFilter.length === 0) {
      return data.vorgaenge
    }
    return data.vorgaenge.filter((v) => form.quickFilter.includes(v.type))
  }, [data.vorgaenge, form.quickFilter])

  return (
    <Frame space>
      <FrameRow>{header}</FrameRow>
      <FrameBody>
        <DataTableCard
          ref={filterRef}
          name="Dashboard"
          title="Vorgänge"
          filterMode="both"
          topActions={actions}
          columns={columns}
          initialOrderBy={['datum', 'type']}
          actions={tableActions}
          value={vorgaengeFiltered}
          loading={apiBusy}
          localStateName="Dashboard"
          wrapMode="normal"
          groupBy={grouper}
          duration={duration}
          header={quickfilter}
          dense
          hover
        />
      </FrameBody>
    </Frame>
  )
}
