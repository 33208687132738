import { Grid2 as Grid } from '@mui/material'
import { PimMengeneinheitField } from '@one/components/common/PimMengeneinheitField'
import { PimMengeneinheitDisplayJson, SeArtikelJson } from '@one/typings/apiTypings'
import { ErrorsType, ModelAction, ValueChangeFn } from '@utils/modelmgr'
import { CardEx } from '@utils/ui/CardEx'
import { RowForm } from '@utils/ui/fields/RowForm'
import React from 'react'
import { EigenlistungsArtikelUseCase } from './model/EigenlistungsArtikelUseCase'
import { PimArtikelMengeneinheitMemo } from './PimArtikelMengeneinheit'
import { resolveObjectField } from '@utils/utils'
import { AutocompleteEx } from '@utils/ui/fields/AutocompleteEx'
import { formatNumber } from '@utils/numberutils'
import { TooltipWrapper } from '@utils/ui/TooltipWrapper'

export interface PimArtikelMengeneinheitenProps {
  model: SeArtikelJson
  onChange?: ValueChangeFn<SeArtikelJson>
  mengeneinheiten?: PimMengeneinheitDisplayJson[]
  mengeneinheitenAll?: PimMengeneinheitDisplayJson[]
  dispatch?: (action: ModelAction) => void
  errors?: ErrorsType
  readonly?: boolean
  eigenlistung?: boolean
}

/**
 * PimArtikelMengeneinheiten wird im EigenlistungsArtikelPflegeView editierbar
 * und im PimArtikelView als readonly genutzt.
 * @param model
 * @param onChange
 * @param mengeneinheiten
 * @param mengeneinheitenAll
 * @param kontext
 * @param dispatch
 * @param errors
 * @param readonly
 * @constructor
 */
export const PimArtikelMengeneinheiten = ({
  model,
  onChange,
  mengeneinheiten,
  mengeneinheitenAll,
  dispatch,
  errors,
  readonly,
  eigenlistung
}: PimArtikelMengeneinheitenProps) => {
  const onAddMengeneinheit = () => {
    dispatch({ type: EigenlistungsArtikelUseCase.ADDMENGENEINHEIT })
  }

  const pimArtikelVersion = model.pimArtikel?.version

  return (
    <CardEx title="Mengeneinheiten" contentStyle={{ padding: 0, paddingBottom: 0 }}>
      <Grid container spacing={2} margin={2}>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 1 }}>
          <TooltipWrapper
            arrow
            placement="right"
            title="Wird nur für Verkaufspreise verwendet, bei denen bisher noch kein Vk-Preis bestand."
          >
            <PimMengeneinheitField
              label="VK-Mengeneinheit"
              value={model.verkaufME}
              name="verkaufME"
              onChange={onChange}
              error={resolveObjectField(errors, 'verkaufME')}
              values={mengeneinheiten}
              fullWidth
              required
              disabled={readonly}
            />
          </TooltipWrapper>
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 1 }}>
          <AutocompleteEx<number>
            label="VK-Preismenge"
            value={model.verkaufPreismenge}
            name="verkaufPreismenge"
            error={resolveObjectField(errors, 'verkaufPreismenge')}
            onChange={onChange}
            options={[1, 10, 100, 1000]}
            optionValue={(v) => v}
            optionLabel={(v) => formatNumber(v, 0)}
            isOptionEqualToValue={(o, v) => o === v}
            fullWidth
            required
            disabled={readonly}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 1 }}>
          <PimMengeneinheitField
            label="Einzelmengeneinheit"
            value={pimArtikelVersion.einzelMengeneinheit}
            name="pimArtikel.version.einzelMengeneinheit"
            onChange={onChange}
            error={resolveObjectField(errors, 'pimArtikel.version.einzelMengeneinheit')}
            values={mengeneinheiten}
            fullWidth
            disabled={readonly || !eigenlistung}
            required
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} padding={0}>
        <RowForm
          rows={pimArtikelVersion.mengeneinheiten}
          onAdd={readonly || !eigenlistung ? null : onAddMengeneinheit}
          addLabel="Mengeneinheit hinzufügen"
          borderlessItems
          body={(me, idx) => (
            <PimArtikelMengeneinheitMemo
              pimArtikelVersionMengeneinheit={me}
              mengeneinheiten={mengeneinheiten}
              mengeneinheitenAll={mengeneinheitenAll}
              onChange={onChange}
              dispatch={dispatch}
              errors={errors}
              idx={idx}
              readonly={readonly || !eigenlistung}
            />
          )}
        />
      </Grid>
    </CardEx>
  )
}

export const PimArtikelMengeneinheitenMemo = React.memo(PimArtikelMengeneinheiten)
