import { CalendarViewDay, Dehaze, Euro, ListAlt } from '@mui/icons-material'
import { Grid2 as Grid } from '@mui/material'
import { UserRoles } from '@one/UserRoles'
import {
  ArtikelDefaultColumnsProps,
  useArtikelColumns
} from '@one/components/Artikel/ArtikelColumn'
import { getStatusColor } from '@one/components/PreisAnlage/PreisAnlageTable'
import { HkmEnum } from '@one/enums/HkmEnum'
import { AuswahlStatus, EtikettenDruckEintragJson } from '@one/typings/apiTypings'
import { formatDate, formatISODate } from '@utils/dateutils'
import { useEnums } from '@utils/enums'
import { useLocalState } from '@utils/localState'
import { useStateEx } from '@utils/stateex'
import { AppContext } from '@utils/ui/App/AppContext'
import { Button } from '@utils/ui/Buttons/Button'
import { DataTableOrderType, RowGrouper } from '@utils/ui/DataTable/DataTable'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { ThemeContext } from '@utils/ui/Theme'
import { TooltipEx } from '@utils/ui/TooltipWrapper'
import { Medal } from '@utils/ui/fields/Medal'
import { useCallback, useContext, useMemo, useState } from 'react'

const initialOrderBy = [{ key: 'gueltigAb', dir: 'asc' }] as DataTableOrderType[]

export interface ArtikelEtikettendruckTableProps {
  title: string
  eintraege: EtikettenDruckEintragJson[]
  getEintragStatus: (a: EtikettenDruckEintragJson) => AuswahlStatus
  setEintragStatus: (a: EtikettenDruckEintragJson[], status: AuswahlStatus) => void
  onSelectVerschiebar: (a: EtikettenDruckEintragJson[]) => void
}

export const ArtikelEtikettendruckTable = ({
  eintraege,
  getEintragStatus,
  setEintragStatus,
  onSelectVerschiebar,
  title
}: ArtikelEtikettendruckTableProps) => {
  const { et } = useEnums()
  const { darkMode } = useContext(ThemeContext)
  const appContext = useContext(AppContext)
  const [toggleAlle, setToggleAlle] = useState({ key: null, wasSelected: false })
  const today = formatISODate(new Date())

  const [selected, setSelected, getSelected] = useStateEx(new Set<EtikettenDruckEintragJson>())

  const setSelection = useCallback(
    (selected: Set<EtikettenDruckEintragJson>) => {
      setSelected(selected)
      onSelectVerschiebar(
        Array.from(selected).filter((item) => item.gueltigAb && item.gueltigAb !== today)
      )
      setToggleAlle({ key: null, wasSelected: false })
    },
    [onSelectVerschiebar, setSelected, today]
  )

  const [grouping, setGrouping] = useLocalState('ArtikeletikettendruckTable.grouping', true)

  const columnProps = useMemo<ArtikelDefaultColumnsProps>(
    () => ({
      isGesellschafter: appContext.isGesellschafter,
      removeDefaultsByField: ['importFehlerData', 'kontexte'],
      fieldWrapper: {
        wrapper: 'artikel'
      },
      addColumnToIndex: [
        {
          beforeField: 'artikel.hageNummer',
          column: {
            field: 'status',
            header: 'Auswahl',
            align: 'center',
            valueGetter: (row) => {
              const status = getEintragStatus(row)
              if (status) {
                return et(HkmEnum.AuswahlStatus, status)
              }
              return ''
            },
            body: (row) => {
              const status = getEintragStatus(row)
              if (status) {
                const statusText = et(HkmEnum.AuswahlStatus, status)
                return (
                  <Medal text={statusText} backgroundColor={getStatusColor(status, darkMode)} />
                )
              }
              return null
            }
          }
        },
        {
          beforeField: 'artikel.hageNummer',
          column: {
            field: 'aenderung',
            header: 'Grund',
            headerTip: 'Grund für Druck',
            align: 'center',
            valueGetter: (row: EtikettenDruckEintragJson) => {
              let info = []
              if (row.artikelAenderung) {
                info = [...info, 'Artikeländerung']
              }
              if (row.vkPreisAenderung) {
                info = [...info, 'Preiseänderung']
              }
              return info
            },
            body: (row: EtikettenDruckEintragJson) => (
              <Grid container justifyContent="center" spacing={1}>
                {row.artikelAenderung && (
                  <Grid>
                    <TooltipEx title="Artikeländerung">
                      <ListAlt fontSize="small" />
                    </TooltipEx>
                  </Grid>
                )}
                {row.vkPreisAenderung && (
                  <Grid>
                    <TooltipEx title="Preisänderung">
                      <Euro fontSize="small" />
                    </TooltipEx>
                  </Grid>
                )}
              </Grid>
            )
          }
        },
        {
          beforeField: 'artikel.hageNummer',
          column: {
            field: 'gueltigAb',
            key: 'gueltigAb',
            header: 'Gültig ab',
            type: 'date',
            body: (row, { column }) => {
              return row.gueltigAb == null ? 'Sofort wirksam' : formatDate(row.gueltigAb)
            }
          }
        }
      ]
    }),
    [appContext.isGesellschafter, darkMode, et, getEintragStatus]
  )

  const columns = useArtikelColumns(columnProps)

  const onStatus = useCallback(
    (status: AuswahlStatus) => {
      setEintragStatus(Array.from(getSelected()), status)
    },
    [setEintragStatus, getSelected]
  )

  const onEinschliessen = useCallback(() => onStatus(AuswahlStatus.EINGESCHL), [onStatus])

  const onAusschliessen = useCallback(() => onStatus(AuswahlStatus.AUSGESCHL), [onStatus])

  const einschStyle = useMemo(
    () => ({
      backgroundColor: getStatusColor(AuswahlStatus.EINGESCHL, darkMode),
      color: 'inherit'
    }),
    [darkMode]
  )

  const ausschStyle = useMemo(
    () => ({
      backgroundColor: getStatusColor(AuswahlStatus.AUSGESCHL, darkMode),
      color: 'inherit'
    }),
    [darkMode]
  )

  const actions = useMemo(
    () => [
      <Button
        key="aus"
        label="Auswählen"
        disabled={selected.size === 0}
        onClick={onEinschliessen}
        style={einschStyle}
        variant="contained"
      />,
      <Button
        key="ein"
        label="Abwählen"
        disabled={selected.size === 0}
        onClick={onAusschliessen}
        style={ausschStyle}
        variant="contained"
      />
    ],
    [ausschStyle, einschStyle, onAusschliessen, onEinschliessen, selected.size]
  )

  const topActions = useMemo(
    () => [
      {
        role: UserRoles.STAMMDATEN_EDITOR,
        tooltip: 'Gruppieren an/aus',
        icon: grouping ? <CalendarViewDay /> : <Dehaze />,
        onClick: () => setGrouping(!grouping)
      }
    ],
    [grouping, setGrouping]
  )

  const groupBy = useMemo(
    () =>
      ({
        field: 'gueltigAb',
        header: 'Gültig ab',
        type: 'date',
        groupEmpty: true,
        pushEmpty: true,
        orderDir: 'asc',
        body: (row, { column }) => {
          const label = row.gueltigAb == null ? 'Sofort wirksam' : formatDate(row.gueltigAb)
          return (
            <Grid container alignItems="center" spacing={2}>
              <Grid>{label}</Grid>
              <Grid>
                <Button
                  size="small"
                  style={{ padding: 0, marginBottom: 5 }}
                  variant="outlined"
                  label="alle"
                  onClickVoid={() => {
                    const key = label
                    if (toggleAlle.key === key && toggleAlle.wasSelected) {
                      setSelection(new Set())
                      setToggleAlle({ key, wasSelected: false })
                      return
                    }
                    setSelection(new Set(eintraege.filter((e) => e.gueltigAb === row.gueltigAb)))
                    setToggleAlle({ key, wasSelected: true })
                  }}
                />
              </Grid>
            </Grid>
          )
        }
      }) as RowGrouper<EtikettenDruckEintragJson>,
    [eintraege, setSelection, toggleAlle]
  )

  return (
    <>
      <DataTableCard
        name="ArtikeletikettendruckTable"
        filterMode="both"
        columns={columns}
        value={eintraege}
        selectMode="multi"
        selected={selected}
        onSelect={setSelection}
        title={title}
        bottomActions={actions}
        groupBy={grouping && groupBy}
        localStateName="ArtikelEtikettendruckTable"
        emptyMessage="Keine Artikel zur Auswahl"
        initialOrderBy={initialOrderBy}
        topActions={topActions}
        selectCol
        paging
        dense
      />
    </>
  )
}
