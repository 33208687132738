import { Grid2 as Grid } from '@mui/material'
import { AllianzJson } from '@one/typings/apiTypings'
import { CardEx } from '@utils/ui/CardEx'
import { TextField } from '@utils/ui/fields/TextField'

export interface GesellschafterAllianzPflegeKopfProps {
  allianz: AllianzJson
  onChange: (e: Event) => void
  errors?: any
}

export const GesellschafterAllianzPflegeKopf = ({
  allianz,
  onChange,
  errors
}: GesellschafterAllianzPflegeKopfProps) => {
  return (
    <CardEx backLink title="Gesellschafter-Allianz-Pflege">
      <Grid container spacing={2} direction="column">
        <Grid container direction="row" spacing={2}>
          <Grid size={{xs:12,sm:6,md:4,lg:3}}>
            <TextField
              name="name"
              label="Name"
              value={allianz.name}
              onChange={onChange}
              fullWidth
              error={errors.name}
              required
            />
          </Grid>
          <Grid size={{xs:12,sm:6,md:4,lg:3}}>
            <TextField
              name="bemerkung"
              label="Bemerkung"
              value={allianz.bemerkung}
              onChange={onChange}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
    </CardEx>
  )
}
