import { PimArtikelJson, SeArtikelJson } from '@one/typings/apiTypings'
import { ValueChangeFn } from '@utils/modelmgr'
import { CardEx } from '@utils/ui/CardEx'
import { NumberField } from '@utils/ui/fields/NumberField'
import { Grid2 as Grid } from '@mui/material'
import React from 'react'

export interface PimArtikelMasseProps {
  pimArtikel: PimArtikelJson
  onChange?: ValueChangeFn<SeArtikelJson>
  errors?: any
  readonly?: boolean
}

/**
 * PimArtikelMasse wird im EigenlistungsArtikelPflegeView editierbar
 * und im PimArtikelView als readonly genutzt.
 * @param pimArtikel
 * @param onChange
 * @param errors
 * @param readonly
 * @constructor
 */
export const PimArtikelMasse = ({
  pimArtikel,
  onChange,
  errors,
  readonly
}: PimArtikelMasseProps) => {
  if (!pimArtikel) {
    return null
  }
  return (
    <CardEx title="Maße">
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 2 }}>
          <NumberField
            label="Breite Netto"
            name="pimArtikel.version.artikelBreiteNetto"
            value={pimArtikel.version.artikelBreiteNetto}
            fraction={4}
            thousandSeparator
            suffix=" mm"
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 2 }}>
          <NumberField
            label="Höhe Netto"
            name="pimArtikel.version.artikelHoeheNetto"
            value={pimArtikel.version.artikelHoeheNetto}
            fraction={4}
            thousandSeparator
            suffix=" mm"
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 2 }}>
          <NumberField
            label="Tiefe Netto"
            name="pimArtikel.version.artikelTiefeNetto"
            value={pimArtikel.version.artikelTiefeNetto}
            fraction={4}
            thousandSeparator
            suffix=" mm"
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 2 }}>
          <NumberField
            label="Gewicht Brutto"
            name="pimArtikel.version.artikelGewichtBrutto"
            value={pimArtikel.version.artikelGewichtBrutto}
            fraction={4}
            thousandSeparator
            suffix=" kg"
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 2 }}>
          <NumberField
            label="Länge Brutto"
            name="pimArtikel.version.artikelLaengeBrutto"
            value={pimArtikel.version.artikelLaengeBrutto}
            fraction={4}
            thousandSeparator
            suffix=" mm"
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
      </Grid>
    </CardEx>
  )
}

export const PimArtikelMasseMemo = React.memo(PimArtikelMasse)
