import { Grid2 as Grid } from '@mui/material'
import { DzLieferantJson, SeLieferantJson } from '@one/typings/apiTypings'
import { ValueChangeFn } from '@utils/modelmgr'
import { CardEx } from '@utils/ui/CardEx'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { NumberField } from '@utils/ui/fields/NumberField'
import { TextField } from '@utils/ui/fields/TextField'
import React from 'react'

export type LieferantenLieferbedingungProps = {
  dzLieferant: DzLieferantJson
  onValueChange: ValueChangeFn<SeLieferantJson>
  errors: any
}

export const LieferantenLieferbedingung = ({
  dzLieferant,
  onValueChange,
  errors
}: LieferantenLieferbedingungProps) => (
  <CardEx title="Lieferbedingungen">
    <Grid container spacing={2}>
      <Grid size={{xs:6,lg:3,xl:2}}>
        <Checkbox
          label="EDI"
          checked={dzLieferant.edi}
          name="dzLieferant.edi"
          onChange={onValueChange}
        />
      </Grid>
      <Grid size={{xs:6,lg:3,xl:2}}>
        <TextField
          label="EDI-Dsp"
          value={dzLieferant.ediDsp}
          name="dzLieferant.ediDsp"
          onChange={onValueChange}
          fullWidth
        />
      </Grid>
      <Grid size={{xs:6,lg:3,xl:2}}/>
      <Grid size={{xs:6,lg:6,xl:4}}>
        <TextField
          label="Lieferbedingung"
          value={dzLieferant.lieferbedingung}
          name="dzLieferant.lieferbedingung"
          onChange={onValueChange}
          maxLength={255}
          fullWidth
        />
      </Grid>
      <Grid size={{xs:6,lg:3,xl:2}}>
        <NumberField
          label="Mindestbestellwert"
          value={dzLieferant.mindestBestellwert}
          fraction={2}
          thousandSeparator
          name="dzLieferant.mindestBestellwert"
          onChange={onValueChange}
          fullWidth
        />
      </Grid>
      <Grid size={{xs:6,lg:3,xl:2}}/>
      <Grid size={{xs:6,lg:3,xl:2}}>
        <TextField
          label="Zahlungsziel"
          value={dzLieferant.zahlungsZiel}
          name="dzLieferant.zahlungsZiel"
          onChange={onValueChange}
          maxLength={10}
          fullWidth
        />
      </Grid>
      <Grid size={{xs:6,lg:3,xl:2}}>
        <TextField
          label="Währung"
          value={dzLieferant.waehrung}
          name="dzLieferant.waehrung"
          onChange={onValueChange}
          maxLength={10}
          fullWidth
        />
      </Grid>
      <Grid size={{xs:6,lg:3,xl:2}}/>
      <Grid size={{xs:6,lg:3,xl:2}}>
        <NumberField
          label="Freihausgrenze"
          value={dzLieferant.freihausgrenze}
          fraction={0}
          name="dzLieferant.freihausgrenze"
          onChange={onValueChange}
          fullWidth
        />
      </Grid>
      <Grid size={{xs:6,lg:3,xl:2}}>
        <NumberField
          label="Freihausmenge"
          value={dzLieferant.freihausmenge}
          fraction={0}
          name="dzLieferant.freihausmenge"
          onChange={onValueChange}
          fullWidth
        />
      </Grid>
      <Grid size={{xs:6,lg:3,xl:2}}>
        <TextField
          label="Vorlauf"
          value={dzLieferant.vorlauf}
          name="dzLieferant.vorlauf"
          error={errors['dzLieferant.vorlauf']}
          onChange={onValueChange}
          maxLength={10}
          fullWidth
        />
      </Grid>
    </Grid>
  </CardEx>
)

export const LieferantenLieferbedingungMemo = React.memo(LieferantenLieferbedingung)
