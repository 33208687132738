/* eslint-disable react/jsx-one-expression-per-line */
import {ArrowBack, ExpandLess, ExpandMore, ViewList} from '@mui/icons-material'
import {Card, CardContent, CardHeader, Collapse, Grid, Grid2} from '@mui/material'
import {isString} from '@utils/utils'
import clsx from 'clsx'
import {CSSProperties, ReactNode, useCallback, useContext, useMemo, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {makeStyles} from 'tss-react/mui'
import {Actions, buildActionButtons} from './Action'
import {AppContext} from './App/AppContext'
import {ButtonRow} from './Buttons/ButtonRow'
import {IconButton} from './Buttons/IconButton'
import {SplitIconButton, SplitIconButtonAction} from './Buttons/SplitIconButton'

const useStyles = makeStyles()((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    '&>div:last-child': {
      flex: '1 1 auto',
      alignSelf: 'stretch'
    }
  },
  borderless: {
    background: 'transparent !important'
    // border: 'none !important'
  },
  header: {
    padding: '8px 16px !important',
    alignItems: 'baseline',
    // marginTop: '5px',
    '& > div.MuiCardHeader-action': {
      margin: 0
    }
  },
  headerComponent: {
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap'
    },
    '& > div.MuiCardHeader-content': {
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        order: 2,
        flexBasis: '100%',
        flexShrink: 0
      }
    }
  },
  headerAction: {
    [theme.breakpoints.down('lg')]: {
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      rowGap: '10px',
      '& > div.MuiCardHeader-action': {
        flexShrink: 1,
        '& > .MuiGrid-container': {
          rowGap: '10px'
        }
      }
    }
  },
  headerComponentWithAction: {
    [theme.breakpoints.down('lg')]: {
      '& > div.MuiCardHeader-content': {
        width: '80%',
        flexGrow: 0
      }
    }
  },
  cardHeaderRoot: {
    paddingLeft: 0
  },
  avatar: {
    marginRight: '4px !important',
    alignSelf: 'flex-start'
  },
  nopadding: {
    '& .MuiCardHeader-root': {
      paddingLeft: '0px!important',
      paddingRight: '0px!important'
    },
    '& .MuiCardHeader-content': {
      paddingLeft: '0px!important',
      paddingRight: '0px!important'
    },
    '& .MuiCardContent-root': {
      paddingLeft: '0px!important',
      paddingRight: '0px!important'
    }
  },
  secondaryBackground: {
    backgroundColor: theme.palette.background.default
  }
}))

export interface CardExProps {
  title?: any
  titleAdd?: any
  name?: string
  subheader?: ReactNode
  collapsiable?: boolean
  topActions?: Actions
  bottomActions?: Actions
  bottomLeft?: any
  children?: any
  marginBottom?: string
  titleSize?: string
  height?: string
  width?: string
  minHeight?: string
  minWidth?: string
  backLink?: boolean
  overviewLink?: string
  overviewLinkTitle?: string
  navLinks?: SplitIconButtonAction[]
  contentStyle?: CSSProperties
  header?: ReactNode
  position?: CSSProperties['position']
  borderless?: boolean
  nopadding?: boolean
  contentClassName?: string
  cardStyle?: CSSProperties
  avatar?: ReactNode
  topContent?: ReactNode
  maxHeight?: string | number
  backgroundColor?: 'default' | 'paper' | undefined
}

export const CardEx = ({
  title,
  name,
  titleAdd,
  subheader,
  topActions,
  bottomActions,
  bottomLeft,
  children,
  marginBottom,
  titleSize,
  height,
  width,
  minHeight,
  minWidth,
  overviewLink,
  overviewLinkTitle,
  navLinks,
  contentStyle,
  header,
  position,
  contentClassName,
  backLink = false,
  collapsiable = false,
  borderless = false,
  nopadding = false,
  cardStyle,
  avatar = null,
  topContent,
  maxHeight,
  backgroundColor
}: CardExProps) => {
  const { classes } = useStyles()
  //@ts-ignore
  const { checkUserRole } = useContext(AppContext)
  const [expanded, setExpanded] = useState(true)
  const navigate = useNavigate()
  const location = useLocation()

  const handleExpand = useCallback(() => {
    setExpanded((last) => !last)
  }, [])

  const collapseAct = useMemo(
    () =>
      collapsiable ? (
        <IconButton
          size="small"
          icon={expanded ? <ExpandLess /> : <ExpandMore />}
          key="expand"
          onClick={handleExpand}
        />
      ) : null,
    [collapsiable, expanded, handleExpand]
  )

  const titleActions = useMemo(
    () => (
      <Grid2 container spacing={1} alignItems="center" justifyItems="flex-end">
        {buildActionButtons({
          checkUserRole,
          actions: topActions,
          xtra: collapseAct,
          asgriditem: true
        })}
      </Grid2>
    ),

    [checkUserRole, topActions, collapseAct]
  )

  const cardActions = useMemo(
    () =>
      (bottomLeft || bottomActions) && (
        <Grid container columnSpacing={1} paddingX={1} paddingBottom={1} alignItems="center">
          <Grid item flexGrow={1}>
            {bottomLeft}
          </Grid>
          <ButtonRow>
            {bottomActions && buildActionButtons({ checkUserRole, actions: bottomActions })}
          </ButtonRow>
        </Grid>
      ),
    [checkUserRole, bottomActions, bottomLeft]
  )

  const titleStyle = useMemo(() => ({ variant: 'h6', style: { fontSize: titleSize } }), [titleSize])

  const realTitle = useMemo(
    () =>
      header || (
        <>
          {title || ''} {titleAdd ? <small>{titleAdd}</small> : null}
        </>
      ),
    [title, titleAdd, header]
  )

  const avatarBody = useMemo(() => {
    let actions = [] as SplitIconButtonAction[]

    if (backLink) {
      actions.push({
        name: 'nav-back',
        icon: <ArrowBack />,
        label: location.key === 'default' ? 'Startseite' : 'Zurück',
        to: location.key === 'default' ? '/' : null,
        onClick: location.key === 'default' ? null : () => navigate(-1)
      })
    }

    if (overviewLink) {
      actions.push({
        name: 'nav-overview',
        icon: <ViewList />,
        label: overviewLinkTitle || 'Zur übersicht',
        to: isString(overviewLink) ? overviewLink : null
      })
    }

    if (Array.isArray(navLinks)) {
      actions = actions.concat(navLinks)
    }

    if (actions.length === 0) {
      return avatar
    }

    return (
      <>
        <SplitIconButton actions={actions} size="small" withLabel zIndex={5000} />
        {avatar}
      </>
    )
  }, [avatar, backLink, navLinks, overviewLink, overviewLinkTitle, location.key, navigate])

  const style = useMemo(
    () => ({
      ...cardStyle,
      marginBottom,
      height,
      width,
      maxHeight,
      minHeight,
      minWidth,
      position
    }),
    [cardStyle, marginBottom, height, width, maxHeight, minHeight, minWidth, position]
  )

  const headerClasses = useMemo(
    () =>
      avatarBody && {
        root: classes.cardHeaderRoot,
        avatar: classes.avatar
      },
    [classes.cardHeaderRoot, classes.avatar, avatarBody]
  )

  const content = collapsiable ? (
    <Collapse in={expanded} timeout="auto" unmountOnExit>
      <CardContent className={contentClassName} style={{ ...contentStyle, paddingTop: 0 }}>
        {children}
      </CardContent>
      {cardActions}
    </Collapse>
  ) : (
    <>
      <CardContent className={contentClassName} style={{ ...contentStyle, paddingTop: 0 }}>
        {children}
      </CardContent>
      {cardActions}
    </>
  )

  return (
    <Card
      className={clsx(
        classes.card,
        borderless && classes.borderless,
        nopadding && classes.nopadding,
        backgroundColor === 'default' && classes.secondaryBackground
      )}
      style={style}
      elevation={borderless ? 0 : undefined}
      data-name={name}
    >
      <CardHeader
        className={`${classes.header} ${
          header
            ? classes.headerComponent
            : Array.isArray(topActions) && topActions.length > 1
              ? classes.headerAction
              : ''
        } ${typeof title !== 'string' ? classes.headerComponentWithAction : ''}`}
        title={realTitle}
        subheader={subheader}
        //@ts-ignore
        titleTypographyProps={titleStyle}
        action={titleActions}
        avatar={avatarBody}
        classes={headerClasses}
        disableTypography={!!header}
      />
      {topContent && <CardContent>{topContent}</CardContent>}
      {content}
    </Card>
  )
}
