/* eslint-disable complexity */
import {ChevronLeft, ChevronRight, Clear} from '@mui/icons-material'
import {Checkbox, FormControl, FormHelperText, Grid2 as Grid, InputLabel, MenuItem, Select, SelectProps} from '@mui/material'
import {Actions, buildActionButtons} from '@utils/ui/Action'
import {AppContext} from '@utils/ui/App/AppContext'
import {IconButton} from '@utils/ui/Buttons/IconButton'
import {FieldCtrlType} from '@utils/ui/FieldCtrlType'
import {stopPropagation} from '@utils/ui/uiutils'
import {ifString, nextKey, oidOf, safeArray} from '@utils/utils'
import clsx from 'clsx'
import {useCallback, useContext, useMemo} from 'react'
import {makeStyles} from 'tss-react/mui'
import {Medal} from '@utils/ui/fields/Medal'
import {blueGrey} from '@mui/material/colors'

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles()((theme: any) => ({
  select: {
    position: 'relative',
    '& .clearButton': {
      // position: 'absolute',
      // right: 14,
      visibility: 'hidden'
    },
    '&:focus-within .clearButton, &:hover .clearButton': {
      visibility: 'visible'
    },
    '&> .MuiSelect-select': {
      // paddingRight: '42px !important'
    }
  },
  borderless: {
    '&::before': {
      borderBottom: 'none'
    }
  },
  menu: {
    '& ul': {
      display: 'table'
    },
    '& li': {
      display: 'table-row'
    },
    '& div:first-of-type': {
      textAlign: 'right'
    },
    '& div': {
      fontSize: '1rem',
      padding: '0 6px',
      display: 'table-cell'
    }
  },
  tableSize: {
    fontSize: '14px',
    '&>div': {
      paddingBottom: '2px'
    }
  },
  checkbox: {
    display: 'inline-block',
    padding: '0',
    margin: '0 0 -4px 0'
  }
}))

export type SelectFieldProps<T = any> = {
  id?: string
  label?: string
  value?: any
  onChange?: React.ChangeEventHandler<HTMLSelectElement>
  options?: T[]
  name?: string
  disabled?: boolean
  renderItem?: (opt: T) => React.ReactNode
  renderSelected?: (opt: T) => React.ReactNode
  emptyText?: string
  error?: any
  helperText?: string
  required?: boolean
  fieldCtrl?: FieldCtrlType
  optionValue?: string | ((opt: T, idx?: number) => any)
  optionText?: string | ((opt: T, idx?: number) => React.ReactNode)
  fullWidth?: boolean
  displayEmpty?: boolean
  title?: string
  className?: SelectProps['className']
  size?: 'small' | 'medium' | 'table'
  optionDisabled?: (opt: T) => boolean
  optionIgnored?: (opt: T) => boolean
  borderless?: boolean
  notNull?: boolean
  withButtons?: false | true | 'reverse'
  actions?: Actions
  noClear?: boolean
  multiple?: boolean
}

export const SelectField = <T = any,>({
  id,
  label,
  value,
  onChange,
  options,
  name,
  disabled,
  renderItem,
  renderSelected,
  emptyText,
  error,
  helperText,
  required,
  fieldCtrl,
  optionValue = 'id',
  optionText,
  fullWidth,
  displayEmpty = true,
  title,
  className,
  size,
  optionDisabled,
  optionIgnored,
  borderless,
  notNull,
  withButtons,
  actions,
  noClear,
  multiple
}: SelectFieldProps<T>) => {
  const { classes } = useStyles()

  const { checkUserRole } = useContext(AppContext)

  const labelId = useMemo(() => (id ? `${id}-label` : `select${nextKey()}-label`), [id])

  const realEmptyText = emptyText || ((required || notNull) && 'zu wählen') || 'Keine'

  const defaultRender =
    renderItem ||
    (typeof optionText === 'function' && optionText) ||
    (typeof optionText === 'string' && ((opt) => opt[optionText])) ||
    (() => 'missing renderItem or optionText!')

  const render = renderSelected || defaultRender

  const handleChange = (e) => {
    console.log('e', e.target.value)
    if (onChange) {
      if (
        e.target.value === '' ||
        e.target.value === '---' ||
        (e.target.value?.length > 0 && e.target.value[e.target.value.length - 1] === '---')
      ) {
        e.target.value = null
      }
      onChange(e)
    }
  }

  const getOptVal = useMemo(
    () =>
      (typeof optionValue === 'function' && optionValue) ||
      (typeof optionValue === 'string' && ((opt) => opt[optionValue])) ||
      ((opt) => opt),
    [optionValue]
  )

  const optionsSafe = useMemo(() => {
    const safe = options || []
    return safe.filter(
      (opt, idx) => value === getOptVal(opt, idx) || !(optionIgnored && optionIgnored(opt))
    )
  }, [getOptVal, optionIgnored, options, value])

  const renderValue = (al: any) => {
    if (al == null || al === '' || (multiple && safeArray(value).length === 0)) {
      return <span style={{ opacity: 0.6 }}>{realEmptyText}</span>
    }
    if (multiple) {
      return (
        <Grid container spacing={1}>
          {safeArray(al).map((aa, key) => (
            <Grid key={key}>
              <Medal variant="square" backgroundColor={blueGrey[200]}>
                {render(optionsSafe.find((x, idx) => getOptVal(x, idx) === aa) || ({} as any))}
              </Medal>
            </Grid>
          ))}
        </Grid>
      )
    }
    return render(optionsSafe.find((x, idx) => getOptVal(x, idx) === al) || ({} as any))
  }
  const pos =
    !withButtons || value == null
      ? null
      : optionsSafe.findIndex((s, idx) => getOptVal(s, idx) === value)

  const handleChevron = useCallback(
    (inc: number) => {
      if (onChange) {
        const npos = pos == null ? 0 : pos + inc
        if (npos >= 0 && npos < optionsSafe.length) {
          const next = optionsSafe[npos]
          // @ts-ignore
          onChange({ target: { name, value: getOptVal(next, npos) } })
        } else if (npos === -1 && !required) {
          // @ts-ignore
          onChange({ target: { name, value: null } })
        }
      }
    },
    [getOptVal, name, onChange, optionsSafe, pos, required]
  )

  const disabledX = disabled || fieldCtrl?.disabled
  const requiredX = notNull ? true : required

  const startAdornment = useMemo(() => {
    const reverse = withButtons === 'reverse'
    const disabledLeft = (pos == null && value == null) || pos === 0 || disabledX
    const disabledRight = pos >= optionsSafe.length - 1 || disabledX
    return withButtons && !disabledX ? (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        onClick={(e) => {
          stopPropagation(e)
        }}
        onKeyDown={(e) => {
          stopPropagation(e)
        }}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <IconButton
          key="prev"
          icon={<ChevronLeft />}
          size="small"
          disabled={reverse ? disabledRight : disabledLeft}
          onClick={() => handleChevron(reverse ? 1 : -1)}
          style={{ marginLeft: -2, marginRight: -2, padding: 2 }}
        />
        <IconButton
          key="next"
          icon={<ChevronRight />}
          size="small"
          disabled={reverse ? disabledLeft : disabledRight}
          onClick={() => handleChevron(reverse ? -1 : 1)}
          style={{ marginLeft: -2, marginRight: -2, padding: 2 }}
        />
      </div>
    ) : null
  }, [disabledX, handleChevron, optionsSafe.length, pos, value, withButtons])

  const hasOptions =
    optionsSafe?.length > 1 || (optionsSafe?.length === 1 && optionsSafe[0] != null)

  const valuex = multiple
    ? (hasOptions && safeArray(value)) || []
    : hasOptions && value != null
      ? value
      : ''

  const select = (
    <Select
      id={id}
      labelId={labelId}
      value={valuex}
      name={name}
      onChange={handleChange}
      displayEmpty={displayEmpty}
      disabled={disabledX}
      data-name={name}
      className={clsx(
        classes.select,
        size == 'table' && classes.tableSize,
        borderless && classes.borderless,
        className
      )}
      renderValue={renderValue}
      fullWidth={fullWidth}
      variant="standard"
      multiple={multiple}
      size={size == 'table' ? 'small' : size}
      SelectDisplayProps={{
        title
      }}
      startAdornment={startAdornment}
      endAdornment={
        <Grid
          container
          padding={0}
          margin={0}
          wrap="nowrap"
          width="auto"
          position="absolute"
          right={16}
        >
          {buildActionButtons({
            checkUserRole,
            actions,
            tiny: true,
            xtra: !disabledX && !requiredX && !noClear && (
              <Grid>
                <IconButton
                  icon={<Clear />}
                  className="clearButton"
                  size="tiny"
                  tabIndex={-1}
                  onClick={(e) =>
                    handleChange({ ...e, target: { ...e.target, name, value: null } })
                  }
                />
              </Grid>
            ),
            asgriditem: true
          })}
        </Grid>
      }
    >
      {(!required && !notNull) || value == null ? (
        <MenuItem key="null" value="---">
          <div>
            <small>{realEmptyText}</small>
          </div>
        </MenuItem>
      ) : null}
      {optionsSafe.map((al, idx) => (
        <MenuItem
          key={ifString(al) || oidOf(al)}
          value={getOptVal(al, idx)}
          disabled={optionDisabled && optionDisabled(al)}
        >
          {multiple ? (
            <Grid container spacing={1}>
              <Grid>
                <Checkbox
                  className={classes.checkbox}
                  checked={Array.isArray(value) && value.includes(getOptVal(al, idx))}
                  size="small"
                />
              </Grid>
              <Grid flexGrow={1}>{defaultRender(al)}</Grid>
            </Grid>
          ) : (
            defaultRender(al)
          )}
        </MenuItem>
      ))}
    </Select>
  )
  if (label)
    return (
      <FormControl
        variant="standard"
        fullWidth={fullWidth}
        error={!!error || fieldCtrl?.error}
        required={required || fieldCtrl?.required}
        disabled={disabledX}
      >
        <InputLabel id={labelId} shrink>
          {label}
        </InputLabel>
        {select}
        <FormHelperText error={!!error || fieldCtrl?.error}>
          {ifString(error) || fieldCtrl?.helperText || helperText}
        </FormHelperText>
      </FormControl>
    )

  return select
}
