import { Button } from '@mui/material'
import { AppPaths } from '@one/AppPaths'
import { LieferantenListeSuchDialog } from '@one/components/LieferantenListe/LieferantenListeSuchDialog/LieferantenListeSuchDialog'
import { HkmEnum } from '@one/enums/HkmEnum'
import { UserRoles } from '@one/UserRoles'
import { useEnums } from '@utils/enums'
import { useStateEx } from '@utils/stateex'
import { CardEx } from '@utils/ui/CardEx'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { useDialogAnker } from '@utils/ui/DialogAnker'
import { useMessageDialog } from '@utils/ui/MessageDialog'
import { ScrollPanel } from '@utils/ui/ScrollPanel'
import { arrayMergeUnique } from '@utils/utils'
import { useCallback, useMemo } from 'react'

export interface LieferantenVerteilerListenTableProps {
  model: any
  updModel: (nextModel: any) => void
}

export const LieferantenVerteilerListenTable = ({
  model,
  updModel
}: LieferantenVerteilerListenTableProps) => {
  const { askToRemove } = useMessageDialog()
  const { et } = useEnums()
  const [selected, setSelected, getSelected] = useStateEx(new Set())

  const [DlgAnker, showDlg] = useDialogAnker()

  const onComplete = useCallback(
    (data) => {
      if (data) {
        updModel((old) => {
          const eintraege = arrayMergeUnique(old.eintraege, data, 'id')
          return {
            ...old,
            eintraege
          }
        })
      }
    },
    [updModel]
  )

  const onMultiRemoveSelection = useCallback(
    (list) => () => {
      updModel((oldModel) => {
        const eintraege = oldModel.eintraege.filter((item) => {
          return !list.has(item)
        })
        return { ...oldModel, eintraege }
      })
    },
    [updModel]
  )

  const removeSelektion = useCallback(
    (data) => () => {
      if (data) {
        onMultiRemoveSelection(new Set([data]))()
      }
    },
    [onMultiRemoveSelection]
  )

  const removeAfterCheck = useCallback(() => {
    askToRemove({
      title: `Selektion mit ${getSelected().size} Lieferantenlisten entfernen`,
      onConfirm: onMultiRemoveSelection(getSelected())
    })
  }, [onMultiRemoveSelection, getSelected, askToRemove])

  const onNewLieferantenListe = useCallback(() => {
    showDlg((visible, onClose) => (
      <LieferantenListeSuchDialog open={visible} onClose={onClose(onComplete)} />
    ))
  }, [onComplete, showDlg])

  const columns = useMemo(
    () => [
      {
        field: 'name',
        header: 'Name'
      },
      {
        field: 'typ',
        header: 'Typ',
        body: (row: any) => et(HkmEnum.LieferantenListeTyp, row.typ)
      },
      {
        field: 'lieferantenAnzahl',
        header: 'Lieferantenanzahl'
      }
    ],
    [et]
  )

  const tableActions = useMemo(
    () => [
      {
        icon: 'arrow_forward',
        tooltip: 'Lieferantenliste öffnen',
        getLink: (data) => AppPaths.lieferanteliste.LieferantenListePflegeFn(data.id)
      },
      {
        icon: 'delete',
        tooltip: 'Löschen',
        handler: (data) => () => {
          askToRemove({
            title: `Selektion "${data.name}" entfernen`,
            onConfirm: removeSelektion(data)
          })
        }
      }
    ],
    [askToRemove, removeSelektion]
  )

  const actions = useMemo(
    () => [
      {
        role: UserRoles.STAMMDATEN_EDITOR,
        tooltip: 'Selektion für alle Lieferantern hinzufügen',
        text: 'Lieferantenliste',
        icon: 'add',
        onClick: onNewLieferantenListe
      }
    ],
    [onNewLieferantenListe]
  )

  const bottomActions = useMemo(
    () => [
      <Button
        disabled={selected.size === 0}
        onClick={removeAfterCheck}
        variant="contained"
        color="error"
        key="removeLieferant"
      >
        Lieferanten entfernen
      </Button>
    ],
    [removeAfterCheck, selected]
  )

  return (
    <CardEx title="Lieferantenlisten" height="100%" topActions={actions}>
      <ScrollPanel>
        <DataTableCard
          name="LieferantenVerteilerListenTableProps"
          columns={columns}
          value={model.eintraege}
          selectMode="multi"
          selected={selected}
          onSelect={setSelected}
          actions={tableActions}
          bottomActions={bottomActions}
          dense
          localStateName="LieferantenVerteilerListenTableProps"
        />
      </ScrollPanel>
      <DlgAnker />
    </CardEx>
  )
}
