import { Chip, Grid2 as Grid, Typography } from '@mui/material'
import { ArtikelCell } from '@one/components/common/ArtikelCell'
import {
  ArtikelDatenpoolDisplayJson,
  ArtikelDatenpoolUebernahmeStatus
} from '@one/typings/apiTypings'
import { CardEx } from '@utils/ui/CardEx'
import React, { useContext } from 'react'
import { MengeneinheitenWerteCell } from '@one/components/ArtikelDatenpool/MengeneinheitenWerteCell'
import { ThemeContext } from '@utils/ui/Theme'
import { ScrollPanel } from '@utils/ui/ScrollPanel'

export interface UebernahmeEigenlistungDetailsProps {
  artikel: ArtikelDatenpoolDisplayJson
}

export const UebernahmeEigenlistungDetails = ({ artikel }: UebernahmeEigenlistungDetailsProps) => {
  const { darkMode } = useContext(ThemeContext)

  const fehler =
    artikel && artikel.meldungen ? (
      <Grid flexGrow={1}>
        <CardEx title="Meldungen" height="100%">
          <table style={{ tableLayout: 'fixed', textAlign: 'right' }}>
            {artikel.meldungen.map((item, index) => (
              // eslint-disable-next-line react/jsx-key
              <tr>
                <td>
                  <Chip
                    color={item.severity === 'WARN' ? 'default' : 'error'}
                    label={item.severity === 'WARN' ? 'Warnung' : 'Fehler'}
                    sx={{ minWidth: 80 }}
                    size="small"
                  />
                  &nbsp;
                </td>
                <td style={{ textAlign: 'left' }}>
                  <div key={`${item.messageId.id}-${index}`}>
                    <Typography variant="body2">{item.message}</Typography>
                  </div>
                </td>
              </tr>
            ))}
          </table>
        </CardEx>
      </Grid>
    ) : null

  const mengeneinheiten =
    artikel && artikel.mengeneinheiten?.length ? (
      <Grid flexGrow={1}>
        <CardEx title="Mengeneinheiten">
          <MengeneinheitenWerteCell werte={artikel.mengeneinheiten} darkMode={darkMode} />
        </CardEx>
      </Grid>
    ) : null

  const artikelList =
    artikel &&
    artikel.status !== ArtikelDatenpoolUebernahmeStatus.ANLEGBAR &&
    artikel.status !== ArtikelDatenpoolUebernahmeStatus.AKTUALISIERBAR &&
    artikel.artikel?.length ? (
      <Grid flexGrow={1}>
        <CardEx
          title="Artikel, die eine Anlage verhindern"
          subheader="hage-Nr., IAN oder EAN werden schon verwendet:"
          height="100%"
        >
          {artikel.artikel
            .filter((a, idx) => artikel.artikel.findIndex((aa) => aa.id == a.id) == idx)
            .map((item) => (
              <ArtikelCell key={`${item.id}`} artikel={item} asLink />
            ))}
        </CardEx>
      </Grid>
    ) : null

  return (
    <ScrollPanel height="100%" autoHeightMax="100%">
      <Grid container direction="column" spacing={2}>
        {mengeneinheiten}
        {fehler}
        {artikelList}
      </Grid>
    </ScrollPanel>
  )
}
