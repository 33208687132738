import { UseCaseStateJson } from '@one/typings/apiTypings'
import { AxiosInstance } from 'axios'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ApiExclusive, useApiCaller } from './apicaller'
import { ChangeFunction, useFormState } from './formstate'
import { isEmpty, safeArray, ValueDecorator } from './utils'

export type SearcherJson<C, R> = {
  criteria?: C
  limited?: boolean
  result?: R[]
}

export type SearcherUebersichtJson<C, R> = {
  searcher?: SearcherJson<C, R>
  state?: UseCaseStateJson
}

export type SearcherStateProps<C, R, U extends SearcherUebersichtJson<C, R>> = {
  doOpen?: boolean | 'search'
  api: AxiosInstance
  url: string
  initialParams?: C
  valueDecorator?: ValueDecorator
  stateInterceptor?: (name: string, state: C, old: C) => C
  handleOpenParams?: (criterias?: C) => C
  onData?: (data: U) => SearcherUebersichtJson<C, R>
  filter?: (row: R) => boolean
}

export interface SearcherResultType<R> {
  rows: R[]
  limited: boolean
  notFound: boolean
  busy: boolean
  duration: number | null
}

export interface SearcherStateType<C, R> {
  criteria: C
  result: SearcherResultType<R>
  setResult: (data: R[] | null | ((old: R[]) => R[]), changeLimited?: boolean) => void
  search: VoidFunction
  searchEx: (criterias?: C) => void
  onCriteriaChange: ChangeFunction<C>
}

export const useSearcherState = <
  C,
  R,
  U extends SearcherUebersichtJson<C, R> = SearcherUebersichtJson<C, R>
>({
  doOpen = true,
  valueDecorator,
  stateInterceptor,
  url,
  api,
  initialParams = {} as C,
  handleOpenParams,
  onData,
  filter
}: SearcherStateProps<C, R, U>): SearcherStateType<C, R> => {
  const [apiCall, apiBusy] = useApiCaller(api)

  const [criteria, onCriteriaChange, getCriteria] = useFormState<C>(
    initialParams,
    valueDecorator,
    stateInterceptor
  )
  const [rows, setRows] = useState<R[]>([])
  const [duration, setDuration] = useState<number | null>(null)
  const [limited, setLimited] = useState(false)
  const [notFound, setNotFound] = useState(false)

  const thisRef = useRef<any>({})
  thisRef.current = {
    url,
    handleOpenParams: handleOpenParams || ((c: C) => c),
    onData: onData || ((d: SearcherUebersichtJson<C, R>) => d),
    prepareRows: (r: R[]) => (filter ? safeArray(r).filter(filter) : safeArray(r))
  }

  const open = useCallback(() => {
    setRows([])
    setDuration(null)
    setNotFound(false)
    apiCall<SearcherUebersichtJson<C, R>>({
      method: 'GET',
      exclusive: ApiExclusive.BLOCK,
      rest: `${thisRef.current.url}/uebersichtAnzeigen/open`,
      onErrorMsg: 'Übersicht konnten nicht geladen werden.',
      onSuccess: (data, _u, d) => {
        if (data.searcher?.criteria != null) {
          onCriteriaChange(() => thisRef.current.handleOpenParams(data.searcher.criteria))
        }
        if (data.searcher?.result != null) {
          data = thisRef.current.onData(data)
          setDuration(d)
          setRows(thisRef.current.prepareRows(data.searcher.result))
          setLimited(data.searcher.limited)
        }
      }
    })
  }, [apiCall, onCriteriaChange])

  const searchEx = useCallback(
    (criterias?: C) => {
      if (criterias) {
        onCriteriaChange(() => criterias)
      }
      apiCall<SearcherUebersichtJson<C, R>>({
        method: 'POST',
        rest: `${thisRef.current.url}/uebersichtAnzeigen/search`,
        data: criterias ?? getCriteria(),
        exclusive: ApiExclusive.BLOCK,
        onSuccess: (data, u, d) => {
          data = thisRef.current.onData(data)
          setDuration(d)
          setRows(thisRef.current.prepareRows(data.searcher.result))
          setLimited(data.searcher.limited)
          setNotFound(isEmpty(data.searcher.result))
        },
        onError: () => {
          setDuration(null)
          setRows([])
          setLimited(false)
          setNotFound(true)
        }
      })
    },
    [apiCall, getCriteria, onCriteriaChange]
  )

  const search = useCallback(() => {
    searchEx()
  }, [searchEx])

  const setResult = useCallback(
    (data: R[] | null | ((old: R[]) => R[]), changeLimited?: boolean) => {
      setRows((rows) => {
        if (typeof data === 'function') {
          return thisRef.current.prepareRows((data as (old: R[]) => R[])(rows))
        }
        return thisRef.current.prepareRows(data ?? rows)
      })
      setLimited(changeLimited ?? limited)
    },
    [limited]
  )

  useEffect(() => {
    if (doOpen === 'search') {
      search()
    } else if (doOpen) {
      open()
    }
  }, [doOpen, open, search])

  const result = useMemo(
    () =>
      ({
        rows,
        limited,
        notFound,
        busy: apiBusy,
        duration
      }) as SearcherResultType<R>,
    [apiBusy, duration, limited, notFound, rows]
  )

  return {
    criteria,
    result,
    search,
    searchEx,
    onCriteriaChange,
    setResult
  }
}
