import { Grid2 as Grid, Typography } from '@mui/material'
import { AppPaths } from '@one/AppPaths'
import { ArtikelDetailsTabelle } from '@one/components/Artikel/ArtikelAnzeigen/details/ArtikelDetailsTabelle'
import { ArtikelEanTable } from '@one/components/Artikel/ArtikelAnzeigen/details/ArtikelEanTable'
import {
  ArtikelLieferantenColumns,
  ArtikelLieferantenTableActions
} from '@one/components/Artikel/ArtikelAnzeigen/details/ArtikelErpLieferanten'
import { ArtikelMengeneinheiten } from '@one/components/Artikel/ArtikelAnzeigen/details/ArtikelMengeneinheiten'
import { ArtikelWarengruppenZe } from '@one/components/Artikel/ArtikelAnzeigen/details/ArtikelWarengruppenZe'
import { ArtikelPimEkPreise } from '@one/components/Artikel/ArtikelAnzeigen/pim/ArtikelPimEkPreise'
import { ArtikelPimVkPreise } from '@one/components/Artikel/ArtikelAnzeigen/pim/ArtikelPimVkPreise'
import { ArtikelBetriebstypCell } from '@one/components/common/ArtikelBetriebstypCell'
import { ZeArtikelAnzeigenJson } from '@one/typings/apiTypings'
import { Action } from '@utils/ui/Action'
import { AppContext } from '@utils/ui/App/AppContext'
import { ButtonRow } from '@utils/ui/Buttons/ButtonRow'
import { ReloadButton } from '@utils/ui/Buttons/ReloadButton'
import { CardEx } from '@utils/ui/CardEx'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { HistoricDataPager } from '@utils/ui/HistoricDataPager'
import { IndexPanel, IndexPanelItem } from '@utils/ui/IndexPanel'
import { TooltipWrapper } from '@utils/ui/TooltipWrapper'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { StaticField } from '@utils/ui/fields/StaticField'
import { useCallback, useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme: any) => ({
  header: {
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'center'
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: '10px'
    }
  },
  historicPagerWrapper: {
    [theme.breakpoints.down('md')]: {
      paddingTop: '10px !important'
    }
  }
}))

export type ZeArtikelViewProps = {
  data: ZeArtikelAnzeigenJson
  reload: () => void
}

export const ZeArtikelView = ({ reload, data }: ZeArtikelViewProps) => {
  const { classes } = useStyles()
  const { isWithEkPreis, isWithVkPreis, isWithErpImport } = useContext(AppContext)
  const navigate = useNavigate()
  const display = data.display
  const revision = data.revision
  const id = display.id
  const partnerArtikelId = data.partnerArtikelId
  const artikel = data.artikel
  const artikelLieferanten = data.artikelLieferanten
  const title = artikel?.bez1 ? `"${artikel.bez1}"` : ''

  const hauptEan = useMemo(() => {
    return artikel?.eans?.find((ean) => ean.hauptEAN)?.ean || null
  }, [artikel])

  const topActions = useMemo(
    () =>
      [
        partnerArtikelId
          ? ({
              navlink: AppPaths.ArtikelFn(partnerArtikelId),
              text: 'Zum ERP-Artikel',
              variant: 'outlined'
            } as Action)
          : null
      ].filter(Boolean),
    [partnerArtikelId]
  )
  const historicNavigate = useCallback(
    (revision) => navigate(AppPaths.ArtikelExFn(id, revision.id, null)),
    [navigate, id]
  )

  const header = useMemo(
    () => (
      <Grid container spacing={3} className={classes.header}>
        <Grid>
          <Typography variant="h6">{`Listungsartikel ${title}`}</Typography>
        </Grid>
        <Grid className={classes.historicPagerWrapper}>
          <HistoricDataPager
            revisions={data.revisions}
            currentRevision={revision}
            to={historicNavigate}
          />
        </Grid>
      </Grid>
    ),
    [
      classes.header,
      classes.historicPagerWrapper,
      title,
      data.revisions,
      revision,
      historicNavigate
    ]
  )

  const content = [
    {
      id: 'bezeichnung',
      label: 'Bezeichnung',
      body: () => (
        <CardEx title="Bezeichnung">
          <Grid container spacing={2}>
            <Grid size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}>
              <StaticField label="Lieferschein Bezeichnung 1" value={artikel.bez1} />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}>
              <StaticField label="Lieferschein Bezeichnung 2" value={artikel.bez2} />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}>
              <StaticField label="Lieferschein Bezeichnung 3" value={artikel.bez3} />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}>
              <StaticField label="Lieferschein Bezeichnung 4" value={artikel.bez4} />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, md: 8, lg: 8, xl: 8 }}>
              <StaticField label="Bontext" value={artikel.bontext} />
            </Grid>
          </Grid>
          <Grid container spacing={2} paddingTop={2}>
            <Grid size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}>
              <StaticField label="Etikett Bezeichnung 1" value={artikel.bez1} />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}>
              <StaticField label="Etikett Bezeichnung 2" value={artikel.bez2} />
            </Grid>
          </Grid>
        </CardEx>
      ),
      exclude: artikel == null
    },
    {
      id: 'kennzeichen',
      label: 'Kennzeichen',
      body: () => (
        <CardEx title="Kennzeichen">
          <Grid container spacing={2}>
            <Grid size={{ xs: 4 }}>
              <StaticField label="hage-Nr." value={artikel.hageNummer} />
            </Grid>
            <Grid size={{ xs: 8 }}>
              <StaticField label="BT-Nr." value={artikel.btNummer} />
            </Grid>
            <Grid size={{ xs: 4 }}>
              <StaticField label="B-Typ" formStyle={{ minWidth: '12rem' }}>
                <ArtikelBetriebstypCell value={artikel.betriebsTyp} all name="betriebsTyp" />
              </StaticField>
            </Grid>
            <Grid size={{ xs: 4 }}>
              <StaticField label="Haupt-EAN" value={hauptEan} />
            </Grid>
            <Grid size={{ xs: 4 }} />
            <Grid size={{ xs: 4 }}>
              <TooltipWrapper title={artikel.artikelArt?.ignoriert ? 'ignorierte Artikelart' : ''}>
                <StaticField
                  label="Artikelart"
                  style={artikel.artikelArt?.ignoriert ? { color: 'grey' } : {}}
                  value={`${artikel.artikelArt?.bez ?? ''} ${
                    artikel.artikelArt?.kurz ? `(${artikel.artikelArt?.kurz})` : ''
                  }`}
                />
              </TooltipWrapper>
            </Grid>
            <Grid size={{ xs: 4 }}>
              <StaticField label="Matchcode" value={artikel.matchcode} />
            </Grid>
            <Grid size={{ xs: 4 }}>
              <StaticField label="Online" value={artikel.online ? 'J' : 'N'} />
            </Grid>
            <Grid size={{ xs: 4 }}>
              <StaticField label="Preisrolle" value={artikel.preisrolle} empty />
            </Grid>
            <Grid size={{ xs: 4 }}>
              <StaticField label="CO2 Abgabe je Basiseinheit" value={artikel.co2AbgabeJeBasiseinheit} empty />
            </Grid>
            <Grid size={{ xs: 4 }}>
              <StaticField label="Pflichttext CO2 Kostenaufteilungsgesetz" value={artikel.pflichttextCO2Kostenaufteilungsgesetz} empty />
            </Grid>
            <Grid size={{ xs: 4 }}>
              <StaticField label="Brennstoffemission je Basiseinheit" value={artikel.brennstoffemissionJeBasiseinheit} empty />
            </Grid>
            <Grid size={{ xs: 4 }}>
              <StaticField label="Energiegehalt je Basiseinheit" value={artikel.energiegehaltJeBasiseinheit} empty />
            </Grid>
            <Grid size={{ xs: 4 }}>
              <StaticField label="Heizwertbezogener Emissionsfaktor je Basiseinheit" value={artikel.heizwertbezogenerEmissionsfaktorJeBasiseinheit} empty />
            </Grid>
          </Grid>
        </CardEx>
      ),
      exclude: artikel == null
    },
    {
      id: 'masse',
      label: 'Maße',
      body: () => (
        <CardEx title="Maße">
          <Grid container spacing={2}>
            <Grid size={{ xs: 3, md: 2 }}>
              <StaticField label="Länge" value={artikel.laenge} />
            </Grid>
            <Grid size={{ xs: 3, md: 2 }}>
              <StaticField label="Breite" value={artikel.breite} />
            </Grid>
            <Grid size={{ xs: 3, md: 2 }}>
              <StaticField label="Höhe" value={artikel.hoehe} />
            </Grid>
            <Grid size={{ xs: 3, md: 6 }} />
            <Grid size={{ xs: 12 }}>
              <StaticField label="Gewicht" value={artikel.gewicht} />
            </Grid>
          </Grid>
        </CardEx>
      ),
      exclude: artikel == null
    },
    {
      id: 'status',
      label: 'Status',
      body: () => (
        <CardEx title="Status">
          <Grid container spacing={2}>
            {isWithErpImport && (
              <>
                <Grid size={{ xs: 6, md: 4, lg: 2 }}>
                  <Checkbox
                    disabled
                    labelNotDisabled
                    indeterminate
                    label="Importfehler"
                    checked={data.importErrorStatus?.importFehlerData}
                  />
                </Grid>
                {isWithEkPreis && (
                  <Grid size={{ xs: 6, md: 4, lg: 2 }}>
                    <Checkbox
                      disabled
                      labelNotDisabled
                      indeterminate
                      label="Importfehler (Preise - EK)"
                      checked={data.importErrorStatus?.importFehlerPreiseEk}
                    />
                  </Grid>
                )}
                {isWithVkPreis && (
                  <Grid size={{ xs: 6, md: 4, lg: 2 }}>
                    <Checkbox
                      disabled
                      labelNotDisabled
                      indeterminate
                      label="Importfehler (Preise - VK)"
                      checked={data.importErrorStatus?.importFehlerPreiseVk}
                    />
                  </Grid>
                )}
              </>
            )}
            <Grid size={{ xs: 6, md: 4, lg: 2 }}>
              <Checkbox label="Mhd" checked={artikel.mhd} disabled labelNotDisabled indeterminate />
            </Grid>
            <Grid size={{ xs: 6, md: 4, lg: 2 }}>
              <Checkbox
                label="Eckpreis"
                checked={artikel.eckpreis}
                disabled
                labelNotDisabled
                indeterminate
              />
            </Grid>
            <Grid size={{ xs: 6, md: 4, lg: 2 }}>
              <Checkbox
                label="Dauerniedrigpreis"
                checked={artikel.dnp}
                disabled
                labelNotDisabled
                indeterminate
              />
            </Grid>
          </Grid>
        </CardEx>
      ),
      exclude: artikel == null
    },
    {
      id: 'eans',
      label: 'EANs',
      body: () => <ArtikelEanTable eans={artikel.eans} />,
      exclude: artikel == null
    },
    {
      id: 'mengeneinheiten',
      label: 'Mengeneinheiten',
      body: () => <ArtikelMengeneinheiten artikel={artikel} mode="ze" />,
      exclude: artikel == null
    },
    {
      id: 'lieferanten',
      label: 'Lieferanten',
      body: () => (
        <ArtikelDetailsTabelle
          name="ArtikelLieferantenSection"
          title="Lieferanten"
          values={artikelLieferanten}
          columns={ArtikelLieferantenColumns}
          tableActions={ArtikelLieferantenTableActions}
          normal
        />
      ),
      exclude: artikel == null || artikelLieferanten == null
    },
    {
      id: 'warengruppen',
      label: 'Warengruppen',
      body: () => <ArtikelWarengruppenZe artikel={artikel} />,
      exclude: artikel == null
    },
    {
      id: 'ekpreise',
      label: 'EK Preise',
      body: () => <ArtikelPimEkPreise lieferanten={data.ekPreise} />,
      exclude: !(data.ekPreise?.length > 0)
    },
    {
      id: 'vkpreise',
      label: 'VK-Preise',
      body: () => <ArtikelPimVkPreise vkPreise={data.vkPreise} />,
      exclude: !(data.vkPreise?.length > 0)
    }
  ] as IndexPanelItem[]

  return (
    <Frame space>
      <FrameRow>
        <CardEx
          backLink
          overviewLink={AppPaths.ArtikelUebersicht}
          topActions={topActions}
          header={header}
          contentStyle={{ paddingBottom: 0 }}
        />
      </FrameRow>
      <FrameBody>
        <IndexPanel content={content} />
      </FrameBody>
      <FrameRow>
        <ButtonRow>
          <ReloadButton onClick={reload} />
        </ButtonRow>
      </FrameRow>
    </Frame>
  )
}
