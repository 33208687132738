import { Grid2 as Grid } from '@mui/material'
import { LieferantField } from '@one/components/Lieferant/LieferantField'
import { EkRabattgruppenListeBearbeitenJson, LieferantDisplayJson } from '@one/typings/apiTypings'
import { ValueChangeFn } from '@utils/modelmgr'
import { CardEx } from '@utils/ui/CardEx'
import { DateField } from '@utils/ui/fields/DateField'

export interface EkRabattgruppenFormProps {
  lieferant?: LieferantDisplayJson
  gueltigVon?: Date | string
  errors?: any
  needCreate: boolean
  onValueChange: ValueChangeFn<EkRabattgruppenListeBearbeitenJson>
}

export const EkRabattgruppenForm = ({
  lieferant,
  errors,
  gueltigVon,
  onValueChange,
  needCreate
}) => {
  return (
    <CardEx
      backLink
      title="EK-Rabattgruppen Massenpflege"
      subheader={
        needCreate &&
        'Es ist  keine Massenpflege für den Lieferenanten aktiv, legen Sie erst eine neue an.'
      }
    >
      <Grid container spacing={3}>
        <Grid size={{xs:12,sm:12,md:8,lg:6,xl:5}}>
          <LieferantField
            value={lieferant}
            label="Lieferant"
            name="lieferant"
            fullWidth
            onChange={onValueChange}
            modus="dezentral"
            error={errors.lieferant}
            asLink
            disabled // vorerst
          />
        </Grid>
        <Grid size={{xs:6}}>
          <DateField
            name="gueltigVon"
            label="Gültigkeitzeitpunkt der Rabatte"
            disableToolbar
            value={gueltigVon}
            error={errors.gueltigVon}
            disabled={!needCreate}
            // minDate={model.gueltigAbMin}
            // maxDate={model.gueltigAbMax}
            onChange={onValueChange}
          />
        </Grid>
      </Grid>
    </CardEx>
  )
}
