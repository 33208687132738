import { CorporateFare, Lock, Person } from '@mui/icons-material'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid2 as Grid,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material'
import { DefaultFrame } from '@one/DefaultFrame'
import { useServerIdent } from '@one/ServerIdent'
import { debugLog } from '@utils/logging'
import { WaitPlane } from '@utils/ui/planes/WaitPlane'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'

export interface LoginDialogProps {
  onLoginOk: () => void
}

// Styling definitions
const useStyles = makeStyles()((theme: any) => ({
  card: {
    overflow: 'auto',
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      width: 345,
      height: 'auto'
    },
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0
    },
    width: '100%',
    height: '100%',
    zIndex: 2
  },
  cardActions: {
    padding: theme.spacing(2)
  }
}))

/**
 * Component that displays a login dialog.
 *
 * @param {func} onLoginOk Callback for successful login
 */
export const LoginDialog = ({ onLoginOk }: LoginDialogProps) => {
  const { classes } = useStyles()

  const [state, setState] = useState({
    gln: localStorage.getItem('lastgln') || '',
    username: '',
    password: '',
    focus: false,
    wait: false
  })

  const { enqueueSnackbar } = useSnackbar()

  const [ident] = useServerIdent()

  const handleLogin = () => {
    const { username, password, gln } = state
    setState({ ...state, username: '', password: '', wait: true })
    axios
      .post('login', { username, password, gln })
      .then(() => {
        if (gln) {
          localStorage.setItem('lastgln', gln)
        }
        onLoginOk()
      })
      .catch((error) => {
        debugLog('ERROR', { ...error })
        setState({ ...state, wait: false, focus: false })
        const response = error.response
        let message = response.headers?.unauthorizedhint
        switch (response.status) {
          case 401:
            message = message || 'Anmeldedaten sind inkorrekt'
            break
          default:
            message =
              message ||
              'Anmeldung gescheitert. Der Server hat einen Fehler gemeldet. Bitte versuchen Sie es später erneut oder wenden Sie sich an den Support.'
        }
        enqueueSnackbar(message, { variant: 'error' })
      })
  }

  const handleLoginKey = (e) => {
    if (e.keyCode === 13 && state.username.length !== 0 && state.password.length !== 0)
      handleLogin()
  }

  /**
   * Change handler for all input fields.
   *
   * @param {object} e The change event
   */
  const handleChange = (e) => {
    const { name, value } = e.target
    setState({ ...state, [name]: value })
  }

  useEffect(() => {
    if (state.focus === false) {
      setTimeout(() => {
        setState({ ...state, focus: true })
        window.document.getElementById(state.gln === '' ? 'gln' : 'username').focus()
      }, 250)
    }
  }, [state])

  return (
    <DefaultFrame>
      <Card className={classes.card} raised>
        <WaitPlane wait={state.wait} />
        <CardHeader
          title={
            <span>
              <Typography variant="h5">Anmeldung</Typography>
              <Typography variant="subtitle2">ONE Frontend</Typography>
              <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <div style={{ fontSize: '60%' }}>{ident?.envName}</div>
                <div style={{ fontSize: '40%', lineHeight: 1 }}>
                  <span>Version </span>
                  {ident?.version}
                </div>
              </Box>
            </span>
          }
          slotProps={{ variant: 'h5' }}
          avatar={<img src="logo.png" height="64px" alt="hagebau" />}
        />
        <CardContent>
          <form>
            <Grid container spacing={4}>
              <Grid width="100%">
                <TextField
                  id="gln"
                  name="gln"
                  label="Gesellschafter-Nr."
                  value={state.gln}
                  onChange={handleChange}
                  onKeyDown={handleLoginKey}
                  fullWidth
                  disabled={state.wait}
                  variant="standard"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CorporateFare />
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid width="100%">
                <TextField
                  id="username"
                  name="username"
                  autoComplete="username"
                  label="Benutzername"
                  value={state.username}
                  onChange={handleChange}
                  onKeyDown={handleLoginKey}
                  fullWidth
                  disabled={state.wait}
                  variant="standard"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Person />
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid width="100%">
                <TextField
                  id="password"
                  name="password"
                  autoComplete="current-password"
                  label="Passwort"
                  value={state.password}
                  onChange={handleChange}
                  onKeyDown={handleLoginKey}
                  fullWidth
                  disabled={state.wait}
                  type="password"
                  variant="standard"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Lock />
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
            </Grid>
          </form>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            fullWidth
            type="submit"
            onClick={handleLogin}
            disabled={state.wait || state.username.length === 0 || state.password.length === 0}
            onKeyDown={handleLoginKey}
          >
            Anmelden
          </Button>
        </CardActions>
      </Card>
    </DefaultFrame>
  )
}
