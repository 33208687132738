import { AppPaths } from '@one/AppPaths'
import { EventNames } from '@one/EventNames'
import { MessageID } from '@one/MessageIDs'
import { api } from '@one/api'
import {
  ArtikelBearbeitungsListeBearbeitenJson,
  ArtikelBearbeitungsListeJson,
  AuswahlStatus
} from '@one/typings/apiTypings'
import { useModelMgr } from '@utils/modelmgr'
import { notifyObservers } from '@utils/observer'
import { AppContext } from '@utils/ui/App/AppContext'
import { RouteContext } from '@utils/ui/App/AppRoute'
import { AppSwitchCtx } from '@utils/ui/App/AppSwitchCtx'
import { ButtonRow } from '@utils/ui/Buttons/ButtonRow'
import { ReloadButton } from '@utils/ui/Buttons/ReloadButton'
import { SaveButton } from '@utils/ui/Buttons/SaveButton'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { useMessageDialog } from '@utils/ui/MessageDialog'
import { RadioGroup } from '@utils/ui/fields/RadioGroup'
import { StatePlane } from '@utils/ui/planes/StatePlane'
import { dataFromEvent, safeArray } from '@utils/utils'
import { useCallback, useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  ArtikelBearbeitungsList_validate,
  ArtikelBearbeitungsListe_editMutator,
  ArtikelBearbeitungsListe_onValueChanged,
  ArtikelBearbeitungsListe_saveMutator,
  ArtikelBearbeitungsListeEdit
} from './ArtikelBearbeitungsListeBearbeitenUseCase'
import { PreisAnlageForm } from './PreisAnlageForm'
import { PreisAnlageTable } from './PreisAnlageTable'

export type PreisAnlageViewProps = {
  mode: 'ek' | 'vk'
  title: string
  rest: string
  eventName: string
  overviewLink: string
  appPathFn: (args: object) => string
  saveCallback?: (save: () => void, anzLieferanten: number) => void
  onSaveCallback: (savedModel: ArtikelBearbeitungsListeEdit) => void
}

export const PreisAnlageView = ({
  mode,
  title,
  rest,
  eventName,
  overviewLink,
  appPathFn,
  saveCallback = (save) => save(),
  onSaveCallback
}: PreisAnlageViewProps) => {
  const { lieferantId, preisListeId, ekPreisListeId, artikelListeRef, kontext, bt } = useContext(
    RouteContext
  ) as any
  const { replaceHistory } = useContext(AppSwitchCtx)
  const { askToConfirm } = useMessageDialog()
  const navigate = useNavigate()
  const { isEinzelhandel, isFachhandel, isPIMModel } = useContext(AppContext)

  const {
    raw,
    model,
    isNew,
    isChanged,
    errors,
    uiLock,
    onValueChange,
    save,
    reload,
    updModel,
    silentUpd
  } = useModelMgr<
    ArtikelBearbeitungsListeJson,
    ArtikelBearbeitungsListeEdit,
    ArtikelBearbeitungsListeBearbeitenJson
  >({
    id: 'neu',
    api,
    title,
    unwrapField: 'liste',
    rest,
    restps: {
      lieferantId,
      preisListeId,
      ekPreisListeId,
      artikelListeRef,
      kontext,
      bt,
      doNotAdd: true
    },
    context: {
      isEinzelhandel,
      isFachhandel,
      isPIMModel
    },
    init: { eintraege: [] },
    retryMessageId: MessageID.IDE_ARTIKEL_PREISE_MUESSEN_NOCH_GEHOLT_WERDEN,
    validate: ArtikelBearbeitungsList_validate,
    editMutator: ArtikelBearbeitungsListe_editMutator,
    saveMutator: ArtikelBearbeitungsListe_saveMutator,
    onValueChanged: ArtikelBearbeitungsListe_onValueChanged,
    onSave: onSaveCallback,
    eventName,
    doReplaceHistory: false
  })

  const { anlegenCount, ignorierenCount } = useMemo(() => {
    let anlegenCount = 0
    let ignorierenCount = 0
    model?.eintraegeChangesMap?.forEach((value) => {
      if (value === AuswahlStatus.EINGESCHL) {
        anlegenCount += 1
      } else if (value === AuswahlStatus.IGNORIEREN) {
        ignorierenCount += 1
      }
    })
    return { anlegenCount, ignorierenCount }
  }, [model.eintraegeChangesMap])

  const anzHauptlieferanten = useMemo(() => {
    if (mode === 'ek') {
      return 1
    }

    const lieferanten = safeArray(raw?.eintraege)
      .filter((a) => model?.eintraegeChangesMap?.get(a.id) === AuswahlStatus.EINGESCHL)
      .map((a) => a.artikel?.hauptlieferant?.id)
      .filter(Boolean)
      .filter((a, i, arr) => arr.indexOf(a) === i)

    return lieferanten.length
  }, [mode, model?.eintraegeChangesMap, raw?.eintraege])

  const setEintraegeChangesMap = useCallback(
    (next: Map<number, AuswahlStatus>) => {
      updModel({ eintraegeChangesMap: next })
    },
    [updModel]
  )

  const handleSelect = useCallback(
    (e) => {
      onValueChange(e)

      const { name, value } = dataFromEvent(e)
      if (name) {
        if (name === 'lieferant') {
          replaceHistory(() =>
            appPathFn({
              lieferantId: value?.id,
              artikelListeRef: model.artikelListe?.id,
              kontext: model.kontext,
              bt: model.bt
            })
          )
        } else if (name === 'kontext') {
          replaceHistory(() =>
            appPathFn({
              lieferantId: model.ekPreisListenRef ? undefined : model.lieferant?.id,
              artikelListeRef: model.ekPreisListenRef ? undefined : model.artikelListe?.id,
              ekPreisListeId: model.ekPreisListenRef?.id,
              kontext: value
            })
          )
        } else if (name === 'bt') {
          replaceHistory(() =>
            appPathFn({
              lieferantId: model.ekPreisListenRef ? undefined : model.lieferant?.id,
              artikelListeRef: model.ekPreisListenRef ? undefined : model.artikelListe?.id,
              ekPreisListeId: model.ekPreisListenRef?.id,
              bt: value
            })
          )
        } else {
          replaceHistory(() =>
            appPathFn({
              lieferantId: model.lieferant?.id,
              artikelListeRef: value?.id,
              kontext: model.kontext,
              bt: model.bt
            })
          )
        }
      }
    },
    [
      appPathFn,
      model.artikelListe?.id,
      model.bt,
      model.ekPreisListenRef?.id,
      model.kontext,
      model.lieferant?.id,
      onValueChange,
      replaceHistory
    ]
  )

  const handleSave = () => {
    if (anlegenCount > 0) {
      saveCallback(save, anzHauptlieferanten)
    } else if (ignorierenCount > 0) {
      askToConfirm({
        title: 'Es wird keine Preisliste erstellt',
        severity: 'info',
        message:
          'Da keine Artikel zur Anlage ausgewählt wurden, wird keine Preisliste erstellt. Es werden nur Artikelvorgänge ignoriert.',
        confirmLabel: 'Speichern',
        onConfirm: () =>
          save({
            then: () => {
              notifyObservers({ name: EventNames.DASHBOARD })
              navigate(AppPaths.Dashboard)
            }
          })
      })
    }
  }

  const einzelOderFachhandel = useMemo(() => {
    if (mode != 'vk' || isEinzelhandel != isFachhandel) {
      return null
    }
    if (isPIMModel) {
      return <span>{model.einzelhandel ? 'Einzelhandelspreise' : 'Fachhandelspreise'}</span>
    }
    return (
      <RadioGroup
        type="boolean"
        name="einzelhandel"
        size="small"
        row
        value={!!model.einzelhandel}
        onChange={onValueChange}
        options={[
          { value: true, label: 'Einzelhandelspreise' },
          { value: false, label: 'Fachhandelspreise' }
        ]}
      />
    )
  }, [isEinzelhandel, isFachhandel, isPIMModel, mode, model.einzelhandel, onValueChange])

  return (
    <StatePlane uiLock={uiLock} altLink={overviewLink}>
      <Frame space>
        <FrameRow>
          <PreisAnlageForm
            title={title}
            overviewLink={overviewLink}
            model={model}
            errors={errors}
            handleSelect={handleSelect}
            onValueChange={onValueChange}
            mode={mode}
          />
        </FrameRow>
        <FrameBody>
          <PreisAnlageTable
            eintraege={raw.eintraege}
            eintraegeChangesMap={model.eintraegeChangesMap ?? new Map()}
            setEintraegeChangesMap={setEintraegeChangesMap}
            silentUpd={silentUpd}
            ignorierbar={model.ignorierbar}
            mode={mode}
            lieferant={model.lieferant}
          />
        </FrameBody>
        <FrameRow>
          <ButtonRow>
            {einzelOderFachhandel}
            <SaveButton
              onClickVoid={handleSave}
              isChanged={isChanged}
              anlegen={isNew && anlegenCount > 0}
              disabled={anlegenCount === 0 && ignorierenCount === 0}
            />
            <ReloadButton
              onClick={reload}
              // wenn der Einsprung über eine ID erfolgt ist, soll "Neu Laden" immer möglich sein
              isNew={!(lieferantId || preisListeId || artikelListeRef)}
              isChanged={isChanged}
            />
          </ButtonRow>
        </FrameRow>
      </Frame>
    </StatePlane>
  )
}
