import { Grid2 as Grid } from '@mui/material'
import { BetriebstypKontextField } from '@one/components/common/BetriebstypKontextField'
import { StandortField } from '@one/components/common/StandortField'
import { AppContext } from '@utils/ui/App/AppContext'
import { CardEx } from '@utils/ui/CardEx'
import { StaticField } from '@utils/ui/fields/StaticField'
import { useContext } from 'react'
import { makeStyles } from 'tss-react/mui'
import { EkPreisMassenPflegeModel } from './model/EkPreisMassenPflegeModel'

export interface EkPreisMassenPflegeKopfProps {
  model: EkPreisMassenPflegeModel
  setPreisEbeneId?: (id: number) => void
}

// Styling definitions
const useStyles = makeStyles()({
  fields: {
    marginTop: '-32px'
  },
  title: {
    flexGrow: 1
  }
})

export const EkPreisMassenPflegeKopf = ({
  setPreisEbeneId,
  model
}: EkPreisMassenPflegeKopfProps) => {
  const { classes } = useStyles()
  const { isAllianz, isPIMModel } = useContext(AppContext)
  return (
    <CardEx backLink title="Einkaufspreisliste">
      <Grid container paddingTop={4} spacing={3} direction="row" className={classes.fields}>
        <Grid size={{ xs: 12, md: 5, lg: 4 }}>
          <StaticField
            label="Name der Preisliste"
            // name="name"
            value={model.name}
            // size="small"
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, md: 5, lg: 4 }}>
          <StandortField
            label={isAllianz ? 'Preisebene' : 'Standort'}
            value={model.preisEbeneId}
            onChange={({ value }) => setPreisEbeneId(value)}
            options={model.preisEbenen}
            usedStandordIds={model.usedStandordIds}
            emptyText="Alle Preise"
            withButtons
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, md: 5, lg: 3 }}>
          <BetriebstypKontextField owner={model} />
        </Grid>
      </Grid>
    </CardEx>
  )
}
