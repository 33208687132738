/* eslint-disable vars-on-top */
/* eslint-disable no-var */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/label-has-associated-control */
import {LinearProgress} from '@mui/material'
import {MessageSeverity, UseCaseStateJson} from '@one/typings/apiTypings'
import {isSeverityError, isSeverityWarning} from '@utils/utils'
import {Axios, AxiosResponse} from 'axios'
import {useCallback, useImperativeHandle, useRef, useState} from 'react'
import {makeStyles} from 'tss-react/mui'
import {SnackbarType, useSnackbarEx} from './snackbarex'

const displayNone = { display: 'none' }
// const displayNone = { visibility: 'hidden', width: "0px!important" } as CSSProperties
const headers = { 'content-type': 'multipart/form-data' }
const progressStyle = { width: '100%' }

export type UploadProgressProps = {
  xref?: any
}

const UploadProgress = ({ xref }: UploadProgressProps) => {
  const [percent, setPercent] = useState(0)

  useImperativeHandle(xref, () => ({
    set: (value) => {
      setPercent(value)
    }
  }))

  return (
    <div style={progressStyle}>
      <div>Upload läuft...</div>
      <LinearProgress variant="determinate" value={percent} style={progressStyle} />
    </div>
  )
}

const useStyles = makeStyles()({
  snack: {
    '& div': {
      width: '100%'
    }
  }
})

export type UploaderProps = {
  api: Axios
  path: string
  quiet?: boolean
  onComplete?: (response: AxiosResponse) => void
  onError?: (error: UseCaseStateJson, response: AxiosResponse) => void
}

export const useUploader = ({ api, path, onComplete, onError, quiet }: UploaderProps) => {
  const { classes } = useStyles()

  const { enqueMsg, closeSnackbar } = useSnackbarEx()

  const progressRef = useRef(undefined)

  const onUploadProgress = useCallback((progressEvent) => {
    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
    if (progressRef.current) {
      //@ts-ignore
      progressRef.current.set(percentCompleted)
    }
  }, [])

  const submitFiles = useCallback(
    (files: File[]) => {
      const formData = new FormData()
      for (var i = 0; i < files.length; i++) {
        formData.append('file', files[i])
      }
      const config = {
        headers,
        onUploadProgress
      }
      const uploadInfoKey = enqueMsg(<UploadProgress xref={progressRef} />, SnackbarType.info, {
        persist: true,
        className: classes.snack
      })

      api
        .post(path, formData, config)
        //@ts-ignore
        .then((response) => {
          closeSnackbar(uploadInfoKey)
          const info: UseCaseStateJson = response.data.state || {}
          const severity = info.mainMessage?.severity
          if (!quiet) {
            if (isSeverityError(severity)) {
              const title = info.mainMessage.message || 'Upload gescheitert!'
              const details =
                info.messages &&
                info.messages
                  //@ts-ignore
                  .map((m) => m.message)
                  //@ts-ignore
                  .filter((v, idx, self) => self.indexOf(v) === idx)
                  //@ts-ignore
                  .map((msg) => <li key={msg}>{msg}</li>)
              //@ts-ignore
              enqueMsg(
                <span>
                  {title}
                  <br />
                  <ul>{details}</ul>
                </span>,
                SnackbarType.error
              )
            } else if (isSeverityWarning(severity)) {
              const title = info.mainMessage.message || 'Upload mit Warnungen durchgeführt!'
              const details = info.messages
                ?.map((m) => m.message)
                .filter((v, idx, self) => self.indexOf(v) === idx)
                .map((msg) => <li key={msg}>{msg}</li>)
              //@ts-ignore
              enqueMsg(
                <span>
                  {title}
                  <br />
                  <ul>{details}</ul>
                </span>,
                SnackbarType.warning
              )
            } else {
              //@ts-ignore
              enqueMsg(info.mainMessage?.message || 'Upload erfolgreich!', SnackbarType.success)
            }
          }
          if (isSeverityError(severity)) {
            if (onError) {
              onError(info, response)
            }
          } else {
            if (onComplete) {
              onComplete(response)
            }
          }
        })
        //@ts-ignore
        .catch((error) => {
          //@ts-ignore
          closeSnackbar(uploadInfoKey)
          const errorState: UseCaseStateJson = error.state || {
            mainMessage: {
              message: 'Der Serviceaufruf ist gescheitert.',
              severity: MessageSeverity.FATAL
            }
          }

          enqueMsg(
            <span>
              Upload gescheitert!
              <br />
              {errorState.mainMessage?.message || error.message}
            </span>,
            SnackbarType.error
          )
          if (onError) {
            onError(errorState, error.response)
          }
        })
    },
    [
      onUploadProgress,
      enqueMsg,
      classes.snack,
      api,
      path,
      closeSnackbar,
      quiet,
      onError,
      onComplete
    ]
  )

  return submitFiles
}
