import { useCallback } from 'react'
import { EventNames } from '@one/EventNames'
import { api } from '@one/api'
import { UmsatzsteuerJson, UmsatzsteuerSearcherCriteriaJson } from '@one/typings/apiTypings'
import { useApiCaller } from '@utils/apicaller'
import { useObserver } from '@utils/observer'
import { useSearcherState } from '@utils/searcher'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { useMessageDialog } from '@utils/ui/MessageDialog'
import { arrayItemReplace } from '@utils/utils'
import { UmsatzsteuerOverviewParams } from './UmsatzsteuerOverviewParams'
import { UmsatzsteuerOverviewTable } from './UmsatzsteuerOverviewTable'

export const UmsatzsteuerOverview = () => {
  const { criteria, onCriteriaChange, result, search, setResult } = useSearcherState<
    UmsatzsteuerSearcherCriteriaJson,
    UmsatzsteuerJson
  >({
    api,
    doOpen: false,
    url: '/tax'
  })

  const [apiCall] = useApiCaller(api)
  const { askToDelete } = useMessageDialog()

  const handleDeleteClick = useCallback(
    (row: UmsatzsteuerJson) => () => {
      askToDelete({
        title: `Umsatzsteuer wirklich löschen`,
        onConfirm: () => {
          apiCall<any>({
            method: 'POST',
            rest: `/tax/delete`,
            params: { id: row.id },
            onErrorMsg: `Umsatzsteuer konnte nicht gelöscht werden.`,
            onSuccessMsg: `Umsatzsteuer erfolgreich gelöscht.`,
            onSuccess: () => setResult((rs) => arrayItemReplace(rs, (r) => r.id === row.id, null))
          })
        }
      })
    },
    [askToDelete, apiCall, setResult]
  )

  useObserver(EventNames.TAX, () => {
    search()
  })

  return (
    <Frame space>
      <FrameRow>
        <UmsatzsteuerOverviewParams
          onSearch={search}
          value={criteria}
          onChange={onCriteriaChange}
        />
      </FrameRow>
      <FrameBody>
        <UmsatzsteuerOverviewTable result={result} reload={search} onDelete={handleDeleteClick} />
      </FrameBody>
    </Frame>
  )
}
