import { Grid2 as Grid } from '@mui/material'
import { PimArtikelJson, SeArtikelJson } from '@one/typings/apiTypings'
import { ValueChangeFn } from '@utils/modelmgr'
import { CardEx } from '@utils/ui/CardEx'
import { NumberField } from '@utils/ui/fields/NumberField'
import { TextField } from '@utils/ui/fields/TextField'
import React from 'react'

export interface PimArtikelHagebauProps {
  pimArtikel: PimArtikelJson
  onChange?: ValueChangeFn<SeArtikelJson>
  errors?: any
  readonly?: boolean
}

/**
 * PimArtikelHagebau wird im EigenlistungsArtikelPflegeView editierbar
 * und im PimArtikelView als readonly genutzt.
 * @param pimArtikel
 * @param onChange
 * @param errors
 * @param readonly
 * @constructor
 */
export const PimArtikelHagebau = ({
  pimArtikel,
  onChange,
  errors,
  readonly
}: PimArtikelHagebauProps) => {
  return (
    <CardEx title="Hagebau Logistik">
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
          <TextField
            label="Einkaufsmengeneinheit"
            name="pimArtikel.version.hagebauLogistikEinkaufMengeneinheit"
            value={pimArtikel.version.hagebauLogistikEinkaufMengeneinheit}
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
          <NumberField
            label="Feinkommissionierung"
            name="pimArtikel.version.hagebauLogistikFeinkommissionierung"
            value={pimArtikel.version.hagebauLogistikFeinkommissionierung}
            onChange={onChange}
            disabled={readonly}
            fraction={0}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
          <NumberField
            label="Lagerbereich"
            name="pimArtikel.version.hagebauLogistikLagerbereich"
            value={pimArtikel.version.hagebauLogistikLagerbereich}
            onChange={onChange}
            disabled={readonly}
            fraction={0}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
          <TextField
            label="Lagerkosten Mengeneinheit"
            name="pimArtikel.version.hagebauLogistikLagerkostenMengeneinheit"
            value={pimArtikel.version.hagebauLogistikLagerkostenMengeneinheit}
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
          <TextField
            label="Produktmanager"
            name="pimArtikel.version.hagebauLogistikProduktmanager"
            value={pimArtikel.version.hagebauLogistikProduktmanager}
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
          <NumberField
            label="Regalfaehigkeit"
            name="pimArtikel.version.hagebauLogistikRegalfaehigkeit"
            value={pimArtikel.version.hagebauLogistikRegalfaehigkeit}
            onChange={onChange}
            disabled={readonly}
            fraction={0}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
          <NumberField
            label="Stapelhoehe"
            name="pimArtikel.version.hagebauLogistikStapelhoehe"
            value={pimArtikel.version.hagebauLogistikStapelhoehe}
            onChange={onChange}
            disabled={readonly}
            fraction={0}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
          <TextField
            label="Warenausgang Handlingskategorie"
            name="pimArtikel.version.hagebauLogistikWarenausgangHandlingskategorie"
            value={pimArtikel.version.hagebauLogistikWarenausgangHandlingskategorie}
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
          <TextField
            label="Warenausgang Mengeneinheit"
            name="pimArtikel.version.hagebauLogistikWarenausgangMengeneinheit"
            value={pimArtikel.version.hagebauLogistikWarenausgangMengeneinheit}
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
          <TextField
            label="Wareneingang Handlingskategorie"
            name="pimArtikel.version.hagebauLogistikWareneingangHandlingskategorie"
            value={pimArtikel.version.hagebauLogistikWareneingangHandlingskategorie}
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
      </Grid>
    </CardEx>
  )
}

export const PimArtikelHagebauMemo = React.memo(PimArtikelHagebau)
