import { ArtikelDatenpoolUebernahmeStatus } from '@one/typings/apiTypings'
import { HkmEnum } from '@one/enums/HkmEnum'
import { useEnums } from '@utils/enums'
import { Medal } from '@utils/ui/fields/Medal'
import { blue, green, grey, orange, red, yellow } from '@mui/material/colors'

export interface ArtikelDatenpoolUebernahmeStatusMedalProps {
  value: ArtikelDatenpoolUebernahmeStatus | null
}

const pickColor = (status: ArtikelDatenpoolUebernahmeStatus) => {
  switch (status) {
    case ArtikelDatenpoolUebernahmeStatus.ANLEGBAR:
      return yellow[500]
    case ArtikelDatenpoolUebernahmeStatus.AKTUALISIERBAR:
      return green[500]
    case ArtikelDatenpoolUebernahmeStatus.ERPARTIKEL:
      return blue[500]
    case ArtikelDatenpoolUebernahmeStatus.LISTUNGSARTIKEL:
      return orange[500]
    case ArtikelDatenpoolUebernahmeStatus.FEHLER:
      return red[500]
    default:
      return grey[500]
  }
}

export const ArtikelDatenpoolUebernahmeStatusMedal = ({
  value
}: ArtikelDatenpoolUebernahmeStatusMedalProps) => {
  const { et } = useEnums()
  return value ? (
    <Medal
      text={et(HkmEnum.ArtikelDatenpoolUebernahmeStatus, value)}
      variant="square"
      fullWidth
      backgroundColor={pickColor(value)}
    />
  ) : null
}
