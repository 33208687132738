import { Fullscreen, FullscreenExit } from '@mui/icons-material'
import { Grid2 as Grid } from '@mui/material'
import { Actions } from '@utils/ui/Action'
import { IconButton } from '@utils/ui/Buttons/IconButton'
import { CardEx } from '@utils/ui/CardEx'
import { ReactNode } from 'react'

export type AdmitViewPanelProps = {
  title: string
  subtitle?: string
  children: ReactNode
  zoomed?: boolean
  setZoomed?: () => void
  actions?: Actions
}

export const AdminViewPanel = ({
  title,
  subtitle,
  zoomed,
  setZoomed,
  children,
  actions
}: AdmitViewPanelProps) => {
  return (
    <CardEx
      title={title}
      subheader={subtitle}
      topActions={
        setZoomed && (
          <Grid>
            <IconButton Icon={zoomed ? FullscreenExit : Fullscreen} onClick={setZoomed} />
          </Grid>
        )
      }
      height="100%"
      contentStyle={{ height: '100%' }}
      bottomActions={actions}
    >
      {children}
    </CardEx>
  )
}
