import { Grid2 as Grid } from '@mui/material'
import { HkmEnum } from '@one/enums/HkmEnum'
import { useFormState } from '@utils/formstate'
import { SearchButton } from '@utils/ui/Buttons/SearchButton'
import { CardEx } from '@utils/ui/CardEx'
import { ShortCutHandler } from '@utils/ui/ShortCutHandler'
import { DateField } from '@utils/ui/fields/DateField'
import { SelectEnumField } from '@utils/ui/fields/SelectEnumField'
import PropTypes from 'prop-types'
import { useEffect } from 'react'

export const ProtokolleUebersichtParams = ({ handleSearch, initialSearchValues }) => {
  const [searchValues, onChangeSearchValues] = useFormState({
    maxStartedAt: null,
    minStartedAt: null,
    status: null,
    typ: null,
    minSeverityType: null
  })

  const submitSearch = () => {
    handleSearch(searchValues)
  }

  useEffect(() => {
    if (initialSearchValues != null) {
      onChangeSearchValues(
        () =>
          initialSearchValues || {
            type: null,
            status: null,
            minSeverityType: null,
            minStartedAt: new Date(),
            maxStartetAt: new Date()
          }
      )
    }
  }, [initialSearchValues, onChangeSearchValues])

  return (
    <ShortCutHandler shortcuts={{ Enter: submitSearch }}>
      <CardEx
        backLink
        title="Jobs"
        collapsiable
        bottomActions={<SearchButton onClick={submitSearch} />}
      >
        <Grid container spacing={3}>
          <Grid size={{xs:6,md:4,lg:3}}>
            <SelectEnumField
              label="Auftragstyp"
              name="typ"
              value={searchValues.typ}
              onChange={onChangeSearchValues}
              displayEmpty
              fullWidth
              enumType={HkmEnum.ProtokollTyp}
              emptyText="Alle"
            />
          </Grid>
          <Grid size={{xs:6,md:4,lg:2}}>
            <SelectEnumField
              label="Status"
              name="status"
              fullWidth
              value={searchValues.status}
              onChange={onChangeSearchValues}
              displayEmpty
              enumType={HkmEnum.ProtokollStatus}
              emptyText="Alle"
            />
          </Grid>
          <Grid size={{xs:6,md:4,lg:2}}>
            <SelectEnumField
              label="Mindest Schweregrad"
              name="minSeverityType"
              value={searchValues.minSeverityType}
              onChange={onChangeSearchValues}
              displayEmpty
              fullWidth
              enumType={HkmEnum.MessageSeverity}
              emptyText="Alle"
            />
          </Grid>
          <Grid size={{xs:6,md:4,lg:2}}>
            <DateField
              // autoOk
              label="Gestartet von"
              disableToolbar
              value={searchValues.minStartedAt}
              name="minStartedAt"
              onChange={onChangeSearchValues}
            />
          </Grid>
          <Grid size={{xs:6,md:4,lg:2}}>
            <DateField
              // autoOk
              label="Gestartet bis"
              disableToolbar
              value={searchValues.maxStartedAt}
              name="maxStartedAt"
              onChange={onChangeSearchValues}
            />
          </Grid>
        </Grid>
      </CardEx>
    </ShortCutHandler>
  )
}

ProtokolleUebersichtParams.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  initialSearchValues: PropTypes.object
}

ProtokolleUebersichtParams.defaultProps = {
  initialSearchValues: null
}
