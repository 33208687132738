import { Grid2 as Grid } from '@mui/material'
import { AppPaths } from '@one/AppPaths'
import { ArtikelDisplayTinyJson } from '@one/typings/apiTypings'
import { Link } from '@utils/ui/Navigation/Link'

export interface ArtikelCellProps {
  artikel: ArtikelDisplayTinyJson
  asLink?: boolean
}

export const ArtikelCell = ({ artikel, asLink = false }: ArtikelCellProps) => {
  if (artikel == null) {
    return null
  }

  const { id, hageNummer, bez1 } = artikel
  const name = bez1

  const link = AppPaths.ArtikelFn(id)

  return (
    <Grid container spacing={1} wrap="nowrap" alignItems="baseline">
      <Grid>
        {asLink ? (
          <Link to={link}>
            {hageNummer} {name}
          </Link>
        ) : (
          <span>
            {hageNummer} {name}
          </span>
        )}
      </Grid>
    </Grid>
  )
}
