/* eslint-disable no-nested-ternary */
/* eslint-disable complexity */
import {Typography} from '@mui/material'
import {red} from '@mui/material/colors'
import {AppPaths} from '@one/AppPaths'
import {EventNames} from '@one/EventNames'
import {api} from '@one/api'
import {FreigabeButton} from '@one/components/common/FreigabeButton'
import {VkPreiseBearbeitenJson, VkPreisListeEintragJson} from '@one/typings/apiTypings'
import {useApiCaller} from '@utils/apicaller'
import {formatDateTime} from '@utils/dateutils'
import {useModelMgr} from '@utils/modelmgr'
import {useObserver} from '@utils/observer'
import {RouteContext} from '@utils/ui/App/AppRoute'
import {AppRouteCtx} from '@utils/ui/App/AppRouteCtx'
import {AppSwitchCtx} from '@utils/ui/App/AppSwitchCtx'
import {ButtonRow} from '@utils/ui/Buttons/ButtonRow'
import {DeleteButton} from '@utils/ui/Buttons/DeleteButton'
import {ReloadButton} from '@utils/ui/Buttons/ReloadButton'
import {SaveButton} from '@utils/ui/Buttons/SaveButton'
import {CardEx} from '@utils/ui/CardEx'
import {Frame, FrameBody, FrameRow} from '@utils/ui/Frame'
import {useMessageDialog} from '@utils/ui/MessageDialog'
import {ShortCutHandler} from '@utils/ui/ShortCutHandler'
import {ThemeContext} from '@utils/ui/Theme'
import {StatePlane} from '@utils/ui/planes/StatePlane'
import {asNumber} from '@utils/utils'
import {useCallback, useContext, useEffect, useState} from 'react'
import {makeStyles} from 'tss-react/mui'
import {VkPreisPflegeBody} from './VkPreisPflegeBody'
import {VkPreisPflegeKopf} from './VkPreispflegeKopf'
import {NextButton} from './fields/NextButton'
import {useVkPreisKopf} from './model/VkPreisKopf'
import {emptyVkPreise, VkPreiseBearbeitenEdit, VkPreisEdit} from './model/VkPreisTypes'
import {initVkPreise, initVkPreiseZumSpeichern, validateVKPreise, VKPreisUsecase} from './model/VkPreisUsecase'

const useStyles = makeStyles()({
  frame: {
    height: 'max-content'
  }
})

export const VkPreispflegeView = () => {
  const { id: _id, eintragId: _eintragId } = useContext(RouteContext) as any
  const id = asNumber(_id)
  const eintragId = asNumber(_eintragId)
  const { classes } = useStyles()
  const { replaceHistory, pushHistory } = useContext(AppSwitchCtx)
  const { visible } = useContext(AppRouteCtx)

  const { askToConfirm } = useMessageDialog()

  const { kopf, reloadKopf, kopfLoading } = useVkPreisKopf(id, eintragId)

  const editMutator = useCallback(
    (data: VkPreiseBearbeitenJson) => initVkPreise(kopf, data),
    [kopf]
  )

  const eventMutator = useCallback((data: VkPreiseBearbeitenJson) => data.listeDisplay, [])

  const observerId = useObserver(
    EventNames.VKPREISLISTE,
    (event) => {
      const preisListId = event.data?.id
      if (preisListId && id === preisListId) {
        reloadEx({ notifyReload: true })
      }
    },
    visible
  )

  const { model, isNew, isChanged, save, reload, reloadEx, dispatch, uiLock, remove } = useModelMgr<
    VkPreiseBearbeitenJson,
    VkPreiseBearbeitenEdit,
    VkPreiseBearbeitenJson,
    VkPreisListeEintragJson
  >({
    id: kopf.eintragId,
    api,
    idField: 'eintragId',
    title: 'VK-Preis',
    rest: 'vkpreisliste',
    init: emptyVkPreise,
    reducer: VKPreisUsecase.reducer,
    validate: validateVKPreise,
    editMutator,
    saveMutator: initVkPreiseZumSpeichern,
    eventName: EventNames.VKPREISLISTE,
    eventMutator,
    observerId
  })
  const { darkMode } = useContext(ThemeContext)
  const [apiCall] = useApiCaller(api)

  const [selected, setSelected] = useState(new Set<VkPreisEdit>())

  const [selectedStandortId, setSelectedStandortId] = useState<number>(null)

  const { neueKondi } = model

  const alleAbgeschlossen =
    neueKondi.blaetter.filter((b) => b.abgeschlossen).length === neueKondi.blaetter.length

  const onSwitchArtikel = useCallback(
    (eintrId: number) => {
      if (!isChanged) {
        replaceHistory(() => AppPaths.VkPreisPflegeFn(id, eintrId))
      }
    },
    [isChanged, id, replaceHistory]
  )

  const onFreigeben = useCallback(() => {
    askToConfirm({
      severity: 'info',
      title: 'Freigabe',
      message: 'Diese Preisliste freigeben. Eine Bearbeitung ist danach nicht mehr möglich.',
      confirmLabel: 'Freigeben',
      onConfirm: () =>
        apiCall({
          method: 'POST',
          rest: '/vkpreisliste/alleFreigeben',
          params: { id: kopf.id },
          onErrorMsg: 'Die Preisliste konnte nicht freigegeben werden',
          onSuccessMsg: 'Die Preisliste wurde erfolgreich freigegeben.',
          onSuccess: (data) => reload()
        })
    })
  }, [askToConfirm, apiCall, kopf.id, reload])

  const gotoNextArtikel = useCallback(() => {
    if (kopf.artikelListe.length > 1) {
      const np = kopf.artikelPos < kopf.artikelListe.length ? kopf.artikelPos + 1 : 1
      const eId = kopf.artikelListe[np - 1].id
      replaceHistory(() => AppPaths.VkPreisPflegeFn(id, eId))
    }
  }, [kopf.artikelListe, kopf.artikelPos, replaceHistory, id])

  const saveAndNext = useCallback(() => {
    save({ then: gotoNextArtikel })
  }, [gotoNextArtikel, save])

  const [saveTrigger, setSaveTrigger] = useState(0)
  useEffect(() => {
    if (saveTrigger) {
      setSaveTrigger(0)
      if (isChanged) {
        save({ then: gotoNextArtikel })
      } else if (saveTrigger === 2) {
        gotoNextArtikel()
      }
    }
  }, [gotoNextArtikel, isChanged, save, saveTrigger])

  const next = useCallback(() => {
    if (model.error != null) {
      gotoNextArtikel()
      return
    }
    if (!alleAbgeschlossen) {
      dispatch({ type: VKPreisUsecase.SETALLEABGESCHLOSSEN })
      setSaveTrigger(2)
    } else if (isChanged) {
      saveAndNext()
    } else {
      gotoNextArtikel()
    }
  }, [model.error, alleAbgeschlossen, isChanged, gotoNextArtikel, dispatch, saveAndNext])

  const onDeleteClick = useCallback(
    () =>
      remove({
        onRemoved: () => {
          pushHistory(AppPaths.VkPreisUebersicht[0])
        },
        ovrId: id
      }),
    [pushHistory, remove, id]
  )

  const onDeleteArtikel = useCallback(() => {
    if (kopf?.artikelListe?.length === 1) {
      onDeleteClick()
    } else {
      remove({
        onRemoved: () => {
          reloadKopf()
          gotoNextArtikel()
        },
        srv: 'deleteEintrag',
        params: { eintragId: kopf.eintragId }
      })
    }
  }, [remove, kopf, onDeleteClick, gotoNextArtikel, reloadKopf])

  const actions = model.freigegeben ? (
    <>
      <span>{`Die Preisliste wurde am ${formatDateTime(model.freigegebenUm)} freigegeben`}</span>
      <ReloadButton isNew={isNew} isChanged={isChanged} onClick={reload} />
    </>
  ) : (
    <>
      <DeleteButton
        label="Preisliste Löschen"
        onDelete={onDeleteClick}
        variant="text"
        isNew={isNew}
        deleteMsg="Gesamte Preisliste wirklich löschen"
        tooltip="Gesamte Preisliste löschen"
      />
      <FreigabeButton
        isChanged={isChanged}
        isNew={isNew}
        actionInfo={model.actionFreigeben}
        onClick={onFreigeben}
      />
      <div style={{ width: 64 }} />
      <DeleteButton
        onDelete={onDeleteArtikel}
        tooltip="Artikel wird aus der Liste entfernt"
        deleteMsg={`Möchten Sie den Artikel wirklich aus dieser VK-Preisliste entfernen?`}
        isNew={false}
        disabled={alleAbgeschlossen || !!model.freigegeben}
      />
      <SaveButton isNew={isNew} isChanged={isChanged} onClickVoid={save} />
      <NextButton
        onClick={next}
        isChanged={isChanged}
        abgeschlossen={alleAbgeschlossen}
        artikelCount={kopf.artikelCount}
        error={model.error != null}
      />
      <ReloadButton isNew={isNew} isChanged={isChanged} onClick={reload} />
    </>
  )

  const shortcuts = {
    'alt+w': next,
    'alt+s': () => save()
  }

  return (
    <ShortCutHandler shortcuts={shortcuts}>
      <StatePlane
        uiLock={uiLock}
        error={kopf.fatalError}
        wait={kopfLoading}
        altLink={AppPaths.VkPreisUebersicht[0]}
      >
        <Frame space fullHeight>
          <FrameRow>
            <VkPreisPflegeKopf
              model={model}
              standortId={selectedStandortId}
              artikelList={kopf.artikelListe}
              artikelListLoading={kopfLoading}
              artikelPos={kopf.artikelPos}
              preislisteId={kopf.id}
              onSwitchArtikel={onSwitchArtikel}
              isChanged={isChanged}
            />
          </FrameRow>
          {model.error ? (
            <FrameRow>
              <CardEx
                title="Fehlerhafter Artikel"
                cardStyle={{ backgroundColor: red[darkMode ? 800 : 100] }}
              >
                <Typography variant="body1">
                  Preispflege ist für diesen Artikel nicht möglich: {model.error.title}
                </Typography>
                <Typography variant="caption">{model.error.details}</Typography>
              </CardEx>
            </FrameRow>
          ) : null}
          <FrameBody className={classes.frame}>
            <VkPreisPflegeBody
              model={model}
              dispatch={dispatch}
              selected={selected}
              onSelect={setSelected}
              setSelectedStandortId={setSelectedStandortId}
              selectedStandortId={selectedStandortId}
            />
          </FrameBody>
          <FrameRow>
            <ButtonRow>{actions}</ButtonRow>
          </FrameRow>
        </Frame>
      </StatePlane>
    </ShortCutHandler>
  )
}
