/* eslint-disable react/jsx-props-no-spreading */
import {css} from '@emotion/css'
import {FilterList} from '@mui/icons-material'
import {Collapse, Grid2 as Grid} from '@mui/material'
import {Action} from '@utils/ui/Action'
import {IconButton} from '@utils/ui/Buttons/IconButton'
import {FilterDef} from '@utils/ui/DataTable/DataTable'
import {TextField} from '@utils/ui/fields/TextField'
import {restartTimer} from '@utils/utils'
import {useEffect, useRef, useState} from 'react'
import {FilterModeIcon} from './FilterModeIcon'

const oc = css`
  @keyframes rotateOpen {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(180deg);
    }
  }
  animation: rotateOpen 0.5s forwards;
`

const cc = css`
  @keyframes rotateClose {
    from {
      transform: rotate(180deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
  animation: rotateClose 0.5s forwards;
`

export type GlobalFilterFieldProps = {
  name: string
  value: FilterDef
  onChange: (e: any, nv: FilterDef) => void
}

export const GlobalFilterField = ({ value, onChange, name }: GlobalFilterFieldProps) => {
  const mode = value?.mode || 'I'
  const text = value?.text || ''

  const editTimeout = useRef<any>(undefined)

  const [filterEdit, setFilterEdit] = useState<string>()

  const [open, setOpen] = useState(false)

  const onFilterEdit = (e: any) => {
    const text = e?.target?.value
    setFilterEdit(text)
    restartTimer(
      editTimeout,
      () => {
        const safe = text && text.trim().toLocaleUpperCase()
        onChange(e, {
          mode,
          text: safe == null || safe.length === 0 ? null : safe
        })
      },
      400
    )
  }

  const onToggleFilter = () => {
    setOpen(!open)
    if (open) {
      setFilterEdit(null)
      onChange(
        {},
        {
          mode,
          text: null
        }
      )
    }
  }

  useEffect(() => {
    setFilterEdit(value?.text)
  }, [value?.text])

  const actions = [
    {
      name: `${name}-mode`,
      icon: <FilterModeIcon size="medium" value={mode} />,
      onClick: () => onChange(null, { mode: mode === 'E' ? 'I' : 'E', text })
    }
  ] as Action[]

  return (
    <Grid container spacing={0}>
      <Grid>
        <Collapse orientation="horizontal" in={open}>
          <TextField
            name={name}
            placeholder="Filtern..."
            value={filterEdit}
            onChange={onFilterEdit}
            startActions={actions}
            fullWidth
          />
        </Collapse>
      </Grid>
      <Grid>
        <IconButton
          icon={<FilterList className={open ? oc : cc} />}
          size="small"
          name={`${name}-show`}
          onClickVoid={onToggleFilter}
          tooltip="Tabelle filtern..."
        />
      </Grid>
    </Grid>
  )
}
