import { Chip, Grid2 as Grid, Tooltip } from '@mui/material'
import {
  ErpArtikelDetailsJson,
  PimArtikelVersionJson,
  PimArtikelVersionMengeneinheitJson
} from '@one/typings/apiTypings'
import { CardEx } from '@utils/ui/CardEx'
import React, { useMemo, useState } from 'react'
import { Column, DataTable } from '@utils/ui/DataTable/DataTable'
import { StaticField } from '@utils/ui/fields/StaticField'
import { ScrollPanel } from '@utils/ui/ScrollPanel'
import { formatNumber } from '@utils/numberutils'
import { ArrowForward } from '@mui/icons-material'
import { TooltipWrapper } from '@utils/ui/TooltipWrapper'
import { Switch } from '@utils/ui/fields/Switch'

export interface PimArtikelMengeneinheitenListProps {
  pimArtikelVersion: PimArtikelVersionJson
  mengeneinheiten?: PimArtikelVersionMengeneinheitJson[]
  erpDetails?: ErpArtikelDetailsJson
}

/**
 * PimArtikelMengeneinheiten wird im EigenlistungsArtikelPflegeView editierbar
 * und im PimArtikelView als readonly genutzt.
 * @param pimArtikelVersion
 * @param mengeneinheiten
 * @param erpDetails
 * @constructor
 */
export const PimArtikelMengeneinheitenList = ({
  pimArtikelVersion,
  mengeneinheiten,
  erpDetails
}: PimArtikelMengeneinheitenListProps) => {
  const [meKontextFilter, setMEKontextFilter] = useState(true)

  function filterVerwendung() {
    setMEKontextFilter(!meKontextFilter)
  }

  const mengeneinheitenGefiltert = meKontextFilter
    ? mengeneinheiten.filter((me) => !me.nichtImKontext)
    : mengeneinheiten

  const columns = useMemo(
    () =>
      [
        {
          field: 'gtin',
          header: 'EAN'
        },
        {
          field: 'pimMengeneinheit.bez',
          header: 'Name',
          tooltip: (row) => row.pimMengeneinheit?.kurzBez
        },
        //{
        //  field: 'mengeBasismengeneinheit.faktor',
        //  header: 'Basismengeneinheit',
        //  align: 'right'
        //},
        //{
        //  field: 'pimBasisMengeneinheit.name',
        //  header: 'Basismengeneinheit-Name',
        //  tooltip: (row) => row.pimBasisMengeneinheit?.bez
        //},
        {
          field: 'pimBasisMengeneinheit.umrechnungInfo',
          header: 'Umrechnung',
          body: (row) => {
            if (row.umrechnungInfo === '') {
              return ''
            }
            return (
              <>
                <Tooltip
                  title={row.meDisplayNamingConversion.basisMengeneinheitDescription}
                  placement="bottom-start"
                  arrow
                >
                  <Chip
                    variant="outlined"
                    size="small"
                    label={
                      row.meDisplayNamingConversion.basisFaktor +
                      ' ' +
                      row.meDisplayNamingConversion.basisMengeneinheit
                    }
                  />
                </Tooltip>
                &nbsp;
                <ArrowForward sx={{ fontSize: 14 }} />
                &nbsp;
                <Tooltip
                  title={row.meDisplayNamingConversion.targetMengeneinheitDescription}
                  placement="bottom-start"
                  arrow
                >
                  <Chip
                    variant="outlined"
                    size="small"
                    label={
                      row.meDisplayNamingConversion.targetMeFaktor +
                      ' ' +
                      row.meDisplayNamingConversion.targetMengeneinheit
                    }
                  />
                </Tooltip>
              </>
            )
          }
        },
        {
          field: 'laenge',
          header: 'Länge',
          align: 'right'
        },
        {
          field: 'breite',
          header: 'Breite',
          align: 'right'
        },
        {
          field: 'hoehe',
          header: 'Höhe',
          align: 'rigth'
          //type: 'number'
        },
        {
          field: 'gewichtBrutto',
          header: 'Gewicht-Brutto',
          type: 'money3'
        },
        {
          field: 'gewichtNetto',
          header: 'Gewicht-Netto',
          type: 'money3'
        },
        {
          field: 'volumne',
          header: 'Volumen',
          align: 'right'
        },
        {
          field: 'stapelfaktor',
          header: 'Stapelfaktor',
          align: 'right'
        },
        {
          field: 'invertiert',
          header: 'Invertiert'
        }
      ] as Column<PimArtikelVersionMengeneinheitJson>[],
    []
  )

  const details = (
    <Grid container gap={2}>
      {erpDetails ? (
        <>
          <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
            <TooltipWrapper title={erpDetails.verkaufME?.kurzBez} placement="bottom-start" arrow>
              <StaticField
                label="VK-Mengeneinheit"
                value={erpDetails.verkaufME?.bez}
                name="verkaufME"
                empty
              />
            </TooltipWrapper>
          </Grid>
          <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
            <StaticField
              label="VK-Preismenge"
              value={formatNumber(erpDetails.verkaufPreismenge)}
              name="verkaufPreismenge"
              align="right"
              empty
            />
          </Grid>
        </>
      ) : (
        <>
          <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
            <TooltipWrapper
              title={pimArtikelVersion.verkaufsverpackungMengeneinheit?.kurzBez}
              placement="bottom-start"
              arrow
            >
              <StaticField
                label="Verkaufsverpackungmengeneinheit"
                value={pimArtikelVersion?.verkaufsverpackungMengeneinheit?.bez}
                name="verkaufsverpackungMengeneinheit"
                empty
              />
            </TooltipWrapper>
          </Grid>
          <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
            <StaticField
              label="Verkaufspreismenge"
              value={formatNumber(pimArtikelVersion?.verkaufPreismenge, 2)}
              name="verkaufPreismenge"
              align="right"
              empty
            />
          </Grid>
        </>
      )}
      <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
        <TooltipWrapper
          title={pimArtikelVersion?.einzelMengeneinheit?.kurzBez}
          placement="bottom-start"
          arrow
        >
          <StaticField
            label="Einzelmengeneinheit"
            value={pimArtikelVersion?.einzelMengeneinheit?.bez}
            name="einzelMengeneinheit"
            empty
          />
        </TooltipWrapper>
      </Grid>
    </Grid>
  )

  return (
    <CardEx
      title="Mengeneinheiten und EANs"
      height="100%"
      name="mengeneinheiten"
      topContent={details}
      topActions={
        <Switch
          name="kontextSpezifischeMEs"
          label="Zeige nur im Kontext verwendete Mengeneinheiten"
          onChange={filterVerwendung}
          checked={meKontextFilter}
          size="small"
        />
      }
    >
      <ScrollPanel autoHeight autoHeightMax="600px">
        <DataTable
          name="PimMengenheitenTabelle"
          value={mengeneinheitenGefiltert}
          columns={columns}
          emptyMessage="Keine Daten"
          dense
        />
      </ScrollPanel>
    </CardEx>
  )
}

export const PimArtikelMengeneinheitenListMemo = React.memo(PimArtikelMengeneinheitenList)
