import { ArrowRightAlt } from '@mui/icons-material'
import { Grid2 as Grid } from '@mui/material'
import { AppPaths } from '@one/AppPaths'
import { HkmEnum } from '@one/enums/HkmEnum'
import {
  ArtikelStammdatenExportEintragExportTyp,
  ArtikelStammdatenExportEintragJson,
  NoExportReasonSeArtikelJson,
  NoExportReasonSeLieferantJson
} from '@one/typings/apiTypings'
import { useEnums } from '@utils/enums'
import { Column } from '@utils/ui/DataTable/DataTable'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { getFieldValue } from '@utils/ui/DataTable/DataTableUtils'
import { Frame, FrameBody } from '@utils/ui/Frame'
import { Link } from '@utils/ui/Navigation/Link'
import { useMemo } from 'react'

export interface ExportToErpTableProps {
  values: ArtikelStammdatenExportEintragJson[]
  loading: boolean
  limited: boolean
  reasonArtikel: NoExportReasonSeArtikelJson[]
  reasonLieferant: NoExportReasonSeLieferantJson[]
}

const formatGueltig = (base: any) =>
  base ? `Gültig ab: ${base.gueltigVon} ${base.gueltigBis ? ` bis: ${base?.gueltigBis}` : ''}` : ''

const formatLieferant = (base: any) => (base ? `${base.nummer} ${base.name1}` : '')

export const ExportToErpTable = ({
  values,
  reasonArtikel,
  reasonLieferant,
  loading = false,
  limited = false
}: ExportToErpTableProps) => {
  const { et } = useEnums()

  const reasonArtikelMap = useMemo(() => {
    const map = new Map<number, NoExportReasonSeArtikelJson>()
    reasonArtikel?.forEach((reason) => {
      map.set(reason.seId, reason)
    })
    return map
  }, [reasonArtikel])

  const reasonLieferantMap = useMemo(() => {
    const map = new Map<number, NoExportReasonSeLieferantJson>()
    reasonLieferant?.forEach((reason) => {
      map.set(reason.seId, reason)
    })
    return map
  }, [reasonLieferant])

  const columns = useMemo(
    () =>
      [
        {
          field: 'typ',
          header: 'Typ',
          valueGetter: (row) => et(HkmEnum.ArtikelStammdatenExportEintragExportTyp, row.typ),
          sortable: true
        },
        {
          field: 'nummer',
          header: 'Nummer',
          valueGetter: (row) =>
            row.typ == ArtikelStammdatenExportEintragExportTyp.ARTIKEL
              ? row?.artikel?.btNummer
              : row?.lieferant?.nummer
        },
        {
          field: 'details',
          header: 'Details',
          width: '100%',
          valueGetter: (row) => {
            if (row.typ === ArtikelStammdatenExportEintragExportTyp.EK_KONDITION) {
              if (row.notExported) {
                const shortString = (str: string) =>
                  str?.length > 100 ? str.slice(0, 100) + '@one.' : str
                const details = []
                row.errorSeArtikelIds.forEach((seId) => {
                  details.push(reasonArtikelMap.get(seId)?.artikel.bez1)
                  details.push(shortString(reasonArtikelMap.get(seId)?.exportError?.message))
                  details.push(shortString(reasonArtikelMap.get(seId)?.importError?.message))
                })
                row.errorSeLieferantIds.forEach((seId) => {
                  details.push(reasonLieferantMap.get(seId)?.lieferant.name1)
                  details.push(shortString(reasonLieferantMap.get(seId)?.exportError?.message))
                  details.push(shortString(reasonLieferantMap.get(seId)?.importError?.message))
                })

                return details.filter(Boolean)
              } else {
                return [
                  row.ekKondition.artikel?.bez1,
                  row.ekKondition.artikel?.bez2,
                  `Gültig ab: ${row.ekKondition.gueltigVon}`,
                  row.ekKondition.gueltigBis ? `Gültig bis: ${row.ekKondition.gueltigBis}` : null,
                  row.ekKondition?.artikel?.ean ? `EAN: ${row.ekKondition?.artikel?.ean}` : null,
                  row.ekKondition?.artikel?.btNummer
                    ? `Artikel-Nr.: ${row.ekKondition?.artikel?.btNummer}`
                    : null,
                  row.ekKondition?.artikel?.hageNummer
                    ? `hage-Nr.: ${row?.ekKondition?.artikel?.hageNummer}`
                    : null,
                  row.ekKondition?.artikel?.industrieArtikelNummer
                    ? `IAN: ${row?.ekKondition?.artikel?.industrieArtikelNummer}`
                    : null
                ].filter(Boolean)
              }
            } else {
              return []
                .concat(
                  getFieldValue(
                    row,
                    row.typ == ArtikelStammdatenExportEintragExportTyp.ARTIKEL
                      ? ['artikel.ean']
                      : []
                  )
                )
                .concat(
                  getFieldValue(
                    row,
                    row.typ == ArtikelStammdatenExportEintragExportTyp.ARTIKEL
                      ? ['artikel.bez1', 'artikel.bez2', 'artikel.bez3', 'artikel.bez4']
                      : ['lieferant.name1', 'lieferant.name2']
                  ),
                  row.ekRabattgruppe
                    ? `${row.ekRabattgruppe?.lieferant.nummer} ${
                        row.ekRabattgruppe?.lieferant.name1
                      } - ${row.ekRabattgruppe?.name} ${row.ekRabattgruppe?.bezeichnung || ''}`
                    : null,
                  row.ekRabattgruppeVersion
                    ? `${formatLieferant(row.ekRabattgruppeVersion.lieferant)} - ${
                        row.ekRabattgruppeVersion?.name
                      } ${
                        row.ekRabattgruppeVersion?.bezeichnung || ''
                      } ${formatGueltig(row.ekRabattgruppeVersion)}`
                    : null,
                  row.ekPreis
                    ? `${formatLieferant(row.ekPreis.lieferant)} ${formatGueltig(row.ekPreis)}`
                    : null,
                  row.vkPreis
                    ? `${formatLieferant(row.vkPreis.lieferant)} ${formatGueltig(row.vkPreis)}`
                    : null,
                  row.lieferant ? `${formatLieferant(row.lieferant)}` : '',
                  row.standortArtikel
                    ? `Standort ${row.standortArtikel.standort.nr} ${row.standortArtikel.standort.name}, Artikel ${row.standortArtikel.artikel.hageNummer} ${row.standortArtikel.artikel.bez1}`
                    : null,
                  row.vkEtiketten
                    ? `${formatGueltig(row.vkEtiketten)}, Standort ${row.vkEtiketten.standort.nr} ${row.vkEtiketten.standort.name}, Artikel ${row.vkEtiketten.artikel.hageNummer} ${row.vkEtiketten.artikel.bez1}`
                    : null
                )
                .filter(Boolean)
            }
          },
          sortable: true
        },
        {
          field: 'notExported',
          header: 'Links',
          body: (row) => {
            if (!row.notExported) {
              return null
            }

            const links = []
            const createLink = (link, key, label) => (
              <Link to={link} key={key}>
                <Grid
                  container
                  spacing="4px"
                  marginBottom="-28px"
                  marginTop="-4px"
                  flexWrap="nowrap"
                >
                  <Grid>
                    <ArrowRightAlt />
                  </Grid>
                  <Grid>{label}</Grid>
                </Grid>
              </Link>
            )
            row.errorSeArtikelIds.forEach((seId) => {
              const exportProtokollId = reasonArtikelMap.get(seId)?.exportError?.protokollId
              if (exportProtokollId) {
                links.push(
                  createLink(
                    AppPaths.ProtokollDetailsViewFn(exportProtokollId),
                    exportProtokollId,
                    'Protokoll'
                  )
                )
                links.push(<br key={seId + 'a'} />)
              }
              const importProtokollId = reasonArtikelMap.get(seId)?.importError?.protokollId
              if (importProtokollId) {
                links.push(
                  createLink(
                    AppPaths.ProtokollDetailsViewFn(importProtokollId),
                    importProtokollId,
                    'Protokoll'
                  )
                )
                links.push(<br key={seId + 'b'} />)
              }
              const artikel = reasonArtikelMap.get(seId)?.artikel
              const artikelId = artikel?.id
              if (artikelId) {
                links.push(
                  createLink(
                    AppPaths.ArtikelFn(artikelId),
                    artikelId,
                    "Artikel '" + artikel.bez1 + "'"
                  )
                )
                links.push(<br key={seId + 'c'} />)
              }
            })
            row.errorSeLieferantIds.forEach((seId) => {
              const exportProtokollId = reasonLieferantMap.get(seId)?.exportError?.protokollId
              if (exportProtokollId) {
                links.push(
                  createLink(
                    AppPaths.ProtokollDetailsViewFn(exportProtokollId),
                    exportProtokollId,
                    'Protokoll'
                  )
                )
                links.push(<br key={seId + 'd'} />)
              }
              const importProtokollId = reasonLieferantMap.get(seId)?.importError?.protokollId
              if (importProtokollId) {
                links.push(
                  createLink(
                    AppPaths.ProtokollDetailsViewFn(importProtokollId),
                    importProtokollId,
                    'Protokoll'
                  )
                )
                links.push(<br key={seId + 'e'} />)
              }
              const lieferant = reasonLieferantMap.get(seId)?.lieferant
              const lieferantId = lieferant?.id
              if (lieferantId) {
                links.push(
                  createLink(AppPaths.LieferantFn(lieferantId), lieferantId, lieferant.name1)
                )
                links.push(<br key={seId + 'f'} />)
              }
            })
            return links
          },
          sortable: false
        }
      ] as Column<ArtikelStammdatenExportEintragJson>[],
    [et, reasonArtikelMap, reasonLieferantMap]
  )

  const extractedValues = useMemo(() => {
    const valid = []
    const invalid = []
    values?.forEach((value) => {
      if (value.notExported) {
        invalid.push(value)
      } else {
        valid.push(value)
      }
    })
    return {
      valid,
      invalid
    }
  }, [values])

  return (
    <Frame space>
      <FrameBody>
        <DataTableCard
          name="ExportToErpTable"
          title="Exportdaten"
          columns={columns}
          value={extractedValues.valid}
          loading={loading}
          limited={limited}
          dense
          initialOrderBy="artikel.hageNummer"
          localStateName="ExportToErpTable"
          paging
          filterMode="both"
          emptyMessage={
            extractedValues.invalid?.length > 0
              ? 'Exportdaten sind blockiert (siehe unten)'
              : 'Keine Daten zum Export verfügbar.'
          }
        />
      </FrameBody>
      {extractedValues.invalid?.length > 0 && (
        <FrameBody>
          <DataTableCard
            name="ExportBLockedToErpTable"
            title="Blockierte Exportdaten"
            columns={columns}
            value={extractedValues.invalid}
            loading={loading}
            limited={limited}
            dense
            initialOrderBy="artikel.hageNummer"
            localStateName="ExportToErpTable"
            paging
            filterMode="both"
            emptyMessage="Keine blockierte Daten vorhanden."
          />
        </FrameBody>
      )}
    </Frame>
  )
}
