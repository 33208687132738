import { api } from '@one/api'
import { ArtikelBetriebstypField } from '@one/components/common/ArtikelBetriebstypField'
import {
  ArtikelArtJson,
  ArtikelDatenpoolDisplayJson,
  ArtikelDatenpoolKeyJson,
  ArtikelDatenpoolUebernahmeBearbeitenJson,
  ArtikelDatenpoolUebernahmeChangeJson,
  EANGruppeDisplayJson,
  HandelTyp,
  UseCaseStateJson
} from '@one/typings/apiTypings'
import { useApiCaller } from '@utils/apicaller'
import { useFormState } from '@utils/formstate'
import { Button } from '@utils/ui/Buttons/Button'
import { CancelButton } from '@utils/ui/Buttons/CancelButton'
import { DialogEx } from '@utils/ui/DialogEx'
import { SelectField } from '@utils/ui/fields/SelectField'
import { SnackbarType, useSnackbarEx } from '@utils/ui/snackbarex'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { AppContext } from '@utils/ui/App/AppContext'
import { NeonKontextField } from '@one/components/common/NeonKontextField'
import { SelectEnumField } from '@utils/ui/fields/SelectEnumField'
import { HkmEnum } from '@one/enums/HkmEnum'
import { WarengruppeField } from '@one/components/Warengruppe/WarengruppeField'
import { SpartenField } from '@one/components/Warengruppe/SparteField'
import { StatePlane } from '@utils/ui/planes/StatePlane'
import { messageToErrors } from '@utils/utils'
import { ErrorsType } from '@utils/modelmgr'
import { Alert, Grid2 as Grid, Typography } from '@mui/material'
import { useArtikelBetriebstypCache } from '@one/datacaches/useArtikelBetriebsTypeCache'

export interface UebernahmeEigenlistungsDialogProps {
  open: boolean
  onClose: (saved: boolean) => void
  anlageSel?: ArtikelDatenpoolDisplayJson[]
  artikelKeys: ArtikelDatenpoolKeyJson[]
}

export const UebernahmeEigenlistungsDialog = ({
  open,
  onClose,
  anlageSel,
  artikelKeys
}: UebernahmeEigenlistungsDialogProps) => {
  const { isPIMModel } = useContext(AppContext)
  const [apiCall, apiBusy] = useApiCaller(api)

  const { data: btList } = useArtikelBetriebstypCache()

  const [state, onChange] = useFormState<ArtikelDatenpoolUebernahmeChangeJson>({
    eanGruppeIds: null,
    bt: null,
    kontext: null,
    sparteId: null,
    warengruppeId: null
  })

  const [errors, setErrors] = useState<ErrorsType>({})
  const [eanGruppen, setEanGruppen] = useState<EANGruppeDisplayJson[]>([])
  const [artikelArten, setArtikelArten] = useState<ArtikelArtJson[]>([])

  const { enqueMsg } = useSnackbarEx()

  const anlage = anlageSel?.length > 0

  const fetchParameter = useCallback(
    () =>
      apiCall<ArtikelDatenpoolUebernahmeBearbeitenJson>({
        method: 'POST',
        rest: '/artikeldatenpool/uebernahme/create',
        data: [],
        onSuccess: (data) => {
          setEanGruppen(data.eanGruppen)
          setArtikelArten(data.artikelArten)
          onChange(() => ({
            bt: data.defaultBetriebstyp,
            artikelArtId: data.defaultArtikelArtId,
            eanGruppeId: data.defaultEanGruppeId,
            kontext: data.defaultKontext,
            pimArtikelArt: data.defaultPimArtikelArt
          }))
        }
      }),
    [apiCall, onChange]
  )

  const message: string | null = useMemo(() => {
    if (anlage) {
      if (isPIMModel) {
        // TODO
      } else {
        if (state.bt != null) {
          const isEH = btList.find((bt) => bt.bt === state.bt)?.handelTyp === HandelTyp.EH
          const isFH = btList.find((bt) => bt.bt === state.bt)?.handelTyp === HandelTyp.FH
          const hasNoMEEH = anlageSel.find((anl) => anl.einkaufMEEH == null) != null
          const hasNoMEFH = anlageSel.find((anl) => anl.einkaufMEFH == null) != null
          console.log('--->', isEH, isFH, hasNoMEEH, hasNoMEFH)
          if (isEH && hasNoMEEH) {
            return 'Es gibt Artikel ohne Einkaufs-ME für Einzelhandel!'
          }
          if (isFH && hasNoMEFH) {
            return 'Es gibt Artikel ohne Einkaufs-ME für Fachhandel!'
          }
        }
      }
    }
    return null
  }, [anlage, anlageSel, btList, isPIMModel, state.bt])

  useEffect(() => {
    if (anlage) {
      fetchParameter()
    }
  }, [anlage, fetchParameter])

  const onSave = useCallback(
    () =>
      apiCall<ArtikelDatenpoolUebernahmeBearbeitenJson>({
        method: 'POST',
        rest: '/artikeldatenpool/uebernahme/save',
        data: {
          bt: state.bt,
          artikelArtId: state.artikelArtId,
          pimArtikelArt: state.pimArtikelArt,
          eanGruppeIds: state.eanGruppeIds,
          kontext: state.kontext,
          sparteId: state.sparteId,
          warengruppeId: state.warengruppeId,
          keyList: artikelKeys
        },
        onSuccess: () => {
          enqueMsg('Anlage/Aktualisierung der Eigenlistung erfolgreich', SnackbarType.success)
          onClose(true)
        },
        onError: (error: UseCaseStateJson) => {
          setErrors(messageToErrors(error?.mainMessage && [error?.mainMessage], 'flat'))
        }
      }),
    [
      apiCall,
      state.bt,
      state.artikelArtId,
      state.eanGruppeIds,
      state.kontext,
      state.sparteId,
      state.warengruppeId,
      artikelKeys,
      enqueMsg,
      onClose
    ]
  )

  const actions = (
    <>
      <Button
        key="eigenlistung"
        label={`${artikelKeys.length} Artikel übernehmen`}
        onClick={onSave}
        variant="contained"
        disabled={message != null}
      />
      <CancelButton onClick={() => onClose(false)} />
    </>
  )

  return (
    <DialogEx
      open={open}
      onClose={() => onClose(false)}
      fullWidth
      height={anlage ? '400px' : '240px'}
      maxWidth={anlage ? 'md' : 'sm'}
      title="Artikeldatenpool Übernahme"
      actions={actions}
    >
      <StatePlane wait={apiBusy} noNav>
        {anlage ? (
          <Grid container spacing={2} padding={2} direction="row">
            {isPIMModel ? (
              <>
                <Grid size={{ xs: 12, sm: 6 }}>
                  <NeonKontextField
                    value={state.kontext}
                    label="Kontext"
                    required
                    onChange={onChange}
                    name="kontext"
                    error={errors['kontext']}
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 6 }}>
                  <SelectEnumField
                    label="Artikelart"
                    value={state.pimArtikelArt}
                    name="artikelArtId"
                    onChange={onChange}
                    enumType={HkmEnum.PimArtikelArt}
                    required
                    fullWidth
                    error={errors['pimArtikelArt']}
                  />
                </Grid>
              </>
            ) : (
              <>
                <Grid size={{ xs: 12, sm: 6 }}>
                  <ArtikelBetriebstypField
                    label="Betriebstyp"
                    value={state.bt}
                    onChange={onChange}
                    name="bt"
                    required
                    error={errors['bt']}
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 6 }}>
                  <SelectField
                    options={artikelArten}
                    optionText={(art) => `${art.kurz} - ${art.bez}`}
                    value={state.artikelArtId}
                    label="Artikelart"
                    name="artikelArtId"
                    onChange={onChange}
                    fullWidth
                    required
                    error={errors['artikelArtId']}
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 6 }}>
                  <SelectField
                    name="eanGruppeIds"
                    label="Standard EAN-Gruppen"
                    // error={errors.defaultEanGruppeId}
                    multiple
                    value={state.eanGruppeIds}
                    options={eanGruppen}
                    onChange={onChange}
                    renderItem={(eanGruppe: EANGruppeDisplayJson) =>
                      eanGruppe.name + ' - ' + eanGruppe.bezeichnung
                    }
                    fullWidth
                    required
                    error={errors['eanGruppeIds']}
                  />
                </Grid>
              </>
            )}
            <Grid size={{ xs: 12, sm: 6 }}>
              <WarengruppeField
                label="Warengruppe"
                value={state.warengruppeId}
                onChange={onChange}
                name="warengruppeId"
                fullWidth
                idMode="seId"
                required
                gfMitStatistik
                auchLeere
                error={errors['warengruppeId']}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <SpartenField
                value={state.sparteId}
                name="sparteId"
                onChange={onChange}
                fullWidth
                required
                error={errors['sparteId']}
                idMode
              />
            </Grid>
            {message && (
              <Grid size={{ sm: 12 }}>
                <Alert variant="filled" color="error">
                  {message}
                </Alert>
              </Grid>
            )}
          </Grid>
        ) : (
          <Typography>Artikeldaten der betroffenen Artikel werden übernommen.</Typography>
        )}
      </StatePlane>
    </DialogEx>
  )
}
