import { FiberManualRecord } from '@mui/icons-material'
import { AppPaths } from '@one/AppPaths'
import { enrichKondiDefinition } from '@one/components/EkPreis/EKPreisPflege/model/EkPreiseUsecase'
import { WerteCell } from '@one/components/common/DataPreisCell'
import { LieferantCell } from '@one/components/common/LieferantCell'
import { formatLieferant } from '@one/components/common/formatters'
import { usePreisEbenenCache } from '@one/datacaches/usePreisEbenenCache'
import {
  ArtikelEkPreiseJson,
  EkKonditionenDefinitionJson,
  StaffelgruppeDisplayJson
} from '@one/typings/apiTypings'
import { compareDates } from '@utils/dateutils'
import { compareNumber } from '@utils/numberutils'
import { AppContext } from '@utils/ui/App/AppContext'
import { AppLink } from '@utils/ui/App/AppLink'
import { CardEx } from '@utils/ui/CardEx'
import { Column, DataTable, RowGrouper } from '@utils/ui/DataTable/DataTable'
import { ScrollPanel } from '@utils/ui/ScrollPanel'
import { ThemeContext } from '@utils/ui/Theme'
import { compareStrings, formatInteger, formatMoney2, safeArray } from '@utils/utils'
import { useContext, useMemo } from 'react'

export interface ArtikelErpEkPreiseProps {
  ekPreise: ArtikelEkPreiseJson[]
  konditionenDefinitionen?: EkKonditionenDefinitionJson[]
}

export const ArtikelErpEkPreise = ({
  ekPreise,
  konditionenDefinitionen
}: ArtikelErpEkPreiseProps) => {
  const { darkMode } = useContext(ThemeContext)
  const { isAllianz } = useContext(AppContext)
  const enrichedKondiDef = enrichKondiDefinition(konditionenDefinitionen[0], isAllianz)
  const { data: preisEbenen } = usePreisEbenenCache()

  const ekPreiseOrdered = useMemo(() => {
    const copy = safeArray(ekPreise).map((ek) => {
      const c = { ...ek, werte: [...safeArray(ek.werte)] }
      c.werte.sort((a, b) => compareNumber(a.staffelmenge, b.staffelmenge))
      return c
    })
    copy.sort((a, b) => {
      const lieferantA = formatLieferant(a.lieferant)
      const lieferantB = formatLieferant(b.lieferant)
      let rc = compareStrings(lieferantA, lieferantB, 'sort')
      if (rc === 0) {
        const peA = preisEbenen.find((pe) => pe.id === a.preisEbeneId)
        const peB = preisEbenen.find((pe) => pe.id === b.preisEbeneId)
        if (peA !== peB) {
          if (peA == null) {
            return -1
          }
          if (peB == null) {
            return 1
          }
          rc = compareStrings(peA.name, peB.name, 'sort')
        }
        if (rc == 0) {
          rc = compareDates(a.gueltigVon, b.gueltigVon)
        }
        return rc
      }
      return 0
    })
    return copy
  }, [ekPreise, preisEbenen])

  const columns = useMemo(
    () =>
      [
        {
          field: 'lieferant.id',
          header: 'Lieferant',
          valueGetter: (row) => formatLieferant(row.lieferant),
          body: (row) => <LieferantCell lieferant={row.lieferant} asLink />
        },
        {
          type: 'string',
          field: 'standortNr',
          align: 'right',
          header: isAllianz ? 'PE-Nr.' : 'Standort-Nr.',
          headerTip: isAllianz ? 'Nummer der Preisebene' : null,
          valueGetter: (row) => preisEbenen.find((pe) => pe.id === row.preisEbeneId)?.nr
        },
        {
          type: 'string',
          field: 'standortBez',
          headerTip: isAllianz ? 'Name der Preisebene' : null,
          header: isAllianz ? 'PE-Name' : 'Standort',
          valueGetter: (row) => {
            const preisEbene = preisEbenen.find((pe) => pe.id === row.preisEbeneId)
            return preisEbene?.alias ?? preisEbene?.name
          }
        },
        {
          type: 'date',
          field: 'gueltigVon',
          align: 'right',
          header: 'Gültig von'
        },
        {
          type: 'date',
          field: 'gueltigBis',
          align: 'right',
          header: 'Gültig bis'
        },
        {
          type: 'string',
          field: 'einheit',
          header: 'Einheit',
          valueGetter: (row) => row.einheit.bez
        },
        {
          field: 'staffelmenge',
          header: 'Staffel',
          headerTip: 'Staffelmenge',
          cellPadding: 0,
          valueGetter: (row) => row.werte?.map((w) => w.staffelmenge),
          body: (row) => (
            <WerteCell
              werte={row.werte}
              field="staffelmenge"
              formatter={(v, w) => {
                if (v == null) {
                  return null
                }
                if (w.hauptstaffel) {
                  return (
                    <span title="Hauptstaffel">
                      <FiberManualRecord style={{ fontSize: '60%', paddingBottom: '1px' }} />
                      {formatInteger(v)}
                    </span>
                  )
                }
                return <>{formatInteger(v)}</>
              }}
              darkMode={darkMode}
            />
          )
        },
        {
          field: 'werte.listenpreis',
          header: enrichedKondiDef.listenpreisLabel,
          // headerTip: enrichedKondiDef.listenpreisTip,
          cellPadding: 0,
          valueGetter: (row) => row.werte?.map((w) => w.listenpreis),
          body: (row) => (
            <WerteCell
              werte={row.werte}
              field="listenpreis"
              formatter={formatMoney2}
              darkMode={darkMode}
            />
          )
        },
        {
          field: 'werte.sonderpreis',
          header: enrichedKondiDef.sonderpreisLabel,
          // headerTip: enrichedKondiDef.sonderpreisTip,
          cellPadding: 0,
          valueGetter: (row) => row.werte?.map((w) => w.sonderpreis),
          body: (row) => (
            <WerteCell
              werte={row.werte}
              field="sonderpreis"
              formatter={formatMoney2}
              darkMode={darkMode}
            />
          )
        },
        {
          field: 'werte.freiHaus',
          header: enrichedKondiDef.ekKondiFreiHausLabel,
          // headerTip: enrichedKondiDef.ekKondiFreiHausTip,
          cellPadding: 0,
          valueGetter: (row) => row.werte?.map((w) => w.freiHaus),
          body: (row) => (
            <WerteCell
              werte={row.werte}
              field="freiHaus"
              formatter={formatMoney2}
              darkMode={darkMode}
            />
          )
        },
        {
          field: 'werte.lastFreiHaus',
          header: enrichedKondiDef.ekKondiFreiHausLabel + ' V',
          // headerTip: enrichedKondiDef.ekKondiFreiHausTip + ' - Vorheriger Wert',
          cellPadding: 0,
          valueGetter: (row) => row.werte?.map((w) => w.lastFreiHaus),
          body: (row) => (
            <WerteCell
              werte={row.werte}
              field="lastFreiHaus"
              formatter={formatMoney2}
              darkMode={darkMode}
            />
          )
        },
        {
          field: 'werte.delta',
          header: enrichedKondiDef.ekKondiFreiDiffLabel,
          // headerTip: enrichedKondiDef.ekKondiFreiDiffTip,
          cellPadding: 0,
          valueGetter: (row) => row.werte?.map((w) => w.delta),
          body: (row) => (
            <WerteCell
              werte={row.werte}
              field="delta"
              formatter={formatMoney2}
              darkMode={darkMode}
            />
          )
        },
        {
          field: 'werte.abs1',
          header: enrichedKondiDef.zuschlAbs1Label,
          // headerTip: enrichedKondiDef.zuschlAbs1Tip,
          cellPadding: 0,
          visible: (row) => !isAllianz,
          valueGetter: (row) => row.werte?.map((w) => w.abs1),
          body: (row) => (
            <WerteCell
              werte={row.werte}
              field="abs1"
              formatter={formatMoney2}
              darkMode={darkMode}
            />
          )
        },
        {
          field: 'werte.abs2',
          header: enrichedKondiDef.zuschlAbs2Label,
          // headerTip: enrichedKondiDef.zuschlAbs2Tip,
          cellPadding: 0,
          visible: (row) => !isAllianz,
          valueGetter: (row) => row.werte?.map((w) => w.abs2),
          body: (row) => (
            <WerteCell
              werte={row.werte}
              field="abs2"
              formatter={formatMoney2}
              darkMode={darkMode}
            />
          )
        },
        {
          field: 'werte.proz1',
          header: enrichedKondiDef.rabattProz1Label,
          // headerTip: enrichedKondiDef.rabattProz1Tip,
          cellPadding: 0,
          valueGetter: (row) => row.werte?.map((w) => w.proz1),
          body: (row) => (
            <WerteCell
              werte={row.werte}
              field="proz1"
              formatter={formatMoney2}
              darkMode={darkMode}
            />
          )
        },
        {
          field: 'werte.proz2',
          header: enrichedKondiDef.rabattProz2Label,
          // headerTip: enrichedKondiDef.rabattProz2Tip,
          cellPadding: 0,
          valueGetter: (row) => row.werte?.map((w) => w.proz2),
          body: (row) => (
            <WerteCell
              werte={row.werte}
              field="proz2"
              formatter={formatMoney2}
              darkMode={darkMode}
            />
          )
        },
        {
          field: 'werte.proz3',
          header: enrichedKondiDef.rabattProz3Label,
          // headerTip: enrichedKondiDef.rabattProz3Tip,
          cellPadding: 0,
          valueGetter: (row) => row.werte?.map((w) => w.proz3),
          body: (row) => (
            <WerteCell
              werte={row.werte}
              field="proz3"
              formatter={formatMoney2}
              darkMode={darkMode}
            />
          )
        },
        {
          field: 'werte.proz4',
          header: enrichedKondiDef.rabattProz4Label,
          // headerTip: enrichedKondiDef.rabattProz4Tip,
          cellPadding: 0,
          valueGetter: (row) => row.werte?.map((w) => w.proz4),
          body: (row) => (
            <WerteCell
              werte={row.werte}
              field="proz4"
              formatter={formatMoney2}
              darkMode={darkMode}
            />
          )
        },
        {
          field: 'werte.proz5',
          header: enrichedKondiDef.rabattProz5Label,
          // headerTip: enrichedKondiDef.rabattProz5Tip,
          cellPadding: 0,
          valueGetter: (row) => row.werte?.map((w) => w.proz5),
          body: (row) => (
            <WerteCell
              werte={row.werte}
              field="proz5"
              formatter={formatMoney2}
              darkMode={darkMode}
            />
          )
        },
        {
          field: 'werte.abs3',
          header: enrichedKondiDef.zuschlAbs3Label,
          // headerTip: enrichedKondiDef.zuschlAbs3Tip,
          cellPadding: 0,
          visible: (row) => !isAllianz,
          valueGetter: (row) => row.werte?.map((w) => w.abs3),
          body: (row) => (
            <WerteCell
              werte={row.werte}
              field="abs3"
              formatter={formatMoney2}
              darkMode={darkMode}
            />
          )
        },
        {
          field: 'werte.abs4',
          header: enrichedKondiDef.zuschlAbs4Label,
          // headerTip: enrichedKondiDef.zuschlAbs4Tip,
          cellPadding: 0,
          visible: (row) => !isAllianz,
          valueGetter: (row) => row.werte?.map((w) => w.abs4),
          body: (row) => (
            <WerteCell
              werte={row.werte}
              field="abs4"
              formatter={formatMoney2}
              darkMode={darkMode}
            />
          )
        },
        {
          field: 'werte.abs5',
          header: enrichedKondiDef.zuschlAbs5Label,
          // headerTip: enrichedKondiDef.zuschlAbs5Tip,
          cellPadding: 0,
          visible: (row) => !isAllianz,
          valueGetter: (row) => row.werte?.map((w) => w.abs5),
          body: (row) => (
            <WerteCell
              werte={row.werte}
              field="abs5"
              formatter={formatMoney2}
              darkMode={darkMode}
            />
          )
        },
        {
          type: 'string',
          field: 'werte.rabattgruppe',
          header: 'EK-RG Staffel',
          headerTip: 'EK-Rabattgruppe der Staffel',
          cellPadding: 0,
          valueGetter: (row) => row.werte?.map((w) => w.rabattgruppe.name),
          body: (row) => (
            <WerteCell
              werte={row.werte}
              field="rabattgruppe"
              formatter={(staffelGruppe: StaffelgruppeDisplayJson) => staffelGruppe.name}
              darkMode={darkMode}
              align="left"
            />
          )
        },
        {
          type: 'string',
          field: 'ebeneRabattgruppe',
          header: isAllianz ? 'EK-RG PE' : 'EK-RG Standort',
          headerTip: isAllianz ? 'EK-Rabattgruppe der Preisebene' : 'EK-Rabattgruppe des Standorts',
          valueGetter: (row) => row.ebeneRabattgruppe?.name,
          body: (row) => (
            <AppLink
              path={AppPaths.EkRabattgruppenPflegeFn({ id: row.ebeneRabattgruppe?.id })}
              text={row.ebeneRabattgruppe?.name}
            />
          )
        },
        {
          type: 'string',
          field: 'artikelRabattgruppe',
          header: 'EK-RG Artikel',
          headerTip: 'EK-Rabattgruppe des Artikels',
          valueGetter: (row) => row.artikelRabattgruppe?.name,
          body: (row) => (
            <AppLink
              path={AppPaths.EkRabattgruppenPflegeFn({ id: row.artikelRabattgruppe?.id })}
              text={row.artikelRabattgruppe?.name}
            />
          )
        },
        {
          type: 'boolean',
          field: 'abgeschlossen',
          header: 'Abgeschlossen',
          tooltip: null
        }
      ] as Column<ArtikelEkPreiseJson>[],
    [darkMode, isAllianz, enrichedKondiDef, preisEbenen]
  )

  const grouper: RowGrouper<ArtikelEkPreiseJson> = {
    field: 'lieferant.id',
    valueGetter: (row) => formatLieferant(row.lieferant),
    body: (row) => <LieferantCell lieferant={row.lieferant} asLink />
  }

  return (
    <CardEx name="ErpEkPreise" title="EK Preise">
      <ScrollPanel autoHeight autoHeightMax="420px">
        <DataTable
          name="ErpEkPreiseTable"
          dense
          columns={columns}
          value={ekPreiseOrdered}
          emptyMessage="Keine Daten"
          groupBy={grouper}
        />
      </ScrollPanel>
    </CardEx>
  )
}
