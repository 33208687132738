/* eslint-disable no-plusplus */
import {Grid} from '@mui/material'
import {AppPaths} from '@one/AppPaths'
import {VerteilungsZielSel} from '@one/components/common/VerteilungsZielSel'
import {HkmEnum} from '@one/enums/HkmEnum'
import {CardEx} from '@utils/ui/CardEx'
import {Checkbox} from '@utils/ui/fields/Checkbox'
import {SelectEnumField} from '@utils/ui/fields/SelectEnumField'
import {TextField} from '@utils/ui/fields/TextField'

export interface ArtikelVerteilerFormProps {
  onChange: (e: any) => void
  model?: any
  errors?: any
  isNew?: boolean
}

export const ArtikelVerteilerForm = ({
  onChange,
  model = {},
  errors = {},
  isNew = false
}: ArtikelVerteilerFormProps) => {
  return (
    <CardEx
      backLink
      overviewLink={AppPaths.artikelverteiler.ArtikelVerteilerUebersicht}
      title={isNew ? 'Artikelverteiler erstellen' : `Artikelverteiler ${model.name || ''}`}
    >
      <Grid container spacing={2} direction="column">
        <Grid item container direction="row" spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              name="name"
              label="Name"
              error={errors.name}
              value={model.name}
              onChange={onChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <SelectEnumField
              label="Preisverteilungseinstellung"
              fullWidth
              name="preisVerteilungsEinstellung"
              value={model.preisVerteilungsEinstellung}
              enumType={HkmEnum.PreisVerteilungEinstellung}
              displayEmpty={false}
              required
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <Checkbox
              name="aktiv"
              label="Verteiler aktivieren"
              checked={model.aktiv}
              onChange={onChange}
            />
          </Grid>
        </Grid>
        <Grid item container direction="row" spacing={2}>
          <Grid item xs={12} sm={6} lg={3}>
            <VerteilungsZielSel
              label="Verteilung an"
              fullWidth
              required
              name="verteilungsZiele"
              placeholder="Wählen Sie eine oder mehrere Firmen aus"
              error={errors.verteilungsZiele}
              value={model.verteilungsZiele}
              options={model.verteilungsZielList}
              onChange={onChange}
            />
          </Grid>
        </Grid>
      </Grid>
    </CardEx>
  )
}
