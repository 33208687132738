/* eslint-disable react/jsx-props-no-spreading */
import SearchIcon from '@mui/icons-material/Search'
import {api} from '@one/api'
import {useApiCaller} from '@utils/apicaller'
import {IconButton} from '@utils/ui/Buttons/IconButton'
import {useDialogAnker} from '@utils/ui/DialogAnker'
import {AutocompleteAsync} from '@utils/ui/fields/AutocompleteAsync'
import {useCallback} from 'react'
import {ArtikelListeSuchDialog} from '../ArtikelListeSuchDialog/ArtikelListeSuchDialog'

export interface ArtikelListenFieldProps {
  name?: string
  label?: string
  placeholder?: string
  value?: any
  error?: any
  onChange?: (e: any) => void
  mitStatistik?: boolean
  multiple?: boolean
  disabled?: boolean
}

export const ArtikelListenField = ({
  value,
  label,
  name,
  onChange,
  placeholder,
  error,
  mitStatistik = false,
  multiple = false,
  disabled = false
}: ArtikelListenFieldProps) => {
  const [DlgAnker, dlgShow] = useDialogAnker()

  const [apiCall] = useApiCaller(api)

  const onOpenSearch = useCallback(
    (event) => {
      event.stopPropagation()
      event.preventDefault()
      dlgShow((open, onClose) => (
        <ArtikelListeSuchDialog
          multiple={multiple}
          open={open}
          onClose={onClose((v) => onChange({ name, value: v }))}
        />
      ))
    },
    [dlgShow, multiple, name, onChange]
  )

  const actions =
    onChange != null && !disabled ? (
      <IconButton icon={<SearchIcon />} style={{ padding: 2 }} onClick={onOpenSearch} />
    ) : null

  const optionLabel = (params) => {
    return `${params.name}`
  }

  const renderItem = (opt) => (
    <div style={{ display: 'flex', flexFlow: 'row', width: '100%' }}>
      <div style={{ flexGrow: '1' }}>{opt.name}</div>
      {mitStatistik && (
        <div>
          <small>{` ${opt.artikelAnzahl} Artikel`}</small>
        </div>
      )}
    </div>
  )

  const isOptionEqualToValue = (opt, val) => {
    if (val === opt) {
      return true
    }
    if (opt == null || val == null) {
      return false
    }
    return opt.id === val.id
  }

  const queryOptions = ({ onSuccess, onError, query, reason }) => {
    if (reason !== 'query') {
      return
    }
    apiCall<any>({
      method: 'POST',
      rest: '/artikelliste/uebersichtAnzeigen/search',
      data: { name: query },
      onSuccess: (dta) => onSuccess(dta.searcher.result),
      onError
    })
  }

  return (
    <>
      <AutocompleteAsync
        fullWidth
        label={label}
        actions={actions}
        renderItem={renderItem}
        optionLabel={optionLabel}
        isOptionEqualToValue={isOptionEqualToValue}
        emptyText={placeholder}
        queryOptions={queryOptions}
        value={value}
        name={name}
        onChange={onChange}
        disabled={disabled}
        error={error}
      />
      <DlgAnker />
    </>
  )
}
