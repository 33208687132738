import { Grid2 as Grid } from '@mui/material'
import { DzLieferantJson } from '@one/typings/apiTypings'
import { formatDateTime } from '@utils/dateutils'
import { CardEx } from '@utils/ui/CardEx'
import { StaticField } from '@utils/ui/fields/StaticField'

export type LieferantErpSperreProps = {
  lieferant: DzLieferantJson
}

export const LieferantErpSperre = ({ lieferant }: LieferantErpSperreProps) => (
  <CardEx title="Sperren">
    <Grid container spacing={2}>
      <Grid size={{xs:4}}>
        <StaticField label="Gelöscht (Listung)" value={lieferant.geloescht ? 'Ja' : 'Nein'} />
      </Grid>
      <Grid size={{xs:4}}>
        <StaticField
          label="Gelöscht (ERP)"
          value={
            lieferant.geloeschtDz
              ? 'Ja ' +
                (lieferant.geloeschtDzGueltigVon
                  ? ` seit ${formatDateTime(lieferant.geloeschtDzGueltigVon)}`
                  : '')
              : 'Nein'
          }
        />
      </Grid>
      <Grid size={{xs:4}}></Grid>
      <Grid size={{xs:4}}>
        <StaticField
          label="Gesperrt (Listung)"
          value={
            lieferant.gesperrt
              ? 'Ja' +
                (lieferant.gesperrtDzGueltigVon
                  ? ` seit ${formatDateTime(lieferant.gesperrtDzGueltigVon)}`
                  : '')
              : 'Nein'
          }
        />
      </Grid>
      <Grid size={{xs:4}}>
        <StaticField label="Gesperrt (ERP)" value={lieferant.gesperrtDz ? 'Ja' : 'Nein'} />
      </Grid>
    </Grid>
  </CardEx>
)
