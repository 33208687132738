/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable react/jsx-props-no-spreading */
import {Error, Info, Warning} from '@mui/icons-material'
import {Dialog, DialogActions, DialogContent, Grid2 as Grid, Typography} from '@mui/material'
import {lightBlue, red, yellow} from '@mui/material/colors'
import {ReactNode, useCallback, useState} from 'react'
import {makeStyles} from 'tss-react/mui'
import {Button} from './Buttons/Button'
import {ColorButton} from './Buttons/ColorButton'
import {useGlobalDialogAnker} from './DialogAnker'
import {Checkbox} from './fields/Checkbox'
import {Breakpoint} from '@mui/system'

const useStyles = makeStyles()({
  checkme: {
    display: 'flex',
    width: '100%',
    flexFlow: 'column',
    marginTop: '12px'
  },
  dialog: {
    padding: '0 !important'
  },

  // buttonInfo: {
  //   backgroundColor: lightBlue[600]
  // },
  // buttonWarning: {
  //   backgroundColor: yellow[600]
  // },
  // buttonSevere: {
  //   backgroundColor: red[600]
  // },

  paperInfo: {
    borderLeft: `solid 1em ${lightBlue[600]}`
  },
  paperWarning: {
    borderLeft: `solid 1em ${yellow[600]}`
  },
  paperSevere: {
    borderLeft: `solid 1em ${red[600]}`
  }
})

export type MessageDialogProps = {
  open?: boolean
  onClose?: any
  type?: 'confirm' | 'okcancel' | 'yesnocancel'
  severity?: 'generic' | 'info' | 'warning' | 'severe'
  extraConfirm?: boolean
  title?: string
  message?: ReactNode
  confirmLabel?: string
  cancelLabel?: string
  noLabel?: string
  onConfirm?: any
  onCancel?: any
  onNo?: any
  maxWidth?: Breakpoint | false
}

export const MessageDialog = ({
  open,
  onClose,
  type,
  severity,
  extraConfirm,
  title,
  message,
  confirmLabel,
  cancelLabel,
  noLabel,
  onConfirm,
  onCancel,
  onNo,
  maxWidth
}: MessageDialogProps) => {
  const { classes } = useStyles()
  const [confirmed, setConfirmed] = useState(extraConfirm == null)

  const getIconType = () => {
    switch (severity) {
      case 'info':
        return <Info style={{ fontSize: '4rem' }} />
      case 'warning':
        return <Warning style={{ fontSize: '4rem' }} />
      case 'severe':
        return <Error style={{ fontSize: '4rem' }} />
      default:
        return null
    }
  }

  const getPaperColor = () => {
    switch (severity) {
      case 'info':
        return classes.paperInfo
      case 'warning':
        return classes.paperWarning
      case 'severe':
        return classes.paperSevere
      default:
        return ''
    }
  }

  const getButtonColor = () => {
    switch (severity) {
      case 'info':
        return lightBlue
      case 'warning':
        return yellow
      case 'severe':
        return red
      default:
        return null
    }
  }
  const _iconType = getIconType()
  const _confirmColor = getButtonColor()
  const _title = title || 'Achtung'
  const _confirmLabel = confirmLabel || (type === 'yesnocancel' && 'Ja') || 'OK'
  const _noLabel = noLabel || (type === 'yesnocancel' && 'Nein')
  const _cancelLabel = cancelLabel || (type === 'confirm' ? 'OK' : 'Abbrechen')
  const _cancelColor = type === 'confirm' ? 'primary' : 'secondary'
  const _extraConfirmLabel = typeof extraConfirm === 'string' ? extraConfirm : 'Ich bestätige'
  const _onCancel = type === 'confirm' ? onConfirm : onCancel

  const paperProps = {
    className: getPaperColor()
  }

  return (
    <Dialog open={open} onClose={onClose(onCancel)} PaperProps={paperProps} maxWidth={maxWidth}>
      <DialogContent className={classes.dialog}>
        <Grid
          container
          direction="row"
          wrap="nowrap"
          alignContent="flex-start"
          spacing={3}
          padding="1rem"
        >
          <Grid>{_iconType}</Grid>
          <Grid container direction="column">
            <Grid>
              <Typography fontSize="1.4rem">{_title}</Typography>
              <Typography>{message}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        {extraConfirm && (
          <Checkbox
            labelPlacement="start"
            checked={confirmed}
            onChange={() => setConfirmed(!confirmed)}
            label={_extraConfirmLabel}
            style={{ marginRight: 12, paddingTop: 0 }}
            name="extraConfirm"
          />
        )}
        {_confirmLabel && type !== 'confirm' && (
          <ColorButton
            variant="contained"
            label={_confirmLabel}
            color={_confirmColor}
            disabled={!confirmed}
            onClick={onClose(onConfirm)}
            name="confirm"
          />
        )}
        {_noLabel && <Button variant="contained" label={_noLabel} onClick={onClose(onNo)} />}
        <Button
          autoFocus
          variant="contained"
          label={_cancelLabel}
          onClick={onClose(_onCancel)}
          color={_cancelColor}
          name="cancel"
        />
      </DialogActions>
    </Dialog>
  )
}

export const useMessageDialog = () => {
  const showDialog = useGlobalDialogAnker()

  const showMsgDlg = useCallback(
    (params: MessageDialogProps) => {
      showDialog((visible, onClose) => (
        <MessageDialog open={visible} onClose={onClose} {...params} />
      ))
    },
    [showDialog]
  )

  const askToDelete = useCallback(
    (params: MessageDialogProps) => {
      showDialog((visible, onClose) => (
        <MessageDialog
          open={visible}
          onClose={onClose}
          type="okcancel"
          severity="severe"
          message="Die Daten werden endgültig gelöscht"
          confirmLabel="Löschen"
          {...params}
        />
      ))
    },
    [showDialog]
  )

  // todo mit askToDelete zusammenführen
  const askToConfirm = useCallback(
    (params: MessageDialogProps) => {
      showDialog((visible, onClose) => (
        <MessageDialog
          open={visible}
          onClose={onClose}
          type="okcancel"
          severity="info"
          message="Die Aktion wird ausgeführt."
          confirmLabel="Durchführen"
          {...params}
        />
      ))
    },
    [showDialog]
  )

  const askToRemove = useCallback(
    (params: MessageDialogProps) => {
      showDialog((visible, onClose) => (
        <MessageDialog
          open={visible}
          onClose={onClose}
          type="okcancel"
          severity="warning"
          message="Nur die Zuordnung wird entfernt"
          confirmLabel="Entfernen"
          {...params}
        />
      ))
    },
    [showDialog]
  )

  return {
    showMsgDlg,
    askToDelete,
    askToRemove,
    askToConfirm
  }
}
