import React from 'react'
import { ArtikelDatenpoolMengeneinheitJson } from '@one/typings/apiTypings'
import { Column, DataTable } from '@utils/ui/DataTable/DataTable'
import { nameOf } from '@utils/utils'

export type ArtikelDpMengeneinheitenWerteCellProps = {
  werte: ArtikelDatenpoolMengeneinheitJson[]
  darkMode: boolean
}
/**
 * WerteCell: Zur Darstellung von Mengeneinheiten zb. innerhalb Tabellen.
 * @param werte
 * @param darkMode
 * @constructor
 */
export const MengeneinheitenWerteCell = ({
  werte,
  darkMode
}: ArtikelDpMengeneinheitenWerteCellProps) => {
  const columns: Column<ArtikelDatenpoolMengeneinheitJson>[] = [
    {
      field: nameOf<ArtikelDatenpoolMengeneinheitJson>('name'),
      header: 'Einheit'
    },
    {
      field: 'smallestItemUnit',
      header: '' //Basis
    },
    {
      field: nameOf<ArtikelDatenpoolMengeneinheitJson>('weight'),
      header: 'Gewicht kg',
      type: 'money3'
    },
    {
      field: nameOf<ArtikelDatenpoolMengeneinheitJson>('length'),
      header: 'Länge mm',
      type: 'number'
    },
    {
      field: nameOf<ArtikelDatenpoolMengeneinheitJson>('width'),
      header: 'Breite mm',
      type: 'number'
    },
    {
      field: nameOf<ArtikelDatenpoolMengeneinheitJson>('height'),
      header: 'Höhe mm',
      type: 'number'
    }
  ]

  return (
    <DataTable<ArtikelDatenpoolMengeneinheitJson>
      dense
      name="Mengeneinheiten"
      columns={columns}
      value={werte}
    />
  )
}
