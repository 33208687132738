import { Grid2 as Grid } from '@mui/material'
import { HkmEnum } from '@one/enums/HkmEnum'
import { MapperUebersichtSearcherCriteriaJson } from '@one/typings/apiTypings'
import { ChangeFunction } from '@utils/formstate'
import { SearchButton } from '@utils/ui/Buttons/SearchButton'
import { CardEx } from '@utils/ui/CardEx'
import { ShortCutHandler } from '@utils/ui/ShortCutHandler'
import { SelectEnumField } from '@utils/ui/fields/SelectEnumField'
import { TextField } from '@utils/ui/fields/TextField'
import { ReactNode } from 'react'

export type ArtikelLieferantSyncTemplateUebersichtParamsProps = {
  value: MapperUebersichtSearcherCriteriaJson
  onSearch: VoidFunction
  onChange: ChangeFunction<MapperUebersichtSearcherCriteriaJson>
  label: string
  subLabel: ReactNode
  isAdminMode: boolean
}

export const ArtikelLieferantSyncTemplateUebersichtParams = ({
  value,
  onChange,
  onSearch,
  label,
  subLabel,
  isAdminMode
}: ArtikelLieferantSyncTemplateUebersichtParamsProps) => {
  const shortcuts = {
    Enter: onSearch
  }

  return (
    <ShortCutHandler shortcuts={shortcuts}>
      <CardEx
        backLink
        collapsiable
        title={label}
        subheader={subLabel}
        bottomActions={<SearchButton onClick={onSearch} />}
      >
        <Grid container spacing={3}>
          <Grid size={{xs:6,md:4,lg:3}}>
            <TextField
              fullWidth
              name="name"
              label="Suchbegriff"
              value={value.name}
              onChange={onChange}
            />
          </Grid>
          {!isAdminMode && (
            <Grid size={{xs:6,md:4,lg:3}}>
              <SelectEnumField
                fullWidth
                name="typ"
                label="Synchronisationstemplatetyp"
                value={value.typ}
                onChange={onChange}
                emptyText="Alle"
                enumType={HkmEnum.DzMapperEinstellungTyp}
              />
            </Grid>
          )}
        </Grid>
      </CardEx>
    </ShortCutHandler>
  )
}
