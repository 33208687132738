import { CalendarViewDay, Dehaze } from '@mui/icons-material'
import { Grid2 as Grid, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { NumberCell, NumberCellOnChange } from '@one/components/common/NumberCell'
import { StaffelField } from '@one/components/EkPreis/EKPreisPflege/fields/StaffelField'
import {
  Ansicht,
  EkKonditionenDefinitionEx
} from '@one/components/EkPreis/EKPreisPflege/model/EkPreiseTypes'
import {
  EkKonditionenRabattgruppeDisplayJson,
  EkKonditionenRabattgruppeKopfJson,
  EkKonditionenRabattgruppenListeEintragJson,
  PreisEbeneDisplayJson
} from '@one/typings/apiTypings'
import { useFormState } from '@utils/formstate'
import { useLocalState } from '@utils/localState'
import { Action } from '@utils/ui/Action'
import { AppContext } from '@utils/ui/App/AppContext'
import { Column } from '@utils/ui/DataTable/DataTable'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { AutocompleteEx } from '@utils/ui/fields/AutocompleteEx'
import { FormField } from '@utils/ui/fields/FormField'
import { useContext, useMemo } from 'react'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()({
  view: {
    marginTop: 16,
    '& button': {
      paddingTop: 4,
      paddingBottom: 4
    }
  }
})

export interface EkRabattgruppenMassenPflegeTableProps {
  kondiDefinition: EkKonditionenDefinitionEx
  values?: EkKonditionenRabattgruppenListeEintragJson[]
  onRabattValueChange: NumberCellOnChange<EkKonditionenRabattgruppenListeEintragJson>
  rabattgruppen?: EkKonditionenRabattgruppeDisplayJson[]
  preisEbenen?: PreisEbeneDisplayJson[]
  staffelMengen?: number[]
  readonly?: boolean
}

export const EkRabattgruppenMassenPflegeTable = ({
  values,
  kondiDefinition,
  onRabattValueChange,
  readonly,
  rabattgruppen,
  preisEbenen,
  staffelMengen
}: EkRabattgruppenMassenPflegeTableProps) => {
  const { classes } = useStyles()
  const { isAllianz } = useContext(AppContext)
  const [grouping, setGrouping] = useLocalState('EkRabattgruppenMassenPflegeTable.grouping', false)
  const [filterState, onChange] = useFormState({
    ansicht: isAllianz ? Ansicht.STANDORT : Ansicht.STAFFEL,
    preisEbeneId: null,
    rabattgruppeId: null,
    staffelMenge: 0
  })

  const filteredValues = useMemo(() => {
    let _filteredValues = values
    switch (filterState.ansicht) {
      case Ansicht.STAFFEL:
        _filteredValues =
          filterState.staffelMenge != null
            ? _filteredValues?.filter((value) => value.staffelmenge === filterState.staffelMenge)
            : _filteredValues
        break

      case Ansicht.STANDORT:
        _filteredValues = filterState.preisEbeneId
          ? _filteredValues?.filter((value) => value.preisEbene?.id === filterState.preisEbeneId)
          : _filteredValues
        break

      case Ansicht.RABATTGRUPPE:
        _filteredValues = _filteredValues?.filter(
          (value) => value.rabattgruppe?.id === filterState.rabattgruppeId
        )
        break
    }
    return _filteredValues
  }, [filterState, values])

  const header = useMemo(() => {
    return (
      <Grid container direction="row" spacing={5} alignItems="center">
        <Grid>
          <FormField label="Ansicht">
            <ToggleButtonGroup
              size="small"
              exclusive
              value={filterState.ansicht}
              onChange={onChange}
              className={classes.view}
            >
              <ToggleButton
                value={isAllianz ? Ansicht.STANDORT : Ansicht.RABATTGRUPPE}
                size="small"
                name="ansicht"
              >
                {isAllianz ? 'Preisebene' : 'Rabattgruppe'}
              </ToggleButton>

              <ToggleButton value={Ansicht.STAFFEL} size="small" name="ansicht">
                Staffel
              </ToggleButton>
            </ToggleButtonGroup>
          </FormField>
        </Grid>
        <Grid size={{xs:6,sm:4}} alignSelf="end">
          {filterState.ansicht === Ansicht.STAFFEL && (
            <StaffelField
              wert={filterState.staffelMenge}
              name="staffelMenge"
              setWert={onChange}
              werte={staffelMengen}
              readonly
            />
          )}
          {filterState.ansicht === Ansicht.STANDORT && (
            <AutocompleteEx
              name="preisEbeneId"
              label="Preisebene"
              value={filterState.preisEbeneId}
              onChange={onChange}
              fullWidth
              options={preisEbenen}
              optionValue={(preisEbene: PreisEbeneDisplayJson) => preisEbene.id}
              optionLabel={(preisEbene: PreisEbeneDisplayJson) => preisEbene.name}
            />
          )}
          {filterState.ansicht === Ansicht.RABATTGRUPPE && (
            <AutocompleteEx
              name="rabattgruppeId"
              label="Rabattgruppe"
              value={filterState.rabattgruppeId}
              onChange={onChange}
              fullWidth
              options={rabattgruppen}
              optionValue={(rg: EkKonditionenRabattgruppeKopfJson) => rg.id}
              optionLabel={(rg: EkKonditionenRabattgruppeKopfJson) => rg.name}
            />
          )}
        </Grid>
      </Grid>
    )
  }, [staffelMengen, isAllianz, onChange, filterState, classes, preisEbenen, rabattgruppen])

  const columns = useMemo<Column<EkKonditionenRabattgruppenListeEintragJson>[]>(
    () => [
      {
        field: 'rabattgruppe.name',
        header: 'Rabattgruppe'
      },
      {
        field: 'rabattgruppe.bezeichnung',
        header: 'Bezeichnung',
        hidden: grouping
      },
      {
        field: 'preisEbene.name',
        header: 'Preisebene',
        off: !isAllianz
      },
      {
        field: 'staffelmenge',
        header: 'Staffelmenge',
        type: 'number'
      },
      {
        field: 'zuschlAbs1',
        header: kondiDefinition.zuschlAbs1Label,
        headerTip: kondiDefinition.zuschlAbs1Tip,
        align: 'center',
        type: 'money2',
        hidden: isAllianz,
        width: '8em',
        body: (row, { edit, id, getSelected }) => (
          <NumberCell
            id={id}
            model={row}
            field="zuschlAbs1"
            onChange={onRabattValueChange}
            getSelected={getSelected}
            defaultValue={row.rabattgruppenWertAlt?.zuschlAbs1}
            fraction={2}
            readonly={readonly || !edit}
            allowNegative
          />
        )
      },
      {
        field: 'zuschlAbs2',
        header: kondiDefinition.zuschlAbs2Label,
        headerTip: kondiDefinition.zuschlAbs2Tip,
        align: 'right',
        type: 'money2',
        hidden: isAllianz,
        width: '8em',
        body: (row, { edit, id, getSelected }) => (
          <NumberCell
            id={id}
            model={row}
            field="zuschlAbs2"
            onChange={onRabattValueChange}
            getSelected={getSelected}
            defaultValue={row.rabattgruppenWertAlt?.zuschlAbs2}
            fraction={2}
            readonly={readonly || !edit}
            allowNegative
          />
        )
      },
      {
        field: 'rabattProz1',
        header: kondiDefinition.rabattProz1Label,
        headerTip: kondiDefinition.rabattProz1Tip,
        type: 'money2',
        width: '8em',
        body: (row, { edit, id, getSelected }) => (
          <NumberCell
            id={id}
            model={row}
            field="rabattProz1"
            onChange={onRabattValueChange}
            getSelected={getSelected}
            defaultValue={row.rabattgruppenWertAlt?.rabattProz1}
            fraction={2}
            readonly={readonly || !edit}
            allowNegative
          />
        )
      },
      {
        field: 'rabattProz2',
        header: kondiDefinition.rabattProz2Label,
        headerTip: kondiDefinition.rabattProz2Tip,
        type: 'money2',
        width: '8em',
        body: (row, { edit, id, getSelected }) => (
          <NumberCell
            id={id}
            model={row}
            field="rabattProz2"
            onChange={onRabattValueChange}
            getSelected={getSelected}
            defaultValue={row.rabattgruppenWertAlt?.rabattProz2}
            fraction={2}
            readonly={readonly || !edit}
            allowNegative
          />
        )
      },
      {
        field: 'rabattProz3',
        header: kondiDefinition.rabattProz3Label,
        headerTip: kondiDefinition.rabattProz3Tip,
        type: 'money2',
        width: '8em',
        body: (row, { edit, id, getSelected }) => (
          <NumberCell
            id={id}
            model={row}
            field="rabattProz3"
            onChange={onRabattValueChange}
            getSelected={getSelected}
            defaultValue={row.rabattgruppenWertAlt?.rabattProz3}
            fraction={2}
            readonly={readonly || !edit}
            allowNegative
          />
        )
      },
      {
        field: 'rabattProz4',
        header: kondiDefinition.rabattProz4Label,
        headerTip: kondiDefinition.rabattProz4Tip,
        type: 'money2',
        width: '8em',
        body: (row, { edit, id, getSelected }) => (
          <NumberCell
            id={id}
            model={row}
            field="rabattProz4"
            onChange={onRabattValueChange}
            getSelected={getSelected}
            defaultValue={row.rabattgruppenWertAlt?.rabattProz4}
            fraction={2}
            readonly={readonly || !edit}
            allowNegative
          />
        )
      },
      {
        field: 'rabattProz5',
        header: kondiDefinition.rabattProz5Label,
        headerTip: kondiDefinition.rabattProz5Tip,
        type: 'money2',
        width: '8em',
        body: (row, { edit, id, getSelected }) => (
          <NumberCell
            id={id}
            model={row}
            field="rabattProz5"
            onChange={onRabattValueChange}
            defaultValue={row.rabattgruppenWertAlt?.rabattProz5}
            fraction={2}
            readonly={readonly || !edit}
            allowNegative
          />
        )
      },
      {
        field: 'zuschlAbs3',
        header: kondiDefinition.zuschlAbs3Label,
        headerTip: kondiDefinition.zuschlAbs3Tip,
        type: 'money2',
        hidden: isAllianz,
        width: '8em',
        body: (row, { edit, id, getSelected }) => (
          <NumberCell
            id={id}
            model={row}
            field="zuschlAbs3"
            onChange={onRabattValueChange}
            getSelected={getSelected}
            defaultValue={row.rabattgruppenWertAlt?.zuschlAbs3}
            fraction={2}
            readonly={readonly || !edit}
            allowNegative
          />
        )
      },
      {
        field: 'zuschlAbs4',
        header: kondiDefinition.zuschlAbs4Label,
        headerTip: kondiDefinition.zuschlAbs4Tip,
        type: 'money2',
        hidden: isAllianz,
        width: '8em',
        body: (row, { edit, id, getSelected }) => (
          <NumberCell
            id={id}
            model={row}
            field="zuschlAbs4"
            onChange={onRabattValueChange}
            getSelected={getSelected}
            defaultValue={row.rabattgruppenWertAlt?.zuschlAbs4}
            fraction={2}
            readonly={readonly || !edit}
            allowNegative
          />
        )
      },
      {
        field: 'zuschlAbs5',
        header: kondiDefinition.zuschlAbs5Label,
        headerTip: kondiDefinition.zuschlAbs5Tip,
        type: 'money2',
        hidden: isAllianz,
        width: '8em',
        body: (row, { edit, id, getSelected }) => (
          <NumberCell
            id={id}
            model={row}
            field="zuschlAbs5"
            onChange={onRabattValueChange}
            getSelected={getSelected}
            defaultValue={row.rabattgruppenWertAlt?.zuschlAbs5}
            fraction={2}
            readonly={readonly || !edit}
            allowNegative
          />
        )
      },
      {
        field: 'staffelgruppe.name',
        header: 'Staffelgruppe'
      }
    ],
    [grouping, isAllianz, kondiDefinition, onRabattValueChange, readonly]
  )

  const groupBy = useMemo(
    () =>
      grouping
        ? {
            field: 'rabattgruppe.name',
            groupEmpty: false,
            body: (row: EkKonditionenRabattgruppenListeEintragJson) =>
              `Rabattgruppe ${row.rabattgruppe.name}${
                row.rabattgruppe.bezeichnung ? ` - ${row.rabattgruppe.bezeichnung}` : ''
              }`
          }
        : null,
    [grouping]
  )

  const topActions = useMemo<Action[]>(
    () => [
      {
        tooltip: 'Gruppieren an/aus',
        icon: grouping ? <CalendarViewDay /> : <Dehaze />,
        onClick: () => setGrouping(!grouping)
      }
    ],
    [grouping, setGrouping]
  )

  return (
    <DataTableCard
      name="EkRabattgruppenPflegeTable"
      filterMode="both"
      selectMode="multi"
      // selectCol
      focustype="cell"
      columns={columns}
      value={filteredValues}
      dense
      title="Rabattgruppen"
      topActions={topActions}
      groupBy={groupBy}
      localStateName="EkRabattgruppenPflegeTable"
      initialOrderBy={['rabattgruppe.name', 'staffelmenge']}
      header={header}
      // rowFiller
    />
  )
}
