import {
  ArrowDropDown,
  ArrowDropUp,
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
  LastPage
} from '@mui/icons-material'
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grid2 as Grid,
  Grow,
  Paper,
  Popper,
  Slider,
  Tooltip
} from '@mui/material'
import { FormField } from '@utils/ui/fields/FormField'
import React, { useLayoutEffect, useRef, useState } from 'react'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme: any) => ({
  popper: {
    zIndex: 4000
  }
}))

export interface DataTablePagerProps {
  variant?: 'text' | 'outlined' | 'contained'
  rowsPerPageOptions?: number[]
  count: number
  rowsPerPage: number
  page: number
  onPageChange: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void
  onRowsPerPageChange?: (event: any) => void
}

export const DataTablePager = ({
  variant = 'text',
  rowsPerPageOptions = null,
  count,
  rowsPerPage,
  page,
  onPageChange = null,
  onRowsPerPageChange = null
}: DataTablePagerProps) => {
  const { classes } = useStyles()

  const menuRef = useRef<any>(undefined)

  const [mode, setMode] = useState(0)
  const [open, setOpen] = useState(false)
  const [edit, setEdit] = useState(0)

  const pos = page * rowsPerPage
  const end = Math.min(page * rowsPerPage + rowsPerPage, count)

  useLayoutEffect(() => {
    const keydown = (e: KeyboardEvent) => {
      if (e.repeat) {
        return
      }
      switch (e.key) {
        case 'Shift':
          setMode(1)
          break
        case 'Control':
          setMode(2)
          break
        default:
          break
      }
    }
    const keyup = (e: KeyboardEvent) => {
      if (e.repeat) {
        return
      }
      setMode(0)
    }

    const blur = () => {
      setMode(0)
    }

    window.addEventListener('blur', blur, true)
    window.document.addEventListener('keydown', keydown, true)
    window.document.addEventListener('keyup', keyup, true)

    return () => {
      window.removeEventListener('blur', blur)
      window.document.removeEventListener('keydown', keydown)
      window.document.removeEventListener('keyup', keyup)
    }
  }, [])

  const onNext = (e: React.MouseEvent<HTMLButtonElement>) => {
    let next = page
    const endp = count / rowsPerPage

    if (mode === 1) {
      next = Math.trunc(next * rowsPerPage) / rowsPerPage + Math.trunc(count / rowsPerPage / 10)
    } else if (mode === 2) {
      next = endp
    } else {
      next = next + 1
    }
    if (next >= endp) {
      next = Math.trunc(endp)
      if (endp === next) {
        next = next - 1
      }
    }
    onPageChange(e, next)
  }

  const onPrev = (e: React.MouseEvent<HTMLButtonElement>) => {
    let next = page

    if (mode === 1) {
      next = Math.trunc(next * rowsPerPage) / rowsPerPage - Math.trunc(count / rowsPerPage / 10)
    } else if (mode === 2) {
      next = 0
    } else {
      next = next - 1
    }
    if (next < 0) {
      next = 0
    }
    onPageChange(e, next)
  }

  const handleOpen = () => {
    setEdit(pos)
    setOpen(!open)
  }

  const handleClose = (event) => {
    if (menuRef.current && menuRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  const handleRowsClick = (e) => {
    setOpen(false)
    setTimeout(() => {
      onRowsPerPageChange(e)
    }, 500)
  }

  const onEdit = (e) => {
    setEdit(e.target.value)
  }

  const handleEditKeyDown = (e) => {
    if (e.key == 'Enter') {
      setOpen(false)
      e.stopPropagation()
      setTimeout(() => {
        onPageChange(e, Math.trunc(edit / rowsPerPage))
      }, 500)
    }
  }

  const menu = ({ TransitionProps = {}, placement = '' }) => (
    <Grow
      {...TransitionProps}
      style={{
        transformOrigin: placement === 'bottom' ? 'left top' : 'left bottom'
      }}
    >
      <Paper elevation={4}>
        <ClickAwayListener onClickAway={handleClose}>
          <Grid container padding={2} direction="column">
            <Grid>
              <FormField label="Position">
                <Slider
                  data-name="data-table-pager-position"
                  style={{ width: '16rem', margin: 16 }}
                  min={0}
                  max={Math.trunc(count / rowsPerPage)}
                  value={page}
                  valueLabelDisplay="auto"
                  valueLabelFormat={(value) => value * rowsPerPage}
                  // marks={rowsPerPageOptions.map((value: number) => ({
                  //   label: value,
                  //   value
                  // }))}
                  onChange={(e) => {
                    // @ts-ignore
                    const val = e.target.value
                    if (val == page) {
                      return
                    }
                    // @ts-ignore
                    onPageChange(e, val)
                  }}
                />
              </FormField>
            </Grid>
            {rowsPerPageOptions ? (
              <Grid>
                <FormField label="Zeilen pro Seite">
                  <Slider
                    data-name="data-table-pager-rows"
                    style={{ width: '16rem', margin: 16 }}
                    min={Math.min(...rowsPerPageOptions)}
                    max={Math.max(...rowsPerPageOptions)}
                    value={rowsPerPage}
                    valueLabelDisplay="auto"
                    marks={rowsPerPageOptions.map((value: number) => ({
                      label: value,
                      value
                    }))}
                    onChange={(e) => {
                      // @ts-ignore
                      const val = e.target.value
                      if (val == rowsPerPage) {
                        return
                      }
                      onRowsPerPageChange(e)
                    }}
                  />
                </FormField>
              </Grid>
            ) : null}
          </Grid>
        </ClickAwayListener>
      </Paper>
    </Grow>
  )

  return (
    <Tooltip title="Seitenwechsel">
      <ButtonGroup orientation="horizontal" variant={variant} size="small" color="inherit">
        <Button
          data-name="data-table-pager-open"
          endIcon={open ? <ArrowDropUp /> : <ArrowDropDown />}
          onClick={handleOpen}
        >
          <span
            ref={menuRef}
            style={{
              fontSize: '1.25em',
              fontWeight: '400',
              marginTop: -2,
              marginBottom: -2
            }}
            title="Click für Auswahl..."
          >
            {`${pos}-${end} / ${count}`}
          </span>
        </Button>
        <Button
          style={{ padding: 0 }}
          title="Zurückblättern, mit Shift schneller, Strg für Start"
          onClick={onPrev}
          disabled={pos === 0}
        >
          {(mode == 1 && <KeyboardDoubleArrowLeft />) || (mode == 2 && <FirstPage />) || (
            <KeyboardArrowLeft />
          )}
        </Button>
        <Button
          style={{ padding: 0 }}
          title="Vorblättern, mit Shift schneller, Strg für Ende"
          onClick={onNext}
          disabled={end === count}
        >
          {(mode == 1 && <KeyboardDoubleArrowRight />) || (mode == 2 && <LastPage />) || (
            <KeyboardArrowRight />
          )}
        </Button>
        <Popper
          open={open}
          anchorEl={menuRef.current}
          role={undefined}
          transition
          disablePortal
          className={classes.popper}
        >
          {menu}
        </Popper>
      </ButtonGroup>
    </Tooltip>
  )
}
