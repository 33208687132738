import { MessageSeverity, UseCaseStateJson } from '@one/typings/apiTypings'
import { AxiosInstance, AxiosRequestHeaders, AxiosResponse, Method } from 'axios'
import { closeSnackbar, SnackbarKey } from 'notistack'
import { RefObject, useCallback, useRef, useState } from 'react'
import { errorLog, protLog, warnLog } from './logging'
import { SnackbarEx, SnackbarType, useSnackbarEx } from './ui/snackbarex'
import { isSeverityError } from './utils'

/**
 * Fehlermeldung aufbereiten und anzeigen
 *
 * @param snackBar Snackbar
 * @param state Fehlerinfos
 * @param response axios Response
 * @param onErrorMsg Aufbereitung des Fehlers oder vorbereiteter Text
 * @param snackbarKeyRef Referenz auf Snackbar-Key
 */
const enqueErrorMessage = (
  snackBar: SnackbarEx,
  state: UseCaseStateJson,
  response: AxiosResponse,
  onErrorMsg: string | ((state: UseCaseStateJson, response: AxiosResponse) => string),
  snackbarKeyRef: RefObject<SnackbarKey>
): void => {
  snackBar.closeSnackbar(snackbarKeyRef.current)
  if (state.mainMessage) {
    if (state.mainMessage.message) {
      snackbarKeyRef.current
      snackBar.enqueState(state)
    } else {
      const message = typeof onErrorMsg === 'function' ? onErrorMsg(state, response) : onErrorMsg
      const tuned = { ...state, mainMessage: { ...state.mainMessage, message } }
      snackBar.enqueState(tuned)
    }
  } else {
    const message = typeof onErrorMsg === 'function' ? onErrorMsg(state, response) : onErrorMsg
    snackBar.enqueError(message)
  }
}

/**
 * Erfolgsmeldung aufbereiten und ggf. anzeigen
 *
 * @param snackBar Snackbar für Ausgabe
 * @param state Meldung aus Service
 * @param response AxiosResponse
 * @param onSuccessMsg Aufbereitung der Meldung oder vorbereiteter Text
 * @param snackbarKeyRef Referenz auf Snackbar-Key
 */
const enqueSuccessMessage = (
  snackBar: SnackbarEx,
  state: UseCaseStateJson,
  response: AxiosResponse,
  onSuccessMsg: ((resp: AxiosResponse) => string) | string,
  snackbarKeyRef: RefObject<SnackbarKey>
) => {
  snackBar.closeSnackbar(snackbarKeyRef.current)
  if (typeof onSuccessMsg === 'function') {
    const msg = onSuccessMsg(response)
    if (typeof msg === 'string') {
      snackBar.enqueMsg(msg, SnackbarType.success)
    }
  } else {
    snackBar.enqueState(state)
    if (snackbarKeyRef.current == null && typeof onSuccessMsg === 'string') {
      snackBar.enqueMsg(onSuccessMsg, SnackbarType.success)
    }
  }
}

/**
 * Aufrufsarten von axios
 */
export type ApiMethod = Method

/**
 * Blocking/Cancelling steuern
 */
export enum ApiExclusive {
  NONE = 0,
  BLOCK = 1,
  CANCEL = '__CANCEL__'
}

/**
 * Controller für abbrechbare Api-Calls
 *
 * @returns Abort-Controller erstellen
 */
export const apiCreateAbort = () => new AbortController()

/**
 * Prüfen, ob Call abgebrochen wurde
 *
 * @param error axios-Error
 * @returns true, wenn Api-Call abgebrochen wurde
 */
export const apiIsAbort = (error?: any) => error?.name === 'CanceledError'

/**
 * Abbruch eines Api-Calls über Controller auslösen
 *
 * @param controller Abort-Controller, erzeugt per apiCreateAbort
 */
export const apiAbort = (controller?: AbortController): void => controller && controller.abort()

/**
 * Aufrufparameter
 */
export type ApiCallProps<T, D, P> = {
  /** Aufrufart */
  method?: ApiMethod
  /** End-Urls für Aufruf */
  rest: string
  /** Akzeptiertes Datenformat, Default: application/json */
  accept?: string
  /** Optionale Parameter (Url oder Body, je nach Typ) */
  params?: P
  /** Body für Aufruf */
  data?: D
  /** Header-Parameter*/
  headers?: AxiosRequestHeaders
  /** Verhalten bei Mehrfachaufruf */
  exclusive?: ApiExclusive
  /** Erfolgsmeldung */
  onSuccessMsg?: ((response: AxiosResponse<T>) => string) | string
  /** Fehlermeldung */
  onErrorMsg?: ((state: UseCaseStateJson, response: AxiosResponse<T>) => string) | string
  /** Manuell Abbruchsteuerung */
  signal?: AbortSignal
  /** Reaktion auf Erfolg */
  onSuccess?(data: T, response: AxiosResponse<T>, duration: number): boolean | void
  /** Reaktion auf Fehler */
  onError?(error: UseCaseStateJson, response: AxiosResponse<T>, duration: number): boolean | void
  /** Bei erfolgreichem Call */
  onCall?(): void
  /** Function, um Retry des Calls zu ermöglichen. Return true bedeutet, der Call wird per retryCall selbst erneut getriggert, sonst wird normal fortgesetzt */
  retryHook?(error: UseCaseStateJson, response: AxiosResponse<T>, retryCall: () => void): boolean
  /** Single-Ref für Snackbars */
  snackKeyRef?: RefObject<SnackbarKey>
}

/**
 * Signatur des ApiCallers
 */
export type ApiCallType = <T, D = any, P = any>(props: ApiCallProps<T, D, P>) => void

/**
 * Internber Status
 */
type ApiCallerState = {
  api: AxiosInstance
  apiBusy: boolean
  snackBar: SnackbarEx
}

/**
 * Controller zum Aufruf von Rerst-Services inklusive Locking und Fehler-Behandlung.
 *
 * @param api axios
 * @returns ApiCaller und Aktiv-Zeichen
 */
export const useApiCaller = (api: AxiosInstance): [apiCall: ApiCallType, apiBusy: boolean] => {
  const snackBar = useSnackbarEx()

  const tokenMapRef = useRef(new Map<string, AbortController>())
  const mySnacksRef = useRef<SnackbarKey>(undefined)

  const [apiBusy, setApiBusy] = useState<boolean>(false)

  const meRef = useRef<ApiCallerState>(undefined)
  meRef.current = {
    api,
    apiBusy: false,
    snackBar
  }

  const apiCall = useCallback<ApiCallType>(
    ({
      method = 'POST',
      rest,
      headers = null,
      params = null,
      data = null,
      exclusive = ApiExclusive.BLOCK,
      onSuccessMsg = null,
      onErrorMsg = null,
      onSuccess = null,
      onError = null,
      signal = undefined,
      onCall = undefined,
      retryHook = undefined,
      accept = undefined,
      snackKeyRef = undefined,
      ...axiosOptions
    }) => {
      const openSnacksRef = snackKeyRef || mySnacksRef
      closeSnackbar(openSnacksRef.current)
      if (meRef.current == null) {
        const errorState = { mainMessage: 'Service call on detached component!', error: true }
        errorLog('apiCall', rest, errorState.mainMessage)
        return
      }
      let exclusiveSignal = null
      if (exclusive === ApiExclusive.CANCEL) {
        const ct = tokenMapRef.current.get(rest)
        if (ct != null) {
          protLog('Abort request', rest)
          ct.abort()
        }
        const source = apiCreateAbort()
        tokenMapRef.current.set(rest, source)
        exclusiveSignal = source.signal
      } else if (exclusive && meRef.current.apiBusy) {
        warnLog('ApiCall block', rest)
        meRef.current.snackBar.enqueMsg(
          'Bitte warten Sie auf Abschluss der letzten Anfrage',
          SnackbarType.warning
        )
        return
      }
      meRef.current.snackBar.closeSnackbar(openSnacksRef.current)
      if (exclusive) {
        meRef.current.apiBusy = true
        setApiBusy(true)
      }

      const call = () => {
        const start = Date.now()
        const headerparams = { ...headers }
        if (accept) {
          headerparams.Accept = accept
        }
        meRef.current.api
          .request({
            ...axiosOptions,
            url: rest,
            method,
            headers: headerparams,
            params,
            data,
            signal: exclusiveSignal || signal,
            paramsSerializer: {
              indexes: null
            }
          })
          .then((response) => {
            const duration = Date.now() - start
            const loaded = response?.data || {}
            const state = (loaded.state || {}) as UseCaseStateJson

            if (retryHook && retryHook(state, response, call)) {
              return
            }

            if (exclusive) {
              meRef.current.apiBusy = false
              setApiBusy(false)
            }

            if (isSeverityError(state.mainMessage?.severity)) {
              if (!onError || !onError(state, response, duration)) {
                enqueErrorMessage(
                  meRef.current.snackBar,
                  state,
                  response,
                  onErrorMsg,
                  openSnacksRef
                )
              }
              return
            }
            if (!onSuccess || !onSuccess(loaded, response, duration)) {
              enqueSuccessMessage(
                meRef.current.snackBar,
                state,
                response,
                onSuccessMsg,
                openSnacksRef
              )
            }
          })
          .catch((error) => {
            const duration = Date.now() - start
            if (meRef.current === null) {
              return
            }
            if (exclusive) {
              meRef.current.apiBusy = false
              setApiBusy(false)
            }
            if (apiIsAbort(error)) {
              return
            }
            const errorState: UseCaseStateJson = (error?.response?.status == 504 && {
              mainMessage: {
                message:
                  method === 'GET'
                    ? 'Der Serviceaufruf ist nicht zurückgekehrt. Sie können den Aufruf wiederholen.'
                    : 'Der Serviceaufruf ist nicht zurückgekehrt. Der Aufruf war evtl. dennoch erfolgeich.',
                severity: MessageSeverity.ERR
              },
              messages: [
                {
                  message:
                    'Wenn der Serviceaufruf zu lange dauert, wird das Warten auf die Antwort abgebrochen. Bei wiederholtem Fehler wenden Sie sich an den Support.',
                  severity: MessageSeverity.ERR
                }
              ]
            }) ||
              (error?.response?.status == 500 && {
                mainMessage: {
                  message: 'Der Serviceaufruf ist gescheitert.',
                  severity: MessageSeverity.FATAL
                },
                messages: [
                  {
                    message:
                      'Der Server ist offline oder abgestürtzt. Bei wiederholtem Fehler wenden Sie sich an den Support.',
                    severity: MessageSeverity.FATAL
                  }
                ]
              }) || {
                mainMessage: {
                  message: 'Der Serviceaufruf ist gescheitert.',
                  severity: MessageSeverity.FATAL
                },
                messages: error.message && [
                  { message: error.message, severity: MessageSeverity.FATAL }
                ]
              }
            if (!onError || !onError(errorState, error.response, duration)) {
              enqueErrorMessage(
                meRef.current.snackBar,
                errorState,
                error.response,
                onErrorMsg,
                openSnacksRef
              )
              errorLog(`Service ${rest} failed`, error)
            }
          })
      }
      call()
      if (onCall) {
        onCall()
      }
    },
    []
  )

  return [apiCall, apiBusy]
}
