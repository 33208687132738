import { Grid2 as Grid } from '@mui/material'
import { AppPaths } from '@one/AppPaths'
import { ErpArtikelDetailsJson, SuchArtikelTyp } from '@one/typings/apiTypings'
import { formatNumber } from '@utils/numberutils'
import { CardEx } from '@utils/ui/CardEx'
import { Column } from '@utils/ui/DataTable/DataTable'
import { ArtikelDetailsTabelle } from './ArtikelDetailsTabelle'

export const ArtikelLieferantenColumns: Column[] = [
  {
    field: 'lieferant.nummer',
    header: 'Lieferantennummer',
    sortable: true
  },
  {
    field: 'lieferant.name1',
    header: 'Lieferantenname 1',
    sortable: true,
    valueGetter: (row) => {
      return row.notanlage ? 'Notlieferant' : row.lieferant?.name1
    }
  },
  {
    field: 'lieferant.name2',
    header: 'Lieferantenname 2',
    sortable: true
  },
  {
    field: 'industrieArtikelNummer',
    header: 'IAN',
    sortable: true
  },
  {
    field: 'einkaufMengenEinheit',
    header: 'Einkaufseinheit',
    sortable: true,
    valueGetter: (row) =>
      `${formatNumber(row.einkaufPreismenge, 0) ?? ''} ${row.einkaufMengenEinheit ?? ''}`
  },
  {
    field: 'ausgelistet',
    header: 'Ausgelistet',
    type: 'boolean',
    sortable: true
  }
]

export const ArtikelLieferantenTableActions = [
  {
    icon: 'local_shipping',
    tooltip: 'Zum Lieferanten',
    getLink: (data: any) => {
      return data.lieferant.typ === SuchArtikelTyp.ZENTRAL
        ? AppPaths.LieferantListungFn(data.lieferant.id)
        : AppPaths.LieferantFn(data.lieferant.id)
    }
  }
]

const StandortColumns: Column[] = [
  {
    field: 'name',
    header: 'Standort',
    sortable: true
  },
  {
    field: 'nr',
    header: 'Standort-Nummer',
    sortable: true
  },
  {
    field: 'hauptlieferant.nummer',
    header: 'Lieferantennummer',
    sortable: true
  },
  {
    field: 'hauptlieferant.name1',
    header: 'Lieferantenname 1',
    sortable: true,
    valueGetter: (row) => (row.notanlage ? 'Notlieferant' : row.name1)
  },
  {
    field: 'hauptlieferant.name2',
    header: 'Lieferantenname 2',
    sortable: true
  }
]

const StandortTableActions = [
  {
    icon: 'local_shipping',
    tooltip: 'Zum Lieferanten',
    getLink: (data: any) => data?.hauptlieferant && AppPaths.LieferantFn(data.hauptlieferant.id)
  }
]

export interface ArtikelErpLieferantenProps {
  details: ErpArtikelDetailsJson
  loading?: boolean
}

export const ArtikelErpLieferanten = ({ details, loading = false }: ArtikelErpLieferantenProps) => {
  if (!details) {
    return null
  }

  const { artikelLieferanten, preisEbenenLieferanten } = details

  return (
    <CardEx title="ERP Lieferant">
      <Grid container spacing={2}>
        {artikelLieferanten?.length > 0 && (
          <Grid size={{ xs: 12 }}>
            <ArtikelDetailsTabelle
              name="ArtikelLieferantenSection"
              values={artikelLieferanten}
              title="Lieferanten"
              columns={ArtikelLieferantenColumns}
              tableActions={ArtikelLieferantenTableActions}
              loading={loading}
            />
          </Grid>
        )}
        {preisEbenenLieferanten?.length > 0 && (
          <Grid size={{ xs: 12 }}>
            <ArtikelDetailsTabelle
              name="ArtikelStandortLieferantenSection"
              values={preisEbenenLieferanten}
              title="Standortspezifischen Lieferanten"
              columns={StandortColumns}
              tableActions={StandortTableActions}
              loading={loading}
            />
          </Grid>
        )}
      </Grid>
    </CardEx>
  )
}
