import { ArtikelAboSection } from '@one/components/Artikel/ArtikelAnzeigen/details/ArtikelAboSection'
import { ArtikelErpEkPreise } from '@one/components/Artikel/ArtikelAnzeigen/details/ArtikelErpEkPreise'
import { ArtikelErpVkPreise } from '@one/components/Artikel/ArtikelAnzeigen/details/ArtikelErpVkPreise'
import { ArtikelErpLabelTableView } from '@one/components/Artikel/ArtikelAnzeigen/details/ArtikelErpLabelTableView'
import { ArtikelListenSection } from '@one/components/Artikel/ArtikelAnzeigen/details/ArtikelListenSection'
import { ArtikelPimComplianceLaender } from '@one/components/Artikel/ArtikelAnzeigen/pim/ArtikelPimComplianceLaender'
import { ArtikelPimDisplayArtikel } from '@one/components/Artikel/ArtikelAnzeigen/pim/ArtikelPimDisplayArtikel'
import { ArtikelPimERPStatus } from '@one/components/Artikel/ArtikelAnzeigen/pim/ArtikelPimERPStatus'
import { ArtikelPimZusatzEANs } from '@one/components/Artikel/ArtikelAnzeigen/pim/ArtikelPimZusatzEANs'
import { PimArtikelKennzeichen } from '@one/components/Artikel/EigenlistungsArtikel/PimArtikelKennzeichen'
import { ArtikelPimEkPreise } from '@one/components/Artikel/ArtikelAnzeigen/pim/ArtikelPimEkPreise'
import { PimArtikelFliese } from '@one/components/Artikel/EigenlistungsArtikel/PimArtikelFliese'
import { ArtikelPimLieferanten } from '@one/components/Artikel/ArtikelAnzeigen/pim/ArtikelPimLieferanten'
import { PimArtikelHagebau } from '@one/components/Artikel/EigenlistungsArtikel/PimArtikelHagebau'
import { PimArtikelMasse } from '@one/components/Artikel/EigenlistungsArtikel/PimArtikelMasse'
import { ArtikelPimPackstuecke } from '@one/components/Artikel/ArtikelAnzeigen/pim/ArtikelPimPackstuecke'
import { PimArtikelPflanzen } from '@one/components/Artikel/EigenlistungsArtikel/PimArtikelPflanzen'
import { PimArtikelProdukt } from '@one/components/Artikel/EigenlistungsArtikel/PimArtikelProdukt'
import { ArtikelPimSteuer } from '@one/components/Artikel/ArtikelAnzeigen/pim/ArtikelPimSteuer'
import { ArtikelPimVkPreise } from '@one/components/Artikel/ArtikelAnzeigen/pim/ArtikelPimVkPreise'
import { PimArtikelSperren } from '@one/components/Artikel/EigenlistungsArtikel/PimArtikelSperren'
import { ArtikelAbonniertStatusMedal } from '@one/components/common/ArtikelAbonniertStatusMedal'
import { PimArtikelKopf } from '@one/components/Artikel/EigenlistungsArtikel/PimArtikelKopf'
import { StandortArtikelRegalplatz } from '@one/components/Artikel/ArtikelAnzeigen/details/StandortArtikelRegalplatz'
import { PimArtikelCompliance } from '@one/components/Artikel/EigenlistungsArtikel/PimArtikelCompliance'
import { PimArtikelBezeichung } from '@one/components/Artikel/EigenlistungsArtikel/PimArtikelBezeichnung'
import { formatNeonKontext } from '@one/components/common/formatters'
import {
  ArtikelAbonniertStatus,
  ArtikelQuelle,
  PimArtikelAnzeigenJson,
  SuchArtikelTyp
} from '@one/typings/apiTypings'
import { formatDateTime } from '@utils/dateutils'
import { useEnums } from '@utils/enums'
import { Button } from '@utils/ui/Buttons/Button'
import { ButtonRow } from '@utils/ui/Buttons/ButtonRow'
import { ReloadButton } from '@utils/ui/Buttons/ReloadButton'
import { CardEx } from '@utils/ui/CardEx'
import { getValueComparator } from '@utils/ui/DataTable/DataTableUtils'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { IndexPanel, IndexPanelItem } from '@utils/ui/IndexPanel'
import { SelectField } from '@utils/ui/fields/SelectField'
import { asNumber, sortArray } from '@utils/utils'
import React, { useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid2 as Grid, Typography } from '@mui/material'
import { AppPaths } from '@one/AppPaths'
import { AppContext } from '@utils/ui/App/AppContext'
import { ThemeContext } from '@utils/ui/Theme'
import { PimArtikelMengeneinheitenListMemo } from '@one/components/Artikel/ArtikelAnzeigen/pim/PimArtikelMengeneinheitenList'
import { NeonKontextChips } from '@one/components/Label/NeonKontextChips'
import { ArtikelErpHauptlieferant } from '@one/components/Artikel/ArtikelAnzeigen/details/ArtikelErpHauptlieferant'
import { PimArtikelKontextDaten } from '@one/components/Artikel/EigenlistungsArtikel/PimArtikelKontextDaten'

export type PimArtikelViewProps = {
  data: PimArtikelAnzeigenJson
  reload: () => void
}

/**
 * PimArtikelView ist die Anzeige für Artikel - die Bearbeitung erfolgt
 * im EigenlistungsArtikelPflegeView.
 * @param data
 * @param reload
 * @constructor
 */
export const PimArtikelView = ({ data, reload }: PimArtikelViewProps) => {
  const navigate = useNavigate()

  const { et } = useEnums()
  const { darkMode } = useContext(ThemeContext)

  const { isWithErpImport } = useContext(AppContext)

  const display = data.display
  const kontext = data.kontext
  const id = display.id
  const partnerArtikelId = data.partnerArtikelId
  const aboStatus = data.details?.aboStatus
  const zentral = data.display.quelle === ArtikelQuelle.ZENTRAL

  const header = useMemo(() => {
    const title = display.bez1 ? `"${display.hageNummer} ${display.bez1}"` : ''

    const kontextField = data.kontexte && (
      <SelectField
        value={kontext}
        onChange={(e) => {
          const val = data.kontexte.find((k) => k.pimKontext.kontext == e.target.value)
          if (val) {
            navigate(AppPaths.ArtikelExFn(display.id, null, val.pimKontext.kontext))
          }
        }}
        notNull
        options={data.kontexte}
        optionValue={(k) => k.pimKontext.kontext}
        // renderItem={(k) => formatNeonKontext(k.pimKontext)}
        renderItem={(k) => {
          if (k.seErgaenzung) {
            return `${formatNeonKontext(k.pimKontext)}${k.seErgaenzung ? ' - ERP-Ergänzung' : ''}`
          }
          return formatNeonKontext(k.pimKontext)
        }}
        renderSelected={(k) => `Kontext: ${formatNeonKontext(k.pimKontext)}`}
        withButtons
      />
    )
    const revisionField = data.versionen && (
      <SelectField
        value={data.artikel?.version?.id}
        onChange={(e) => navigate(AppPaths.ArtikelExFn(id, asNumber(e.target.value), kontext))}
        options={sortArray(
          data.versionen,
          getValueComparator('desc', (o) => o.nr)
        )}
        renderItem={(k) => (
          <span>
            Revision {k.nr}: <b>{formatDateTime(k.zeitpunkt)}</b>
          </span>
        )}
        renderSelected={(k) => (
          <span>
            Revision {k.nr}: <b>{formatDateTime(k.zeitpunkt)}</b>
          </span>
        )}
        notNull
        withButtons="reverse"
      />
    )

    return (
      <Grid container spacing={3} alignItems="center">
        <Grid>
          <Typography variant="h6">
            {data.quelle === ArtikelQuelle.ERP ? 'ERP-Artikel ' : 'PIM-Artikel '}
            {title}
          </Typography>
        </Grid>
        {data.display.eigenlistung && (
          <Grid>
            <Typography variant={'body1'}>Eigenlistung</Typography>
          </Grid>
        )}

        {aboStatus &&
          (aboStatus === ArtikelAbonniertStatus.EINGESCHL ||
            aboStatus === ArtikelAbonniertStatus.UNTERBROCHEN) && (
            <Grid>
              <ArtikelAbonniertStatusMedal value={aboStatus} et={et} darkMode={darkMode} />
            </Grid>
          )}
        <Grid>{kontextField}</Grid>
        <Grid>{revisionField}</Grid>
        <Grid container spacing={1} alignItems="center">
          <Grid>
            <Typography variant="body2">Kontextstatus</Typography>
          </Grid>
          <Grid>
            <NeonKontextChips
              value={data?.display?.kontexte}
              fehlerArtikel={data?.display?.kontexteFehlerArtikel}
              fehlerEkPreis={data?.display?.kontexteFehlerEkPreis}
              fehlerVkPreis={data?.display?.kontexteFehlerVkPreis}
              green
            />
          </Grid>
        </Grid>
        {zentral && data.artikel?.record?.offset && (
          <Grid flexGrow={1} textAlign="right" fontSize="60%">
            Kafka-Offset {data.artikel?.record?.offset}
          </Grid>
        )}
        {partnerArtikelId && (
          <Grid flexGrow={1} textAlign="right">
            <Button
              label={zentral ? 'Zum ERP-Artikel' : 'Zum PIM-Artikel'}
              to={AppPaths.ArtikelFn(partnerArtikelId)}
            />
          </Grid>
        )}
      </Grid>
    )
  }, [
    display.bez1,
    display.id,
    data.kontexte,
    data.versionen,
    data.artikel?.version?.id,
    data.artikel?.record?.offset,
    data.quelle,
    data.display.eigenlistung,
    data.display?.kontexte,
    data.display?.kontexteFehlerArtikel,
    data.display?.kontexteFehlerEkPreis,
    data.display?.kontexteFehlerVkPreis,
    kontext,
    aboStatus,
    et,
    darkMode,
    zentral,
    partnerArtikelId,
    navigate,
    id
  ])

  const items = [
    {
      id: 'kopf',
      label: 'Kopf',
      body: () => <PimArtikelKopf pimArtikel={data.artikel} details={data.details} readonly />
    },
    {
      id: 'produkt',
      label: 'Produkt',
      body: () => <PimArtikelProdukt produkt={data.artikel?.version?.pimProdukt} readonly />
    },
    {
      id: 'kennzeichen',
      label: 'Kennzeichen',
      body: () => (
        <PimArtikelKennzeichen
          pimArtikelVersion={data.artikel.version}
          warengruppe={data.details?.warengruppe}
          sparte={data.details?.sparte}
          mode="readonly"
          grundpreisFaktor={data.details?.grundpreisFaktor}
          grundpreisMengeneinheit={data.details?.grundpreisMengeneinheit}
          mengeneinheiten={
            data.details?.grundpreisMengeneinheit ? [data.details?.grundpreisMengeneinheit] : []
          }
        />
      )
    },
    {
      id: 'bezeichnungen',
      label: 'Bezeichnungen',
      body: () => (
        <PimArtikelBezeichung
          pimArtikelVersionSprache={data.artikel.version.sprache}
          pimArtikelVersion={data.artikel.version}
          readonly
        />
      )
    },
    {
      id: 'masse',
      label: 'Maße',
      exclude: !data.artikel.version,
      body: () => <PimArtikelMasse pimArtikel={data.artikel} readonly />
    },
    {
      id: 'kontext',
      label: 'Kontext',
      exclude: !data.artikel.versionKontext,
      body: () => (
        <PimArtikelKontextDaten pimArtikelVersionKontext={data.artikel.versionKontext} readonly />
      )
    },
    {
      id: 'display',
      label: 'Display',
      body: () => <ArtikelPimDisplayArtikel artikel={data.artikel} />
    },
    {
      id: 'status',
      label: 'ERP Import-Status',
      exclude: !isWithErpImport,
      body: () => <ArtikelPimERPStatus pimData={data} />
    },
    {
      id: 'vksperre',
      label: 'VK-Sperre',
      body: () => <PimArtikelSperren pimArtikel={data.artikel} readonly />
    },
    {
      id: 'lieferanten',
      label: 'Lieferanten',
      body: () => <ArtikelPimLieferanten artikel={data.artikel} />
    },
    {
      id: 'hauptlieferant',
      label: 'Hauptlieferant',
      exclude: !data.details,
      body: () => <ArtikelErpHauptlieferant details={data.details} />
    },
    {
      id: 'hagebau-logistik',
      label: 'Hagebau-Logistik',
      body: () => <PimArtikelHagebau pimArtikel={data.artikel} readonly />
    },
    {
      id: 'eans',
      label: 'Mengeneinheiten und EANs',
      body: () => (
        <PimArtikelMengeneinheitenListMemo
          erpDetails={data.details}
          pimArtikelVersion={data.artikel?.version}
          mengeneinheiten={data.artikel?.version?.mengeneinheiten}
        />
      ),
      exclude: data.artikel?.version?.mengeneinheiten == null
    },
    {
      id: 'zusatzeans',
      label: 'Zusatz EANs',
      body: () => <ArtikelPimZusatzEANs zusatzEANs={data.artikel.version?.eans} />,
      exclude: !(data.artikel.version?.eans?.length > 0)
    },
    {
      id: 'packstuecke',
      label: 'Packstücke',
      body: () => <ArtikelPimPackstuecke packstuecke={data.artikel.version?.packstuecke} />,
      exclude: !(data.artikel.version?.packstuecke?.length > 0)
    },
    {
      id: 'ekpreise',
      label: 'EK-Preise',
      body: () =>
        zentral ? (
          <ArtikelPimEkPreise lieferanten={data.artikel.lieferanten} />
        ) : (
          <ArtikelErpEkPreise
            ekPreise={data?.details?.ekPreise}
            konditionenDefinitionen={data?.details?.konditionenDefinitionen}
          />
        ),
      exclude:
        // Since the backend return for artikel.lieferant an "[null]" as empty a length check won't work
        // so we need to check if the first element is null
        (zentral && data.artikel?.lieferanten?.at(0) == null) ||
        (!zentral && data.details?.ekPreise == null)
    },
    {
      id: 'vkpreise',
      label: 'VK-Preise',
      body: () =>
        zentral ? (
          <ArtikelPimVkPreise vkPreise={data.vkPreise} />
        ) : (
          <ArtikelErpVkPreise
            vkPreise={data.details?.vkPreise}
            preisGruppen={data?.details?.vkPreisgruppen}
            mengeneinheiten={data.details?.mengeneinheiten}
          />
        ),
      exclude:
        (zentral && data.vkPreise?.length == 0) || (!zentral && data.details?.vkPreise == null)
    },
    {
      id: 'fliese',
      label: 'Fliese',
      body: () => <PimArtikelFliese fliese={data.artikel.version.fliese} readonly />,
      exclude: data.artikel?.version?.fliese == null
    },
    {
      id: 'pflanze',
      label: 'Pflanze',
      body: () => <PimArtikelPflanzen pflanze={data.artikel.version.pflanze} readonly />,
      exclude: data.artikel?.version?.pflanze == null
    },
    {
      id: 'compliance',
      label: 'Compliance',
      body: () => (
        <PimArtikelCompliance compliance={data.artikel.version.compliance} readonly={true} />
      ),
      exclude: data.artikel.version.compliance == null
    },
    {
      id: 'compliance-laender',
      label: 'Compliance-Länder',
      body: () => <ArtikelPimComplianceLaender compliance={data.artikel.version.compliance} />,
      exclude: data.artikel.version.compliance == null
    },
    {
      id: 'steuer',
      label: 'Steuerindikation',
      body: () => <ArtikelPimSteuer artikel={data.artikel} />
    },
    {
      id: 'regalplaetze',
      label: 'Regalplätze',
      body: () => <StandortArtikelRegalplatz regalplaetze={data.details?.regalplaetze} />,
      exclude: zentral || !(data.details?.regalplaetze?.length > 0)
    },
    {
      id: 'artikelabo',
      label: 'Artikelabo',
      body: () => <ArtikelAboSection selektionen={data.details?.selektionen} />,
      exclude: zentral || !(data.details?.selektionen?.length > 0)
    },
    {
      id: 'artikellisten',
      label: 'Artikellisten',
      body: () => <ArtikelListenSection manuelleListen={data.details?.manuelleListen} />,
      exclude: zentral || !(data.details?.manuelleListen?.length > 0)
    },
    {
      id: 'artikellabel',
      label: 'Artikel-Label',
      body: () => (
        <ArtikelErpLabelTableView
          labels={data.details?.labels}
          artikel={{ typ: SuchArtikelTyp.DEZENTRAL, id }}
          reload={reload}
        />
      ),
      exclude: zentral
    }
  ] as IndexPanelItem[]

  return (
    <Frame space>
      <FrameRow>
        <CardEx
          backLink
          overviewLink={AppPaths.ArtikelUebersicht}
          header={header}
          contentStyle={{ display: 'none' }}
        />
      </FrameRow>
      <FrameBody>
        <IndexPanel content={items} leftStartWidth={300} />
      </FrameBody>
      <FrameRow>
        <ButtonRow>
          <ReloadButton onClick={reload} />
        </ButtonRow>
      </FrameRow>
    </Frame>
  )
}
