import { PimArtikelJson, SeArtikelJson } from '@one/typings/apiTypings'
import { ValueChangeFn } from '@utils/modelmgr'
import { CardEx } from '@utils/ui/CardEx'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { DateField } from '@utils/ui/fields/DateField'
import { TextField } from '@utils/ui/fields/TextField'
import { Grid2 as Grid } from '@mui/material'
import React from 'react'
import { resolveObjectField } from '@utils/utils'

export interface PimArtikelSperrenProps {
  pimArtikel: PimArtikelJson
  onChange?: ValueChangeFn<SeArtikelJson>
  errors?: any
  readonly?: boolean
}

/**
 * PimArtikelSperren wird im EigenlistungsArtikelPflegeView editierbar
 * und im PimArtikelView als readonly genutzt.
 * @param pimArtikel
 * @param onChange
 * @param errors
 * @param readonly
 * @constructor
 */
export const PimArtikelSperren = ({
  pimArtikel,
  onChange,
  errors,
  readonly
}: PimArtikelSperrenProps) => {
  return (
    <CardEx title="VK-Sperre">
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
          <Checkbox
            label="VK-Sperre"
            name="pimArtikel.version.vkSperre"
            size="small"
            checked={pimArtikel.version.vkSperre}
            onChange={onChange}
            disabled={readonly}
            labelNotDisabled
          />
        </Grid>
        <Grid width="100%">
          <CardEx title="Kontext" backgroundColor="default">
            <Grid container spacing={2}>
              <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
                <DateField
                  label="VK-Sperre ab"
                  name="pimArtikel.versionKontext.vkSperreAb"
                  value={pimArtikel.versionKontext?.vkSperreAb}
                  error={resolveObjectField(errors, 'pimArtikel.versionKontext.vkSperreAb')}
                  onChange={onChange}
                  disabled={readonly}
                  fullWidth
                />
              </Grid>
              <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
                <DateField
                  label="VK-Sperre bis"
                  name="pimArtikel.versionKontext.vkSperreBis"
                  value={pimArtikel.versionKontext?.vkSperreBis}
                  error={resolveObjectField(errors, 'pimArtikel.versionKontext.vkSperreBis')}
                  onChange={onChange}
                  disabled={readonly}
                  fullWidth
                />
              </Grid>
              <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
                <TextField
                  label="VK-Sperre Grund"
                  name="pimArtikel.versionKontext.vkSperreGrund"
                  value={pimArtikel.versionKontext?.vkSperreGrund}
                  onChange={onChange}
                  disabled={readonly}
                  fullWidth
                />
              </Grid>
            </Grid>
          </CardEx>
        </Grid>
      </Grid>
    </CardEx>
  )
}

export const PimArtikelSperrenMemo = React.memo(PimArtikelSperren)
