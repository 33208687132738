import { AppPaths } from '@one/AppPaths'
import { EventNames } from '@one/EventNames'
import { api } from '@one/api'
import { EkPreisVorgangErstellenDialog } from '@one/components/ArtikelAbo/EkPreisVorgangErstellenDialog'
import { enrichKondiDefinition } from '@one/components/EkPreis/EKPreisPflege/model/EkPreiseUsecase'
import {
  EkKonditionenRabattgruppeJson,
  EkKonditionenRabattgruppeVersionJson,
  EkKonditionenRabattgruppeWertJson,
  EkRabattgruppenBearbeitenJson
} from '@one/typings/apiTypings'
import {
  formatDateRange,
  formatISODate,
  getDateToday,
  isDateLower,
  trimDate0000
} from '@utils/dateutils'
import { useModelMgr } from '@utils/modelmgr'
import { notifyObservers } from '@utils/observer'
import { AppContext } from '@utils/ui/App/AppContext'
import { RouteContext } from '@utils/ui/App/AppRoute'
import { ButtonRow } from '@utils/ui/Buttons/ButtonRow'
import { DeleteButton } from '@utils/ui/Buttons/DeleteButton'
import { ReloadButton } from '@utils/ui/Buttons/ReloadButton'
import { SaveButton } from '@utils/ui/Buttons/SaveButton'
import { CardEx } from '@utils/ui/CardEx'
import { useDialogAnker } from '@utils/ui/DialogAnker'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { useMessageDialog } from '@utils/ui/MessageDialog'
import { ScrollPanel } from '@utils/ui/ScrollPanel'
import { StatePlane } from '@utils/ui/planes/StatePlane'
import { aidOf, arrayItemReplace } from '@utils/utils'
import {
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react'
import { useNavigate } from 'react-router-dom'
import { EkRabattgruppeVersionTable } from './EkRabattgruppeVersionTable'
import { EkRabattgruppeVersionWerteTable } from './EkRabattgruppeVersionWerteTable'
import { EkRabattgruppenPflegeKopf } from './EkRabattgruppenPflegeKopf'
import { NewEkRabattgruppeVersionDialog } from './NewEkRabattgruppeVersionDialog'

const validate = () => {
  const errors = {} as any

  return errors
}

const saveMutator = (model: EkRabattgruppenBearbeitenJson) => model.rabattgruppe

export const EkRabattgruppenPflegeView = () => {
  const { id, lieferantId, versionId } = useContext(RouteContext) as any
  const [versionIndex, setVersionIndex] = useState<number>(null)
  const navigate = useNavigate()
  const navigateOnSaveRef = useRef(false)
  const { isAllianz } = useContext(AppContext)
  const { askToConfirm } = useMessageDialog()
  const [DlgAnker, showDialog] = useDialogAnker()

  const onSave = useCallback(
    (model: EkRabattgruppenBearbeitenJson) => {
      if (model.artikelListeEkPreiseRef) {
        if (navigateOnSaveRef.current) {
          navigateOnSaveRef.current = false
          const _navigate = (artikelListeRef: string) => {
            navigate(AppPaths.EkPreisAnlageFn({ artikelListeRef }))
          }
          if (model.weitereArtikelListeEkPreise) {
            askToConfirm({
              title: 'Mehrere EK Preislisten',
              message:
                'Es wurden mehrere EK Preislisten angelegt. Möchten Sie zur aktuellsten Liste weitergeleitet werden? Alle anderen Listen finden Sie im Dashboard.',
              confirmLabel: 'Zur EK Preisliste',
              onConfirm: () => _navigate(model.artikelListeEkPreiseRef)
            })
          } else if (model.artikelListeEkPreiseRef) {
            _navigate(model.artikelListeEkPreiseRef)
          }
        }
        notifyObservers({ name: EventNames.DASHBOARD })
      }
    },
    [askToConfirm, navigate]
  )

  const { model, isNew, isChanged, onValueChange, save, reload, updModel, uiLock, remove, errors } =
    useModelMgr<
      EkRabattgruppenBearbeitenJson,
      EkRabattgruppenBearbeitenJson,
      EkRabattgruppenBearbeitenJson,
      EkKonditionenRabattgruppeJson
    >({
      id: id ?? 'neu',
      api,
      restps: { lieferantId },
      title: 'EK-Rabattgruppe',
      rest: 'ekrabattgruppe',
      validate,
      saveMutator,
      onSave,
      eventName: EventNames.EKRABATTGRUPPEN
    })

  const findCurrentVersionIndex = useCallback(
    (versionen: EkKonditionenRabattgruppeVersionJson[], id: number) => {
      // existierende version finden
      let currentIndex = versionen.findIndex((v) => id === v.id)
      if (currentIndex === -1) {
        // neue version finden
        currentIndex = versionen.findIndex((v) => !v.id)
      }
      return currentIndex
    },
    []
  )

  const findCurrentVersionIndex2 = useCallback(
    (
      versionen: EkKonditionenRabattgruppeVersionJson[],
      ver: EkKonditionenRabattgruppeVersionJson
    ) => {
      // existierende version finden
      let currentIndex = versionen.findIndex((v) => aidOf(v) === aidOf(ver))
      return currentIndex
    },
    []
  )

  const getPreisKategorieName = useCallback(
    (preisEbenenId: number | null) => {
      if (preisEbenenId == null) {
        return 'übergreifend'
      }
      const preisKategorie = model.preisEbenen.find((preisEbene) => preisEbene.id === preisEbenenId)
      return preisKategorie?.name ?? 'unbekannt'
    },
    [model.preisEbenen]
  )

  useEffect(() => {
    if (versionId) {
      const newVersionIndex = findCurrentVersionIndex(
        model?.rabattgruppe?.versionen ?? [],
        parseInt(versionId, 10)
      )
      setVersionIndex(newVersionIndex)
    }
  }, [model, versionId, setVersionIndex, findCurrentVersionIndex])

  const myref = useRef<any>(undefined)

  useImperativeHandle(myref, () => {
    return {
      getVersion: () => (versionIndex !== null ? model.rabattgruppe?.versionen[versionIndex] : null)
    }
  }, [model, versionIndex])

  const getVersion = useCallback<() => EkKonditionenRabattgruppeVersionJson>(
    () => myref.current && myref.current.getVersion(),
    []
  )

  const version = useMemo(
    () => (versionIndex !== null ? model.rabattgruppe?.versionen[versionIndex] : null),
    [model, versionIndex]
  )

  const staffelWerte = useMemo(() => {
    return version?.staffelwerte ?? []
  }, [version])

  const staffelMenge = useMemo<number[]>(() => {
    const gruppe = version?.staffelgruppeId
      ? model.staffelgruppen.find((s) => s.id === version.staffelgruppeId)
      : { staffelmengen: [] }
    const staffelmenge = new Set(gruppe.staffelmengen)
    if (version) {
      version.staffelwerte.forEach((s) => staffelmenge.add(s.staffelmenge))
    }
    return Array.from(staffelmenge)
  }, [version, model])

  const setVersion = useCallback(
    (nextVersion) => {
      updModel((m: EkRabattgruppenBearbeitenJson) => {
        const versionen = [...(m.rabattgruppe?.versionen || [])]
        const currentIndex = findCurrentVersionIndex2(versionen, nextVersion)
        if (currentIndex === -1) {
          versionen.push(nextVersion)
          setVersionIndex(versionen.length - 1)
        } else {
          versionen[currentIndex] = nextVersion
        }
        return { ...m, rabattgruppe: { ...m.rabattgruppe, versionen } }
      })
    },
    [updModel, findCurrentVersionIndex2]
  )

  const checkStaffelwerte = useCallback(() => {
    const version = getVersion() as any
    const usedStaffelwerte = version.staffelwerte
      .map((w) => ({
        staffelmenge: w.staffelmenge,
        hasValues:
          w.zuschlAbs1 != null ||
          w.zuschlAbs2 != null ||
          w.rabattProz1 != null ||
          w.rabattProz2 != null ||
          w.rabattProz3 != null ||
          w.rabattProz4 != null ||
          w.rabattProz5 != null ||
          w.zuschlAbs3 != null ||
          w.zuschlAbs4 != null ||
          w.zuschlAbs5 != null
      }))
      .reduce(
        (acc, staffelInfo) => {
          acc[staffelInfo.staffelmenge] = acc[staffelInfo.staffelmenge] || staffelInfo.hasValues
          return acc
        },
        {} as Map<number, boolean>
      )

    return usedStaffelwerte
  }, [getVersion])

  const kondiDefinition = useMemo(() => {
    return enrichKondiDefinition(model.konditionDefinition, isAllianz)
  }, [isAllianz, model.konditionDefinition])

  const { today, minDate } = useMemo(() => {
    const today = trimDate0000(getDateToday())

    const versionen = model.rabattgruppe?.versionen || []
    let minDate = versionen[0] ? new Date(versionen[0].gueltigVon) : today
    if (minDate) {
      versionen.forEach((version) => {
        const versionDate = new Date(version.gueltigVon)
        if (versionDate > minDate) {
          minDate = versionDate
        }
      })
    }
    return { today, minDate }
  }, [model.rabattgruppe?.versionen])

  const onStaffelChange = useCallback(
    (nextStaffelMenge: any) => {
      const version = getVersion() as any
      const staffelwerte = nextStaffelMenge.map((e: number[]) => {
        const staffelmenge = e[0]
        const staffelwert = version.staffelwerte.find((w) => w.staffelmenge === staffelmenge)
        if (staffelwert) {
          return staffelwert
        }

        return {
          staffelmenge
        }
      })

      setVersion({ ...version, staffelwerte })
    },
    [getVersion, setVersion]
  )

  const onStaffelGruppenChange = useCallback(
    (staffelgruppeId: number) => {
      let staffelmengen =
        model.staffelgruppen.find((s) => s.id === staffelgruppeId)?.staffelmengen ?? []
      if (staffelmengen.find((w) => w === 0) == null) {
        staffelmengen = [0, ...staffelmengen]
      }

      const version = getVersion()
      const nextVersion = { ...version }
      nextVersion.staffelgruppeId = staffelgruppeId
      nextVersion.staffelwerte = staffelmengen.map((m) => ({ staffelmenge: m }))
      setVersion(nextVersion)
    },
    [getVersion, model, setVersion]
  )

  const onStaffelwertChange = useCallback(
    (staffel: EkKonditionenRabattgruppeWertJson, field, value) => {
      const version = getVersion() as any
      const newStaffelwert = { ...staffel, [field]: value }
      if (!version) {
        return
      }

      const edit = {
        ...version,
        staffelwerte: arrayItemReplace(
          version.staffelwerte,
          (s) => s.staffelmenge === staffel.staffelmenge,
          newStaffelwert
        )
      }
      setVersion(edit)
    },
    [setVersion, getVersion]
  )

  const onAddVersion = useCallback(() => {
    const onDupCheck = ({ gueltigVon, preisEbeneId }) => {
      const versionen = model.rabattgruppe?.versionen || []
      const iso = formatISODate(gueltigVon)
      return versionen.find((v) => v.gueltigVon === iso && v.preisEbeneId === preisEbeneId) != null
    }
    const onNew = ({ gueltigVon, gueltigBis, preisEbeneId, staffelgruppeId }) => {
      const version = {
        gueltigBis: gueltigBis && formatISODate(gueltigBis),
        gueltigVon: gueltigVon && formatISODate(gueltigVon),
        staffelgruppeId,
        preisEbeneId,
        staffelwerte: [
          {
            staffelmenge: 0
          }
        ]
      } as EkKonditionenRabattgruppeVersionJson

      setVersion(version)

      // replaceHistory(() =>
      //   AppPaths.EkRabattgruppenPflegeFn({
      //     id: model.rabattgruppe?.id,
      //     lieferantId: model.rabattgruppe?.id == null ? lieferantId : undefined
      //   })
      // )
    }

    showDialog((open, onClose) => (
      <NewEkRabattgruppeVersionDialog
        open={open}
        onClose={onClose}
        preisEbenen={isAllianz ? model.preisEbenen : null}
        staffelgruppen={model.staffelgruppen}
        minDate={minDate}
        onNew={onNew}
        onDupCheck={onDupCheck}
      />
    ))
  }, [
    showDialog,
    model.rabattgruppe?.versionen,
    model.preisEbenen,
    model.staffelgruppen,
    setVersion,
    isAllianz,
    minDate
  ])

  const onRemoveVersion = useCallback(
    (version: EkKonditionenRabattgruppeVersionJson) => {
      if (version.id == null) {
        updModel((m: EkRabattgruppenBearbeitenJson) => {
          const versionen = m.rabattgruppe?.versionen.filter((v) => aidOf(v) !== aidOf(version))
          setVersionIndex(null)
          return { ...m, rabattgruppe: { ...m.rabattgruppe, versionen } }
        })
      }
    },
    [updModel]
  )

  const handleSave = useCallback(() => {
    if (isNew) {
      save()
    } else {
      showDialog((open, onClose) => (
        <EkPreisVorgangErstellenDialog
          open={open}
          onClose={onClose}
          onCheck={(checked: boolean) => {
            updModel((m) => ({
              ...m,
              rabattgruppe: {
                ...m.rabattgruppe,
                ekPreislisteErzeugen: !!checked
              }
            }))
          }}
          onNavigate={() => {
            navigateOnSaveRef.current = true
          }}
          onSave={save}
          hatPreisaenderungen={false}
        />
      ))
    }
  }, [save, isNew, showDialog, updModel])

  const onDelete = useCallback(() => {
    remove({
      onRemoved: () => {
        navigate(AppPaths.EkRabattgruppenUebersichtFn(model.rabattgruppe?.lieferant?.nummer))
      }
    })
  }, [remove, navigate, model.rabattgruppe?.lieferant?.nummer])

  return (
    <StatePlane uiLock={uiLock}>
      <ScrollPanel>
        <Frame space>
          <FrameRow>
            <EkRabattgruppenPflegeKopf
              readonly={!isNew}
              model={model}
              errors={errors}
              onValueChanges={onValueChange}
            />
          </FrameRow>
          <FrameBody grow={2}>
            <EkRabattgruppeVersionTable
              model={model}
              currentVersion={version}
              setVersionIndex={setVersionIndex}
              onAddVersion={onAddVersion}
              onRemoveVersion={onRemoveVersion}
              checkStaffelwerte={checkStaffelwerte}
              staffelmengen={staffelMenge}
              onStaffelChange={onStaffelChange}
              onStaffelGruppenChange={onStaffelGruppenChange}
              getPreisKategorieName={getPreisKategorieName}
            />
          </FrameBody>
          <FrameBody dense={!version}>
            {version ? (
              <EkRabattgruppeVersionWerteTable
                versionInfo={`'${getPreisKategorieName(
                  version.preisEbeneId
                )}', gültig ${formatDateRange(version.gueltigVon, version.gueltigBis)}`}
                values={staffelWerte}
                kondiDefinition={kondiDefinition}
                onValueChange={onStaffelwertChange}
                readonly={
                  version?.id == null
                    ? false
                    : isDateLower(trimDate0000(version?.gueltigVon), today) ||
                      isDateLower(trimDate0000(version?.gueltigBis), today)
                }
              />
            ) : (
              <CardEx
                title="Keine Version gewählt"
                subheader="Um Staffelwerte zu betrachten, bitte eine Version auswählen"
              ></CardEx>
            )}
          </FrameBody>
          <FrameRow>
            <ButtonRow>
              <DeleteButton
                onDelete={onDelete}
                isNew={isNew}
                deleteMsg={`EK-Rabattgruppe für "${model?.rabattgruppe?.lieferant?.name1} (${model?.rabattgruppe?.lieferant?.nummer})" wirklich löschen`}
                tooltip="EK-Rabattgruppe löschen"
              />
              <SaveButton onClick={handleSave} isNew={isNew} isChanged={isChanged} />
              <ReloadButton onClick={reload} isNew={isNew} isChanged={isChanged} />
            </ButtonRow>
          </FrameRow>
        </Frame>
        <DlgAnker />
      </ScrollPanel>
    </StatePlane>
  )
}
