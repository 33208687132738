/* eslint-disable react/no-danger */
import {ScrollPanel} from '@utils/ui/ScrollPanel'
import {isObject} from '@utils/utils'
import clsx from 'clsx'
import DOMPurify from 'dompurify'
import {makeStyles} from 'tss-react/mui'

export const useHtmlStyles = makeStyles()({
  html: {
    '& p, & ol, & ul': {
      margin: 0,
      lineHeight: 1.28
      // whiteSpace: 'pre-wrap'
    },
    '& h1': {
      margin: 0,
      whiteSpace: 'pre',
      fontSize: '2em'
    },
    '& h2': {
      margin: 0,
      whiteSpace: 'pre',
      fontSize: '1.5em'
    },
    '& h3': {
      margin: 0,
      whiteSpace: 'pre',
      fontSize: '1.17em'
    },
    '& h4': {
      margin: 0,
      whiteSpace: 'pre',
      fontSize: '1em'
    },
    '& h5': {
      margin: 0,
      whiteSpace: 'pre',
      fontSize: '0.83em'
    },
    '& h6': {
      margin: 0,
      whiteSpace: 'pre',
      fontSize: '0.67em'
    }
  }
})

export type StaticHtmlProps = {
  value?: string
  sanitize?: any
  autoSroll?: boolean
  className?: any
}

export const StaticHtml = ({ className, value, sanitize, autoSroll }: StaticHtmlProps) => {
  const { classes } = useHtmlStyles()
  const dangerouslySetInnerHTML = {
    __html: sanitize ? DOMPurify.sanitize(value, isObject(sanitize) ? sanitize : undefined) : value
  }
  if (autoSroll) {
    return value ? (
      <ScrollPanel>
        <div
          className={clsx(classes.html, className)}
          dangerouslySetInnerHTML={dangerouslySetInnerHTML}
        />
      </ScrollPanel>
    ) : (
      <div />
    )
  }

  return value ? (
    <div className={classes.html} dangerouslySetInnerHTML={dangerouslySetInnerHTML} />
  ) : (
    <div />
  )
}
