import { Grid2 as Grid } from '@mui/material'
import { api } from '@one/api'
import { useApiCaller } from '@utils/apicaller'
import { useFormState } from '@utils/formstate'
import { Button } from '@utils/ui/Buttons/Button'
import { DialogEx } from '@utils/ui/DialogEx'
import { StructPanel } from '@utils/ui/StructPanel'
import { TextField } from '@utils/ui/fields/TextField'
import { GlassPlane } from '@utils/ui/planes/GlassPlane'
import { WaitPlane } from '@utils/ui/planes/WaitPlane'
import { copyToClipboard, download } from '@utils/utils'
import { useState } from 'react'
import beautify from 'xml-beautifier'

export type PIMJsonExportDialogProps = {
  open: boolean
  onClose: (arg?: (arg?: any) => void) => (arg?: any) => void
}

export const PIMJsonExportDialog = ({ open, onClose }: PIMJsonExportDialogProps) => {
  const [apiCall, apiBusy] = useApiCaller(api)
  const [xml, setXml] = useState(null)
  const [artState, onArtChange] = useFormState({ hageNr: '' })

  const onArtXml = () => {
    apiCall<any>({
      method: 'GET',
      rest: 'pim/exportForImport',
      params: {
        hageNr: artState.hageNr
      },
      // accept: 'application/json',
      onSuccess: (data) => {
        setXml({ template: `pim-import.json`, content: JSON.stringify(data) })
      }
    })
  }

  return (
    <DialogEx
      open={open}
      onClose={onClose()}
      title="PIM json-Datei Erzeugen"
      maxWidth="md"
      fullWidth
      height="80%"
      actions={
        <>
          <Button
            label="Download"
            onClick={() => download(xml.template, beautify(xml.content))}
            disabled={xml == null}
          />
          <Button
            label="Copy Clipboard"
            onClick={() => copyToClipboard(beautify(xml.content))}
            disabled={xml == null || navigator.clipboard == null}
          />
        </>
      }
    >
      <GlassPlane show={apiBusy}>
        <WaitPlane wait={apiBusy}>
          <Grid container direction="column" height="100%" justifyContent="stretch" spacing={2}>
            <Grid>
              <TextField
                label="hage-Nr. (kommasepariert) ('zauberfuermich' für einen voll gefüllten Demo Artikel)"
                fullWidth
                required
                value={artState.hageNr}
                onChange={onArtChange}
                name="hageNr"
              />
            </Grid>
            <Grid>
              <Button
                label="json erzeugen"
                variant="contained"
                onClick={onArtXml}
                disabled={artState?.hageNr == null || artState?.hageNr?.length === 0}
              />
            </Grid>
            {xml && (
              <Grid flexGrow={1} marginLeft={2} marginRight={2}>
                <StructPanel value={xml.content} />
              </Grid>
            )}
          </Grid>
        </WaitPlane>
      </GlassPlane>
    </DialogEx>
  )
}
