import {
  ArtikelDisplayTinyJson,
  BetriebstypJson,
  LieferantDisplayTinyJson,
  MengeneinheitDisplayTinyJson,
  MengeneinheitJson,
  NeonKontextDisplayJson,
  PreisEbeneDisplayJson,
  WarengruppeDisplayJson
} from '@one/typings/apiTypings'
import { zeroPad } from '@utils/utils'

export const formatLieferant = (lieferant: LieferantDisplayTinyJson) => {
  if (lieferant?.notanlage) {
    return `Notanlage (${lieferant?.nummer ?? ''})`
  }
  return lieferant ? `${lieferant.nummer} - ${lieferant.name1}` : null
}

export const formatLieferantLang = (lieferant: LieferantDisplayTinyJson) => {
  if (lieferant?.notanlage) {
    return `Notanlage (${lieferant?.nummer ?? ''})`
  }
  return lieferant ? `${lieferant.nummer} - ${lieferant.name1} ${lieferant.name2 ?? ''}` : null
}

export const formatErpWarengruppe = (warengruppe: WarengruppeDisplayJson) =>
  warengruppe ? `${warengruppe.nummer} - ${warengruppe.name}` : null

/*TODO: me.kurz ist undefined - passe an, wenn beschreibung vorhanden: me ? `${me.bez} (${me.kurz})` : null*/
export const formatMengeneinheit = (me: MengeneinheitJson | MengeneinheitDisplayTinyJson) =>
  me ? me.bez : null

export const formatNeonKontext = (kontext: NeonKontextDisplayJson) =>
  kontext && `${kontext.kontextNr} ${kontext.kontextName} (${kontext.betriebstypNr || ''})`

export const formatBetriebsTyp = (bt: BetriebstypJson) =>
  bt ? `${zeroPad(bt?.btNr, 3)}${bt?.bezeichnung ? ` - ${bt?.bezeichnung}` : ''}` : null

export const formatPreisEbene = (pe: PreisEbeneDisplayJson) =>
  pe == null ? null : `${pe.nr} - ${pe.name}`

export const formatArtikel = (artikel: ArtikelDisplayTinyJson) => {
  return artikel
    ? `${artikel.hageNummer ?? ''} - ${artikel.bez1 ?? ''} ${artikel.bez2 ?? ''}`
    : null
}
