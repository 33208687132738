/* eslint-disable no-plusplus */
import {Grid2 as Grid} from '@mui/material'
import {AppPaths} from '@one/AppPaths'
import {ArtikelListeJson, NeonKontext} from '@one/typings/apiTypings'
import {UserRoles} from '@one/UserRoles'
import {Action} from '@utils/ui/Action'
import {AppContext} from '@utils/ui/App/AppContext'
import {CardEx} from '@utils/ui/CardEx'
import {EmphasizeFrame} from '@utils/ui/EmphasizeFrame'
import {TextField} from '@utils/ui/fields/TextField'
import {useCallback, useContext, useMemo} from 'react'
import {BetriebstypKontextField} from '@one/components/common/BetriebstypKontextField'
import {ArtikelListeTypField} from '@one/components/ArtikelListe/common/ArtikelListeTypField'

export interface ArtikelListeFormProps {
  onChange: (e: Event) => void
  model?: ArtikelListeJson
  errors?: any
  isNew?: boolean
  readonly?: boolean
  isChanged?: boolean
  kontext?: NeonKontext
}

export const ArtikelListeForm = ({
  onChange,
  model = {},
  errors = {},
  isNew = false,
  readonly = false,
  isChanged = false,
  kontext = null
}: ArtikelListeFormProps) => {
  const { isAllianz } = useContext(AppContext)

  const tooltipText = useCallback(
    (defaultText) => (isChanged || isNew ? 'Bitte zunächst speichern' : defaultText),
    [isChanged, isNew]
  )

  const virtuell = model?.typ?.startsWith('VIRTUELL')

  const actions = useMemo(
    () =>
      [
        {
          key: 'hauptlieferant',
          role: UserRoles.STAMMDATEN_EDITOR,
          tooltip: tooltipText('Hauptlieferanten Pflege'),
          navlink: isNew
            ? null
            : AppPaths.HauptlieferantPflegeFn(model.id, model.kontext, model.bt),
          text: 'Hauptlieferanten-Pflege',
          variant: 'outlined',
          enabled: !isNew && !isChanged,
          visible: !isAllianz
        },
        {
          key: 'einkauf',
          role: UserRoles.STAMMDATEN_EDITOR,
          tooltip: tooltipText('Einkaufspreis-Pflege auf Grundlage dieser Artikelliste beginnen'),
          navlink: isNew
            ? null
            : AppPaths.EkPreisAnlageFn({
                artikelListeRef: model.id,
                kontext: model.kontext,
                bt: model.bt
              }),
          text: 'Einkaufspreis-Pflege',
          variant: 'outlined',
          enabled: !isNew && !isChanged
        },
        {
          key: 'verkauf',
          role: UserRoles.STAMMDATEN_EDITOR,
          tooltip: tooltipText('Verkaufspreis-Pflege auf Grundlage dieser Artikelliste beginnen'),
          navlink: isNew
            ? null
            : AppPaths.VkPreisAnlageFn({
                artikelListeRef: model.id,
                kontext: model.kontext,
                bt: model.bt
              }),
          text: 'Verkaufspreis-Pflege',
          variant: 'outlined',
          enabled: !isNew && !isChanged,
          visible: !isAllianz
        }
      ] as Action[],
    [tooltipText, isNew, model.id, model.kontext, model.bt, isChanged, isAllianz]
  )

  return (
    <CardEx
      backLink
      overviewLink={AppPaths.artikelliste.ArtikelListeUebersicht}
      title={isNew ? 'Artikelliste erstellen' : `Artikelliste ${model.name || ''}`}
      topActions={actions}
    >
      <Grid container spacing={2} direction="column">
        <Grid container direction="row" spacing={2}>
          {virtuell ? null : (
            <>
              <Grid size={{ xs: 12, sm: 6, lg: 3 }}>
                <TextField
                  name="name"
                  label="Name"
                  error={errors.name}
                  value={model.name}
                  onChange={onChange}
                  fullWidth
                  required
                  disabled={readonly}
                />
              </Grid>
              <Grid size={{ xs: 12, sm: 6, lg: 3 }}>
                <ArtikelListeTypField
                  name="typ"
                  value={model.typ}
                  error={errors.typ}
                  onChange={onChange}
                  disabled
                />
              </Grid>
            </>
          )}
          {(model.kontext || model.bt) && (
            <Grid size={{ xs: 12, sm: 6, lg: 3 }}>
              <EmphasizeFrame emphasize={kontext != null}>
                <BetriebstypKontextField
                  prefix="Einschränkung auf "
                  kontext={model.kontext}
                  betriebsTyp={model.bt}
                />
              </EmphasizeFrame>
            </Grid>
          )}
        </Grid>
      </Grid>
    </CardEx>
  )
}
