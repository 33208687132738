import { HkmEnum } from '@one/enums/HkmEnum'
import { ArtikelListeTyp } from '@one/typings/apiTypings'
import { ChangeFunction } from '@utils/formstate'
import { SelectEnumField } from '@utils/ui/fields/SelectEnumField'

export interface ArtikelListeTypFieldProps {
  value: ArtikelListeTyp | undefined | null
  name?: string
  onChange?: ChangeFunction<any>
  type?: 'all' | 'virtuell' | 'real'
  disabled?: boolean
  required?: boolean
  error?: any
}

export const ArtikelListeTypField = ({
  value,
  name,
  type,
  required,
  disabled,
  error,
  onChange
}: ArtikelListeTypFieldProps) => {
  console.log('ArtikelListeTypField', { value, type })
  return (
    <SelectEnumField
      label="Typ"
      name={name}
      value={value}
      onChange={onChange}
      displayEmpty
      fullWidth
      required={required}
      disabled={disabled}
      error={error}
      enumType={HkmEnum.ArtikelListeTyp}
      filter={(opt) =>
        type == null || type === 'all' || opt.id.startsWith('VIRTUELL_') === (type === 'virtuell')
      }
      emptyText="Alle"
    />
  )
}
