import { Sync } from '@mui/icons-material'
import { Grid2 as Grid } from '@mui/material'
import { api } from '@one/api'
import { useNeonKontextCache } from '@one/datacaches/useNeonKontextCache'
import {
  LieferantBetriebstypListeJson,
  NeonKontext,
  NeonKontextDisplayJson
} from '@one/typings/apiTypings'
import { useApiCaller } from '@utils/apicaller'
import { Rotate } from '@utils/ui/Rotate'
import { SelectField } from '@utils/ui/fields/SelectField'
import { ifString, zeroPad } from '@utils/utils'
import { useEffect, useRef, useState } from 'react'

export interface NeonKontextFieldProps {
  value: NeonKontext | NeonKontext[] | NeonKontextDisplayJson | null
  onChange?: React.ChangeEventHandler<HTMLSelectElement>
  name?: string
  error?: string | boolean
  label?: string
  emptyText?: string
  disabled?: boolean
  required?: boolean
  asJson?: boolean
  helperText?: string
  showArtikelzahlVonLieferantId?: number
  multiple?: boolean
}

interface NeonKontextDisplayJsonEx extends NeonKontextDisplayJson {
  artikelAnzahl?: number
}

export const NeonKontextField = ({
  value = null,
  onChange = null,
  name = null,
  label = null,
  emptyText = null,
  error = null,
  disabled = false,
  required = false,
  asJson = false,
  helperText = '',
  showArtikelzahlVonLieferantId = null,
  multiple
}: NeonKontextFieldProps) => {
  const { data } = useNeonKontextCache()
  const [apiCall, apiBusy] = useApiCaller(api)
  const lastLieferantIdRef = useRef(null)
  const [kontexte, setKontexte] = useState<NeonKontextDisplayJsonEx[]>([])

  useEffect(() => {
    if (showArtikelzahlVonLieferantId == null) {
      setKontexte(data)
    } else if (lastLieferantIdRef.current != showArtikelzahlVonLieferantId) {
      lastLieferantIdRef.current = showArtikelzahlVonLieferantId
      apiCall<LieferantBetriebstypListeJson>({
        method: 'GET',
        rest: 'lieferantbetriebstypen',
        params: { lieferantId: showArtikelzahlVonLieferantId },
        onSuccess: (loaded) => {
          setKontexte(
            data.map((kontext) => ({
              ...kontext,
              artikelAnzahl:
                loaded.lieferantBetriebstypen.find((a) => a.betriebstyp === kontext.betriebstyp)
                  ?.artikelAnzahl || 0
            }))
          )
        }
      })
    }
  }, [apiCall, data, showArtikelzahlVonLieferantId])

  return (
    <SelectField
      label={label}
      value={value}
      error={!!error}
      helperText={ifString(error) ?? helperText}
      options={kontexte}
      onChange={onChange}
      name={name}
      optionValue={asJson ? (o) => o : 'kontext'}
      optionText={(typ: NeonKontextDisplayJsonEx) => (
        <Grid container spacing={1} alignItems="center">
          <Grid>{zeroPad(typ?.kontextNr, 3)}</Grid>
          <Grid>
            <span>{typ?.kontextName}</span>
          </Grid>
          <Grid fontSize="70%">
            <span>{typ?.betriebstyp && `(BT ${typ?.betriebstypNr})`}</span>
          </Grid>
          {showArtikelzahlVonLieferantId ? (
            <Grid flexGrow={1}>
              {apiBusy ? (
                <Rotate>
                  <Sync fontSize="small" />
                </Rotate>
              ) : (
                <small title="Anzahl Artikel des Lieferanten">
                  {' '}
                  ~{typ.artikelAnzahl || 0} Artikel
                </small>
              )}
            </Grid>
          ) : null}
        </Grid>
      )}
      // optionIgnored={(typ: NeonKontextDisplayJsonEx) => !alle && !typ.zugeordnet}
      displayEmpty
      emptyText={emptyText}
      disabled={disabled}
      required={required}
      fullWidth
      multiple={multiple}
    />
  )
}
