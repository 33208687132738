import { Button } from '@mui/material'
import { AppPaths } from '@one/AppPaths'
import { ArtikelListeSuchDialog } from '@one/components/ArtikelListe/ArtikelListeSuchDialog/ArtikelListeSuchDialog'
import { HkmEnum } from '@one/enums/HkmEnum'
import { ArtikelListeTyp } from '@one/typings/apiTypings'
import { UserRoles } from '@one/UserRoles'
import { useEnums } from '@utils/enums'
import { useStateEx } from '@utils/stateex'
import { Column } from '@utils/ui/DataTable/DataTable'
import { DataTableAction } from '@utils/ui/DataTable/DataTableBody'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { useGlobalDialogAnker } from '@utils/ui/DialogAnker'
import { useMessageDialog } from '@utils/ui/MessageDialog'
import { PermissionUtil } from '@utils/ui/Permission'
import { arrayMergeUnique } from '@utils/utils'
import { useCallback, useMemo } from 'react'

export interface ArtikelVerteilerListenTableProps {
  updModel: (prevModel: any) => any
  model?: any
}

const getLinkFn = (artikelListeTyp: ArtikelListeTyp) => {
  switch (artikelListeTyp) {
    case ArtikelListeTyp.ABO:
      return AppPaths.ArtikelAboPflegeFn
    case ArtikelListeTyp.ARTIKEL_SELEKTION:
      return AppPaths.ArtikelAboSelektionPflegeFn
    default:
      return null
  }
}

export const ArtikelVerteilerListenTable = ({
  updModel,
  model = {}
}: ArtikelVerteilerListenTableProps) => {
  const { askToRemove } = useMessageDialog()
  const { et } = useEnums()
  const [selected, setSelected, getSelected] = useStateEx(new Set())

  const showDlg = useGlobalDialogAnker()

  const onComplete = useCallback(
    (data) => {
      if (data) {
        updModel((prevModel) => {
          const eintraege = arrayMergeUnique(prevModel.eintraege, data, 'id')
          return { ...prevModel, eintraege }
        })
      }
    },
    [updModel]
  )

  const onMultiRemoveSelection = useCallback(
    (list) => () => {
      updModel((prevModel) => {
        const eintraege = prevModel.eintraege.filter((item) => {
          return !list.has(item)
        })
        return { ...prevModel, eintraege }
      })
    },
    [updModel]
  )

  const removeSelektion = useCallback(
    (data) => () => {
      if (data) {
        onMultiRemoveSelection(new Set([data]))()
      }
    },
    [onMultiRemoveSelection]
  )

  const removeAfterCheck = useCallback(() => {
    askToRemove({
      title: `Selektion mit ${getSelected().size} Artikellisten entfernen`,
      onConfirm: onMultiRemoveSelection(getSelected())
    })
  }, [onMultiRemoveSelection, getSelected, askToRemove])

  const onNewArtikelListe = useCallback(() => {
    showDlg((visible, onClose) => (
      <ArtikelListeSuchDialog open={visible} onClose={onClose(onComplete)} />
    ))
  }, [onComplete, showDlg])

  const columns = useMemo<Column[]>(
    () => [
      {
        field: 'name',
        header: 'Name'
      },
      {
        field: 'typ',
        header: 'Typ',
        body: (row: any) => et(HkmEnum.ArtikelListeTyp, row.typ)
      },
      {
        field: 'artikelAnzahl',
        header: 'Artikelanzahl'
      }
    ],
    [et]
  )

  const tableActions = useMemo(
    (): DataTableAction[] => [
      {
        icon: 'arrow_forward',
        tooltip: 'Abo/Selektion öffnen',
        check: (data) => PermissionUtil.enabled(getLinkFn(data.typ) != null),
        getLink: (data) => getLinkFn(data.typ)?.(data.ownerId)
      },
      {
        icon: 'file_open',
        tooltip: 'Artikelliste öffnen',
        getLink: (data) => AppPaths.artikelliste.ArtikelListePflegeFn(data.id)
      },
      {
        icon: 'delete',
        tooltip: 'Artikelliste entfernen',
        handler: (data) => () => {
          askToRemove({
            title: `Artikelliste "${data.name}" entfernen`,
            onConfirm: removeSelektion(data)
          })
        }
      }
    ],
    [askToRemove, removeSelektion]
  )

  const actions = useMemo(
    () => [
      {
        role: UserRoles.STAMMDATEN_EDITOR,
        tooltip: 'Selektion für alle Lieferanternartikel hinzufügen',
        text: 'Artikelliste',
        icon: 'add',
        onClick: onNewArtikelListe
        // variant: 'contained'
      }
    ],
    [onNewArtikelListe]
  )

  const bottomActions = useMemo(
    () => [
      <Button
        disabled={selected.size === 0}
        onClick={removeAfterCheck}
        variant="contained"
        color="error"
        key="removeArtikel"
      >
        Artikellisten entfernen
      </Button>
    ],
    [removeAfterCheck, selected]
  )

  return (
    <DataTableCard
      name="ArtikelVerteilerListenTable"
      title="Artikellisten"
      topActions={actions}
      columns={columns}
      value={model.eintraege}
      actions={tableActions}
      selectMode="multi"
      selected={selected}
      onSelect={setSelected}
      bottomActions={bottomActions}
      dense
      localStateName="ArtikelVerteilerListenTable"
    />
  )
}
