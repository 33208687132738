import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { CardEx } from '@utils/ui/CardEx'
import { Grid2 as Grid, Typography } from '@mui/material'
import { Switch } from '@utils/ui/fields/Switch'
import { api } from '@one/api'
import { useModelMgr } from '@utils/modelmgr'
import { StatePlane } from '@utils/ui/planes/StatePlane'
import { ButtonRow } from '@utils/ui/Buttons/ButtonRow'
import { SaveButton } from '@utils/ui/Buttons/SaveButton'
import { ReloadButton } from '@utils/ui/Buttons/ReloadButton'

export const AutomatikenPflegeView = () => {
  const { model, isChanged, uiLock, onValueChange, save, reload } = useModelMgr({
    noid: true,
    api,
    title: 'Gesellschaftereinstellungen',
    rest: 'gesellschafter/einstellungen',
    init: {}
  })

  return (
    <StatePlane uiLock={uiLock}>
      <Frame space>
        <FrameBody>
          <CardEx
            backLink
            title="Gesellschafter-Einstellungen"
            subheader="Globale Systemeinstellungen verwalten."
            height="100%"
          >
            <Grid container spacing={2} paddingTop={2}>
              <Grid width="100%">
                <Typography variant="h6">Artikelabo-Selektion</Typography>
                <Typography>Vorgaben für neue Abo-Selektionen</Typography>
              </Grid>
              <Grid>
                <Switch
                  label="Änderungen automatisch übernehmen"
                  checked={model.aenderungenAutomatischUebernehmen}
                  name="aenderungenAutomatischUebernehmen"
                  onChange={onValueChange}
                />
              </Grid>
              <Grid>
                <Switch
                  label="Preisänderungen getrennt bearbeiten"
                  checked={model.preisAenderungenSeparatBearbeiten}
                  name="preisAenderungenSeparatBearbeiten"
                  onChange={onValueChange}
                />
              </Grid>
            </Grid>
          </CardEx>
        </FrameBody>
        <FrameRow>
          <ButtonRow>
            <SaveButton onClickVoid={save} isChanged={isChanged} isNew={false} />
            <ReloadButton onClick={reload} isChanged={isChanged} />
          </ButtonRow>
        </FrameRow>
      </Frame>
    </StatePlane>
  )
}
