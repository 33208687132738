import { Divider, Grid2 as Grid, Typography } from '@mui/material'
import { formatMengeneinheit } from '@one/components/common/formatters'
import { ArtikelMengeneinheitJson, DzArtikelJson, ZeArtikelJson } from '@one/typings/apiTypings'
import { formatNumber } from '@utils/numberutils'
import { CardEx } from '@utils/ui/CardEx'
import { Column, DataTable } from '@utils/ui/DataTable/DataTable'
import { StaticField } from '@utils/ui/fields/StaticField'
import React, { useCallback, useMemo, useState } from 'react'

export interface ArtikelMengeneinheitenProps {
  artikel: DzArtikelJson | ZeArtikelJson
  mode?: 'dz' | 'ze'
  loading?: boolean
}

export const ArtikelMengeneinheiten = ({ artikel, mode, loading }: ArtikelMengeneinheitenProps) => {
  const [meKontextFilter, setMEKontextFilter] = useState(true)

  function filterVerwendung() {
    setMEKontextFilter(!meKontextFilter)
  }

  const mengeneinheitenGefiltert = meKontextFilter
    ? artikel.mengeneinheiten.filter((me) => !me.nichtImKontext)
    : artikel.mengeneinheiten

  const renderLabel = useCallback((mengeneinheit: any) => formatMengeneinheit(mengeneinheit), [])

  const columns = useMemo<Column<ArtikelMengeneinheitJson>[]>(
    () => [
      {
        field: 'mengeneinheit.bez',
        header: 'Mengeneinheit',
        body: (row) => renderLabel(row.mengeneinheit)
      },
      //{
      //  field: 'umrechnungInfo',
      //  header: 'Umrechnung (Bruch)',
      //  body: (row) => {
      //  if (!row.umrechnungInfo) {
      //    Nichts anzeigen
      //    return ''
      //  }
      //  let res = row.umrechnungInfo.replace(
      //    /SeMengeneinheit{.*}/,
      //    renderLabel(row.mengeneinheit)
      //  )
      //  if (row.faktor.nenner === 1) {
      //  res = res.replace(/(\d)+\/1/, `${row.faktor?.zaehler}`)
      //  }
      //  return res.replace(/[\d]+$/, renderLabel(row.basisMengeneinheit))
      //  }
      //  },
      //  {
      //  field: 'umrechnungInfo',
      //  header: 'Umrechnung',
      //  key: 'umrechnungInfoNumber',
      //  body: (row) => {
      //  const label = renderLabel(row.basisMengeneinheit) ?? ''
      //  if (!row.umrechnungInfo) {
      // Nichts anzeigen
      // return ''
      // }
      //
      // if (row.faktor.nenner === 1) {
      // return `${row.faktor.zaehler} ${label}`
      // }
      // return `${formatNumber(
      // Math.round((row.faktor.zaehler / row.faktor.nenner + Number.EPSILON) * 1000) / 1000,
      // 7
      // }
      // },
      {
        field: 'umrechnungInfo',
        header: 'Umrechnung',
        key: 'umrechnungInfoNumber',
        body: (row) => row.umrechnungInfo
      },
      {
        field: 'gewicht',
        header: 'Gewicht',
        type: mode == 'dz' ? 'money4' : 'money3'
      },
      {
        field: 'hoehe',
        header: 'Höhe',
        type: 'money3'
      },
      {
        field: 'laenge',
        header: 'Länge',
        type: 'money3'
      },
      {
        field: 'breite',
        header: 'Breite',
        type: 'money3'
      },
      {
        field: 'shouldBeHiddenFromERP',
        header: 'kein Export',
        headerTip: 'Mengeneinheit wird vom Export zum ERP ausgeschlossen.'
      }
    ],
    [mode, renderLabel]
  )

  const groupBy = useMemo(
    () => ({
      field: 'basisMengeneinheit.id',
      body: (row: any) => <b>Basismengeneinheit: {renderLabel(row.basisMengeneinheit)}</b>,
      groupEmpty: false
    }),
    [renderLabel]
  )

  const dzArtikel = artikel as DzArtikelJson

  return (
    <CardEx title="Mengeneinheiten">
      {
        //<Grid size={{ xs: 12, sm: 6, md: 6, lg: 4, xl: 4 }}>
        //<Checkbox
        //  name="kontextSpezifischeMEs"
        //  onChange={filterVerwendung}
        //  checked={meKontextFilter}
        ///>
        //Im Kontext verwendet
        //</Grid>
      }
      <DataTable
        name="MengeneinheitenTable"
        columns={columns}
        groupBy={groupBy}
        value={mengeneinheitenGefiltert}
        loading={loading}
        dense
      />
      <Grid spacing={2}>
        <Grid size={{ xs: 12 }}>
          <Typography variant="h6" component="h6" sx={{ marginTop: '20px' }}>
            Einheiten
          </Typography>
        </Grid>
        <Grid size={{ xs: 12 }}>
          <Divider sx={{ opacity: '0.6' }} />
        </Grid>
        <Grid paddingTop={2} size={{ xs: 12 }}>
          <Grid container width="100%" spacing={2}>
            <Grid size={{ xs: 12, sm: 6, md: 6, lg: 2, xl: 2 }}>
              <StaticField
                label="Einkaufsmengeneinheit"
                value={formatMengeneinheit(artikel.einkaufME)}
                fullWidth
              />
            </Grid>
            {mode === 'dz' && (
              <>
                <Grid size={{ xs: 12, sm: 6, md: 6, lg: 2, xl: 2 }}>
                  <StaticField
                    label="Verkaufsmengeneinheit"
                    value={formatMengeneinheit(dzArtikel.verkaufME)}
                    fullWidth
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 6, md: 6, lg: 2, xl: 2 }}>
                  <StaticField
                    label="Einzelmengeneinheit (kleinste lieferbare Einheit)"
                    value={formatMengeneinheit(dzArtikel.einzelME)}
                    fullWidth
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 6, md: 6, lg: 2, xl: 2 }}>
                  <StaticField
                    label="Lagermengeneinheit"
                    value={formatMengeneinheit(dzArtikel.lagerME)}
                    fullWidth
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 6, md: 6, lg: 2, xl: 2 }}>
                  <StaticField
                    label="Einkaufspreismenge"
                    value={formatNumber(dzArtikel.einkaufPreismengeWert, 2)}
                    fullWidth
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </CardEx>
  )
}
