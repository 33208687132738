import { Grid2 as Grid } from '@mui/material'
import { api } from '@one/api'
import { LieferantField } from '@one/components/Lieferant/LieferantField'
import { WarengruppeField } from '@one/components/Warengruppe/WarengruppeField'
import { HkmEnum } from '@one/enums/HkmEnum'
import {
  ArtikelBetriebstyp,
  ArtikelSelektionJson,
  ArtikelSelektionType,
  LieferantDisplayJson
} from '@one/typings/apiTypings'
import { useEnums } from '@utils/enums'
import { useModelMgr } from '@utils/modelmgr'
import { AppContext } from '@utils/ui/App/AppContext'
import { CancelButton } from '@utils/ui/Buttons/CancelButton'
import { SaveButton } from '@utils/ui/Buttons/SaveButton'
import { DialogEx } from '@utils/ui/DialogEx'
import { TextField } from '@utils/ui/fields/TextField'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { StatePlane } from '@utils/ui/planes/StatePlane'
import { dataFromEvent } from '@utils/utils'
import { useCallback, useContext, useMemo } from 'react'
import { ArsstSelectField } from './ArsstSelectField'
import { ArtikelSammlerField } from './ArtikelSammlerField'

export interface ArtikelSelektionJsonWithArtikel extends ArtikelSelektionJson {
  artikel: any[]
}

const validate = (model: ArtikelSelektionJsonWithArtikel) => {
  const validationErrors = {} as any

  switch (model.typ) {
    case ArtikelSelektionType.WARENGRUPPE:
      if (!model.warengruppe) {
        validationErrors.warengruppe = 'Warengruppe ist ein Pflichtfeld'
      }
      break
    case ArtikelSelektionType.ARTIKEL:
      if (!model.artikel) {
        validationErrors.artikel = 'Artikelliste ist ein Pflichtfeld'
      }
      break
    default:
      break
  }
  if (!model.name) {
    validationErrors.name = 'Name ist ein Pflichtfeld'
  }
  if (!model.arsstId) {
    validationErrors.arsstId = 'ArSST Einstellung ist ein Pflichtfeld'
  }
  return validationErrors
}

export interface ArtikelSelektionCreateDialogProps {
  lieferant: LieferantDisplayJson
  betriebsTyp: ArtikelBetriebstyp
  typ: ArtikelSelektionType
  visible?: boolean
  onClose?: (fn?: any) => () => void
  aboId?: number
  onComplete?: (data: any) => void
}

export const ArtikelSelektionCreateDialog = ({
  lieferant,
  betriebsTyp,
  typ,
  visible = true,
  onClose = null,
  aboId = null,
  onComplete = null
}: ArtikelSelektionCreateDialogProps) => {
  const { et } = useEnums()

  const { isEinzelhandel, isFachhandel } = useContext(AppContext)

  const editMutator = useCallback(
    (model) => {
      return {
        ...model,
        typ,
        name: typ && et(HkmEnum.ArtikelSelektionType, typ)
      }
    },
    [et, typ]
  )

  const saveMutator = useCallback((model) => {
    return {
      ...model,
      artikel: undefined,
      artikelIds: model.artikel && model.artikel.map((a) => a.id)
    }
  }, [])

  const { model, errors, uiLock, onValueChange, updModel, save } =
    useModelMgr<ArtikelSelektionJsonWithArtikel>({
      id: 'neu',
      api,
      title: 'Artikelselektion',
      rest: 'artikelselektion',
      restps: { aboId },
      unwrapField: 'selektion',
      init: {
        name: null,
        typ,
        aboId,
        lieferant,
        ergebnisse: []
      } as ArtikelSelektionJsonWithArtikel,
      validate,
      editMutator,
      saveMutator,
      onSave: onClose(onComplete),
      ignoreAsyncTaskState: true
    })

  const onWGChange = useCallback(
    (e) => {
      const { value } = dataFromEvent(e)
      updModel({ warengruppe: value, name: value?.name })
    },
    [updModel]
  )

  const isEmpty = useMemo(() => {
    switch (model.typ) {
      case ArtikelSelektionType.ALLE:
        return false

      case ArtikelSelektionType.ARTIKEL:
        return model.artikel == null

      case ArtikelSelektionType.WARENGRUPPE:
        return model.warengruppe == null

      default:
        return false
    }
  }, [model.artikel, model.typ, model.warengruppe])

  const typeField = useMemo(() => {
    switch (model.typ) {
      case ArtikelSelektionType.ALLE:
        return null

      case ArtikelSelektionType.ARTIKEL:
        return (
          <ArtikelSammlerField
            name="artikel"
            label="Artikelliste"
            error={errors.artikel}
            value={model.artikel}
            lieferant={lieferant}
            betriebsTyp={betriebsTyp}
            onChange={onValueChange}
            fullWidth
          />
        )

      case ArtikelSelektionType.WARENGRUPPE:
        return (
          <WarengruppeField
            value={model.warengruppe}
            name="warengruppe"
            label="Zentrale-Warengruppe"
            error={errors.warengruppe}
            required
            onChange={onWGChange}
            lieferantId={lieferant.id}
            fullWidth
          />
        )

      default:
        return null
    }
  }, [
    model.typ,
    model.artikel,
    model.warengruppe,
    errors.artikel,
    errors.warengruppe,
    lieferant,
    betriebsTyp,
    onValueChange,
    onWGChange
  ])

  return (
    <DialogEx
      height={model.typ === ArtikelSelektionType.ARTIKEL ? '80vh' : '480px'}
      open={visible}
      onClose={onClose()}
      fullWidth
      maxWidth={model.typ === ArtikelSelektionType.ARTIKEL ? 'lg' : 'md'}
      title={`Artikelselektion ${et(HkmEnum.ArtikelSelektionType, model.typ)} erstellen`}
      actions={
        <>
          <SaveButton onClickVoid={save} anlegen disabled={isEmpty} />
          <CancelButton onClick={onClose()} />
        </>
      }
    >
      <StatePlane uiLock={uiLock} onClose={onClose()} noNav>
        <Frame space fullHeight>
          <FrameRow>
            <Grid container spacing={2} paddingBottom={2} direction="column">
              <Grid size={{xs:12,sm:12}}>
                <LieferantField
                  label="Lieferant"
                  value={model.lieferant}
                  fullWidth
                  disabled
                  modus="dezentral"
                />
              </Grid>
              <Grid size={{xs:12,sm:12}}>
                <TextField
                  label="Name"
                  error={errors.name}
                  name="name"
                  value={model.name}
                  onChange={onValueChange}
                  required
                  fullWidth
                />
              </Grid>
              <Grid size={{xs:12,sm:12}}>
                <ArsstSelectField
                  arsstId={model.arsstId}
                  disabled={model.forEinzelhandel}
                  errors={errors}
                  onChange={onValueChange}
                  required
                />
              </Grid>
            </Grid>
          </FrameRow>
          <FrameBody style={{ minHeight: '500px' }}>
            <div
              // Workaround wg h-scroller...
              style={{ width: '99%', height: '100%' }}
            >
              {typeField}
            </div>
          </FrameBody>
        </Frame>
      </StatePlane>
    </DialogEx>
  )
}
