import { AppPaths } from '@one/AppPaths'
import { EkPreisListeFreigabeDialog } from '@one/components/EkPreis/EkPreisListeFreigabeDialog'
import { EventNames } from '@one/EventNames'
import { formatDateTime } from '@utils/dateutils'
import { notifyObservers, useObserver } from '@utils/observer'
import { useStateEx } from '@utils/stateex'
import { Action } from '@utils/ui/Action'
import { AppContext } from '@utils/ui/App/AppContext'
import { RouteContext } from '@utils/ui/App/AppRoute'
import { AppRouteCtx } from '@utils/ui/App/AppRouteCtx'
import { Button } from '@utils/ui/Buttons/Button'
import { ButtonRow } from '@utils/ui/Buttons/ButtonRow'
import { DeleteButton } from '@utils/ui/Buttons/DeleteButton'
import { ReloadButton } from '@utils/ui/Buttons/ReloadButton'
import { useDialogAnker } from '@utils/ui/DialogAnker'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { useMessageDialog } from '@utils/ui/MessageDialog'
import { StatePlane } from '@utils/ui/planes/StatePlane'
import { useSnackbarEx } from '@utils/ui/snackbarex'
import { asNumber } from '@utils/utils'
import { useCallback, useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { EkPreisMassenAenderDialog } from './EkPreisMassenAenderDialog'
import { EkPreisMassenArtikelRabattgruppeDialog } from './EkPreisMassenArtikelRabattgruppeDialog'
import { EkPreisMassenPflegeKopf } from './EkPreisMassenPflegeKopf'
import { EkPreisMassenPflegeTable } from './EkPreisMassenPflegeTable'
import {
  EkPreiseMasseBearbeitenEintragExJson,
  useEkPreisMassenPflegeModel
} from './model/EkPreisMassenPflegeModel'
import { PreiseAbgeschlossenDialog } from '@one/components/common/PreiseAbgeschlossenDialog'

export const EkPreisMassenPflegeView = () => {
  const { id: _id } = useContext(RouteContext) as any
  const id = asNumber(_id)

  const { visible } = useContext(AppRouteCtx)

  const navigate = useNavigate()

  const [DlgAnker, showDlg] = useDialogAnker()

  const { askToConfirm } = useMessageDialog()

  const { enqueMsg } = useSnackbarEx()

  const {
    model,
    updateArtikelliste,
    updateModel,
    reload,
    remove,
    removeSelected,
    apiCall,
    apiBusy,
    uiLock
  } = useEkPreisMassenPflegeModel(id)

  const [selected, setSelected, getSelected] = useStateEx<
    Set<EkPreiseMasseBearbeitenEintragExJson>
  >(new Set())

  const { actionFreigeben, actionAbschliessen } = model

  const { isAllianz } = useContext(AppContext)

  const observerId = useObserver(
    EventNames.EKPREISLISTE,
    (event) => {
      const listeId = event.data?.id
      if (listeId && model?.id == listeId) {
        enqueMsg('Die Daten wurden außerhalb des Editors geändert!', 'warning')
        reload()
      }
    },
    visible
  )

  const notifyEkPreisListenEvent = useCallback(() => {
    notifyObservers({
      name: EventNames.EKPREISLISTE,
      origin: observerId,
      data: { id: model.id }
    })
  }, [model.id, observerId])

  const onChangePreis = useCallback(() => {
    showDlg((visible, onClose) => (
      <EkPreisMassenAenderDialog
        kopf={model}
        visible={visible}
        onClose={onClose}
        onComplete={(updated) => {
          updateArtikelliste(updated.artikelListe)
          notifyEkPreisListenEvent()
        }}
        selected={Array.from(getSelected())}
        allianzModus={isAllianz}
      />
    ))
  }, [showDlg, model, getSelected, isAllianz, updateArtikelliste, notifyEkPreisListenEvent])

  const onChangeArtikelRabattgruppe = useCallback(() => {
    showDlg((visible, onClose) => (
      <EkPreisMassenArtikelRabattgruppeDialog
        kopf={model}
        visible={visible}
        onClose={onClose}
        onComplete={(updated) => {
          updateArtikelliste(updated.artikelListe)
          notifyEkPreisListenEvent()
        }}
        selected={Array.from(getSelected())}
        allianzModus={isAllianz}
      />
    ))
  }, [showDlg, model, getSelected, isAllianz, updateArtikelliste, notifyEkPreisListenEvent])

  const onChangeAbgeschlossenStatus = useCallback(() => {
    showDlg((visible, onClose) => (
      <PreiseAbgeschlossenDialog
        listeId={model.id}
        preisEbeneId={model.preisEbeneId}
        visible={visible}
        onClose={onClose}
        onComplete={(updated) => {
          updateModel(updated)
          notifyEkPreisListenEvent()
        }}
        selected={Array.from(getSelected())}
        title="EK-Preis Massenänderung"
        rest="/ekpreisliste/executeaction"
      />
    ))
  }, [showDlg, model, getSelected, updateModel, notifyEkPreisListenEvent])

  const onFreigeben = useCallback(() => {
    showDlg((open, onClose) => (
      <EkPreisListeFreigabeDialog
        open={open}
        onClose={onClose}
        id={model.id}
        navigate={navigate}
        onSuccess={() => {
          reload()
          notifyEkPreisListenEvent()
        }}
      />
    ))
  }, [showDlg, model.id, navigate, reload, notifyEkPreisListenEvent])

  const onAlleAbschliessen = useCallback(() => {
    askToConfirm({
      title: 'Soll die Preisliste abgeschlossen werden?',
      message: 'Es werden alle Preise abgeschlossen und können dann freigegeben werden.',
      onConfirm: () => {
        apiCall({
          method: 'POST',
          rest: '/ekpreisliste/alleAbschliessen',
          params: { id: model.id },
          onErrorMsg: 'Die Preisliste konnte nicht abgeschlossen werden',
          onSuccessMsg: 'Die Preisliste wurde erfolgreich abgeschlossen.',
          onSuccess: () => {
            reload()
            notifyEkPreisListenEvent()
          }
        })
      }
    })
  }, [apiCall, askToConfirm, notifyEkPreisListenEvent, model.id, reload])

  const onDeleteClick = useCallback(
    () =>
      remove({
        onRemoved: () => {
          notifyEkPreisListenEvent()
          navigate(AppPaths.EkPreisUebersicht)
        }
      }),
    [notifyEkPreisListenEvent, navigate, remove]
  )

  const actions = model.freigegeben ? (
    <span>{`Die Preisliste wurde am ${formatDateTime(model.freigegebenUm)} freigegeben`}</span>
  ) : (
    <>
      {actionFreigeben && actionFreigeben.visible && (
        <Button
          label="Preisliste freigeben"
          tooltip={actionFreigeben.info}
          onClick={onFreigeben}
          variant="text"
          color="primary"
          disabled={actionFreigeben.enabled === false}
        />
      )}
      {actionAbschliessen && actionAbschliessen.visible && (
        <Button
          label="Preisliste abschließen"
          tooltip={actionAbschliessen.info}
          onClick={onAlleAbschliessen}
          variant="text"
          color="primary"
          disabled={actionAbschliessen.enabled === false}
        />
      )}
      <DeleteButton
        label="Liste löschen"
        onDelete={onDeleteClick}
        isNew={false}
        deleteMsg="Gesamte Preisliste wirklich löschen"
        tooltip="Gesamte Preisliste löschen"
      />
      <ReloadButton onClick={() => reload()} />
    </>
  )

  const onDeleteSelectedArtikel = useCallback(() => {
    if (model.artikelListe.length === getSelected().size) {
      onDeleteClick()
    } else {
      removeSelected({
        selected: Array.from(getSelected()).map((eintrag: any) => eintrag.id)
      })
    }
  }, [removeSelected, onDeleteClick, getSelected, model.artikelListe.length])

  const selectiodArr = Array.from(selected)
  const homogenePreisSelektion =
    selectiodArr.filter((row: any) => row.listenpreis == null).length === selectiodArr.length ||
    selectiodArr.filter((row: any) => row.listenpreis != null).length === selectiodArr.length
  const abgeschlossenSelektion = selectiodArr.find((row: any) => row.abgeschlossen) !== undefined
  const homogeneAbgeschlossenSelektion =
    selectiodArr.filter((row: any) => row.abgeschlossen).length === selectiodArr.length ||
    selectiodArr.filter((row: any) => !row.abgeschlossen).length === selectiodArr.length

  const bottomActions = useMemo(
    () =>
      [
        {
          text: 'EK Preise ändern',
          variant: 'contained',
          enabled: selected.size !== 0 && homogenePreisSelektion && !abgeschlossenSelektion,
          visible: !model.freigegeben,
          tooltip: abgeschlossenSelektion
            ? 'EK Preise können nicht geändert werden, da die Selektion abgeschlossene Preise enthält.'
            : homogenePreisSelektion
              ? null
              : 'Es können Artikel nur zusammen geändert werden, die alle mit oder alle ohne Listenpreis sind',
          onClick: onChangePreis
        },
        {
          text: 'Artikel Rabattgruppe ändern',
          variant: 'contained',
          enabled: selected.size !== 0,
          visible: !model.freigegeben,
          onClick: onChangeArtikelRabattgruppe
        },
        {
          text: 'Abgeschlossen ändern',
          variant: 'contained',
          enabled: selected.size !== 0 && homogeneAbgeschlossenSelektion,
          tooltip: homogeneAbgeschlossenSelektion
            ? null
            : 'Es können Artikel nur geändert werden, wenn alle abgeschlossen oder alle nicht abgeschlossen sind',
          visible: !model.freigegeben,
          onClick: onChangeAbgeschlossenStatus
        },
        <DeleteButton
          key="artikel-entfernen"
          //  className={classes.deleteButton}
          onDelete={onDeleteSelectedArtikel}
          disabled={actionFreigeben?.enabled}
          visible={!model.freigegeben}
          size="small"
          tooltip="Ausgewählte Artikel werden aus der Liste entfernt"
          isNew={!(selected.size !== 0 && homogenePreisSelektion)}
          deleteMsg="Möchten Sie alle gewählten Artikel wirklich aus dieser EK-Preisliste entfernen?"
        />,
        {
          text: 'Letzte Änderung rückgängig machen',
          variant: 'contained',
          enabled: false,
          visible: false // !model.readonly,
        }
      ] as Action[],
    [
      homogenePreisSelektion,
      homogeneAbgeschlossenSelektion,
      model.freigegeben,
      onChangePreis,
      selected.size,
      onDeleteSelectedArtikel,
      actionFreigeben,
      onChangeArtikelRabattgruppe,
      onChangeAbgeschlossenStatus,
      abgeschlossenSelektion
    ]
  )

  return (
    <StatePlane wait={apiBusy} uiLock={uiLock} altLink={AppPaths.EkPreisUebersicht}>
      <Frame space>
        <FrameRow>
          <EkPreisMassenPflegeKopf model={model} setPreisEbeneId={reload} />
        </FrameRow>
        <FrameBody>
          <EkPreisMassenPflegeTable
            selected={selected}
            preisEbeneId={model.preisEbeneId}
            onSelect={setSelected}
            reload={reload}
            value={model.artikelListe}
            bottomActions={bottomActions}
            preislisteId={model.id}
            editMode
            loading={apiBusy}
            readOnly={model.freigegeben}
            kondiDefinition={model.kondiDefinition}
          />
        </FrameBody>
        <FrameRow>
          <ButtonRow>{actions}</ButtonRow>
          <DlgAnker />
        </FrameRow>
      </Frame>
    </StatePlane>
  )
}
