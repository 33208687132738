import { Grid2 as Grid } from '@mui/material'
import { LieferantAboField } from '@one/components/LieferantAbo/LieferantAboField'
import { VkPreisListeSearcherCriteriaJson } from '@one/typings/apiTypings'
import { SearchButton } from '@utils/ui/Buttons/SearchButton'
import { CardEx } from '@utils/ui/CardEx'
import { ShortCutHandler } from '@utils/ui/ShortCutHandler'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { DateField } from '@utils/ui/fields/DateField'
import { TextField } from '@utils/ui/fields/TextField'

export type VkPreisUebersichtParamsProps = {
  value: VkPreisListeSearcherCriteriaJson
  onValueChange: (e: any) => void
  onSearch: (critertia?: VkPreisListeSearcherCriteriaJson) => void
  preislistenIdMode?: boolean
}

export const VkPreisUebersichtParams = ({
  value,
  onValueChange,
  onSearch,
  preislistenIdMode
}: VkPreisUebersichtParamsProps) => {
  return (
    <ShortCutHandler shortcuts={{ Enter: () => onSearch() }}>
      <CardEx
        backLink
        title={preislistenIdMode ? 'Angelegte VK-Preislisten' : 'Verkaufspreispflege'}
        subheader={
          preislistenIdMode
            ? 'Die Anlage für die zuvor gewählten Artikel hat je Hauptlieferant eine Vk-Preisliste erstellt'
            : null
        }
        collapsiable
        bottomActions={<SearchButton onClick={() => onSearch()} />}
      >
        {preislistenIdMode ? null : (
          <Grid container spacing={3}>
            <Grid size={{xs:12,sm:6,md:4,lg:3}}>
              <LieferantAboField
                value={value.lieferant}
                label="Lieferant"
                name="lieferant"
                onChange={onValueChange}
              />
            </Grid>
            <Grid size={{xs:12,sm:6,md:4,lg:3}}>
              <TextField
                fullWidth
                value={value.name}
                label="Name"
                name="name"
                onChange={onValueChange}
              />
            </Grid>
            <Grid size={{xs:12,sm:4,lg:2}}>
              <DateField
                label="Gültig von"
                disableToolbar
                value={value.gueltigVon}
                name="gueltigVon"
                onChange={onValueChange}
              />
            </Grid>
            <Grid size={{xs:12,sm:4,md:3,lg:2}}>
              <DateField
                label="Gültig bis"
                disableToolbar
                value={value.gueltigBis}
                name="gueltigBis"
                onChange={onValueChange}
              />
            </Grid>
            <Grid size={{xs:12,sm:4,lg:2}}>
              <Checkbox
                label="Auch freigegebene"
                size="small"
                checked={value.abgeschlossene}
                name="abgeschlossene"
                onChange={onValueChange}
              />
            </Grid>
          </Grid>
        )}
      </CardEx>
    </ShortCutHandler>
  )
}
