import { ArtikelAbonniertStatusEx } from '@one/components/ArtikelSelektion/model/ArtikelSelektionTypes'
import { ArtikelAbonniertStatus } from '@one/typings/apiTypings'
import { EnumTextGetter } from '@utils/enums'
import { Medal } from '@utils/ui/fields/Medal'
import { memo } from 'react'
import {
  getAbonniertStatusIcon,
  getArtikelAbonniertStatusColor,
  getArtikelAbonniertStatusText
} from './ArtikelAbonniertStatusSymbol'

export interface ArtikelAbonniertStatusMedalProps {
  value: ArtikelAbonniertStatusEx | ArtikelAbonniertStatus
  oldValue?: ArtikelAbonniertStatusEx
  et: EnumTextGetter
  darkMode: boolean
  andereHS?: boolean
  disabled?: boolean
  erpAusgelistent?: boolean
  onClick?: React.MouseEventHandler<HTMLDivElement>
  onDblClick?: React.MouseEventHandler<HTMLDivElement>
}

export const ArtikelAbonniertStatusMedal = memo(
  ({
    value,
    oldValue,
    et,
    andereHS,
    disabled,
    darkMode,
    onClick,
    onDblClick,
    erpAusgelistent
  }: ArtikelAbonniertStatusMedalProps) => {
    const backgroundColor =
      andereHS || erpAusgelistent
        ? 'rgba(200,200,200,0.4)'
        : getArtikelAbonniertStatusColor(value, darkMode)

    return (
      <Medal
        variant="square"
        text={getArtikelAbonniertStatusText(et, value, oldValue)}
        icon={getAbonniertStatusIcon(value)}
        backgroundColor={backgroundColor}
        onClick={!onClick || disabled || andereHS ? null : onClick}
        onDblClick={!onDblClick || disabled || andereHS ? null : onDblClick}
        placement="right-end"
      />
    )
  }
)

ArtikelAbonniertStatusMedal.displayName = 'ArtikelAbonniertStatusMedal'
