import { Grid2 as Grid } from '@mui/material'
import { useArtikelBetriebstypCache } from '@one/datacaches/useArtikelBetriebsTypeCache'
import { useNeonKontextCache } from '@one/datacaches/useNeonKontextCache'
import { ArtikelBetriebstyp, BetriebstypKontext, NeonKontext } from '@one/typings/apiTypings'
import { AppContext } from '@utils/ui/App/AppContext'
import { StaticField } from '@utils/ui/fields/StaticField'
import { zeroPad } from '@utils/utils'
import { useContext } from 'react'

export type BetriebstypKontextOwnerType = {
  [key: string]: any
  betriebstyp?: ArtikelBetriebstyp
  betriebsTyp?: ArtikelBetriebstyp
  kontext?: NeonKontext
}

export interface BetriebstypKontextFieldProps {
  owner?: BetriebstypKontextOwnerType | BetriebstypKontext
  betriebsTyp?: ArtikelBetriebstyp
  kontext?: NeonKontext
  prefix?: string
}

export const BetriebstypKontextField = ({
  owner,
  betriebsTyp,
  kontext,
  prefix
}: BetriebstypKontextFieldProps) => {
  const { isPIMModel } = useContext(AppContext)
  const { get: getBT } = useArtikelBetriebstypCache()
  const { get: getNK } = useNeonKontextCache()

  const format = () => {
    if (isPIMModel) {
      const typ = (kontext || owner?.kontext) && getNK(kontext || owner.kontext)
      return (
        typ && (
          <Grid container spacing={1} alignItems="center">
            <Grid>{zeroPad(typ?.kontextNr, 3)}</Grid>
            <Grid>
              <span>{typ?.kontextName}</span>
            </Grid>
            <Grid fontSize="70%">
              <span>{typ?.betriebstyp && `(BT ${typ?.betriebstypNr})`}</span>
            </Grid>
          </Grid>
        )
      )
    }
    const typ =
      (betriebsTyp || (owner && (owner.betriebstyp || owner['betriebsTyp']))) &&
      getBT(betriebsTyp || owner.betriebstyp || owner['betriebsTyp'])
    return (
      typ && (
        <span>{`${zeroPad(typ?.btNr, 3)}${typ?.bezeichnung ? ` - ${typ?.bezeichnung}` : ''}`}</span>
      )
    )
  }

  return (
    <StaticField label={`${prefix ?? ''}${isPIMModel ? 'Kontext' : 'Betriebstyp'}`}>
      {format()}
    </StaticField>
  )
}
