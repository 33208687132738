import { PimArtikelVersionKontextJson, SeArtikelJson } from '@one/typings/apiTypings'
import { CardEx } from '@utils/ui/CardEx'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { Grid2 as Grid } from '@mui/material'
import React from 'react'
import { ErrorsType, ValueChangeFn } from '@utils/modelmgr'
import { TextField } from '@utils/ui/fields/TextField'
import { Constraints } from '@one/typings/constraints'
import { NumberField } from '@utils/ui/fields/NumberField'

export interface PimArtikelKontextDatenProps {
  pimArtikelVersionKontext?: PimArtikelVersionKontextJson
  onChange?: ValueChangeFn<SeArtikelJson>
  // dispatch?: (action: pimArtikelVersionKontextAction) => void
  errors?: ErrorsType
  readonly?: boolean
}

export const PimArtikelKontextDaten = ({
  pimArtikelVersionKontext,
  onChange,
  errors,
  readonly
}: PimArtikelKontextDatenProps) => {
  return (
    <CardEx title="Kontext">
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 2 }}>
          <TextField
            label="Bt-Artikel-Nr."
            name="pimArtikel.versionKontext.btArtikelNr"
            value={pimArtikelVersionKontext.btArtikelNr}
            maxLength={Constraints.PimArtikelVersionKontext.btArtikelNr.maxSize}
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 2 }}>
          <TextField
            label="Bt-Warengruppe"
            value={pimArtikelVersionKontext.btWarengruppe}
            name="pimArtikel.versionKontext.btWarengruppe"
            maxLength={Constraints.PimArtikelVersionKontext.btWarengruppe.maxSize}
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 2 }}>
          <TextField
            label="Matchcode"
            name="pimArtikel.versionKontext.matchcode"
            value={pimArtikelVersionKontext.matchcode}
            maxLength={Constraints.PimArtikelVersionKontext.matchcode.maxSize}
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 2 }}>
          <TextField
            label="Artikelbeschreibung"
            value={pimArtikelVersionKontext.artikelbeschreibung}
            name="pimArtikel.versionKontext.artikelbeschreibung"
            maxLength={Constraints.PimArtikelVersionKontextSprache.artikelbeschreibung.maxSize}
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 2 }}>
          <TextField
            label="Fokus"
            value={pimArtikelVersionKontext.focus}
            name="pimArtikel.versionKontext.focus"
            maxLength={Constraints.PimArtikelVersionKontext.focus.maxSize}
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 2 }}>
          <TextField
            label="Preisrolle"
            value={pimArtikelVersionKontext.preisrolle}
            name="pimArtikel.versionKontext.preisrolle"
            maxLength={Constraints.PimArtikelVersionKontext.preisrolle.maxSize}
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 2 }}>
          <TextField
            label="Versandkostenklasse"
            value={pimArtikelVersionKontext.versandkostenklasse}
            name="pimArtikel.versionKontext.versandkostenklasse"
            maxLength={Constraints.PimArtikelVersionKontext.versandkostenklasse.maxSize}
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 2 }}>
          <NumberField
            label="Transportzeit"
            value={pimArtikelVersionKontext.transportzeit}
            name="pimArtikel.versionKontext.transportzeit"
            min={0}
            max={4294967295}
            fraction={0}
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 2 }}>
          <TextField
            label="Retourenfrist Online-Bestellung"
            value={pimArtikelVersionKontext.retourenfristOnlineBestellung}
            name="pimArtikel.versionKontext.retourenfristOnlineBestellung"
            maxLength={Constraints.PimArtikelVersionKontext.retourenfristOnlineBestellung.maxSize}
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 2 }}>
          <TextField
            label="Cross-Channel Variante Online"
            value={pimArtikelVersionKontext.crossChannelVariantenOnlineList?.join('\n')}
            name="pimArtikel.versionKontext.crossChannelVariantenOnlineList"
            multiline
            maxLength={
              Constraints.PimArtikelVersionKontextCrossChannelVarianteOnline
                .crossChannelVarianteOnline.maxSize
            }
            onChange={(e) => {
              const ee = {
                target: {
                  name: 'pimArtikel.versionKontext.crossChannelVariantenOnlineList',
                  value: e.target.value?.split('\n')
                }
              }
              console.log('EEEE', ee)
              onChange(ee)
            }}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 2 }}>
          <TextField
            label="Abweichende MwSt Endkunde-B2C"
            value={pimArtikelVersionKontext.abweichendeMwStEndkundeB2C}
            name="pimArtikel.versionKontext.abweichendeMwStEndkundeB2C"
            maxLength={Constraints.PimArtikelVersionKontext.abweichendeMwStEndkundeB2C.maxSize}
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 2 }}>
          <NumberField
            label="Co Auftrag"
            value={pimArtikelVersionKontext.coAuftrag}
            name="pimArtikel.versionKontext.coAuftrag"
            min={0}
            max={4294967295}
            fraction={0}
            onChange={onChange}
            disabled={readonly}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 2 }}>
          <Checkbox
            label="Kontext Aktiv"
            checked={pimArtikelVersionKontext.kontextAktiv}
            name="pimArtikel.versionKontext.kontextAktiv"
            onChange={onChange}
            disabled={readonly}
            indeterminate
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 2 }}>
          <Checkbox
            label="Online"
            checked={pimArtikelVersionKontext.online}
            name="pimArtikel.versionKontext.online"
            onChange={onChange}
            disabled={readonly}
            indeterminate
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 2 }}>
          <Checkbox
            label="Online Bestellbar Endkunde"
            checked={pimArtikelVersionKontext.onlineBestellbarEndkunde}
            name="pimArtikel.versionKontext.onlineBestellbarEndkunde"
            onChange={onChange}
            disabled={readonly}
            indeterminate
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 2 }}>
          <Checkbox
            label="Online Reservierfaehig Endkunde"
            checked={pimArtikelVersionKontext.onlineReservierfaehigEndkunde}
            name="pimArtikel.versionKontext.onlineReservierfaehigEndkunde"
            onChange={onChange}
            disabled={readonly}
            indeterminate
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 2 }}>
          <Checkbox
            label="OE Norm-Relevanz"
            checked={pimArtikelVersionKontext.oeNormRelevanz}
            name="pimArtikel.versionKontext.oeNormRelevanz"
            onChange={onChange}
            disabled={readonly}
            indeterminate
          />
        </Grid>
      </Grid>
    </CardEx>
  )
}

export const PimArtikelKontextDatenMemo = React.memo(PimArtikelKontextDaten)
