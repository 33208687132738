import { Grid2 as Grid } from '@mui/material'
import { AppPaths } from '@one/AppPaths'
import { GesellschafterJson } from '@one/typings/apiTypings'
import { ErrorsType, ValueChangeFn } from '@utils/modelmgr'
import { CardEx } from '@utils/ui/CardEx'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { TextField } from '@utils/ui/fields/TextField'

export interface OrganisationAnlageKopfProps {
  isNew: boolean
  errors: ErrorsType
  readonly: boolean
  onValueChange: ValueChangeFn<GesellschafterJson>
  model: GesellschafterJson
}

export const OrganisationAnlageKopf = ({
  isNew,
  errors,
  readonly,
  onValueChange,
  model
}: OrganisationAnlageKopfProps) => {
  return (
    <CardEx
      backLink
      overviewLink={AppPaths.artikelliste.ArtikelListeUebersicht}
      title={isNew ? 'Gesellschafter anlegen' : `Gesellschafter ${model.name || ''}`}
    >
      <Grid container spacing={2} direction="column">
        <Grid container direction="row" spacing={2}>
          <Grid size={{xs:12,sm:6,lg:3}}>
            <TextField
              name="name"
              label="Name"
              error={errors.name}
              value={model.name}
              onChange={onValueChange}
              fullWidth
              required
              disabled={readonly}
            />
          </Grid>
          <Grid size={{xs:12,sm:6,lg:3}}>
            <TextField
              name="gln"
              label="Gesellschafternr."
              error={errors.gln}
              value={model.gln}
              onChange={onValueChange}
              fullWidth
              required
              disabled={readonly}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={2}>
          <Grid size={{xs:12,sm:6,lg:3}}>
            <TextField
              name="nr"
              label="ERP Firmennr."
              error={errors.nr}
              value={model.nr}
              onChange={onValueChange}
              fullWidth
              required
              disabled={readonly}
            />
          </Grid>
          <Grid size={{xs:12,sm:6,lg:3}}>
            <Checkbox
              label="ist Einzelhändler"
              title={model.pimDatenmodell ? 'bei PIM-Datenmodell immer aktiv' : ''}
              name="einzlhandelAusPIM"
              checked={model.einzlhandelAusPIM}
              onChange={onValueChange}
              disabled={model.pimDatenmodell} // bei PIM-Datenmodell ist Einzelhändler laut Stammebene.java immer true
            />
          </Grid>
          <Grid size={{xs:12,sm:6,lg:3}}>
            <Checkbox
              label="kein Export VK-Preise für Coupon Artikel"
              title="Stellt ein, das beim ERP-Export keine VK-Preise für Coupon Artikel ausgegeben werden sollen."
              name="ohneExportVkPreisFuerCouponArtikel"
              checked={model.ohneExportVkPreisFuerCouponArtikel}
              onChange={onValueChange}
            />
          </Grid>
        </Grid>
      </Grid>
    </CardEx>
  )
}
