import { Grid2 as Grid, Typography } from '@mui/material'
import { AppPaths } from '@one/AppPaths'
import { api } from '@one/api'
import { UseCaseStateJson, ZeLieferantAnzeigenJson } from '@one/typings/apiTypings'
import { useApiCaller } from '@utils/apicaller'
import { Action } from '@utils/ui/Action'
import { RouteContext } from '@utils/ui/App/AppRoute'
import { ButtonRow } from '@utils/ui/Buttons/ButtonRow'
import { ReloadButton } from '@utils/ui/Buttons/ReloadButton'
import { CardEx } from '@utils/ui/CardEx'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { HistoricDataPager } from '@utils/ui/HistoricDataPager'
import { IndexPanel, IndexPanelItem } from '@utils/ui/IndexPanel'
import { StaticField } from '@utils/ui/fields/StaticField'
import { StatePlane } from '@utils/ui/planes/StatePlane'
import { asNumber } from '@utils/utils'
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { LieferantAdresse } from './details/LieferantAdresse'
import { LieferantBezeichnung } from './details/LieferantBezeichnung'
import { LieferantenLieferbedingung } from './details/LieferantenLieferbedingung'

export const ZeLieferantView = () => {
  const { id: _id, revision: _revision } = useContext(RouteContext) as any
  const id = asNumber(_id)
  const revision = asNumber(null)
  const navigate = useNavigate()

  const [error, setError] = useState<UseCaseStateJson>()
  const [lieferantData, setLieferantData] = useState<ZeLieferantAnzeigenJson>()
  const [currentRevision, setCurrentRevision] = useState(revision)

  const lieferant = lieferantData?.lieferant

  const [apiCall, apiBusy] = useApiCaller(api)

  const fetchData = useCallback(() => {
    apiCall<ZeLieferantAnzeigenJson>({
      method: 'GET',
      rest: `/lieferant/ze/open`,
      params: { id, revision },
      onErrorMsg: `Lieferant mit ID "${id}" konnte nicht geladen werden.`,
      onError: (err) => setError(err),
      onSuccess: (data) => {
        setLieferantData(data)
        if (data.revisions) {
          if (revision) {
            const current = data.revisions.find((item) => item.id == revision)
            setCurrentRevision(current?.id)
          } else {
            const current = data.revisions.find((item) => item.bis == null)
            setCurrentRevision(current?.id)
          }
        }
      }
    })
  }, [apiCall, id, revision])

  const lastIdRef = useRef<any>(undefined)
  useEffect(() => {
    if (id !== lastIdRef.current) {
      lastIdRef.current = id
      fetchData()
    }
  }, [fetchData, id])

  const reload = useCallback(() => fetchData(), [fetchData])

  const topActions = useMemo(
    () =>
      [
        {
          navlink:
            lieferantData?.dzLieferantId && AppPaths.LieferantFn(lieferantData.dzLieferantId),
          text: `Zum ERP-Lieferant`,
          visible: lieferantData?.dzLieferantId != null
        }
      ].filter(Boolean) as Action[],
    [lieferantData?.dzLieferantId]
  )

  const historicNavigate = useCallback(
    (revision) => {
      navigate(AppPaths.LieferantFn(id, revision.id))
    },
    [id, navigate]
  )

  const header = useMemo(
    () => (
      <Grid container spacing={3}>
        <Grid>
          <Typography variant="h6">{`Listungs-Lieferant "${
            lieferant?.hauptAdresse ? lieferant?.hauptAdresse?.name1 : ''
          }"`}</Typography>
        </Grid>
        <Grid>
          <HistoricDataPager
            revisions={lieferantData?.revisions}
            currentRevision={currentRevision}
            to={historicNavigate}
          />
        </Grid>
      </Grid>
    ),
    [lieferant?.hauptAdresse, lieferantData?.revisions, currentRevision, historicNavigate]
  )

  const content = [
    {
      id: 'bezeichnung',
      label: 'Lieferant',
      exclude: lieferant == null,
      body: () => <LieferantBezeichnung lieferant={lieferant} type="ze" />
    },
    {
      id: 'hadresse',
      label: 'Hauptadresse',
      exclude: lieferant?.hauptAdresse == null,
      body: () => <LieferantAdresse adresse={lieferant?.hauptAdresse} label="Hauptadresse" />
    },
    {
      id: 'aadresse',
      label: 'Abholadresse',
      exclude: lieferant?.abholAdresse == null,
      body: () => <LieferantAdresse adresse={lieferant?.abholAdresse} label="Abholadresse" />
    },
    {
      id: 'sperren',
      label: 'Sperren',
      exclude: lieferant == null,
      body: () => (
        <CardEx title="Sperren">
          <Grid container spacing={2}>
            <Grid size={{ xs: 4 }}>
              <StaticField label="Gelöscht" value={lieferant.geloescht ? 'Ja' : 'Nein'} />
            </Grid>
            <Grid size={{ xs: 4 }}>
              <StaticField label="Gesperrt" value={lieferant.gesperrt ? 'Ja' : 'Nein'} />
            </Grid>
          </Grid>
        </CardEx>
      )
    },
    {
      id: 'lieferbedingungen',
      label: 'Lieferbedingungen',
      exclude: lieferant == null,
      body: () => <LieferantenLieferbedingung lieferant={lieferant} />
    }
  ] as IndexPanelItem[]

  return (
    <StatePlane wait={apiBusy} error={error}>
      <Frame space>
        <FrameRow>
          <CardEx
            backLink
            overviewLink={AppPaths.LieferantUebersicht}
            topActions={topActions}
            header={header}
            contentStyle={{ paddingBottom: 0 }}
          />
        </FrameRow>
        <FrameBody>
          <IndexPanel content={content} />
        </FrameBody>
        <FrameRow>
          <ButtonRow>
            <ReloadButton onClick={reload} />
          </ButtonRow>
        </FrameRow>
      </Frame>
    </StatePlane>
  )
}
