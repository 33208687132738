import { Grid2 as Grid } from '@mui/material'
import { UserRoles } from '@one/UserRoles'
import { MapperEinstellungElementJson } from '@one/typings/apiTypings'
import { Action } from '@utils/ui/Action'
import { Column } from '@utils/ui/DataTable/DataTable'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { useMemo } from 'react'

export interface ArtikelLieferantSyncTemplatePflegeTabelleViewProps {
  elemente: MapperEinstellungElementJson[]
  readOnly: boolean
  groupMap: Map<string, boolean>
  onValueChange: (event: any) => void
  toggleAll: (value: boolean) => void
  toggleGroup: (groupName: string) => void
}

export const ArtikelLieferantSyncTemplatePflegeTabelleView = ({
  onValueChange,
  toggleAll,
  toggleGroup,
  elemente,
  groupMap,
  readOnly
}: ArtikelLieferantSyncTemplatePflegeTabelleViewProps) => {
  const columns = useMemo<Column<MapperEinstellungElementJson>[]>(
    () => [
      {
        field: 'needMap',
        sortable: false,
        header: 'Benötigt',
        align: 'center',
        width: '2rem',
        body: (row) => {
          return (
            <Checkbox
              compact
              name={`elemente.[attributeId=${row.attributeId}].needMap`}
              checked={row.needMap}
              onChange={onValueChange}
              disabled={readOnly || !row.optional || row.editInGruppe}
            />
          )
        }
      },
      {
        field: 'mapperName',
        sortable: true,
        header: 'Mapper',
        width: '10rem'
      },
      {
        field: 'name',
        sortable: true,
        header: 'Name',
        width: '10rem'
      },
      {
        field: 'erpName',
        sortable: true,
        header: 'ERP-Feldname',
        width: '10rem'
      },
      {
        field: 'groupName',
        sortable: true,
        header: 'Gruppenname',
        width: '10rem'
      }
    ],
    [onValueChange, readOnly]
  )

  const actions = useMemo(
    () =>
      !readOnly &&
      ([
        {
          role: UserRoles.STAMMDATEN_EDITOR,
          text: 'Alle Auswählen',
          onClick: () => toggleAll(true)
        },
        {
          role: UserRoles.STAMMDATEN_EDITOR,
          text: 'Alle Abwählen',
          onClick: () => toggleAll(false)
        }
      ] as Action[]),
    [readOnly, toggleAll]
  )

  const groupBy = useMemo(
    () => ({
      field: 'groupName',
      groupEmpty: false,
      body: (row: MapperEinstellungElementJson) =>
        row.groupName ? (
          <Grid container spacing={1} alignItems="center" padding={2}>
            <Grid>
              <Checkbox
                compact
                checked={groupMap.get(row.groupName)}
                onChange={() => toggleGroup(row.groupName)}
                disabled={readOnly}
              />
            </Grid>
            <Grid>
              <span>{`Gruppe "${row.groupName}"`}</span>
            </Grid>
          </Grid>
        ) : (
          ''
        )
    }),
    [groupMap, readOnly, toggleGroup]
  )

  return (
    <DataTableCard
      name="ArtikelLieferantSyncTemplatePflegeTabelleView"
      title="Mappings"
      filterMode="both"
      columns={columns}
      value={elemente}
      size="slim"
      selectMode="single"
      selectCol={false}
      initialOrderBy={['mapperName', 'name']}
      bottomActions={actions}
      groupBy={groupBy}
      localStateName="ArtikelLieferantSyncTemplatePflegeTabelleView"
      rowFiller
    />
  )
}
