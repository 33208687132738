import { Grid2 as Grid } from '@mui/material'
import { AppPaths } from '@one/AppPaths'
import { LieferantField } from '@one/components/Lieferant/LieferantField'
import { EkRabattgruppenBearbeitenJson } from '@one/typings/apiTypings'
import { ErrorsType, ValueChangeFn } from '@utils/modelmgr'
import { CardEx } from '@utils/ui/CardEx'
import { TextField } from '@utils/ui/fields/TextField'

export interface EkRabattgruppenPflegeKopfProps {
  model: EkRabattgruppenBearbeitenJson
  errors: ErrorsType
  onValueChanges: ValueChangeFn<any>
  readonly?: boolean
}

export const EkRabattgruppenPflegeKopf = ({
  model,
  onValueChanges,
  errors,
  readonly = true
}: EkRabattgruppenPflegeKopfProps) => {
  const kopf = model?.rabattgruppe?.kopf
  const lieferant = model?.rabattgruppe?.lieferant || null

  return (
    <CardEx
      backLink
      overviewLink={AppPaths.EkRabattgruppenUebersicht}
      title={`EK-Rabattgruppe ${readonly ? kopf?.bezeichnung || kopf?.name : 'anlegen'}`}
    >
      <Grid container spacing={2}>
        <Grid size={{xs:6,md:4,lg:2}}>
          <TextField
            fullWidth
            required
            label="Name"
            name="rabattgruppe.kopf.name"
            value={kopf?.name}
            onChange={onValueChanges}
            disabled={readonly}
            error={errors.name}
          />
        </Grid>
        <Grid size={{xs:6,md:4,lg:2}}>
          <TextField
            fullWidth
            label="Bezeichnung"
            name="rabattgruppe.kopf.bezeichnung"
            value={kopf?.bezeichnung}
            onChange={onValueChanges}
            disabled={readonly}
            error={errors.bezeichnung}
          />
        </Grid>
        <Grid size={{xs:6,md:4,lg:2}}>
          <LieferantField
            disabled
            disableActions
            value={lieferant}
            label="Lieferant"
            name="lieferant"
            fullWidth
            asLink
            modus="dezentral"
          />
        </Grid>
      </Grid>
    </CardEx>
  )
}
