import { Grid2 as Grid } from '@mui/material'
import { AppPaths } from '@one/AppPaths'
import { UserRoles } from '@one/UserRoles'
import { ErpStammdatenExportUebersichtJson } from '@one/typings/apiTypings'
import { formatDateTime } from '@utils/dateutils'
import { ActionItem } from '@utils/ui/Action'
import { Button } from '@utils/ui/Buttons/Button'
import { CardEx } from '@utils/ui/CardEx'
import { StaticField } from '@utils/ui/fields/StaticField'

export interface ExportToErpParamsProps {
  onRefresh: () => void
  data?: ErpStammdatenExportUebersichtJson
}

export const ExportToErpParams = ({ onRefresh, data }: ExportToErpParamsProps) => {
  const actions = [
    {
      role: UserRoles.STAMMDATEN_EDITOR,
      tooltip: 'Ausführungsprotokoll anzeigen',
      navlink: AppPaths.ErpExportsUebersicht,
      text: 'Protokoll',
      variant: 'outlined'
    }
  ] as ActionItem[]

  return (
    <CardEx
      title="ERP Stammdatenexport durchführen"
      backLink
      collapsiable
      topActions={actions}
      bottomActions={[
        <Button key="refresh" color="primary" label="Aktualisieren" onClick={onRefresh} />
      ]}
    >
      <Grid container spacing={3}>
        <Grid flexGrow={1}>
          <StaticField fullWidth label="Exportdetails" value={data.info} />
        </Grid>
        {data.automatischerExport && data.nextExportTime && (
          <Grid>
            <StaticField
              fullWidth
              label="Automatischer Export um"
              value={formatDateTime(data.nextExportTime)}
            />
          </Grid>
        )}
      </Grid>
    </CardEx>
  )
}
