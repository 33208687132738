/* eslint-disable react/jsx-props-no-spreading */
import {Business, BusinessOutlined, Folder, LeaderboardOutlined} from '@mui/icons-material'
import {ChipContainer} from '@utils/ui/fields/ChipContainer'
import {TreeSelectField} from '@utils/ui/fields/TreeSelectField'

export const VerteilungsZielSel = ({
  label = null,
  name = null,
  value = null,
  options = null,
  placeholder = 'Wählen Sie ein oder mehr Standorte aus',
  onChange = null,
  disabled = false,
  fullWidth = false,
  required = false,
  error = null
}) => {
  return (
    <TreeSelectField
      label={label}
      name={name}
      value={value}
      disabled={disabled}
      onChange={onChange}
      options={options}
      multiSelect
      getOptionIcon={(o: any) => (o.gruppe ? <Folder /> : <Business />)}
      getOptionTip={(o: any) => (o.gruppe ? 'Firmengruppe' : 'Firma')}
      getOptionItems={(o: any) => o.items}
      getOptionLabel={(o: any) => (o.gruppe ? `${o.name} (${o.items?.length || 0})` : o.name)}
      getOptionValue={(o: any) => o.id}
      placeholder={placeholder}
      fullWidth={fullWidth}
      required={required}
      error={error}
    />
  )
}

export const VerteilungsZieleField = ({
  label = null,
  name = null,
  value = null,
  fullWidth = false
}) => {
  return (
    <ChipContainer
      // label={label}
      // name={name}
      value={value}
      getValueIcon={(o: any) =>
        o.gruppe ? (
          <LeaderboardOutlined style={{ verticalAlign: 'sub' }} fontSize="small" />
        ) : (
          <BusinessOutlined style={{ verticalAlign: 'sub' }} fontSize="small" />
        )
      }
      getValueTip={(o: any) => (o.gruppe ? 'Firmengruppe' : 'Firma')}
      // getValueItems={(o: any) => o.items}
      getValueLabel={(o: any) => (o.gruppe ? `${o.name} (${o.items?.length || 0})` : o.name)}
      fullWidth={fullWidth}
    />
  )
}
