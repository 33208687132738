import { PimArtikelVersionGtinJson } from '@one/typings/apiTypings'
import { CardEx } from '@utils/ui/CardEx'
import { Column, DataTable } from '@utils/ui/DataTable/DataTable'
import { ScrollPanel } from '@utils/ui/ScrollPanel'

export interface ArtikelPimZusatzEANsProps {
  zusatzEANs: PimArtikelVersionGtinJson[]
}

export const ArtikelPimZusatzEANs = ({ zusatzEANs }: ArtikelPimZusatzEANsProps) => {
  const column: Column<PimArtikelVersionGtinJson>[] = [
    {
      field: 'gtin',
      header: 'EAN'
    },
    {
      field: 'geloescht',
      header: 'gelöscht'
    }
  ]

  return (
    <CardEx title="Zusatz EAN" height="100%" name="ZusatzEANs">
      <ScrollPanel autoHeight autoHeightMax="600px">
        <DataTable
          name="PimZusatzEANsTabelle"
          value={zusatzEANs}
          columns={column}
          emptyMessage="Keine Daten"
          dense
          rowFiller
        />
      </ScrollPanel>
    </CardEx>
  )
}
