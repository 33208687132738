import { Grid2 as Grid } from '@mui/material'
import { api } from '@one/api'
import { DatabaseIndexStatistics, DatabaseStatisticsJson } from '@one/typings/apiTypings'
import { useApiCaller } from '@utils/apicaller'
import { formatNumber } from '@utils/numberutils'
import { Column } from '@utils/ui/DataTable/DataTable'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { ScrollPanel } from '@utils/ui/ScrollPanel'
import { NumberField } from '@utils/ui/fields/NumberField'
import { TextField } from '@utils/ui/fields/TextField'
import { StatePlane } from '@utils/ui/planes/StatePlane'
import { formatBytes } from '@utils/utils'
import { useCallback, useEffect, useState } from 'react'
import { AdminViewPanel } from './AdminViewPanel'

export type AVStatisticsProps = {
  zoomed: boolean
  setZoomed: () => void
}

const tableColumns = [
  {
    field: 'name',
    header: 'Tabelle',
    type: 'string'
  },
  {
    field: 'zeilen',
    header: 'Zeilen',
    type: 'number',
    valueGetter: (row) => row.value[1],
    body: (row) => formatNumber(row.value[1])
  },
  {
    field: 'bytes',
    header: 'Größe',
    type: 'number',
    valueGetter: (row) => row.value[0],
    body: (row) => formatBytes(row.value[0])
  }
] as Column[]

const indexColumns = [
  {
    field: 'indexName',
    header: 'Index',
    type: 'string'
  },
  {
    field: 'tableName',
    header: 'Tabelle',
    type: 'string'
  },
  {
    field: 'blevel',
    header: 'blevel',
    type: 'number'
  },
  {
    field: 'leafBlocks',
    header: 'leafBlocks',
    type: 'number'
  },
  {
    field: 'lastAnalyzed',
    header: 'lastAnalyzed',
    type: 'datetimesecond'
  }
] as Column<DatabaseIndexStatistics>[]

export const AVStatistics = ({ zoomed, setZoomed }: AVStatisticsProps) => {
  const [apiCall, apiBusy] = useApiCaller(api)
  const [statistics, setStatistics] = useState<DatabaseStatisticsJson>()

  const sizes = statistics?.datenbankTableSizes
    ? Object.keys(statistics.datenbankTableSizes).map((key) => ({
        name: key,
        value: statistics.datenbankTableSizes[key]
      }))
    : []

  const fetchStatistics = useCallback(() => {
    apiCall<DatabaseStatisticsJson>({
      method: 'GET',
      rest: 'admin/dbstatistics',
      params: { all: zoomed },
      onSuccess: (data) => setStatistics(data)
    })
  }, [apiCall, zoomed])

  useEffect(() => {
    fetchStatistics()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AdminViewPanel zoomed={zoomed} setZoomed={setZoomed} title="Statistiken">
      <StatePlane wait={apiBusy}>
        <ScrollPanel autoHeight={!zoomed}>
          <Grid container spacing={2} direction="column">
            <Grid container spacing={2}>
              <Grid size={{xs:4,lg:1}}>
                <TextField
                  label="Datenbankgröße"
                  value={formatBytes(statistics?.datenbankMB * 1024 * 1024)}
                  align="right"
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid size={{xs:4,lg:2}}>
                <NumberField
                  label="Datenbanksitzungen"
                  value={statistics?.datenbankSession}
                  disabled
                  fullWidth
                  thousandSeparator
                  fraction={0}
                />
              </Grid>
              <Grid size={{xs:12,lg:5}}>
                <TextField
                  label="Datenbankversion"
                  value={statistics?.datenbankVersion}
                  disabled
                  fullWidth
                />
              </Grid>
              <Grid size={{xs:4,lg:2}}>
                <NumberField
                  label="Anzahl Benutzer"
                  value={statistics?.anzBenutzer}
                  disabled
                  fullWidth
                  thousandSeparator
                  fraction={0}
                />
              </Grid>
              <Grid size={{xs:4,lg:2}}>
                <NumberField
                  label="Anmeldungen Heute"
                  value={statistics?.anzLoginsHeute}
                  disabled
                  fullWidth
                  thousandSeparator
                  fraction={0}
                />
              </Grid>
              <Grid size={{xs:4,lg:1}}>
                <NumberField
                  label="Orgsanisation"
                  value={statistics?.anzOrganisationen}
                  disabled
                  fullWidth
                  thousandSeparator
                  fraction={0}
                />
              </Grid>
              <Grid size={{xs:4,lg:1}}>
                <NumberField
                  label="Firmen"
                  value={statistics?.anzFirmen}
                  disabled
                  fullWidth
                  thousandSeparator
                  fraction={0}
                />
              </Grid>
              <Grid size={{xs:4,lg:1}}>
                <NumberField
                  label="Listungsartikel"
                  value={statistics?.anzListungsArtikel}
                  disabled
                  fullWidth
                  thousandSeparator
                  fraction={0}
                />
              </Grid>
              <Grid size={{xs:4,lg:1}}>
                <NumberField
                  label="Listungslieferanten"
                  value={statistics?.anzListungsLieferanten}
                  disabled
                  fullWidth
                  thousandSeparator
                  fraction={0}
                />
              </Grid>
              <Grid size={{xs:4,lg:1}}>
                <NumberField
                  label="ERP Artikel"
                  value={statistics?.anzErpArtikel}
                  disabled
                  fullWidth
                  thousandSeparator
                  fraction={0}
                />
              </Grid>
              <Grid size={{xs:4,lg:1}}>
                <NumberField
                  label="ERP Lieferanten"
                  value={statistics?.anzErpLieferanten}
                  disabled
                  fullWidth
                  thousandSeparator
                  fraction={0}
                />
              </Grid>
              <Grid size={{xs:4,lg:1}}>
                <NumberField
                  label="Artikelselektionen"
                  value={statistics?.anzErpArtikelSelektionen}
                  disabled
                  fullWidth
                  thousandSeparator
                  fraction={0}
                />
              </Grid>
            </Grid>
            {zoomed && (
              <Grid>
                <DataTableCard
                  value={sizes}
                  title="Tabellengrößen"
                  name="datatablesizes"
                  columns={tableColumns}
                  height="300px"
                  dense
                  rowFiller="100%"
                  filterMode="both"
                  borderless
                  initialOrderBy={[
                    { key: 'zeilen', dir: 'desc' },
                    { key: 'bytes', dir: 'desc' }
                  ]}
                />
              </Grid>
            )}
            {zoomed && (
              <Grid flexGrow={1}>
                <DataTableCard
                  value={statistics?.datenbankIndexes}
                  title="Indexe"
                  name="datatableindexe"
                  columns={indexColumns}
                  height="300px"
                  dense
                  rowFiller="100%"
                  filterMode="both"
                  borderless
                />
              </Grid>
            )}
          </Grid>
        </ScrollPanel>
      </StatePlane>
    </AdminViewPanel>
  )
}
