import { useEffect, useRef, useState } from 'react'
import isEqual from 'react-fast-compare'
import { EventNames } from './EventNames'
import { api } from './api'
import { PulseJson } from './typings/apiTypings'
import { apiCreateAbort } from './utils/apicaller'
import { debugLog } from './utils/logging'
import { notifyObservers, useObserver } from './utils/observer'
import { isSeverityError } from './utils/utils'

type HeartbeatState = {
  timer: any
  lastUserAction: number
  last: any
  exportSyncInfo: any
  importSchedulerState: any
  syncSeq: number
  aborterController: AbortController
}

export const useHeartbeat = (): void => {
  const [state, setState] = useState({
    timeoutOk: null,
    timeoutErr: null,
    timeoutUser: null
  })

  const observerId = useObserver<any>(EventNames.SESSION_CONNECT, ({ data }) => {
    const next = {
      timeoutOk: data?.ptoOk,
      timeoutErr: data?.ptoErr,
      timeoutUser: data?.ptoUser
    }
    debugLog('heartbeat config', next)
    setState(next)
  })

  const meRef = useRef<HeartbeatState>(undefined)

  useEffect(() => {
    if (state.timeoutOk == null) {
      debugLog('heartbeat off')
      return () => {}
    }

    debugLog('heartbeat init')
    meRef.current = {
      timer: null,
      lastUserAction: Date.now(),
      last: undefined,
      exportSyncInfo: undefined,
      importSchedulerState: undefined,
      syncSeq: undefined,
      aborterController: apiCreateAbort()
    }

    const me = meRef.current

    // Nur für Entwicklung, wenn durch Änderung von Dateien die Komponente neu gerendert wird
    const isMeValid = () => me === meRef.current

    const pollOk = () =>
      isMeValid() && state.timeoutUser && Date.now() - me.lastUserAction < state.timeoutUser

    const sendEvent = (data: PulseJson, status: number) => {
      if (!isEqual(data, me.last)) {
        me.last = data
        if (status) {
          if (status === 504) {
            notifyObservers({
              origin: observerId,
              name: EventNames.PULSE_ERROR,
              data: 'Server nicht erreichbar'
            })
          }
          notifyObservers({ origin: observerId, name: EventNames.PULSE_ERROR, data })
        } else if (isSeverityError(data.state?.mainMessage?.severity)) {
          notifyObservers({
            origin: observerId,
            name: EventNames.PULSE_ERROR,
            data: data.state.mainMessage
          })
        } else {
          if (!isEqual(me.exportSyncInfo, data.exportSyncInfo)) {
            me.exportSyncInfo = data.exportSyncInfo
            notifyObservers({
              origin: observerId,
              name: EventNames.PULSE_EXPORTSTATUS,
              data: data.exportSyncInfo
            })
          }
          if (!isEqual(me.importSchedulerState, data.importSchedulerState)) {
            me.importSchedulerState = data.importSchedulerState
            notifyObservers({
              origin: observerId,
              name: EventNames.PULSE_IMPORTSCHEDULERSTATUS,
              data: data.importSchedulerState
            })
          }
        }
      }
    }

    const onHeartbeat = () => {
      api
        .get(me.syncSeq ? `heartbeat?syncSeq=${me.syncSeq}` : 'heartbeat', {
          signal: me.aborterController.signal
        })
        .then((response) => {
          if (isMeValid()) {
            const data = response.data as PulseJson
            sendEvent(data, null)
            me.syncSeq = data.timestamp
            if (pollOk()) {
              me.timer = setTimeout(onHeartbeat, state.timeoutOk || 2000)
            }
          }
        })
        .catch((error) => {
          if (isMeValid()) {
            const status = error?.response?.status
            me.syncSeq = null
            sendEvent(null, status)
            if (status === 401 || status === 403 || state.timeoutErr == null) {
              me.timer = null
            } else {
              if (pollOk()) {
                me.timer = setTimeout(onHeartbeat, state.timeoutErr || 20000)
              }
            }
          }
        })
    }

    const interceptor = api.interceptors.response.use((response: any) => {
      if (isMeValid() && me.timer == null && state.timeoutOk != null) {
        me.timer = setTimeout(onHeartbeat, 1000)
      }

      return response
    })

    const handleEvent = () => {
      if (isMeValid && state.timeoutUser != null && !pollOk()) {
        me.timer = setTimeout(onHeartbeat, 1000)
      }
      me.lastUserAction = Date.now()
    }

    window.document.addEventListener('keydown', handleEvent, true)
    window.document.addEventListener('mousemove', handleEvent, true)

    me.timer = setTimeout(onHeartbeat, 1000)

    return () => {
      clearTimeout(me.timer)
      me.aborterController.abort()
      me.timer = null
      api.interceptors.request.eject(interceptor)
      window.document.removeEventListener('keydown', handleEvent)
      window.document.removeEventListener('mousemove', handleEvent)
    }
  }, [observerId, state.timeoutErr, state.timeoutOk, state.timeoutUser])
}
