import { Grid2 as Grid } from '@mui/material'
import { LieferantField } from '@one/components/Lieferant/LieferantField'
import { ArtikelAboSearcherArtikelAboCriteriaJson } from '@one/typings/apiTypings'
import { ChangeFunction } from '@utils/formstate'
import { SearchButton } from '@utils/ui/Buttons/SearchButton'
import { CardEx } from '@utils/ui/CardEx'
import { ShortCutHandler } from '@utils/ui/ShortCutHandler'
import { TextField } from '@utils/ui/fields/TextField'

export interface ArtikelAboUebersichtParamsProps {
  value: ArtikelAboSearcherArtikelAboCriteriaJson
  onSearch: VoidFunction
  onChange: ChangeFunction<ArtikelAboSearcherArtikelAboCriteriaJson>
}

export const ArtikelAboUebersichtParams = ({
  value,
  onSearch,
  onChange
}: ArtikelAboUebersichtParamsProps) => {
  const shortcuts = {
    Enter: onSearch
  }

  return (
    <ShortCutHandler shortcuts={shortcuts}>
      <CardEx
        title="Artikelabopflege"
        backLink
        collapsiable
        bottomActions={<SearchButton onClickVoid={onSearch} />}
      >
        <Grid container spacing={3}>
          <Grid size={{xs:6,md:4,lg:2}}>
            <TextField
              fullWidth
              label="Abo-Name"
              name="name"
              value={value.name}
              onChange={onChange}
            />
          </Grid>
          <Grid size={{xs:6,md:5,lg:3}}>
            <LieferantField
              label="Lieferant"
              value={value.lieferant}
              name="lieferant"
              onChange={onChange}
              fullWidth
              modus="alle"
            />
          </Grid>
        </Grid>
      </CardEx>
    </ShortCutHandler>
  )
}
