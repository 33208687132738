import { Grid2 as Grid } from '@mui/material'
import { ArtikelAbonniertStatus, ErpArtikelDetailsJson } from '@one/typings/apiTypings'
import { CardEx } from '@utils/ui/CardEx'
import { StaticField } from '@utils/ui/fields/StaticField'

export interface ArtikelErpHauptlieferantProps {
  details: ErpArtikelDetailsJson
}

export const ArtikelErpHauptlieferant = ({ details }: ArtikelErpHauptlieferantProps) => {
  if (!details) {
    return null
  }

  const { hauptLieferant } = details

  return (
    <CardEx title="Hauptlieferant">
      <Grid container spacing={2}>
        <Grid size={{ xs: 12 }}>
          <StaticField label="Hauptlieferant">
            {hauptLieferant ? (
              // <Link to={AppPaths.LieferantFn(hauptlieferantId.id)}>{ wg abo/zentral anzupassen....
              <>
                {hauptLieferant.nummer} {hauptLieferant.name1}
              </>
            ) : // }</Link>
            details.aboStatus === ArtikelAbonniertStatus.UNTERBROCHEN ? (
              'Abo Unterbrochen'
            ) : (
              'Kein Hauptlieferant'
            )}
          </StaticField>
        </Grid>
      </Grid>
    </CardEx>
  )
}
