/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/control-has-associated-label */
import {CircularProgress} from '@mui/material'
import {FieldCtrlType} from '@utils/ui/FieldCtrlType'
import {ThemeContext} from '@utils/ui/Theme'
import {ifString} from '@utils/utils'
import clsx from 'clsx'
//import {Jodit} from 'jodit-react'
import {lazy, Suspense, useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react'
import {makeStyles} from 'tss-react/mui'
import {FormField} from './FormField'
import {StaticHtml, useHtmlStyles} from './StaticHtml'

const JoditEditor = lazy(() => import('jodit-react'))

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles()({
  root: {
    width: '100%',
    height: '100%',
    '& .jodit-react-container': {
      height: '100%'
    },
    '& .jodit-editor__resize': {
      display: 'none'
    }
  },
  labeldist: {
    paddingTop: '16px'
  },
  h100: {
    height: '100%'
  },
  jodit: {
    height: '100%!important'
  }
})

export type EditorFieldProps = {
  label?: string
  name?: any
  value?: any
  onChange?: (e: any) => void
  fullHeight?: boolean
  fullWidth?: boolean
  autoScroll?: boolean
  error?: any
  helperText?: string
  disabled?: boolean
  required?: boolean
  fieldCtrl?: FieldCtrlType
  placeholder?: string
}

export const EditorField = ({
  label,
  name,
  value,
  onChange,
  fullHeight,
  fullWidth,
  autoScroll,
  error,
  helperText,
  disabled,
  required,
  fieldCtrl,
  placeholder
}: EditorFieldProps) => {
  const { classes } = useStyles()
  const { classes: htmlClasses } = useHtmlStyles()

  const { darkMode } = useContext(ThemeContext)

  const editorRef = useRef(undefined)

  const [html, setHtml] = useState(value)

  const lastValueRef = useRef(undefined)
  useEffect(() => {
    if (lastValueRef.current !== value) {
      lastValueRef.current = value
      setHtml(value || '')
    }
  }, [value])

  const onChangeInt = useCallback(
    (text) => {
      if (onChange) {
        setHtml(text)
        lastValueRef.current = text
        onChange({ name, value: text })
      }
    },
    [name, onChange]
  )

  const config = useMemo<any>(
    () => ({
      readonly: false,
      placeholder,
      height: '100%',
      statusbar: false,
      removeButtons: ['fullsize'],
      theme: darkMode ? 'dark' : null,
      enableDragAndDropFileToEditor: false,
      editorClassName: htmlClasses.html
    }),
    [placeholder, darkMode, htmlClasses.html]
  )
  return (
    <FormField
      label={label}
      fullWidth={fullWidth || autoScroll}
      className={fullHeight ? classes.h100 : null}
      error={!!error || fieldCtrl?.error}
      helperText={ifString(error) || fieldCtrl?.helperText || helperText}
      required={required || fieldCtrl?.required}
      disabled={disabled || fieldCtrl?.disabled}
    >
      <div className={clsx(classes.root, label && classes.labeldist)}>
        {disabled ? (
          <StaticHtml value={value || placeholder} autoSroll={autoScroll} />
        ) : (
          <Suspense fallback={<CircularProgress size={60} />}>
            <JoditEditor
              ref={editorRef}
              className={classes.jodit}
              value={html}
              config={config}
              onChange={onChangeInt}
            />
          </Suspense>
        )}
      </div>
    </FormField>
  )
}
