import { Grid2 as Grid, Typography } from '@mui/material'
import { orange, red, yellow } from '@mui/material/colors'
import { MapperEinstellungElementJson } from '@one/typings/apiTypings'
import { Medal } from '@utils/ui/fields/Medal'

export interface ArtikelAbonniertAenderungenCellProps {
  ids?: number[]
  artikelMapperEinstellungen?: MapperEinstellungElementJson[]
  zusatz?: boolean
  konflikt?: boolean
}

export const ArtikelAbonniertAenderungenCell = ({
  ids,
  zusatz,
  konflikt,
  artikelMapperEinstellungen
}: ArtikelAbonniertAenderungenCellProps) => {
  if (ids == null || ids.length === 0 || artikelMapperEinstellungen == null) {
    return null
  }

  const buildMsg = () => {
    if (konflikt) {
      return zusatz ? 'ERP-Konflikte der Folgestufe in:' : 'ERP-Konflikte in:'
    }
    return zusatz ? 'Änderungen der Folgestufe in:' : 'Änderungen in:'
  }

  const buildColor = () => {
    if (konflikt) {
      return zusatz ? orange[500] : red[500]
    }
    return yellow[600]
  }

  const texte = ids
    .filter((id, idx, a) => a.indexOf(id) === idx)
    .map((k) => artikelMapperEinstellungen.find((a) => a.attributeId === k))
    .filter(Boolean)
    .map((konflikt) => ({
      attributeId: konflikt.attributeId,
      name: konflikt.name,
      anzahl: ids.filter((id) => id === konflikt.attributeId)?.length
    }))

  const tooltipContent = (
    <>
      <Typography variant="inherit">{buildMsg()}</Typography>
      <ul style={{ paddingLeft: 16, marginTop: 2 }}>
        {texte.map((t) => (
          <li key={t.attributeId}>
            <Grid container spacing={1}>
              <Grid flexGrow={1}>
                <Typography variant="inherit" key={t.attributeId}>
                  {t.name}
                </Typography>
              </Grid>
              <Grid textAlign="right">
                {t.anzahl > 1 ? `${t.anzahl}x` : <>&nbsp;&nbsp;&nbsp;</>}
              </Grid>
            </Grid>
          </li>
        ))}
      </ul>
    </>
  )

  return <Medal text={'' + ids.length} backgroundColor={buildColor()} tooltip={tooltipContent} />
}
