import { Grid2 as Grid, Typography } from '@mui/material'
import { EkPreisListeImportStatus, MessageJson } from '@one/typings/apiTypings'
import { flattenErrors } from '@utils/error'
import { CardEx } from '@utils/ui/CardEx'
import { FrameBody } from '@utils/ui/Frame'
import { useMemo } from 'react'

export interface EkPreisImportPflegeStep0ErrorProps {
  status: EkPreisListeImportStatus
  globaleFehler: MessageJson[]
}

export const EkPreisImportPflegeStep0Error = ({
  status,
  globaleFehler
}: EkPreisImportPflegeStep0ErrorProps) => {
  const errorStyle = {
    backgroundColor: '#ff0000',
    padding: '5px 5px 0 5px',
    color: 'white'
  }

  const errors = useMemo(() => flattenErrors(globaleFehler), [globaleFehler])

  return (
    <FrameBody>
      <CardEx title="Import-Fehler" height="100%">
        <Grid container>
          {errors?.length > 0 ? (
            <Grid>
              {errors.map((fehler) => (
                <Typography key={fehler.message} variant="h6">
                  {fehler.message}
                </Typography>
              ))}
            </Grid>
          ) : (
            <Grid>
              <Typography variant="h5">Der Import ist gescheitert.</Typography>
            </Grid>
          )}
        </Grid>
      </CardEx>
    </FrameBody>
  )
}
