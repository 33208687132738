import { Grid2 as Grid } from '@mui/material'
import { ArtikelListeSearcherCriteriaJson } from '@one/typings/apiTypings'
import { ChangeFunction } from '@utils/formstate'
import { SearchButton } from '@utils/ui/Buttons/SearchButton'
import { CardEx } from '@utils/ui/CardEx'
import { ShortCutHandler } from '@utils/ui/ShortCutHandler'
import { TextField } from '@utils/ui/fields/TextField'
import { ArtikelListeTypField } from '@one/components/ArtikelListe/common/ArtikelListeTypField'
import { MenuButton, MenuButtonItem } from '@utils/ui/Buttons/MenuButton'
import { useEnums } from '@utils/enums'
import { HkmEnum } from '@one/enums/HkmEnum'
import { useNavigate } from 'react-router-dom'
import { AppPaths } from '@one/AppPaths'

export interface ArtikelListeUebersichtParamsProps {
  value: ArtikelListeSearcherCriteriaJson
  onSearch: VoidFunction
  onChange: ChangeFunction<ArtikelListeSearcherCriteriaJson>
}

export const ArtikelListeUebersichtParams = ({
  value,
  onSearch,
  onChange
}: ArtikelListeUebersichtParamsProps) => {
  const { items } = useEnums()
  const navigate = useNavigate()
  const shortcuts = {
    Enter: onSearch
  }

  const specialItems: MenuButtonItem[] = items(HkmEnum.ArtikelListeTyp)
    .filter((e) => e.id.startsWith('VIRTUELL_'))
    .map((item) => ({
      label: item.text,
      onClick: () => {
        navigate(
          AppPaths.artikelliste.ArtikelListePflegeFn(-1 * parseInt(item.id.split('_')[1], 10))
        )
      }
    }))

  const actions = [
    <MenuButton key="si" items={specialItems} label="Automatisch generierte Artikellisten" />
  ]

  return (
    <ShortCutHandler shortcuts={shortcuts}>
      <CardEx
        backLink
        collapsiable
        title="Artikelliste"
        topActions={actions}
        bottomActions={<SearchButton onClick={onSearch} />}
      >
        <Grid container spacing={3}>
          <Grid size={{ xs: 6, md: 4, lg: 3 }}>
            <TextField fullWidth label="Name" name="name" value={value.name} onChange={onChange} />
          </Grid>
          <Grid size={{ xs: 6, md: 4, lg: 3 }}>
            <ArtikelListeTypField name="typ" value={value.typ} onChange={onChange} type="real" />
          </Grid>
        </Grid>
      </CardEx>
    </ShortCutHandler>
  )
}
