import { Grid2 as Grid } from '@mui/material'
import { enrichKondiDefinition } from '@one/components/EkPreis/EKPreisPflege/model/EkPreiseUsecase'
import { EkKonditionenDefinitionJson } from '@one/typings/apiTypings'
import { AppContext } from '@utils/ui/App/AppContext'
import { CardEx } from '@utils/ui/CardEx'
import { Column, DataTable } from '@utils/ui/DataTable/DataTable'
import { StaticField } from '@utils/ui/fields/StaticField'
import { useContext, useMemo } from 'react'

export interface LieferantDetailsEkKonditionenProps {
  ekKonditionen: EkKonditionenDefinitionJson
  loading: boolean
}

export const LieferantDetailsEkKonditionen = ({
  ekKonditionen,
  loading
}: LieferantDetailsEkKonditionenProps) => {
  const { isAllianz } = useContext(AppContext)

  const values = useMemo(() => {
    const buildRow = (label: string, kurz: string, bez: string, ekpfh: boolean) => {
      return {
        feld: label,
        kurz: kurz,
        bez: bez,
        enabled: ekpfh
      }
    }
    const ekKondiEx = enrichKondiDefinition(ekKonditionen, isAllianz)
    const list = [
      buildRow(
        ekKondiEx.zuschlAbs1Label,
        ekKonditionen.kurzZuschlAbs1,
        ekKonditionen.bezZuschlAbs1,
        ekKondiEx.zuschlAbs1
      ),
      buildRow(
        ekKondiEx.zuschlAbs2Label,
        ekKonditionen.kurzZuschlAbs2,
        ekKonditionen.bezZuschlAbs2,
        ekKondiEx.zuschlAbs2
      ),
      buildRow(
        ekKondiEx.rabattProz1Label,
        ekKonditionen.kurzRabattProz1,
        ekKonditionen.bezRabattProz1,
        ekKondiEx.rabattProz1
      ),
      buildRow(
        ekKondiEx.rabattProz2Label,
        ekKonditionen.kurzRabattProz2,
        ekKonditionen.bezRabattProz2,
        ekKondiEx.rabattProz2
      ),
      buildRow(
        ekKondiEx.rabattProz3Label,
        ekKonditionen.kurzRabattProz3,
        ekKonditionen.bezRabattProz3,
        ekKondiEx.rabattProz3
      ),
      buildRow(
        ekKondiEx.rabattProz4Label,
        ekKonditionen.kurzRabattProz4,
        ekKonditionen.bezRabattProz4,
        ekKondiEx.rabattProz4
      ),
      buildRow(
        ekKondiEx.rabattProz5Label,
        ekKonditionen.kurzRabattProz5,
        ekKonditionen.bezRabattProz5,
        ekKondiEx.rabattProz5
      ),
      buildRow(
        ekKondiEx.zuschlAbs3Label,
        ekKonditionen.kurzZuschlAbs3,
        ekKonditionen.bezZuschlAbs3,
        ekKondiEx.zuschlAbs3
      ),
      buildRow(
        ekKondiEx.zuschlAbs4Label,
        ekKonditionen.kurzZuschlAbs4,
        ekKonditionen.bezZuschlAbs4,
        ekKondiEx.zuschlAbs4
      ),
      buildRow(
        ekKondiEx.zuschlAbs5Label,
        ekKonditionen.kurzZuschlAbs5,
        ekKonditionen.bezZuschlAbs5,
        ekKondiEx.zuschlAbs5
      )
    ]
    return list
  }, [ekKonditionen, isAllianz])

  const columns: Column[] = useMemo(
    () => [
      {
        field: 'feld',
        header: 'Feld',
        sortable: true
      },
      {
        field: 'kurz',
        header: 'Kürzel',
        sortable: true
      },
      {
        field: 'bez',
        header: 'Bezeichnung',
        sortable: true
      },
      {
        field: 'enabled',
        header: 'EKPFH relevant',
        align: 'center',
        sortable: true
      }
    ],
    []
  )

  return (
    <CardEx title="EK-Kalkulationsschema">
      <Grid container spacing={2}>
        <Grid size={{xs:3}}>
          <StaticField
            label="Sonderpreis in Rabatt"
            value={
              ekKonditionen.sonderpreisInRabatt
                ? `Rabatt ${ekKonditionen.sonderpreisInRabatt}`
                : 'Nein'
            }
          />
        </Grid>
      </Grid>
      <DataTable
        name="LieferantDetailsEkKonditionenTable"
        columns={columns}
        value={values}
        loading={loading}
        dense
        rowFiller
      />
    </CardEx>
  )
}
