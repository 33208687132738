import { Grid2 as Grid } from '@mui/material'
import { formatSparte, formatWarengruppe } from '@one/components/common/WarengruppeCell'
import { DzArtikelJson } from '@one/typings/apiTypings'
import { CardEx } from '@utils/ui/CardEx'
import { StaticField } from '@utils/ui/fields/StaticField'

export const ArtikelWarengruppenDz = ({ artikel }: { artikel: DzArtikelJson }) => (
  <CardEx title="Warengruppen">
    <Grid container spacing={2} direction="column" size={{xs:6}}>
      <Grid>
        <StaticField label="Warengruppe" value={formatWarengruppe(artikel?.warengruppe)} />
      </Grid>
      <Grid>
        <StaticField label="BDB-Warengruppe" value={artikel?.warengruppeBDBNummer} fullWidth />
      </Grid>
      <Grid>
        <StaticField
          label="Hagebau Warengruppe"
          value={formatWarengruppe(artikel?.hageWarengruppe)}
          fullWidth
        />
      </Grid>
      <Grid>
        <StaticField label="Sparte" value={formatSparte(artikel?.sparte)} />
      </Grid>
    </Grid>
  </CardEx>
)
