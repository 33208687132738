import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight
} from '@mui/icons-material'
import { Grid2 as Grid } from '@mui/material'
import { StandortGruppeJson, StandortJson, SynchronisationStatus } from '@one/typings/apiTypings'
import { UpdModelFn } from '@utils/modelmgr'
import { useStateEx } from '@utils/stateex'
import { IconButton } from '@utils/ui/Buttons/IconButton'
import { Column } from '@utils/ui/DataTable/DataTable'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { arrayFromSet, distinct } from '@utils/utils'
import { useCallback, useMemo } from 'react'

export interface StandortgruppeTableProps {
  updModel: UpdModelFn<StandortGruppeJson>
  model?: StandortGruppeJson
  standorte: StandortJson[]
}

export const StandortgruppeTable = ({ updModel, standorte, model }: StandortgruppeTableProps) => {
  const [selected, setSelected, getSelected] = useStateEx(new Set<StandortJson>())
  const [selectedAll, setSelectedAll, getSelectedAll] = useStateEx(new Set<StandortJson>())

  const readonly =
    model.status !== SynchronisationStatus.DEAKTIVIERT &&
    model.status !== SynchronisationStatus.AKTIVIERUNG_FEHLGESCHLAGEN

  const standorteAll = useMemo(() => {
    return standorte.filter((s1) => !model.standorte?.find((s2) => s1.id === s2.id))
  }, [model.standorte, standorte])

  const add = useCallback(() => {
    const selection = arrayFromSet(getSelectedAll())
    updModel((old) => ({
      ...old,
      standorte: old.standorte.concat(selection).filter(distinct)
    }))
  }, [getSelectedAll, updModel])

  const addAll = useCallback(() => {
    updModel((old) => ({
      ...old,
      standorte: old.standorte.concat(standorteAll).filter(distinct)
    }))
  }, [standorteAll, updModel])

  const rem = useCallback(() => {
    const sel = getSelected()
    updModel((old) => ({
      ...old,
      standorte: old.standorte?.filter((s) => !sel.has(s))
    }))
  }, [getSelected, updModel])

  const remAll = useCallback(() => {
    updModel((old) => ({
      ...old,
      standorte: []
    }))
  }, [updModel])

  const columns = useMemo<Column<StandortJson>[]>(
    () => [
      {
        field: 'nr',
        header: 'Nr',
        type: 'number'
      },
      {
        field: 'name',
        header: 'Name'
      }
    ],
    []
  )

  return (
    <Grid container spacing={1} direction="row" height="100%" flexWrap="nowrap">
      <Grid width="calc( 50% - 24px )">
        <DataTableCard
          name="StandortgruppeTable"
          localStateName="StandortgruppeTable"
          title="Standorte"
          selectMode="multi"
          selectCol
          selected={selected}
          onSelect={setSelected}
          columns={columns}
          value={model.standorte}
          paging
          filterMode="both"
          dense
          rowFiller
          emptyMessage="Mindestens ein Standort erforderlich"
        />
      </Grid>
      <Grid width="48px" container direction="column" justifyContent="center">
        <Grid>
          <IconButton
            icon={<KeyboardArrowLeft />}
            disabled={readonly || selectedAll.size === 0}
            onClickVoid={add}
          />
        </Grid>
        <Grid>
          <IconButton
            icon={<KeyboardArrowRight />}
            disabled={readonly || selected.size === 0}
            onClickVoid={rem}
          />
        </Grid>
        <Grid>
          <IconButton
            icon={<KeyboardDoubleArrowLeft />}
            disabled={readonly || standorteAll.length === 0}
            onClickVoid={addAll}
          />
        </Grid>
        <Grid>
          <IconButton
            icon={<KeyboardDoubleArrowRight />}
            disabled={readonly || model.standorte.length === 0}
            onClickVoid={remAll}
          />
        </Grid>
      </Grid>
      <Grid width="calc( 50% - 24px )">
        <DataTableCard
          name="StandortgruppeAlleTable"
          localStateName="StandortgruppeTable"
          title="Verfügbare Standorte"
          selectMode="multi"
          selectCol
          selected={selectedAll}
          onSelect={setSelectedAll}
          columns={columns}
          value={standorteAll}
          paging
          filterMode="both"
          dense
          rowFiller
          emptyMessage="Kein (weiterer) Standort verfügbar"
        />
      </Grid>
    </Grid>
  )
}
