import { Grid2 as Grid, Typography } from '@mui/material'
import { green, grey, red, yellow } from '@mui/material/colors'
import { AppPaths } from '@one/AppPaths'
import { UserRoles } from '@one/UserRoles'
import {
  ArtikelDefaultColumnsProps,
  useArtikelColumns
} from '@one/components/Artikel/ArtikelColumn'
import { LabelEditResponse, useArtikelLabelEdit } from '@one/components/Label/useArtikelLabelEdit'
import {
  ArtikelAbonniertStatusSymbol,
  getArtikelAbonniertStatusText
} from '@one/components/common/ArtikelAbonniertStatusSymbol'
import { LieferantCell } from '@one/components/common/LieferantCell'
import { getArchiviertRowStyle } from '@one/components/common/TableUtils'
import { formatLieferant } from '@one/components/common/formatters'
import { HkmEnum } from '@one/enums/HkmEnum'
import {
  ArtikelBearbeitungsEintragJson,
  AuswahlStatus,
  LieferantDisplayJson
} from '@one/typings/apiTypings'
import { useEnums } from '@utils/enums'
import { SilentUpd, SilentUpdTarget } from '@utils/modelmgr'
import { useStateEx } from '@utils/stateex'
import { AppContext } from '@utils/ui/App/AppContext'
import { Button } from '@utils/ui/Buttons/Button'
import { RowGrouper } from '@utils/ui/DataTable/DataTable'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import {
  AdditionalFilterConfig,
  useAdditionalFilter
} from '@utils/ui/DataTable/filter/AdditionalFilterMenu'
import { Permission } from '@utils/ui/Permission'
import { ThemeContext } from '@utils/ui/Theme'
import { TooltipWrapper } from '@utils/ui/TooltipWrapper'
import { Medal } from '@utils/ui/fields/Medal'
import { useCallback, useContext, useMemo } from 'react'
import { ArtikelBearbeitungsListeEdit } from './ArtikelBearbeitungsListeBearbeitenUseCase'

export interface PreisAnlageTableProps {
  eintraegeChangesMap: Map<number, AuswahlStatus>
  setEintraegeChangesMap: (next: Map<number, AuswahlStatus>) => void
  silentUpd: SilentUpd<ArtikelBearbeitungsListeEdit>
  eintraege?: ArtikelBearbeitungsEintragJson[]
  mode: 'ek' | 'vk'
  ignorierbar?: boolean
  lieferant?: LieferantDisplayJson
}

export const getStatusColor = (status: AuswahlStatus, darkMode: boolean) => {
  switch (status) {
    case AuswahlStatus.AUSGESCHL:
      return red[darkMode ? 800 : 200]
    case AuswahlStatus.EINGESCHL:
      return green[darkMode ? 800 : 200]
    case AuswahlStatus.IGNORIEREN:
      return yellow[darkMode ? 800 : 200]
    default:
      return grey[darkMode ? 800 : 200]
  }
}

export const PreisAnlageTable = ({
  eintraegeChangesMap,
  setEintraegeChangesMap,
  eintraege = [],
  silentUpd,
  mode,
  ignorierbar,
  lieferant
}: PreisAnlageTableProps) => {
  const { et } = useEnums()
  const { darkMode } = useContext(ThemeContext)
  const appContext = useContext(AppContext)
  const { openLabelsEdit } = useArtikelLabelEdit()

  const afc = useMemo<AdditionalFilterConfig[]>(() => {
    const filterException = (row: any) => {
      const currentChange = eintraegeChangesMap.get(row.id)
      return currentChange == AuswahlStatus.EINGESCHL || row.status == AuswahlStatus.EINGESCHL
    }

    return [
      {
        label: 'archivierte',
        name: 'archivierte',
        onFilter: (row) => row.artikel.archiviert && !filterException(row)
      },
      {
        label: 'ignorierte Artikelarten',
        name: 'ignArtArt',
        onFilter: (row) => row.artikel.artikelArt?.ignoriert && !filterException(row)
      }
    ] as AdditionalFilterConfig[]
  }, [eintraegeChangesMap])

  const [eintraegeFiltered, additionalFilter] = useAdditionalFilter(eintraege, afc)

  const columnProps = useMemo<ArtikelDefaultColumnsProps<ArtikelBearbeitungsEintragJson>>(
    () => ({
      isGesellschafter: appContext.isGesellschafter,
      removeDefaultsByField: ['hauptlieferant'],
      fieldWrapper: {
        wrapper: 'artikel'
      },
      addColumnToIndex: [
        {
          beforeField: 'artikel.hageNummer',
          column: {
            field: 'status',
            header: 'Auswahl',
            align: 'center',
            valueGetter: (row) => {
              if (mode === 'vk' && row.artikel.hauptlieferant == null) {
                return 'nicht auswählbar'
              }
              const status = eintraegeChangesMap.get(row.id) || row.status
              if (status) {
                return et(HkmEnum.AuswahlStatus, status)
              }
              return ''
            },
            body: (row) => {
              const status = eintraegeChangesMap.get(row.id) || row.status
              if (
                status !== AuswahlStatus.IGNORIEREN &&
                mode === 'vk' &&
                row.artikel.hauptlieferant == null
              ) {
                return (
                  <TooltipWrapper title="Hauptlieferant fehlt" arrow>
                    <em>nicht auswählbar</em>
                  </TooltipWrapper>
                )
              }
              if (status) {
                const statusText = et(HkmEnum.AuswahlStatus, status)
                return (
                  <Medal text={statusText} backgroundColor={getStatusColor(status, darkMode)} />
                )
              }
              return null
            }
          }
        },
        {
          beforeField: 'artikel.hageNummer',
          column: {
            field: 'artikel.aboStatus',
            align: 'center',
            header: 'Abo',
            body: (row) =>
              row.artikel && <ArtikelAbonniertStatusSymbol value={row.artikel.aboStatus} et={et} />,
            valueGetter: (row) =>
              row.artikel && getArtikelAbonniertStatusText(et, row.artikel.aboStatus)
          }
        },
        {
          beforeField: 'artikel.betriebsTyp',
          column: {
            field: 'artikel.hauptlieferant',
            header: 'Hauptlieferant',
            hidden: mode === 'vk',
            valueGetter: (row) => formatLieferant(row?.artikel?.hauptlieferant),
            body: (row) => <LieferantCell lieferant={row?.artikel?.hauptlieferant} asLink />
          }
        }
      ]
    }),
    [appContext.isGesellschafter, mode, eintraegeChangesMap, et, darkMode]
  )

  const columns = useArtikelColumns(columnProps)

  const tableActions = useMemo(
    () => [
      {
        icon: 'info',
        title: 'Archivierter Artikel',
        handler: () => {},
        check: (row) => (row.archiviert ? Permission.ENABLED : Permission.HIDDEN)
      },
      {
        icon: 'arrow_forward',
        tooltip: 'Artikel öffnen',
        getLink: (data: any) => AppPaths.ArtikelFn(data.id)
      }
    ],
    []
  )

  const [selected, setSelected, getSelected] = useStateEx(new Set<ArtikelBearbeitungsEintragJson>())

  const onStatus = useCallback(
    (status: AuswahlStatus) => {
      const next = new Map(eintraegeChangesMap)
      getSelected().forEach((row: any) => {
        if (
          mode === 'ek' ||
          row.artikel?.hauptlieferant != null ||
          status === AuswahlStatus.IGNORIEREN ||
          status === AuswahlStatus.AUSGESCHL
        ) {
          next.set(row.id, status)
        }
      })
      setEintraegeChangesMap(next)
    },
    [eintraegeChangesMap, getSelected, setEintraegeChangesMap, mode]
  )

  const onEinschliessen = useCallback(() => {
    onStatus(AuswahlStatus.EINGESCHL)
  }, [onStatus])

  const onAusschliessen = useCallback(() => {
    onStatus(AuswahlStatus.AUSGESCHL)
  }, [onStatus])

  const onIgnorieren = useCallback(() => {
    onStatus(AuswahlStatus.IGNORIEREN)
  }, [onStatus])

  const onRowDoubleClick = useCallback(
    // eslint-disable-next-line no-unused-vars
    (row: ArtikelBearbeitungsEintragJson, e, col) => {
      if (e.type === 'dblclick' && col.field != 'status') {
        return
      }
      if (row.artikel?.hauptlieferant == null) {
        return
      }
      let next = null as AuswahlStatus
      const status = eintraegeChangesMap.get(row.id) || row.status
      switch (status) {
        case AuswahlStatus.EINGESCHL:
          next = AuswahlStatus.AUSGESCHL
          break

        case AuswahlStatus.AUSGESCHL:
          next = ignorierbar ? AuswahlStatus.IGNORIEREN : AuswahlStatus.EINGESCHL
          break

        case AuswahlStatus.IGNORIEREN:
          next = AuswahlStatus.EINGESCHL
          break

        default:
          return
      }

      const ns = new Set(getSelected())
      ns.add(row)

      const nc = new Map(eintraegeChangesMap)
      ns.forEach((r) => {
        nc.set(r.id, next)
      })
      setEintraegeChangesMap(nc)
      setSelected(ns)
    },
    [eintraegeChangesMap, getSelected, ignorierbar, setEintraegeChangesMap, setSelected]
  )

  const einschStyle = useMemo(
    () => ({
      backgroundColor: getStatusColor(AuswahlStatus.EINGESCHL, darkMode),
      color: 'inherit'
    }),
    [darkMode]
  )

  const ausschStyle = useMemo(
    () => ({
      backgroundColor: getStatusColor(AuswahlStatus.AUSGESCHL, darkMode),
      color: 'inherit'
    }),
    [darkMode]
  )

  const ignorierStyle = useMemo(
    () => ({
      backgroundColor: getStatusColor(AuswahlStatus.IGNORIEREN, darkMode),
      color: 'inherit'
    }),
    [darkMode]
  )

  const statistics = useMemo(() => {
    const stat = eintraege
      .map((erg) =>
        mode === 'vk' && erg.artikel?.hauptlieferant == null
          ? 'NAN'
          : eintraegeChangesMap.get(erg.id) || erg.status
      )
      .filter((status) => status != null)
      .reduce((v, status) => {
        if (v[status] == null) {
          v[status] = 0
        }
        v[status] += 1
        return v
      }, {})

    const einText = et(HkmEnum.AuswahlStatus, AuswahlStatus.EINGESCHL)
    const ausText = et(HkmEnum.AuswahlStatus, AuswahlStatus.AUSGESCHL)
    const ignText = et(HkmEnum.AuswahlStatus, AuswahlStatus.IGNORIEREN)
    const nauText = 'Nicht auswählbar'

    const ein = stat[AuswahlStatus.EINGESCHL]
    const aus = stat[AuswahlStatus.AUSGESCHL]
    const ign = stat[AuswahlStatus.IGNORIEREN]
    const nau = stat['NAN']

    return (
      <Grid container alignItems="center" gap={2}>
        {ein && (
          <Grid>
            <Medal
              text={`${einText}: ${ein} `}
              backgroundColor={getStatusColor(AuswahlStatus.EINGESCHL, darkMode)}
            />
          </Grid>
        )}
        {aus && (
          <Grid>
            <Medal
              text={`${ausText}: ${aus} `}
              backgroundColor={getStatusColor(AuswahlStatus.AUSGESCHL, darkMode)}
            />
          </Grid>
        )}
        {ign && (
          <Grid>
            <Medal
              text={`${ignText}: ${ign} `}
              backgroundColor={getStatusColor(AuswahlStatus.IGNORIEREN, darkMode)}
            />
          </Grid>
        )}
        {nau && (
          <Grid>
            <Typography>{`${nauText}: ${nau} `}</Typography>
          </Grid>
        )}
      </Grid>
    )
  }, [eintraege, et, darkMode, mode, eintraegeChangesMap])

  const updateLabels = useCallback(
    ({ data, isAdded }: LabelEditResponse) => {
      silentUpd(SilentUpdTarget.RAW, (model) => {
        const eintraege = model.eintraege.map((eintrag) => {
          if (data.artikelIds.includes(eintrag.artikel.id)) {
            // Set-zu-Array-Umwandlung, um Duplikate zu vermeiden
            let labels = Array.from(new Set([...(eintrag.artikel.labels ?? []), ...data.labelIds]))
            if (!isAdded) {
              labels = labels.filter((labelId: number) => !data.labelIds.includes(labelId))
            }
            const neweintrag = {
              ...eintrag,
              artikel: { ...eintrag.artikel, labels }
            }
            return neweintrag
          }
          return eintrag
        })
        return { ...model, eintraege }
      })
    },
    [silentUpd]
  )

  const openLabelDialog = useCallback(() => {
    openLabelsEdit({
      artikel: Array.from(getSelected()).map((e) => e.artikel),
      callback: { then: updateLabels }
    })
  }, [openLabelsEdit, getSelected, updateLabels])

  const topActions = useMemo(
    () => [
      {
        role: UserRoles.STAMMDATEN_EDITOR,
        tooltip: 'Labels bearbeiten',
        icon: 'label',
        onClick: openLabelDialog
      }
    ],
    [openLabelDialog]
  )

  const actions = useMemo(
    () => [
      <Button
        key="aus"
        label="Auswählen"
        disabled={selected.size === 0}
        onClick={onEinschliessen}
        style={einschStyle}
        variant="contained"
      />,
      <Button
        key="ein"
        label="Abwählen"
        disabled={selected.size === 0}
        onClick={onAusschliessen}
        style={ausschStyle}
        variant="contained"
      />,
      ignorierbar && (
        <Button
          key="ign"
          label="Ignorieren"
          tooltip="Der Artikel wird ignoriert, so dass er nicht mehr im Vorgang erscheint."
          disabled={selected.size === 0}
          onClick={onIgnorieren}
          style={ignorierStyle}
          variant="contained"
        />
      )
    ],
    [
      ausschStyle,
      einschStyle,
      ignorierStyle,
      ignorierbar,
      onAusschliessen,
      onEinschliessen,
      onIgnorieren,
      selected.size
    ]
  )

  const groupBy = useMemo<RowGrouper<ArtikelBearbeitungsEintragJson>>(
    () =>
      mode == 'vk' && eintraegeFiltered.length > 0
        ? {
            field: 'artikel.hauptlieferant.id',
            groupEmpty: true,
            dontHideColumn: true,
            body: (row) => (
              <Grid container direction="row" spacing={1}>
                <Grid>Artikel {`${row.artikel.hauptlieferant ? 'mit Hauptlieferant' : ''}`} </Grid>
                <Grid>
                  <LieferantCell
                    lieferant={row.artikel.hauptlieferant}
                    emptyText="ohne Hauptlieferanten"
                    asLink
                  />
                </Grid>
              </Grid>
            )
          }
        : null,
    [mode, eintraegeFiltered.length]
  )

  const getRowStyle = useCallback(
    (row: ArtikelBearbeitungsEintragJson) => getArchiviertRowStyle(row?.artikel?.archiviert),
    []
  )

  return (
    <DataTableCard
      name="PreisAnlageTable"
      filterMode="both"
      columns={columns}
      topActions={topActions}
      actions={tableActions}
      value={eintraegeFiltered}
      dense
      selectMode="multi"
      selectCol
      paging
      selected={selected}
      onSelect={setSelected}
      title="Ausgewählte Artikel"
      onRowDoubleClick={onRowDoubleClick}
      bottomActions={actions}
      bottomLeft={statistics}
      groupBy={groupBy}
      rowStyle={getRowStyle}
      additionalFilter={additionalFilter}
      localStateName="PreisAnlageTable"
      emptyMessage={
        mode === 'ek' && lieferant == null
          ? 'Lieferant muss gewählt sein'
          : 'Keine Artikel zur Auswahl'
      }
    />
  )
}
