import { Grid2 as Grid } from '@mui/material'
import { GesellschafterSearcherCriteriaJson } from '@one/typings/apiTypings'
import { ChangeFunction } from '@utils/formstate'
import { SearchButton } from '@utils/ui/Buttons/SearchButton'
import { CardEx } from '@utils/ui/CardEx'
import { ShortCutHandler } from '@utils/ui/ShortCutHandler'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { SelectField } from '@utils/ui/fields/SelectField'
import { TextField } from '@utils/ui/fields/TextField'
import { useMemo } from 'react'

export interface GesellschafterUebersichtParamsProps {
  value: GesellschafterSearcherCriteriaJson | null
  onSearch: () => void
  onChange: ChangeFunction<GesellschafterSearcherCriteriaJson>
}

export const GesellschafterUebersichtParams = ({
  value,
  onSearch,
  onChange
}: GesellschafterUebersichtParamsProps) => {
  const actions = useMemo(() => <SearchButton onClick={() => onSearch()} />, [onSearch])

  const typen = useMemo(
    () => [
      {
        id: true,
        text: 'Firma'
      },
      {
        id: false,
        text: 'Gesellschafter'
      }
    ],
    []
  )

  return (
    <ShortCutHandler shortcuts={{ Enter: onSearch }}>
      <CardEx backLink title="Gesellschafter und Firmen" bottomActions={actions}>
        <Grid container spacing={2} direction="column">
          <Grid container direction="row" spacing={2} alignItems="center">
            <Grid size={{xs:12,sm:6,md:2}}>
              <TextField
                name="name"
                label="Name"
                value={value.name}
                onChange={onChange}
                fullWidth
              />
            </Grid>
            <Grid size={{xs:12,sm:6,md:2}}>
              <TextField
                name="gln"
                label="Gesellschafternr."
                value={value.gln}
                onChange={onChange}
                fullWidth
              />
            </Grid>
            <Grid size={{xs:12,sm:4,md:2}}>
              <SelectField
                name="isFirma"
                label="Typ"
                optionText="text"
                emptyText="Alle"
                value={value.isFirma}
                onChange={onChange}
                options={typen}
                fullWidth
              />
            </Grid>
            <Grid size={{xs:12,sm:3,md:2,lg:2}}>
              <Checkbox
                label="Allianzen"
                name="isAllianz"
                checked={value.isAllianz}
                onChange={onChange}
              />
            </Grid>
            <Grid size={{xs:12,sm:3,md:3,lg:2}}>
              <Checkbox
                label="Allianzmitglieder"
                name="isAllianzMitglied"
                checked={value.isAllianzMitglied}
                onChange={onChange}
              />
            </Grid>
            <Grid size={{xs:12,sm:3,md:3,lg:2}}>
              <Checkbox
                label="gelöschte"
                name="isGeloeschte"
                checked={value.isGeloeschte}
                onChange={onChange}
                indeterminate
              />
            </Grid>
          </Grid>
        </Grid>
      </CardEx>
    </ShortCutHandler>
  )
}
