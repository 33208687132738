import { api } from '@one/api'
import {
  PreisEbeneDisplayJson,
  UseCaseStateJson,
  VkKalkulationsvorschlagJson,
  VkPreiseMasseBearbeitenKopfJson,
  VkPreisGruppeJson,
  VkRundungsregelJson
} from '@one/typings/apiTypings'
import { ApiExclusive, useApiCaller } from '@utils/apicaller'
import { arrayMerge, buildMap, compareStrings, safeArray } from '@utils/utils'
import { useCallback, useEffect, useState } from 'react'
import isEqual from 'react-fast-compare'

const empty = {
  artikelListe: [],
  columns: []
}

export interface VkPreiseMasseBearbeitenKopfJsonEx extends VkPreiseMasseBearbeitenKopfJson {
  standortId?: number
  standort0Id?: number
  artikelCount?: number
  standorteMap?: Map<number, PreisEbeneDisplayJson>
  preisgruppenMap?: Map<number, VkPreisGruppeJson>
  basisPreisgruppe?: VkPreisGruppeJson
  kalkulationsvorschlaegeMap?: Map<number, VkKalkulationsvorschlagJson>
  fatalError?: UseCaseStateJson
  rundungsregelnMap?: Map<number, VkRundungsregelJson>
}

const compareNames = (a, b) => compareStrings(a?.name, b?.name)

export const useVkPreisMassenKopf = (preislisteId: any) => {
  const [kopf, setKopf] = useState<VkPreiseMasseBearbeitenKopfJsonEx>(empty)
  const [last, setLast] = useState()
  const [apiCall, apiBusy] = useApiCaller(api)

  const updateArtikelliste = useCallback((al) => {
    setKopf((old) => ({
      ...old,
      artikelListe: arrayMerge(old.artikelListe, al, (a) => a.id)
    }))
  }, [])

  const reload = useCallback(
    (nextStandortId = null) => {
      const paramStandortId = nextStandortId // auch null erlaubt
      setKopf((old) => ({
        ...old,
        id: preislisteId,
        paramStandortId
      }))
      apiCall<VkPreiseMasseBearbeitenKopfJson>({
        method: 'GET',
        rest: 'vkpreisliste/openmassenpflege',
        params: { id: preislisteId, standortId: paramStandortId },
        exclusive: ApiExclusive.CANCEL,
        onSuccess: (data) => {
          const artikelListe = safeArray(data.artikelListe)
          const kalkulationsvorschlaege = safeArray(data.kalkulationsvorschlaege).sort(compareNames)
          const kalkulationsvorschlaegeMap = buildMap(kalkulationsvorschlaege, (b) => b.id)
          const preisgruppen = safeArray(data.preisgruppen).sort(compareNames)
          const preisgruppenMap = buildMap(preisgruppen, (b) => b.id)
          const standorte = safeArray(data.standorte).sort((a, b) => compareStrings(a.nr, b.nr)) // a.nr - b.nr)
          const standorteMap = buildMap(standorte, (b) => b.id)
          const standort0Id = (standorte.find((s) => s.defaultStandort) || {}).id
          const rundungsregeln = safeArray(data.rundungsregeln).sort(compareNames)
          const rundungsregelnMap = buildMap(rundungsregeln, (b) => b.id)

          // Test data.basisPreisgruppeId = 44009

          const basisPreisgruppe =
            data.basisPreisgruppeId && preisgruppenMap.get(data.basisPreisgruppeId)

          setKopf({
            id: preislisteId,
            standortId: paramStandortId,
            artikelListe,
            artikelCount: artikelListe.length,
            standorte,
            standorteMap,
            standort0Id,
            preisgruppen,
            preisgruppenMap,
            basisPreisgruppe,
            kalkulationsvorschlaege,
            kalkulationsvorschlaegeMap,
            rundungsregeln,
            rundungsregelnMap,
            actionAbschliessen: data.actionAbschliessen,
            actionFreigeben: data.actionFreigeben,
            freigegegen: data.freigegegen,
            freigegebenUm: data.freigegebenUm,
            zufuhrMultis: data.zufuhrMultis,
            kontext: data.kontext,
            preisEbeneId: data.preisEbeneId,
            forEinzelhandel: data.forEinzelhandel,
            betriebsTyp: data.betriebsTyp,
            name: data.name
          })
        },
        onError: (error) => setKopf({ ...empty, fatalError: error })
      })
    },
    [apiCall, preislisteId]
  )

  const remove = useCallback(
    ({ onRemoved }) => {
      apiCall({
        method: 'POST',
        rest: '/vkpreisliste/delete',
        params: { id: preislisteId },
        onSuccess: (data) => {
          if (onRemoved) {
            onRemoved(data)
          }
        },
        onErrorMsg: `Löschen der Preisliste mit ID ${preislisteId} gescheitert.`,
        onError: (error) => setKopf({ ...empty, fatalError: error })
      })
    },
    [preislisteId, apiCall]
  )

  const removeSelected = useCallback(
    ({ selected, onRemoved }) => {
      apiCall({
        method: 'POST',
        rest: '/vkpreisliste/deleteEintraege',
        data: selected,
        onSuccess: () => {
          if (onRemoved) {
            onRemoved()
          }
        },
        onErrorMsg: `Löschen der ausgewählten Artikel gescheitert.`,
        onError: (error) => setKopf({ ...empty, fatalError: error })
      })
    },
    [apiCall]
  )

  useEffect(() => {
    if (!isEqual(preislisteId, last)) {
      setLast(preislisteId)
      setKopf(empty)
      reload()
    }
  }, [apiCall, last, preislisteId, reload])

  return { kopf, updateArtikelliste, reload, remove, removeSelected, apiCall, apiBusy }
}
