import { Close, PushPin, PushPinOutlined } from '@mui/icons-material'
import { Drawer, Grid2 as Grid, Typography, useTheme } from '@mui/material'
import { grey } from '@mui/material/colors'
import { useApiCaller } from '@utils/apicaller'
import { formatDateTimeSecond } from '@utils/dateutils'
import { useFormState } from '@utils/formstate'
import { useLocalState } from '@utils/localState'
import { Button } from '@utils/ui/Buttons/Button'
import { IconButton } from '@utils/ui/Buttons/IconButton'
import { ReloadButton } from '@utils/ui/Buttons/ReloadButton'
import { SaveButton } from '@utils/ui/Buttons/SaveButton'
import { EditorField } from '@utils/ui/fields/EditorField'
import { StaticField } from '@utils/ui/fields/StaticField'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { WaitPlane } from '@utils/ui/planes/WaitPlane'
import { useCallback, useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'

export interface HelpLocation {
  key: string
  title?: string
  chapterKey?: string
  chapterTitle?: string
}

export interface HelpPanelProps {
  visible: boolean
  forcedOverlay?: boolean
  setForcedOverlay: (isForced: any) => void
  setVisible: (isVisible: any) => void
  helpLocation: HelpLocation
  editAllowed: boolean
  api: any
}

// Constants
const drawerWidth = '25%'

// Styling definitions
const useStyles = makeStyles()((theme: any) => ({
  drawer: {
    // [theme.breakpoints.up('xl')]: {
    width: drawerWidth

    // flexShrink: 0,
    // zIndex: 1099
    // }
  },
  drawerPaper: {
    zIndex: 1400,
    width: drawerWidth,
    '& .MuiPaper-root': {
      width: drawerWidth,
      padding: '8px',
      //   overflowX: 'hidden',
      top: 48,
      height: 'calc(100% - 48px)'
      //   // [theme.breakpoints.up('lg')]: {
      //   //   height: 'calc(100% - 48px)'
    }
    // }
  },
  helpTitle: {
    //@ts-ignore
    backgroundColor: theme.darkMode ? grey[600] : grey[200]
  }
}))

/**
 * Container component for a navigational drawer.
 *
 * @param {boolean} visible Flag indicating if the drawer should be visible or not
 * @param {function} onDrawerToggle Callback function to toggle drawer visibility
 */
export const HelpPanel = ({
  visible,
  forcedOverlay,
  setForcedOverlay,
  setVisible,
  helpLocation,
  editAllowed,
  api
}: HelpPanelProps) => {
  const { classes } = useStyles()
  const theme = useTheme()
  const [overlay, setOverlay] = useLocalState('helpOverlay', true)
  const [org, setOrg] = useState()

  const [help, onChange] = useFormState({
    lastModified: null,
    author: null,
    lasModified: null,
    text: null
  })

  const [apiCall, apiBusy] = useApiCaller(api)
  const [editMode, setEditMode] = useState(false)
  const [readonly, setReadonly] = useState(true)

  const dirty = org !== help

  const onDrawerToggle = () => {
    if (!overlay && forcedOverlay) {
      setForcedOverlay(false)
    } else {
      setVisible(false)
    }
  }

  const load = useCallback(
    (em?: boolean) => {
      if (helpLocation.key) {
        let key = helpLocation.key
        if (helpLocation.chapterKey) {
          key = key + '-' + helpLocation.chapterKey
        }
        apiCall<any>({
          method: 'GET',
          rest: '/help/view',
          params: { key, editMode: em },
          onSuccess: (data) => {
            setOrg(data.data)
            onChange(() => data.data)
            setReadonly(data.readonly)
            setEditMode(!!em)
          },
          onError: (error, response) => {
            setReadonly(response?.data?.readonly)
            const empty = {}
            //@ts-ignore
            setOrg(empty)
            onChange(() => empty)
            if (error?.mainMessage?.messageId?.id === 'ID_NOTFOUND') {
              setEditMode(!!em)
              return true
            }
            setEditMode(false)
            return false
          }
        })
      }
    },
    [apiCall, helpLocation.chapterKey, helpLocation.key, onChange]
  )

  const save = useCallback(() => {
    let key = helpLocation.key
    if (helpLocation.chapterKey) {
      key = key + '-' + helpLocation.chapterKey
    }
    apiCall<any>({
      method: 'POST',
      rest: '/help/save',
      params: { key },
      data: { ...help, title: helpLocation.chapterTitle || helpLocation.title },
      onSuccess: (data) => {
        const d = data.data || {}
        onChange(() => d)
        setOrg(d)
        setEditMode(false)
        setReadonly(data.readonly)
      },
      onError: (error, response) => {
        setReadonly(response?.data?.readonly)
      }
    })
  }, [
    apiCall,
    help,
    helpLocation.chapterKey,
    helpLocation.chapterTitle,
    helpLocation.key,
    helpLocation.title,
    onChange
  ])

  const beginEdit = () => {
    load(true)
  }

  useEffect(() => {
    load()
  }, [apiCall, helpLocation, load, onChange])

  const body = (
    <WaitPlane wait={apiBusy} fullHeight>
      <Frame fullHeight>
        <FrameRow>
          <Grid container direction="column" paddingBottom={1}>
            <Grid container alignItems="center" className={classes.helpTitle} paddingLeft={1}>
              <Grid style={{ flexGrow: 1 }}>
                <Typography>Hilfe</Typography>
              </Grid>
              {!forcedOverlay && (
                <Grid>
                  <IconButton
                    size="small"
                    icon={overlay ? <PushPinOutlined /> : <PushPin />}
                    onClick={() => setOverlay(!overlay)}
                  />
                </Grid>
              )}
              <Grid>
                <IconButton size="small" icon={<Close />} onClick={() => setVisible(false)} />
              </Grid>
            </Grid>
            {/* TODO Suche nach Texten, Sprung auf andere Hilfetexte
            <Grid item container alignItems="center" paddingTop={2}>
              <Grid item style={{ flexGrow: 1 }}>
                <TextField
                  placeholder="Suche..."
                  fullWidth
                  startActions={[
                    { icon: <ArrowBack /> },
                    { icon: <Home /> }
                  ]}
                />
              </Grid>
            </Grid>
            <Grid item style={{ flexGrow: 1 }} paddingTop={3} paddingBottom={3}>
              <StaticField label="Titel" value={helpLocation.title} fullWidth />
            </Grid> */}
          </Grid>
        </FrameRow>
        <FrameBody>
          <EditorField
            // label="Beschreibung"
            fullWidth
            fullHeight
            name="text"
            value={help?.text}
            onChange={onChange}
            placeholder="Für diese Seite ist keine Beschreibung vorhanden"
            autoScroll
            disabled={!editMode || readonly}
          />
        </FrameBody>
        <FrameRow>
          <Grid container direction="row" marginTop={1} spacing={1} justifyContent="space-between">
            <Grid>
              {help.author ? (
                <StaticField
                  label="Zuletzt geändert"
                  value={
                    readonly
                      ? formatDateTimeSecond(help.lastModified)
                      : `${formatDateTimeSecond(help.lastModified)} - ${help.author}`
                  }
                  variant="body2"
                />
              ) : null}
            </Grid>
            <Grid container style={{ marginLeft: 'auto', width: 'auto' }}>
              <Grid container justifyContent="end" alignItems="center">
                <Button
                  label="Bearbeiten"
                  variant="text"
                  onClick={beginEdit}
                  visible={editAllowed && !readonly && !editMode && Boolean(helpLocation.key)}
                  disabled={apiBusy}
                />
                {readonly ? null : (
                  <>
                    <SaveButton
                      visible={editMode}
                      disabled={apiBusy || !dirty}
                      onClickVoid={save}
                    />
                    <ReloadButton isChanged={editMode} variant="text" onClickVoid={load} />
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </FrameRow>
      </Frame>
    </WaitPlane>
  )

  const drawerToggled = (ovl, open) => (
    <Drawer
      className={classes.drawerPaper}
      variant={ovl ? 'temporary' : 'permanent'}
      anchor={theme.direction === 'rtl' ? 'left' : 'right'}
      open={open}
      onClose={onDrawerToggle}
      ModalProps={{
        keepMounted: false
      }}
    >
      {body}
    </Drawer>
  )

  if (forcedOverlay || overlay) {
    return drawerToggled(true, visible)
  }

  if (visible) {
    return <div className={visible ? classes.drawer : null}>{drawerToggled(false, true)}</div>
  }

  return null
}
