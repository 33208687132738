import { Typography } from '@mui/material'
import { AppPaths } from '@one/AppPaths'
import { EventNames } from '@one/EventNames'
import { api } from '@one/api'
import {
  StandortGruppeBearbeitenJson,
  StandortGruppeJson,
  SynchronisationStatus
} from '@one/typings/apiTypings'
import { useModelMgr } from '@utils/modelmgr'
import { RouteContext } from '@utils/ui/App/AppRoute'
import { ButtonRow } from '@utils/ui/Buttons/ButtonRow'
import { ButtonWithConfirmation } from '@utils/ui/Buttons/ButtonWithConfirmation'
import { DeleteButton } from '@utils/ui/Buttons/DeleteButton'
import { ReloadButton } from '@utils/ui/Buttons/ReloadButton'
import { SaveButton } from '@utils/ui/Buttons/SaveButton'
import { CardEx } from '@utils/ui/CardEx'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { Link } from '@utils/ui/Navigation/Link'
import { StatePlane } from '@utils/ui/planes/StatePlane'
import { useCallback, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { StandortgruppeForm } from './StandortgruppeForm'
import { StandortgruppeTable } from './StandortgruppeTable'

const validate = (model: any) => {
  const errors = {} as any
  // checkRequired(model, errors, 'name', 'Name')
  // checkRequired(model, errors, 'artikel', 'Artikel')
  return errors
}

export const StandortgruppeView = () => {
  const { id } = useContext(RouteContext) as any
  const navigate = useNavigate()

  const {
    envelope,
    model,
    isNew,
    isChanged,
    errors,
    uiLock,
    onValueChange,
    save,
    post,
    reload,
    remove,
    updModel
  } = useModelMgr<StandortGruppeBearbeitenJson, StandortGruppeJson>({
    id,
    api,
    title: 'Standortgruppe',
    unwrapField: 'standortgruppe',
    rest: 'standortgruppe',
    init: {},
    validate,
    eventName: EventNames.STANDORTGRUPPE
  })

  const onDelete = useCallback(
    () =>
      remove({
        addVersion: true,
        onRemoved: () => {
          navigate(AppPaths.StandortgruppeUebersicht)
        }
      }),
    [navigate, remove]
  )

  const onAktivieren = useCallback(
    () =>
      post({
        srv: 'aktivieren',
        addVersion: true,
        onPost: () => {
          navigate(AppPaths.StandortgruppeUebersicht)
          return true
        }
      }),
    [navigate, post]
  )

  const onDeaktivieren = useCallback(
    () =>
      post({
        srv: 'deaktivieren',
        addVersion: true,
        onPost: () => {
          return false
        }
      }),
    [post]
  )

  const onSync = useCallback(
    () =>
      post({
        srv: 'sync',
        addVersion: true,
        onPost: () => {
          return false
        }
      }),
    [post]
  )

  const canAktivieren =
    model.standorte?.length > 0 &&
    (model.status === SynchronisationStatus.AKTIVIERUNG_FEHLGESCHLAGEN ||
      model.status === SynchronisationStatus.DEAKTIVIERT)

  const canDeaktivieren =
    model.status === SynchronisationStatus.AKTIVIERT ||
    model.status === SynchronisationStatus.AKTIVIERUNG_FEHLGESCHLAGEN

  const canDelete =
    model.status === SynchronisationStatus.AKTIVIERT ||
    model.status === SynchronisationStatus.AKTIVIERUNG_FEHLGESCHLAGEN ||
    model.status === SynchronisationStatus.DEAKTIVIERT

  return (
    <StatePlane uiLock={uiLock} altLink={AppPaths.StandortgruppeUebersicht}>
      <Frame space>
        <FrameRow>
          <StandortgruppeForm
            kontexte={envelope.kontexte}
            model={model}
            errors={errors}
            isNew={isNew}
            onChange={onValueChange}
            isChanged={isChanged}
          />
        </FrameRow>
        <FrameBody>
          {(model.kontext && (
            <StandortgruppeTable model={model} updModel={updModel} standorte={envelope.standorte} />
          )) ||
            (envelope.kontexte?.length === 0 && (
              <CardEx title="Es existiert noch kein Standort mit einem Kontext">
                <Typography>
                  Standorte können hier zugeordnet werden:{' '}
                  <Link to={AppPaths.StandortePflege}>Standortpflege</Link>
                </Typography>
              </CardEx>
            ))}
        </FrameBody>
        <FrameRow>
          <ButtonRow>
            <ButtonWithConfirmation
              visible={canAktivieren}
              disabled={isChanged || isNew}
              onAction={onAktivieren}
              confirmationMsg="Aktivierung durchführen"
              label="Aktivieren"
              variant="contained"
            />
            <ButtonWithConfirmation
              visible={canDeaktivieren}
              disabled={isChanged || isNew}
              onAction={onDeaktivieren}
              confirmationMsg="Deaktivierung durchführen"
              label="Deaktivieren"
              variant="contained"
            />
            <DeleteButton
              visible={canDelete}
              disabled={isChanged || isNew}
              onDelete={onDelete}
              deleteMsg={`Standortgruppe "${model.name}" wirklich löschen`}
              tooltip="Standortgruppe löschen"
            />
            <ButtonWithConfirmation
              visible={canDeaktivieren}
              disabled={isChanged || isNew}
              onAction={onSync}
              confirmationMsg="Aktualisierung durchführen"
              label="Aktualisieren"
              variant="contained"
            />
            <SaveButton onClickVoid={save} isNew={isNew} isChanged={isChanged} />
            <ReloadButton onClick={reload} isNew={isNew} isChanged={isChanged} />
          </ButtonRow>
        </FrameRow>
      </Frame>
    </StatePlane>
  )
}
