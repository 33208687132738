/* eslint-disable react/jsx-props-no-spreading */
import {Button as MUIButton, ButtonProps as MUIButtonProps, Theme} from '@mui/material'
import {TooltipWrapper} from '@utils/ui/TooltipWrapper'
import {makeStyles} from 'tss-react/mui'

const useStyles = makeStyles<{ color: Record<number, string> }>()(
  (theme: Theme, { color }): any => ({
    root: {
      color: theme.palette.getContrastText(color[500]),
      backgroundColor: color[500],
      '&:hover': {
        backgroundColor: color[700]
      }
    }
  })
)

export type ColorButtonProps = {
  visible?: boolean
  label?: any
  component?: any
  to?: any
  onClick?: MUIButtonProps['onClick']
  onClickVoid?: () => void
  disabled?: boolean
  variant?: MUIButtonProps['variant']
  style?: any
  className?: any
  size?: MUIButtonProps['size']
  startIcon?: any
  StartIcon?: React.ComponentType<any>
  autoFocus?: boolean
  href?: string
  title?: string
  tooltip?: React.ReactNode
  color?: Record<number, string>
  name?: string
}

export const ColorButton = ({
  visible = true,
  label,
  startIcon,
  tooltip,
  StartIcon,
  color,
  onClick,
  onClickVoid,
  name,
  ...props
}: ColorButtonProps) => {
  const { classes } = useStyles({ color }) as any
  if (visible) {
    const button = (
      <MUIButton
        onClick={onClick || (onClickVoid && (() => onClickVoid()))}
        startIcon={(StartIcon && <StartIcon />) || startIcon}
        className={classes.root}
        data-name={name || label}
        {...props}
      >
        {label}
      </MUIButton>
    )
    return tooltip ? (
      <TooltipWrapper title={tooltip} arrow>
        {button}
      </TooltipWrapper>
    ) : (
      button
    )
  }
  return null
}
