/* eslint-disable no-restricted-syntax */
import {amber, blue, blueGrey, green, grey, yellow} from '@mui/material/colors'
import {makeStyles} from 'tss-react/mui'

export const useDataTableStyles = makeStyles()((theme: any): any => {
  const borderColor = theme.darkMode ? grey[600] : grey[300]
  const headerColor = theme.darkMode ? '#2f2f2f' : grey[100]
  const stripeEvenColor = theme.darkMode ? '#525252' : grey[50]
  const stripeOddColor = theme.darkMode ? grey[800] : blue[100]
  const focusColor = theme.darkMode ? '#192a1b' : yellow[100]
  const hoverColor = theme.darkMode ? 'black' : amber[200]
  const selectColor = theme.darkMode ? '#0c3910' : yellow[100]
  const filterColor1 = headerColor
  const filterColor2 = theme.darkMode ? blueGrey[700] : yellow[400]
  return {
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1
    },
    table: {
      borderCollapse: 'separate!important', // wg border-scroll https://stackoverflow.com/questions/50361698/border-style-do-not-work-with-sticky-position-element
      borderSpacing: 0,
      margin: '0px 0px 16px 0px',
      width: 'calc( 100% - 16px )',
      // '& tbody > tr.Mui-selected,tbody > tr.Mui-selected:hover': {
      //   backgroundColor: alpha(selectedColor, 0.62)
      // },
      '& thead>tr>th': {
        whiteSpace: 'nowrap'
      },
      '& i.selcls': {
        fontSize: '1.28rem'
      }
    },
    nowrap: {
      '& tbody>tr>td': {
        whiteSpace: 'nowrap'
      }
    },
    normalwrap: {
      '& tbody>tr>td': {
        whiteSpace: 'normal'
      }
    },
    actionColTH: {
      textAlign: 'center!important',
      paddingBottom: '0px!important'
    },
    actionColumn: {
      display: 'flex',
      flexFlow: 'row',
      gap: '1px'
    },
    stickyheader: {
      position: 'sticky',
      top: 0,
      zIndex: 2,
      // borderBottomWidth: '2px',
      // backgroundColor: defaultColor,
      backgroundColor: theme.palette.background.paper
    },
    stickyColTH: {
      position: 'sticky',
      zIndex: 3
    },
    stickySpecialColTD: {
      // backgroundColor: theme.darkMode ? 'rgba(61,61,61,0.8)' : 'rgb(241 241 241 / 70%)'
    },
    stickyColTD: {
      position: 'sticky',
      zIndex: 1
      // backgroundColor: theme.darkMode ? 'rgba(61,61,61,0.9)' : 'rgba(252,252,252,0.9)'
    },
    stickyActionTH: {
      right: 0,
      width: 1
    },
    stickyActionTD: {
      right: 0
    },
    stickySelectColTH: {
      left: 0,
      zIndex: 3
    },
    stickySelectColTD: {
      left: 0,
      zIndex: 2
    },

    tablerow: {
      '&>td': {
        backgroundColor: theme.palette.background.paper,
        outline: 'none',
        border: '2px solid transparent'
      },
      '&.Mui-selected>td': {
        backgroundColor: selectColor
      }
    },

    tablerowHover: {
      '&:focus>td, &.Mui-selected:focus>td': {
        backgroundColor: focusColor
      },
      '&:hover>td, &.Mui-selected:hover>td': {
        backgroundColor: hoverColor
      }
    },

    focusCell: {
      '& tbody>tr>td:focus-within': {
        border: `2px solid ${green[600]}`
      }
    },
    colHeader: {
      whiteSpace: 'nowrap'
    },
    denseCols: {
      '& td': {
        padding: '3px 12px'
      },
      '& th': {
        padding: '3px 12px'
      }
    },
    colHead: {
      position: 'relative',
      // marginTop: -8,
      display: 'block'
    },

    colHeadFilterSpaceRight: {
      paddingRight: '32px!important'
    },
    colHeadFilterSpaceLeft: {
      paddingLeft: '32px!important'
    },
    colHeadFilterSpaceCenter: {
      paddingLeft: '16px!important',
      paddingRight: '16px!important'
    },
    colHeadFilterActive: {
      background: `repeating-linear-gradient( 45deg, ${filterColor1}, ${filterColor1} 4px, ${filterColor2} 4px, ${filterColor2} 8px  )`
    },
    headerCol: {
      '&:hover .colFilterOff': {
        visibility: 'visible'
      },
      '&:focus-within .colFilterOff': {
        visibility: 'visible'
      }
    },
    sortLabel: {
      marginTop: -8,
      flexDirection: 'column-reverse',
      '&>span': {
        marginTop: -12
      },
      '&>svg': {
        fontSize: '24px'
      }
    },
    colFilter: {
      position: 'absolute',
      top: -2,
      // fontSize: '80%',
      visibility: 'hidden',
      '& button': {
        margin: 0,
        padding: 0
      },
      '& svg': {
        fontSize: '14px'
      }
    },
    colFilterRight: {
      right: -16
    },
    colFilterLeft: {
      left: -16
    },
    colFilterRightOn: {
      visibility: 'visible',
      right: -28
    },
    colFilterLeftOn: {
      visibility: 'visible',
      left: -28
    },
    pager: {},
    loading: {
      padding: 0,
      border: 'none !important',
      '&>th': {
        position: 'sticky',
        border: 'none !important',
        zIndex: 3,
        padding: 0,
        '&>div': {
          marginTop: -4
        }
      }
    },
    groupByTRnormal: {
      '&> td': {
        borderBottom: 'none'
      }
    },
    groupByTRgrouper: {
      '&> td': {
        paddingTop: 24,
        paddingBottom: 0,
        borderBottomWidth: '2px!important',
        fontWeight: 'bold',
        borderBottomColor: theme.darkMode ? grey[500] : grey[400]
      }
    },
    borders: {
      '& thead>tr>th': {
        borderTop: `1.5px solid ${borderColor}`,
        borderLeft: `1.5px solid ${borderColor}`,
        borderRight: `1.5px solid ${borderColor}`,
        backgroundColor: headerColor
      },
      '& tbody>tr>td': {
        border: `1.5px solid ${borderColor}`
      }
    },
    striped: {
      '& tbody>tr:nth-of-type(even):not(.Mui-selected):not(:hover) td': {
        backgroundColor: stripeEvenColor
      },
      '& tbody>tr:nth-of-type(odd):not(.Mui-selected):not(:hover) td': {
        backgroundColor: stripeOddColor
      }
    },
    dndTop: {
      '& td': {
        // borderTop: '2.4px solid ' + blue[400] + '!important',
        paddingTop: 40
      }
    },
    dndBottom: {
      '& td': {
        // borderBottom: '2.4px solid ' + blue[400] + '!important',
        paddingBottom: 40
      }
    },
    actionHeader: {
      padding: '8px 12px 0px 12px',
      width: '10px',
      '& svg': {
        fontSize: '14px'
      }
    }
  }
})
