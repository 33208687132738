import { Edit, ExpandMore } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Divider,
  Grid,
  ListItem,
  Paper
} from '@mui/material'
import { HkmEnum } from '@one/enums/HkmEnum'
import {
  EkPreisListeImportDefinitionStepJson,
  EkPreisListImportStepId,
  LabelJson
} from '@one/typings/apiTypings'
import { useEnums } from '@utils/enums'
import { useFormState } from '@utils/formstate'
import { ApplyButton } from '@utils/ui/Buttons/ApplyButton'
import { CancelButton } from '@utils/ui/Buttons/CancelButton'
import { IconButton } from '@utils/ui/Buttons/IconButton'
import { DialogEx } from '@utils/ui/DialogEx'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { SelectEnumField } from '@utils/ui/fields/SelectEnumField'
import { SelectField } from '@utils/ui/fields/SelectField'
import { TextField } from '@utils/ui/fields/TextField'
import { useCallback, useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()({
  paper: {
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: '10px 20px'
  },
  labelSelect: {
    marginTop: '10px',
    maxWidth: '200px'
  },
  item: {
    width: 'auto',
    padding: '8px 5px'
  },
  paperTitle: {
    display: 'flex',
    alignItems: 'center'
  },
  editButton: {
    paddingLeft: '10px'
  },
  emptyText: {
    padding: '5px 0',
    fontSize: '0.8rem'
  },
  divider: { margin: '10px 0' },
  accordion: {
    margin: '0 10px'
  }
})

export interface EkPReisImportKonfigurationsDialogProps {
  open: boolean
  onClose: (step?: EkPreisListeImportDefinitionStepJson) => void
  step: EkPreisListeImportDefinitionStepJson
  labels: LabelJson[]
}

export const EkPReisImportKonfigurationsDialog = ({
  open,
  onClose,
  step,
  labels
}: EkPReisImportKonfigurationsDialogProps) => {
  const { classes } = useStyles()
  const [params, onChangeParams, getParams] = useFormState({
    name: step.name,
    status: step.status,
    freigabe: step.freigabe,
    stepId: step.stepId,
    peMeUmrechnen: step.peMeUmrechnen
  })
  const { et, items } = useEnums()
  const [showAddLabels, setShowAddLabels] = useState<string>()
  const [labelKeys, setLabelKeys] = useState([])
  const [isAccordionOpen, setIsAccordionOpen] = useState(false)

  const [labelAssignment, setLabelAssignment] = useState<Map<string, Map<number, LabelJson>>>(
    new Map()
  )
  const [unusedLabels, setUnusedLabels] = useState([])

  useEffect(() => {
    const artikelLabelIds = step.artikelLabelIds ?? {}
    let _labelKeys = Object.keys(artikelLabelIds)
    if (!_labelKeys.length) {
      _labelKeys = items(HkmEnum.EkPreisListeImportDefinitionStepLabelsType).map((item) => item.id)
    }
    setLabelKeys(_labelKeys)
    setLabelAssignment(() => {
      const nextMap = new Map()
      _labelKeys.forEach((key) => {
        const assignment = new Map()
        artikelLabelIds[key]?.forEach((labelId) => {
          const label = labels.find((label: LabelJson) => label.id === labelId)
          if (label) {
            assignment.set(labelId, label)
          }
        })
        nextMap.set(key, assignment)
      })
      return nextMap
    })

    const flatAssignetLabels = Object.keys(artikelLabelIds).reduce((list, key) => {
      return list.concat(artikelLabelIds?.[key] ?? [])
    }, [])
    const _ununsedLabels = []
    labels.forEach((label) => {
      if (!flatAssignetLabels.includes(label.id)) {
        _ununsedLabels.push(label)
      }
    })

    setUnusedLabels(_ununsedLabels)
  }, [step, labels, items])

  const onDeleteLabel = useCallback(
    (key, labelId) => () => {
      let label: LabelJson
      setLabelAssignment((prevLabelAssignment) => {
        const byKey = labelAssignment.get(key)
        label = byKey.get(labelId)
        byKey.delete(labelId)
        prevLabelAssignment.set(key, byKey)
        return prevLabelAssignment
      })

      setUnusedLabels((prev) => {
        return [...prev, label]
      })
    },
    [labelAssignment]
  )

  const onAddLabel = useCallback(
    (key, label: LabelJson) => () => {
      setLabelAssignment((prevLabelAssignment) => {
        const byKey = labelAssignment.get(key)
        byKey.set(label.id, label)
        prevLabelAssignment.set(key, byKey)
        return prevLabelAssignment
      })

      setUnusedLabels((prev) => {
        return prev.filter((prevLabel) => prevLabel.id !== label.id)
      })
    },
    [labelAssignment]
  )

  const toggleAddLabels = (key: string) => () => {
    if (key === showAddLabels) {
      setShowAddLabels(null)
    } else {
      setShowAddLabels(key)
    }
  }

  const onCancel = () => {
    onClose()
  }

  const onSave = () => {
    const artikelLabelIds = {}
    labelAssignment.forEach((assignment, key) => {
      const ids = []
      assignment.forEach((label) => {
        ids.push(label.id)
      })
      if (ids.length) {
        artikelLabelIds[key] = ids
      }
    })
    onClose({ ...getParams(), artikelLabelIds })
  }

  const onAccordionChange = (_event, isOpen) => {
    setIsAccordionOpen(isOpen)
  }

  const actions = [
    <ApplyButton key="save-btn" onClick={onSave} />,
    <CancelButton key="cancel-btn" onClick={onCancel} />
  ]

  const renderLabelAssignment = (key: string, labels: Map<number, LabelJson>) => {
    const chips = []
    const labelArr = []
    labels?.forEach((label) => {
      labelArr.push(label)
      chips.push(
        <ListItem key={label.id} className={classes.item}>
          <Chip
            label={`${label.bezeichnung} (${label.kurz})`}
            onDelete={showAddLabels === key ? onDeleteLabel(key, label.id) : null}
          />
        </ListItem>
      )
    })

    const onSelect = (e) => {
      const labelId = e.target.value
      if (labelId) {
        const label = unusedLabels.find((unusedLabel) => unusedLabel.id === labelId)
        onAddLabel(key, label)()
      }
    }

    // TODO: unusedChips in Select-Box umwandeln
    return (
      <div key={key}>
        <h4 className={classes.paperTitle}>
          {et(HkmEnum.EkPreisListeImportDefinitionStepLabelsType, key)}
          <IconButton
            className={classes.editButton}
            icon={<Edit />}
            color={showAddLabels === key ? 'primary' : 'default'}
            onClick={toggleAddLabels(key)}
            size="small"
          />
        </h4>
        <Paper component="ul" className={classes.paper}>
          {showAddLabels === key && (
            <SelectField
              className={classes.labelSelect}
              fullWidth
              label="Label wählen"
              displayEmpty={false}
              required
              options={unusedLabels}
              onChange={onSelect}
              renderItem={(label: LabelJson) => `${label.bezeichnung} (${label.kurz})`}
            />
          )}
          {chips.length > 0 ? chips : <p className={classes.emptyText}>Kein Labels ausgewählt</p>}
        </Paper>
        <Divider className={classes.divider} light />
      </div>
    )
  }

  return (
    <DialogEx
      open={open}
      onClose={onCancel}
      actions={actions}
      title="Bearbeiten"
      disabledFullscreen
      maxWidth="md"
      fullWidth
      height={isAccordionOpen ? '600px' : '350px'}
    >
      <Grid container direction="row" spacing={2} padding={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="name"
            value={params.name}
            onChange={onChangeParams}
            label="Name"
            required
          />
        </Grid>
        {step.optional && (
          <Grid item xs={12}>
            <SelectEnumField
              label="Status"
              name="status"
              value={params.status}
              onChange={onChangeParams}
              enumType={HkmEnum.EkPreisListeImportDefinitionStepStatus}
              fullWidth
              required
            />
          </Grid>
        )}
        {!step.needVerifikation && (
          <Grid item xs={12}>
            <SelectEnumField
              label="Freigabe"
              name="freigabe"
              value={params.freigabe}
              onChange={onChangeParams}
              enumType={HkmEnum.EkPreisListeImportDefinitionStepSchrittfreigabe}
              fullWidth
              required
            />
          </Grid>
        )}
        {step.stepId === EkPreisListImportStepId.STEP5EKMEDIFF && (
          <Grid item xs={12}>
            <Checkbox
              label="Mengeneinheiten automatisch umrechnen"
              name="peMeUmrechnen"
              checked={params.peMeUmrechnen}
              onChange={onChangeParams}
            />
          </Grid>
        )}
        <Grid item xs={12}></Grid>
      </Grid>
      <div className={classes.accordion}>
        <Accordion onChange={onAccordionChange}>
          <AccordionSummary expandIcon={<ExpandMore />} id="label-assignment">
            Label zuweisen
          </AccordionSummary>
          <AccordionDetails>
            {labelKeys.map((key) => {
              const entry = labelAssignment.get(key)
              return renderLabelAssignment(key, entry)
            })}
          </AccordionDetails>
        </Accordion>
      </div>
    </DialogEx>
  )
}
