import { Typography } from '@mui/material'
import { api } from '@one/api'
import { AppPaths } from '@one/AppPaths'
import { EkPreisVorgangErstellenDialog } from '@one/components/ArtikelAbo/EkPreisVorgangErstellenDialog'
import { enrichKondiDefinition } from '@one/components/EkPreis/EKPreisPflege/model/EkPreiseUsecase'
import {
  EkKonditionenRabattgruppenListeChangeJson,
  EkKonditionenRabattgruppenListeEintragJson,
  EkKonditionenRabattgruppenListeJson,
  EkRabattgruppenListeBearbeitenJson
} from '@one/typings/apiTypings'
import { formatDateTime } from '@utils/dateutils'
import { useModelMgr } from '@utils/modelmgr'
import { AppContext } from '@utils/ui/App/AppContext'
import { RouteContext } from '@utils/ui/App/AppRoute'
import { AppRouteCtx } from '@utils/ui/App/AppRouteCtx'
import { Button } from '@utils/ui/Buttons/Button'
import { ButtonRow } from '@utils/ui/Buttons/ButtonRow'
import { DeleteButton } from '@utils/ui/Buttons/DeleteButton'
import { ReloadButton } from '@utils/ui/Buttons/ReloadButton'
import { SaveButton } from '@utils/ui/Buttons/SaveButton'
import { useDialogAnker } from '@utils/ui/DialogAnker'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { StatePlane } from '@utils/ui/planes/StatePlane'
import { checkRequired, clearTimer, restartTimer } from '@utils/utils'
import { useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { EkRabattgruppenForm } from './EkRabattgruppenForm'
import { EkRabattgruppenMassenPflegeTable } from './EkRabattgruppenMassenPflegeTable'
import {
  EkKonditionenRabattgruppenListeEditJson,
  EKRabattgruppenMassenPflegeUsecase
} from './model/EkRabattgruppeMassenPflegeModel'

const validate = (model: any) => {
  const errors = {} as any
  checkRequired(model, errors, 'gueltigVon', 'Gültigkeitzeitpunkt der Rabatte')
  return errors
}

export const EkRabattgruppenMassenPflegeView = () => {
  const { lieferantId } = useContext(RouteContext) as any
  const navigate = useNavigate()
  const { invalidateRoute, visible: viewVisible } = useContext(AppRouteCtx)
  const saveTimerRef = useRef<any>(undefined)
  const [DlgAnker, showDialog] = useDialogAnker()
  const createListe = useRef(false)
  const onNavigate = useRef(false)

  const { isAllianz } = useContext(AppContext)

  const {
    model,
    envelope,
    onValueChange,
    uiLock,
    remove,
    save,
    errors,
    reload,
    isChanged,
    isNew,
    dispatch,
    post
  } = useModelMgr<
    EkKonditionenRabattgruppenListeJson,
    EkKonditionenRabattgruppenListeEditJson,
    EkRabattgruppenListeBearbeitenJson,
    EkKonditionenRabattgruppenListeChangeJson
  >({
    noid: true,
    api,
    title: 'EK-Rabattgruppen',
    rest: 'ekrabattgruppenliste',
    restps: { lieferantId },
    unwrapField: 'ekRabattgruppenListe',
    init: {},
    reducer: EKRabattgruppenMassenPflegeUsecase.reducer,
    saveMutator: EKRabattgruppenMassenPflegeUsecase.saveMutator,
    editMutator: EKRabattgruppenMassenPflegeUsecase.editMutator,
    validate
  })

  const onRabattValueChange = useCallback(
    (
      model:
        | EkKonditionenRabattgruppenListeEintragJson
        | Set<EkKonditionenRabattgruppenListeEintragJson>,
      field: string,
      value: number | null
    ) =>
      dispatch({ type: EKRabattgruppenMassenPflegeUsecase.CHANGERABATTVALUE, model, field, value }),
    [dispatch]
  )

  const kondiDef = useMemo(
    () => enrichKondiDefinition(envelope?.konditionDefinition, isAllianz),
    [envelope?.konditionDefinition, isAllianz]
  )

  const needCreate = useMemo(
    () => envelope.state?.mainMessage?.messageId?.id === 'ID_NEED_CREATE',
    [envelope?.state]
  )

  const onDeleteClick = useCallback(() => {
    if (model?.id) {
      clearTimer(saveTimerRef)
      return remove({
        ovrId: model.id,
        onRemoved: () => {
          invalidateRoute()
          navigate(AppPaths.EkRabattgruppenUebersicht)
        }
      })
    }
  }, [remove, model?.id, invalidateRoute, navigate])

  const onFreigeben = useCallback(
    (ekPreislisteErzeugen: boolean = false) => {
      clearTimer(saveTimerRef)
      post({
        srv: 'freigeben',
        params: {
          id: model.id,
          version: model.version,
          ekPreislisteErzeugen
        },
        onPost: onNavigate.current
          ? (data) => {
              const artikelListeRef = data.artikelListeEkPreiseRef
              if (artikelListeRef) {
                onNavigate.current = false
                createListe.current = false
                navigate(AppPaths.EkPreisAnlageFn({ artikelListeRef }))
                return true
              }
              return false
            }
          : null
      })
    },
    [model.id, model.version, post, navigate]
  )

  useEffect(() => {
    if (!needCreate) {
      restartTimer(
        saveTimerRef,
        () => {
          save()
        },
        5000
      )
    }
  }, [model, save, needCreate])

  const handleFreigeben = useCallback(() => {
    showDialog((open, onClose) => (
      <EkPreisVorgangErstellenDialog
        open={open}
        onClose={onClose}
        onCheck={(checked: boolean) => {
          createListe.current = checked
        }}
        onNavigate={() => {
          onNavigate.current = true
        }}
        onSave={() => onFreigeben(createListe.current)}
        hatPreisaenderungen={false}
        confirmLabel="Freigeben"
        navigateLabel="Freigeben und weiter"
      />
    ))
  }, [showDialog, onFreigeben])

  return (
    <StatePlane uiLock={uiLock}>
      <Frame space>
        <FrameRow>
          <EkRabattgruppenForm
            lieferant={model.lieferant}
            gueltigVon={model.gueltigVon}
            errors={errors}
            onValueChange={onValueChange}
            needCreate={needCreate}
          />
        </FrameRow>
        <FrameBody>
          {!needCreate && (
            <EkRabattgruppenMassenPflegeTable
              values={model?.eintraege}
              kondiDefinition={kondiDef}
              onRabattValueChange={onRabattValueChange}
              readonly={model.freigegebenUm != null}
              rabattgruppen={model.rabattgruppen}
              preisEbenen={model.preisEbenen}
              staffelMengen={model.staffelMengen}
            />
          )}
        </FrameBody>
        <FrameRow>
          <ButtonRow>
            {model.freigegebenUm && (
              <Typography
                paddingTop={1}
                paddingRight={1}
              >{`Die Rabattgruppenversionen wurden am ${formatDateTime(
                model.freigegebenUm
              )} freigegeben`}</Typography>
            )}
            <Button
              disabled={isNew || isChanged}
              visible={!needCreate && model.freigegebenUm == null}
              label="Freigeben"
              variant="text"
              color="primary"
              tooltip="Schließt die Bearbeitung ab und erzeugt neue EK-Rabattgruppen-Versionen."
              onClick={handleFreigeben}
            />
            <DeleteButton
              onDelete={onDeleteClick}
              isNew={isNew}
              deleteMsg="Liste und alle erfassten Eingaben löschen"
              tooltip="Liste löschen"
              disabled={isNew || isChanged}
              visible={!needCreate && model.freigegebenUm == null}
            />
            <SaveButton
              onClickVoid={save}
              anlegen={needCreate}
              isChanged={isChanged}
              visible={model.freigegebenUm == null}
            />
            <ReloadButton
              onClick={reload}
              isNew={isNew}
              isChanged={isChanged}
              visible={model.freigegebenUm == null}
            />
          </ButtonRow>
        </FrameRow>
      </Frame>
      <DlgAnker />
    </StatePlane>
  )
}
