import { Grid2 as Grid } from '@mui/material'
import { TextField } from '@utils/ui/fields/TextField'
import { ArtikelListeTypField } from '@one/components/ArtikelListe/common/ArtikelListeTypField'

export interface ArtikelListeSuchFormProps {
  searchValues: any
  onChange: (e?: any) => void
}

export const ArtikelListeSuchForm = ({ searchValues, onChange }: ArtikelListeSuchFormProps) => (
  <Grid container spacing={3}>
    <Grid size={{ xs: 6 }}>
      <ArtikelListeTypField onChange={onChange} name="typ" value={searchValues.typ} type="real" />
    </Grid>
    <Grid size={{ xs: 6 }}>
      <TextField fullWidth onChange={onChange} name="name" label="Name" value={searchValues.name} />
    </Grid>
  </Grid>
)
