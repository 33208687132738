import { AppPaths } from '@one/AppPaths'
import { EventNames } from '@one/EventNames'
import { UserRoles } from '@one/UserRoles'
import { api } from '@one/api'
import { LieferantDisplayJson, LieferantSearcherCriteriaJson } from '@one/typings/apiTypings'
import { useObserver } from '@utils/observer'
import { useSearcherState } from '@utils/searcher'
import { AppContext } from '@utils/ui/App/AppContext'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { useContext } from 'react'
import { useNavigate } from 'react-router'
import { LieferantenUebersichtParams } from './LieferantenUebersichtParams'
import { LieferantenUebersichtTable } from './LieferantenUebersichtTable'

export const LieferantenUebersichtView = () => {
  const { isAllianzMitglied, isPIMModel, checkUserRole } = useContext(AppContext)

  const zentralName = isAllianzMitglied ? 'forAbo' : 'zentral'

  const navigate = useNavigate()

  const eigenpflegeAllowed = isPIMModel && checkUserRole(UserRoles.EIGENPFLEGE_EDITOR)

  const { criteria, onCriteriaChange, result, search } = useSearcherState<
    LieferantSearcherCriteriaJson,
    LieferantDisplayJson
  >({
    api,
    doOpen: false,
    url: '/lieferant',
    initialParams: {
      mitStatistik: false,
      [zentralName]: true,
      dezentral: true,
      notlieferant: null
    }
  })

  const onCreateEigenlistungsLieferant = () => {
    navigate(AppPaths.EigenlistungsLieferantPflegeFn('neu'))
  }

  useObserver(EventNames.EIGENLISTUNGSLIEFERANT, () => {
    search()
  })

  return (
    <Frame space>
      <FrameRow>
        <LieferantenUebersichtParams
          onSearch={search}
          value={criteria}
          onChange={onCriteriaChange}
          zentralName={zentralName}
          onCreateEigenlistungsLieferant={
            eigenpflegeAllowed ? onCreateEigenlistungsLieferant : undefined
          }
        />
      </FrameRow>
      <FrameBody>
        <LieferantenUebersichtTable result={result} />
      </FrameBody>
    </Frame>
  )
}
