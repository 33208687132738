import { AppPaths } from '@one/AppPaths'
import { EventNames } from '@one/EventNames'
import { api } from '@one/api'
import { formatLieferant, formatNeonKontext } from '@one/components/common/formatters'
import {
  ArtikelAbonniertStatus,
  EigenlistungsArtikelBearbeitenJson,
  SeArtikelJson
} from '@one/typings/apiTypings'
import { onChangeWrapper, useModelMgr } from '@utils/modelmgr'
import { RouteContext } from '@utils/ui/App/AppRoute'
import { ButtonRow } from '@utils/ui/Buttons/ButtonRow'
import { DeleteButton } from '@utils/ui/Buttons/DeleteButton'
import { ReloadButton } from '@utils/ui/Buttons/ReloadButton'
import { SaveButton } from '@utils/ui/Buttons/SaveButton'
import { CardEx } from '@utils/ui/CardEx'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { IndexPanel, IndexPanelItem } from '@utils/ui/IndexPanel'
import { StatePlane } from '@utils/ui/planes/StatePlane'
import React, { useContext, useMemo, useRef } from 'react'
import { PimArtikelBezeichungMemo } from './PimArtikelBezeichnung'
import { PimArtikelComplianceMemo } from './PimArtikelCompliance'
import { PimArtikelComplianceLaenderMemo } from './PimArtikelComplianceLaender'
import { PimArtikelDisplayArtikelMemo } from './PimArtikelDisplayArtikel'
import { PimArtikelFlieseMemo } from './PimArtikelFliese'
import { PimArtikelHagebauMemo } from './PimArtikelHagebau'
import { PimArtikelKennzeichenMemo } from './PimArtikelKennzeichen'
import { PimArtikelKopfMemo } from './PimArtikelKopf'
import { PimArtikelLieferantMemo } from './PimArtikelLieferant'
import { PimArtikelLieferantenMemo } from './PimArtikelLieferanten'
import { PimArtikelMasseMemo } from './PimArtikelMasse'
import { PimArtikelMengeneinheitenMemo } from './PimArtikelMengeneinheiten'
import { PimArtikelPackstueckeMemo } from './PimArtikelPackstuecke'
import { PimArtikelPflanzenMemo } from './PimArtikelPflanzen'
import { PimArtikelProduktMemo } from './PimArtikelProdukt'
import { PimArtikelSperrenMemo } from './PimArtikelSperren'
import { PimArtikelSteuerindikationenMemo } from './PimArtikelSteuerindikationen'
import { EigenlistungsArtikelUseCase } from './model/EigenlistungsArtikelUseCase'
import { SelectField } from '@utils/ui/fields/SelectField'
import { useNavigate } from 'react-router-dom'
import { Grid2, Typography } from '@mui/material'
import { TooltipEx, TooltipWrapper } from '@utils/ui/TooltipWrapper'
import { PimArtikelZusatzEANsMemo } from '@one/components/Artikel/EigenlistungsArtikel/PimArtikelZusatzEANs'
import { ArtikelAbonniertStatusMedal } from '@one/components/common/ArtikelAbonniertStatusMedal'
import { useEnums } from '@utils/enums'
import { ThemeContext } from '@utils/ui/Theme'
import { Medal } from '@utils/ui/fields/Medal'
import { yellow } from '@mui/material/colors'
import { PimArtikelKontextDatenMemo } from '@one/components/Artikel/EigenlistungsArtikel/PimArtikelKontextDaten'
import { Switch } from '@utils/ui/fields/Switch'

export const EigenlistungsArtikelPflegeView = () => {
  const { id, kontext } = useContext(RouteContext) as any

  const { et } = useEnums()
  const { darkMode } = useContext(ThemeContext)

  const navigate = useNavigate()

  const titleRef = useRef<string>(null)

  const {
    model,
    isNew,
    isChanged,
    envelope,
    errors,
    dispatch,
    save,
    reload,
    uiLock,
    remove,
    onValueChange
  } = useModelMgr<EigenlistungsArtikelBearbeitenJson, SeArtikelJson>({
    id,
    idField: 'suchArtikelId',
    apiIdField: 'id',
    api,
    title: titleRef.current,
    rest: 'artikel/eigenlistung',
    restps: { kontext },
    unwrapField: 'seArtikel',
    errorStyle: 'structured',
    eventName: EventNames.EIGENLISTUNGSARTIKEL,
    reducer: EigenlistungsArtikelUseCase.reducer,
    validate: EigenlistungsArtikelUseCase.validate,
    onValueChanged: EigenlistungsArtikelUseCase.onValueChanged
  })

  titleRef.current =
    (model?.eigenlistung == null && 'Lade...') ||
    (model?.eigenlistung ? 'Eigenlistungsartikel' : 'ERP-Artikel ergänzen')

  const readonly = model?.eigenlistung && model?.aboStatus === ArtikelAbonniertStatus.EINGESCHL

  const mengeneinheitenFiltered = useMemo(
    () =>
      model.pimArtikel?.version?.mengeneinheiten
        ?.map((me) => me.pimMengeneinheit)
        .filter(Boolean)
        .filter((e, i, a) => a.findIndex((e1) => e1.id === e.id) === i) ?? [],
    [model.pimArtikel?.version?.mengeneinheiten]
  )

  console.log('envelope?.kontexte', envelope?.kontexte)

  const header = useMemo(
    () => (
      <Grid2 container spacing={3}>
        <Grid2>
          {isNew
            ? `Neuer ${titleRef.current}`
            : `${titleRef.current} ${model?.pimArtikel?.hageNummer ?? ''} ${model?.pimArtikel?.version?.sprache?.lieferschein1 ?? ''}`}
        </Grid2>
        {model?.aboStatus &&
          (model?.aboStatus === ArtikelAbonniertStatus.EINGESCHL ||
            model?.aboStatus === ArtikelAbonniertStatus.UNTERBROCHEN) && (
            <Grid2>
              <ArtikelAbonniertStatusMedal value={model?.aboStatus} et={et} darkMode={darkMode} />
            </Grid2>
          )}
        <Grid2>
          <SelectField
            value={model?.pimArtikel?.versionKontext?.kontext ?? kontext}
            onChange={(e) => {
              const val = envelope?.kontexte.find((k) => k.pimKontext.kontext === e.target.value)
              if (val) {
                navigate(
                  AppPaths.EigenlistungsArtikelPflegeFn(
                    id === 'neu' ? id : envelope?.display?.id,
                    val.pimKontext.kontext
                  )
                )
              }
            }}
            notNull
            options={envelope?.kontexte}
            optionValue={(k) => k.pimKontext.kontext}
            renderItem={(k) => {
              if (k.id == null) {
                return (
                  <span>
                    <TooltipEx title="Nicht vorhanden, kann bei Auswahl angelegt werden">
                      <i>{`${formatNeonKontext(k.pimKontext)} - N/V`}</i>
                    </TooltipEx>
                  </span>
                )
              }
              if (k.seErgaenzung) {
                return `${formatNeonKontext(k.pimKontext)}${k.seErgaenzung ? ' - ERP-Ergänzung' : ''}`
              }
              return formatNeonKontext(k.pimKontext)
            }}
            renderSelected={(k) =>
              `Kontext: ${formatNeonKontext(k.pimKontext)}${k.seErgaenzung ? ' - ERP-Ergänzung' : ''}`
            }
            disabled={isChanged}
            withButtons
          />
        </Grid2>
        {((model?.pimArtikel?.id != null && model?.pimArtikel?.versionKontext?.id == null) ||
          model?.pimArtikel?.versionKontext?.seErgaenzung) && (
          <Grid2>
            <Medal
              text="Achtung! Dies ist kein Kontext der Hagebau-Listung. Sie sind für die Pflege der Daten selber verantwortlich"
              variant="square"
              backgroundColor={yellow[200]}
            />
          </Grid2>
        )}
      </Grid2>
    ),
    [
      darkMode,
      envelope?.display?.id,
      envelope?.kontexte,
      et,
      id,
      isChanged,
      isNew,
      kontext,
      model?.aboStatus,
      model?.pimArtikel?.hageNummer,
      model?.pimArtikel?.id,
      model?.pimArtikel?.version?.sprache?.lieferschein1,
      model?.pimArtikel?.versionKontext?.id,
      model?.pimArtikel?.versionKontext?.kontext,
      model?.pimArtikel?.versionKontext?.seErgaenzung,
      navigate,
      readonly
    ]
  )

  const content = [
    {
      id: 'kopf',
      label: 'Kopf',
      exclude: model.pimArtikel == null,
      body: () => (
        <PimArtikelKopfMemo
          pimArtikel={model.pimArtikel}
          onChange={onValueChange}
          errors={errors}
          readonly={readonly || !model?.eigenlistung}
        />
      )
    },
    {
      id: 'kennzeichen',
      label: 'Kennzeichen',
      exclude: model.pimArtikel?.version?.sprache == null,
      body: () => (
        <PimArtikelKennzeichenMemo
          pimArtikelVersion={model.pimArtikel.version}
          onChange={onValueChange}
          errors={errors}
          mode={(readonly && 'readonly') || (model?.eigenlistung ? 'edit' : 'amend')}
          warengruppe={model.warengruppe}
          sparte={model.sparte}
          grundpreisFaktor={model.grundpreisFaktor}
          grundpreisMengeneinheit={model.grundpreisMengeneinheit}
          mengeneinheiten={mengeneinheitenFiltered}
        />
      )
    },
    {
      id: 'bezeichner',
      label: 'Bezeichnungen',
      exclude: model.pimArtikel?.version?.sprache == null,
      body: () => (
        <PimArtikelBezeichungMemo
          pimArtikelVersionSprache={model.pimArtikel.version.sprache}
          pimArtikelVersion={model.pimArtikel.version}
          onChange={onValueChange}
          errors={errors}
          readonly={readonly || !model?.eigenlistung}
        />
      )
    },
    {
      id: 'artikelkontext',
      label: 'Kontext',
      exclude: model.pimArtikel?.versionKontext == null,
      body: () => (
        <PimArtikelKontextDatenMemo
          pimArtikelVersionKontext={model?.pimArtikel?.versionKontext}
          onChange={onValueChange}
          errors={errors}
          readonly={readonly || !model?.eigenlistung}
        />
      )
    },
    {
      id: 'mengeneinheiten',
      label: 'Mengeneinheiten',
      exclude: model.pimArtikel == null,
      body: () => (
        <PimArtikelMengeneinheitenMemo
          model={model}
          onChange={onValueChange}
          mengeneinheiten={mengeneinheitenFiltered}
          mengeneinheitenAll={envelope.mengeneinheiten}
          dispatch={dispatch}
          errors={errors}
          readonly={readonly}
          eigenlistung={model?.eigenlistung}
        />
      )
    },
    {
      id: 'hagebaulogistik',
      label: 'Hagebau Logistik',
      exclude: model.pimArtikel == null,
      body: () => (
        <PimArtikelHagebauMemo
          pimArtikel={model.pimArtikel}
          onChange={onValueChange}
          errors={errors}
          readonly={readonly || !model?.eigenlistung}
        />
      )
    },
    {
      id: 'masse',
      label: 'Maße',
      exclude: model.pimArtikel == null,
      body: () => (
        <PimArtikelMasseMemo
          pimArtikel={model.pimArtikel}
          onChange={onValueChange}
          errors={errors}
          readonly={readonly || !model?.eigenlistung}
        />
      )
    },
    {
      id: 'sperren',
      label: 'Sperren',
      exclude: model.pimArtikel == null,
      body: () => (
        <PimArtikelSperrenMemo
          pimArtikel={model.pimArtikel}
          onChange={onValueChange}
          errors={errors}
          readonly={readonly || !model?.eigenlistung}
        />
      )
    },
    {
      id: 'produkt',
      label: 'Produkt',
      exclude: model.pimArtikel == null,
      body: () => (
        <PimArtikelProduktMemo
          produkt={model.pimArtikel.version.pimProdukt}
          onChange={onValueChange}
          errors={errors}
          readonly={readonly || !model?.eigenlistung}
        />
      )
    },
    {
      id: 'display',
      label: 'Display',
      exclude:
        model.pimArtikel == null ||
        (!(model.pimArtikel.version.displayArtikel?.length > 0) && !model?.eigenlistung),
      body: () => (
        <PimArtikelDisplayArtikelMemo
          displayArtikel={model.pimArtikel.version.displayArtikel}
          onChange={onValueChange}
          dispatch={dispatch}
          errors={errors}
          readonly={readonly || !model?.eigenlistung}
        />
      )
    },
    {
      id: 'compliance',
      label: 'Compliance',
      exclude: model.pimArtikel?.version?.compliance == null,
      body: () => (
        <PimArtikelComplianceMemo
          compliance={model.pimArtikel.version.compliance}
          onChange={onValueChange}
          errors={errors}
          readonly={readonly || !model?.eigenlistung}
        />
      ),
      content: [
        {
          id: 'compliance-laender',
          label: 'Länder',
          body: () => (
            <PimArtikelComplianceLaenderMemo
              laender={envelope.laender}
              complianceLaender={model.pimArtikel.version.compliance?.laender}
              onChange={onValueChange}
              dispatch={dispatch}
              errors={errors}
              readonly={readonly || !model?.eigenlistung}
            />
          )
        }
      ]
    },
    {
      id: 'fliese',
      label: 'Fliese',
      exclude:
        model.pimArtikel?.version == null ||
        (model.pimArtikel?.version.fliese == null && !model?.eigenlistung),
      body: () => (
        <PimArtikelFlieseMemo
          fliese={model.pimArtikel.version.fliese}
          dispatch={dispatch}
          onChange={onValueChange}
          errors={errors}
          readonly={!model?.eigenlistung}
        />
      )
    },
    {
      id: 'pflanze',
      label: 'Pflanze',
      exclude:
        model.pimArtikel?.version == null ||
        (model.pimArtikel?.version.pflanze == null && !model?.eigenlistung),
      body: () => (
        <PimArtikelPflanzenMemo
          pflanze={model.pimArtikel.version.pflanze}
          dispatch={dispatch}
          onChange={onChangeWrapper(onValueChange, 'pimArtikel.version.pflanze')}
          errors={errors}
          readonly={readonly || !model?.eigenlistung}
        />
      )
    },
    {
      id: 'lieferanten',
      label: 'Lieferanten',
      exclude: model.pimArtikel == null,
      append: true,
      body: () => <PimArtikelLieferantenMemo dispatch={dispatch} readonly={readonly} />,
      content: model.pimArtikel?.version?.lieferanten?.map(
        (pimArtikelVersionLieferant, idx) =>
          ({
            id: `lieferant-${idx}` /*lieferant-${pimArtikelVersionLieferant.id}*/,
            label: (
              <Typography
                style={
                  pimArtikelVersionLieferant?.kontext?.kontext == null && {
                    textDecoration: 'line-through'
                  }
                }
              >
                {formatLieferant(pimArtikelVersionLieferant?.lieferant)}
              </Typography>
            ),
            body: () => (
              <PimArtikelLieferantMemo
                hageNummer={isNew ? null : model.pimArtikel.hageNummer}
                pimArtikelVersionLieferant={pimArtikelVersionLieferant}
                mengeneinheiten={mengeneinheitenFiltered}
                laender={envelope.laender}
                kontext={model.pimArtikel.versionKontext.kontext}
                onChange={onValueChange}
                dispatch={dispatch}
                errors={errors}
                idx={idx}
                readonly={readonly}
                hauptlieferantId={model.hauptlieferantId}
              />
            )
          }) as IndexPanelItem
      )
    },
    {
      id: 'zusatzEANs',
      label: 'Zusatz EANs',
      exclude: model.pimArtikel == null,
      body: () => (
        <PimArtikelZusatzEANsMemo
          zusatzEANs={model.pimArtikel.version.eans}
          onChange={onValueChange}
          dispatch={dispatch}
          errors={errors}
          readonly={readonly || !model?.eigenlistung}
        />
      )
    },
    {
      id: 'steuerindikationen',
      label: 'Steuerindikationen',
      exclude: model.pimArtikel == null,
      body: () => (
        <PimArtikelSteuerindikationenMemo
          steuerLaender={model.pimArtikel.version.laender}
          laender={envelope.laender}
          dispatch={dispatch}
          onChange={onValueChange}
          errors={errors}
          readonly={readonly || !model?.eigenlistung}
        />
      )
    },
    {
      id: 'packstuecke',
      label: 'Packstücke',
      exclude: !(model?.pimArtikel?.version?.packstuecke?.length > 0),
      body: () => (
        <PimArtikelPackstueckeMemo
          packstuecke={model.pimArtikel.version.packstuecke}
          onChange={onValueChange}
          dispatch={dispatch}
          errors={errors}
          readonly={readonly || !model?.eigenlistung}
        />
      )
    }
  ] as IndexPanelItem[]

  const anlageSwitch = useMemo(
    () =>
      envelope?.seArtikel?.eigenlistungInAnlage ? (
        <TooltipWrapper
          arrow
          title="Solange der Artikel unfertig ist, wird der Artikel nicht an das ERP übertragen. Sobald der Modus beendet wird, wird der Artikel zum Export markiert. Der Modus kann nicht wieder eingeschaltet werden, sobald er ausgeschaltet wurde!"
        >
          <Switch
            label="Unfertiger Artikel"
            name="eigenlistungInAnlage"
            checked={model.eigenlistungInAnlage}
            onChange={onValueChange}
          />
        </TooltipWrapper>
      ) : null,
    [envelope?.seArtikel?.eigenlistungInAnlage, model.eigenlistungInAnlage, onValueChange]
  )

  return (
    <StatePlane uiLock={uiLock}>
      <Frame space>
        <FrameRow>
          <CardEx
            title={header}
            backLink
            overviewLink={AppPaths.ArtikelUebersicht}
            contentStyle={{ paddingBottom: 0 }}
          />
        </FrameRow>
        <FrameBody>
          <IndexPanel content={content} />
        </FrameBody>
        <FrameRow>
          <ButtonRow>
            {anlageSwitch}
            <DeleteButton isNew={isNew} remove={remove} visible={model?.eigenlistung} />
            <SaveButton isNew={isNew} isChanged={isChanged} onClickVoid={save} />
            <ReloadButton isNew={isNew} isChanged={isChanged} onClickVoid={reload} />
          </ButtonRow>
        </FrameRow>
      </Frame>
    </StatePlane>
  )
}
