/* eslint-disable complexity */
import {EnumInfo, EnumItemType, useEnums} from '@utils/enums'
import {Actions} from '@utils/ui/Action'
import {SelectField} from './SelectField'

export type SelectEnumFieldProps = {
  enumType: EnumInfo
  id?: string
  label?: string
  value?: any
  onChange?: (e?: any) => void
  filter?: (opt: EnumItemType) => boolean
  name?: string
  emptyText?: string
  error?: any
  helperText?: string
  title?: string
  displayEmpty?: boolean
  fullWidth?: boolean
  required?: boolean
  disabled?: boolean
  actions?: Actions
  noClear?: boolean
  notNull?: boolean
}

export const SelectEnumField = ({
  id,
  label,
  enumType,
  value,
  onChange,
  filter,
  name,
  emptyText,
  error,
  helperText,
  title,
  displayEmpty = true,
  fullWidth,
  disabled,
  required,
  actions,
  noClear,
  notNull
}: SelectEnumFieldProps) => {
  const { items } = useEnums()
  const filtered = filter ? items(enumType).filter(filter) : items(enumType)
  return (
    <SelectField
      id={id}
      label={label}
      options={filtered}
      value={value}
      name={name}
      onChange={onChange}
      displayEmpty={displayEmpty}
      disabled={disabled}
      fullWidth={fullWidth}
      emptyText={emptyText}
      optionText="text"
      title={title}
      error={error}
      helperText={helperText}
      required={required}
      actions={actions}
      noClear={noClear}
      notNull={notNull}
    />
  )
}
