/* eslint-disable no-plusplus */
import PropTypes from 'prop-types'
import {createContext, ReactElement, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState} from 'react'
import {AppRouteCtx} from './App/AppRouteCtx'

export const DialogAnker = ({ _ref, visiblityCheck }) => {
  const [open, setOpen] = useState(false)
  const [active, setActive] = useState(false)
  const [dyn, setDyn] = useState(null)
  const { visible: routeVisible } = useContext(AppRouteCtx)

  const onClose = useCallback((closeFnc) => {
    return (...args) => {
      setOpen(false)
      setTimeout(() => {
        setActive(false)
        setTimeout(() => {
          setDyn(null)
        }, 250)
      }, 250)
      if (closeFnc) {
        closeFnc(...args)
      }
    }
  }, [])

  const isOpen = useCallback(() => {
    return open
  }, [open])

  const show = useCallback((p) => {
    setDyn(() => p)
    setActive(true)
    setOpen(true)
  }, [])

  useImperativeHandle(
    _ref,
    () => ({
      show,
      isOpen
    }),
    [show, isOpen]
  )

  const openState = visiblityCheck ? open && routeVisible : open

  return active && dyn && dyn(openState, onClose)
}

DialogAnker.propTypes = {
  _ref: PropTypes.any.isRequired
}

export const useDialogAnker = (
  visiblityCheck: boolean = true // wenn true, wird der Dialog nur angezeigt, wenn die Route sichtbar ist
): [
  Anker: any,
  show: (
    arg: (
      visible: boolean,
      onClose: (arg?: (arg?: any) => void) => (arg?: any) => void
    ) => ReactElement
  ) => void
] => {
  const ref = useRef(undefined)

  const show = useCallback((p) => {
    //@ts-ignore
    ref.current.show(p)
  }, [])

  const Anker = useCallback(
    () => <DialogAnker _ref={ref} visiblityCheck={visiblityCheck} />,
    [visiblityCheck]
  )

  return [Anker, show]
}

// ///////////////////////////////////////////////////////////////////////////////////////////////////////////
export type GlobalDialogContextType = {
  showDlg: (render: any) => void
  currentRouteKey: string | null
  setCurrentRouteKey: (key: string | null) => void
}
export const GlobalDialogContext = createContext<GlobalDialogContextType>({
  showDlg: null,
  currentRouteKey: null,
  setCurrentRouteKey: null
})

const DialogAnker2 = ({ render, onClose, routeKey }) => {
  const [open, setOpen] = useState(true)
  const [active, setActive] = useState(true)
  const { currentRouteKey } = useContext(GlobalDialogContext)
  const visible = !routeKey || routeKey === currentRouteKey
  const doClose = useCallback(
    (closeFnc) =>
      (...args) => {
        setOpen(false)
        setTimeout(() => {
          setActive(false)
          setTimeout(() => {
            if (closeFnc) {
              closeFnc(...args)
            }
            onClose()
          }, 250)
        }, 250)
      },
    [onClose]
  )

  return useMemo(
    () => active && render?.(open && visible, doClose),
    [render, doClose, active, open, visible]
  )
}

export const DialogProvider = ({ children }) => {
  const idRef = useRef(1)
  const [dialogs, setDialogs] = useState(new Map())
  const [currentRouteKey, setCurrentRouteKey] = useState<string>()
  const onClose = useCallback(
    (id) => () => {
      setDialogs((old) => {
        const c = new Map(old)
        c.delete(id)
        return c
      })
    },
    []
  )
  const showDlg = useCallback(
    (render) => {
      const id = idRef.current++
      setDialogs((old) => {
        const c = new Map(old)
        c.set(
          id,
          <DialogAnker2 key={id} routeKey={currentRouteKey} render={render} onClose={onClose(id)} />
        )
        return c
      })
    },
    [onClose, currentRouteKey]
  )

  // die Nutzung einer ref anstelle eines memo() optimiert unnötige rerenderings, wenn es keine Dialoge gibt
  const ctx = useRef({
    showDlg,
    setCurrentRouteKey,
    currentRouteKey
  })

  useEffect(() => {
    if (dialogs.size) {
      ctx.current = {
        showDlg,
        currentRouteKey,
        setCurrentRouteKey
      }
    }
  }, [currentRouteKey, dialogs, showDlg])

  return (
    <GlobalDialogContext.Provider value={ctx.current}>
      {children}
      {Array.from(dialogs.values())}
    </GlobalDialogContext.Provider>
  )
}

export const useGlobalDialogAnker = () => {
  const { showDlg } = useContext(GlobalDialogContext)
  return useCallback(
    (render) => {
      showDlg(render)
    },
    [showDlg]
  )
}
