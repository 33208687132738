/* eslint-disable vars-on-top */
/* eslint-disable no-var */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/label-has-associated-control */
import {CloudUpload} from '@mui/icons-material'
import {Button, ButtonProps} from '@utils/ui/Buttons/Button'
import {IconButton} from '@utils/ui/Buttons/IconButton'
import {useUploader} from '@utils/ui/Uploader'
import {Axios, AxiosResponse} from 'axios'
import {useCallback, useMemo, useState} from 'react'
import {v4 as uuidv4} from 'uuid'

const displayNone = { display: 'none' }

export type UploadButtonProps = {
  api: Axios
  path: string
  accept?: string
  multiple?: boolean
  id?: string
  disabled?: boolean
  onComplete?: (response: AxiosResponse) => void
  quiet?: boolean
  label?: string
  name?: string
  visible?: boolean
  variant?: ButtonProps['variant']
  color?: ButtonProps['color']
  tooltip?: React.ReactNode
}

export const UploadAccepts = {
  Tabellen:
    '.csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
}

export const UploadButton = ({
  api,
  path,
  accept,
  multiple,
  id: oid,
  disabled,
  onComplete,
  quiet,
  label,
  name,
  visible = true,
  variant,
  color,
  tooltip
}: UploadButtonProps) => {
  const [state, setState] = useState({ disabled: false, toggleId: uuidv4() })

  const onCompleteInt = useCallback(
    (response) => {
      setState({ disabled: false, toggleId: uuidv4() })
      if (onComplete) {
        onComplete(response)
      }
    },
    [onComplete]
  )

  const onError = useCallback((error, response) => {
    setState({ disabled: false, toggleId: uuidv4() })
  }, [])

  const submitFiles = useUploader({ api, path, quiet, onComplete: onCompleteInt, onError })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const id = useMemo(() => oid || `upload-${uuidv4()}`, [])

  //@ts-ignore
  const onChange = (e: any) => {
    setState((old) => ({ ...old, disabled: true }))
    submitFiles(e.target.files)
  }

  return !visible ? null : (
    <span>
      <input
        key={state.toggleId}
        accept={accept}
        style={displayNone}
        id={id}
        multiple={multiple}
        type="file"
        onChange={onChange}
        disabled={disabled || state.disabled}
      />
      <label htmlFor={id}>
        {label ? (
          <Button
            label={label}
            startIcon={<CloudUpload />}
            tooltip={tooltip}
            name={name || label}
            component="span"
            disabled={disabled || state.disabled}
            variant={variant}
            color={color}
          />
        ) : (
          <IconButton
            icon={<CloudUpload />}
            tooltip={tooltip}
            name={name}
            component="span"
            disabled={disabled || state.disabled}
          />
        )}
      </label>
    </span>
  )
}
