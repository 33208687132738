/* eslint-disable no-plusplus */
import {Grid} from '@mui/material'
import {AppPaths} from '@one/AppPaths'
import {UserRoles} from '@one/UserRoles'
import {HkmEnum} from '@one/enums/HkmEnum'
import {HandelTyp, NeonKontextDisplayJson, StandortGruppeJson} from '@one/typings/apiTypings'
import {useEnums} from '@utils/enums'
import {Action} from '@utils/ui/Action'
import {CardEx} from '@utils/ui/CardEx'
import {AutocompleteEx} from '@utils/ui/fields/AutocompleteEx'
import {StaticField} from '@utils/ui/fields/StaticField'
import {TextField} from '@utils/ui/fields/TextField'
import {useMemo} from 'react'

export interface StandortgruppeFormProps {
  onChange: (e: Event) => void
  model?: StandortGruppeJson
  kontexte: NeonKontextDisplayJson[]
  errors?: any
  isNew?: boolean
  readonly?: boolean
  isChanged?: boolean
}

export const StandortgruppeForm = ({
  kontexte = [],
  onChange,
  model = {},
  errors = {},
  isNew = false,
  readonly = false,
  isChanged = false
}: StandortgruppeFormProps) => {
  const { et } = useEnums()

  const actions = useMemo(
    () =>
      [
        {
          role: UserRoles.STAMMDATEN_EDITOR,
          tooltip: 'VK-Preisgruppen-Pflege',
          navlink: AppPaths.VkStandardPreisgruppenByStandortgruppeFn(model.id),
          text: 'VK-Preisgruppenregeln',
          variant: 'outlined',
          enabled: !isNew,
          visible: model.handelTyp === HandelTyp.EH
        },
        {
          role: UserRoles.STAMMDATEN_EDITOR,
          tooltip: 'Sortimentspflege',
          navlink: AppPaths.SortimentPflegeByStandortgruppeFn(model.id),
          text: 'Sortiment',
          variant: 'outlined',
          enabled: !isNew
        }
      ] as Action[],
    [isNew, model.id, model.handelTyp]
  )

  return (
    <CardEx
      backLink
      overviewLink={AppPaths.StandortgruppeUebersicht}
      topActions={actions}
      title={isNew ? 'Standortgruppe erstellen' : `Standortgruppe ${model.name || ''}`}
    >
      <Grid container spacing={2} direction="column">
        <Grid item container direction="row" spacing={2}>
          <Grid item xs={12} sm={2} lg={1}>
            <TextField
              name="nr"
              label="Nummer"
              error={errors.nr}
              value={model.nr}
              onChange={onChange}
              fullWidth
              disabled={readonly}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TextField
              name="name"
              label="Name"
              error={errors.name}
              value={model.name}
              onChange={onChange}
              fullWidth
              required
              disabled={readonly}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <AutocompleteEx
              fullWidth
              required
              label="Kontext"
              value={model.kontext}
              options={kontexte}
              optionValue="kontext"
              optionLabel={(opt: NeonKontextDisplayJson) =>
                `${opt.kontextNr} ${opt.kontextName} (${opt.betriebstypNr || ''})`
              }
              name="kontext"
              onChange={onChange}
              disabled={model.standorte?.length > 0}
            />
          </Grid>
          <Grid item>
            <StaticField label="Status" value={et(HkmEnum.SynchronisationStatus, model.status)} />
          </Grid>
        </Grid>
      </Grid>
    </CardEx>
  )
}
