import { ArrowLeft } from '@mui/icons-material'
import { Grid2 as Grid } from '@mui/material'
import { Button } from '@utils/ui/Buttons/Button'
import { CardEx } from '@utils/ui/CardEx'
import { useNavigate } from 'react-router-dom'

export const ForbiddenPage = () => {
  const navigate = useNavigate()
  return (
    <Grid container justifyContent="left" alignItems="center">
      <CardEx
        title="Sie haben keine Berechtigung für diese Seite"
        minWidth="30%"
        bottomActions={
          <Button label="Zur Startseite" onClick={() => navigate('/')} startIcon={<ArrowLeft />} />
        }
      />
    </Grid>
  )
}
