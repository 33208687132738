import { Remove } from '@mui/icons-material'
import { UserRoles } from '@one/UserRoles'
import { api } from '@one/api'
import { AllianzMitgliedDisplayJson } from '@one/typings/apiTypings'
import { useApiCaller } from '@utils/apicaller'
import { Column } from '@utils/ui/DataTable/DataTable'
import { DataTableAction } from '@utils/ui/DataTable/DataTableBody'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { useDialogAnker } from '@utils/ui/DialogAnker'
import { useMessageDialog } from '@utils/ui/MessageDialog'
import { useCallback, useMemo } from 'react'
import { AllianzMitgliedAnlageDialog } from './mitglied/AllianzMitgliedAnlageDialog'
import { Action } from '@utils/ui/Action'

export interface GesellschafterAllianzPflegeTableProps {
  mitglieder: AllianzMitgliedDisplayJson[]
  isNew: boolean
  reload: () => void
  setWait: (isWaiting: boolean) => void
  allianzId: number
}

export const GesellschafterAllianzPflegeTable = ({
  mitglieder,
  isNew,
  allianzId,
  reload,
  setWait
}: GesellschafterAllianzPflegeTableProps) => {
  const [DlgAnker, showDlg] = useDialogAnker()

  const { askToConfirm } = useMessageDialog()

  const [apiCall] = useApiCaller(api)

  const columns = useMemo<Column<AllianzMitgliedDisplayJson>[]>(
    () => [
      {
        header: 'GLN',
        field: 'firma.gln',
        align: 'right',
        sortable: true
      },
      {
        header: 'Nummer',
        field: 'firma.nr',
        align: 'right',
        sortable: true
      },
      {
        header: 'Name',
        field: 'firma.name',
        sortable: true
      },
      {
        header: 'Mitglied seit',
        field: 'audit.createdOn',
        type: 'datetime'
      }
    ],
    []
  )

  const onNewMitgliedAnlage = useCallback(() => {
    showDlg((visible, onClose) => (
      <AllianzMitgliedAnlageDialog
        allianzId={allianzId}
        mitglieder={mitglieder}
        open={visible}
        onClose={onClose((saved: boolean) => saved && reload())}
      />
    ))
  }, [showDlg, mitglieder, allianzId, reload])

  const topActions = useMemo<Action[]>(
    () => [
      {
        role: UserRoles.ADMIN,
        tooltip: 'Mitglied hinzufügen',
        text: 'Mitglied',
        icon: 'add',
        visible: !isNew,
        onClick: onNewMitgliedAnlage
      }
    ],
    [isNew, onNewMitgliedAnlage]
  )

  const onAllianzMitgliedEntfernen = useCallback(
    (row: AllianzMitgliedDisplayJson) => {
      askToConfirm({
        severity: 'severe',
        title: 'Allianzmitgliedschaft beenden',
        extraConfirm: true,
        message: `Sind Sie sicher, die Mitgliedschaft für die Gesellschaft ${row.firma.gln} ${row.firma.name} zu beenden?`,
        confirmLabel: 'Mitgliedschaft beenden',
        onConfirm: () => {
          setWait(true)
          apiCall({
            method: 'POST',
            rest: 'allianz/mitglied/delete',
            params: {
              allianzMitgliedId: row.id
            },
            onSuccess: () => reload()
          })
        }
      })
    },
    [apiCall, askToConfirm, reload, setWait]
  )

  const actions = useMemo(
    (): DataTableAction<AllianzMitgliedDisplayJson>[] => [
      {
        icon: <Remove />,
        tooltip: 'Allianzmitgliedschaft beenden',
        direct: true,
        handler: (row) => onAllianzMitgliedEntfernen(row)
      }
    ],
    [onAllianzMitgliedEntfernen]
  )

  return (
    <>
      <DataTableCard
        title="Allianzmitglieder"
        name="GesellschafterAllianzTabelle"
        topActions={topActions}
        actions={actions}
        columns={columns}
        value={mitglieder}
        dense
        selectMode="none"
        rowFiller
      />
      <DlgAnker />
    </>
  )
}
