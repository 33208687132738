import { Divider, Grid2 as Grid, Slider, Typography } from '@mui/material'
import { ApplyButton } from '@utils/ui/Buttons/ApplyButton'
import { Button } from '@utils/ui/Buttons/Button'
import { CancelButton } from '@utils/ui/Buttons/CancelButton'
import { ResetButton } from '@utils/ui/Buttons/ResetButton'
import { DialogEx } from '@utils/ui/DialogEx'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { FormField } from '@utils/ui/fields/FormField'
import { Switch } from '@utils/ui/fields/Switch'
import { useEffect, useMemo, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { ActionsPos, Column, DataTable } from './DataTable'
import { DataTableConfig } from './DataTableConfig'
import { buildColumnKey } from './DataTableUtils'

const useStyles = makeStyles()((theme: any) => ({
  sticky: {
    position: 'sticky',
    top: 0,
    right: 0
  }
}))

export interface DataTableConfigDialogProps {
  open: boolean
  onClose: () => void
  columns: Column[]
  hash: number
  config: DataTableConfig
  stickyColumsLeftDefault: number | null
  stripedDefault?: boolean | null
  bordersDefault?: boolean | null
  actionsPosDefault?: ActionsPos
  setConfig: (config: DataTableConfig) => void
  hasState: boolean
}

export const DataTableConfigDialog = ({
  open,
  onClose,
  columns,
  hash,
  config,
  stickyColumsLeftDefault,
  stripedDefault,
  bordersDefault,
  actionsPosDefault,
  setConfig,
  hasState
}: DataTableConfigDialogProps) => {
  const { classes } = useStyles()

  const onToggleHidden = (key: string) => {
    setRows(rows.map((r) => (r.key === key ? { ...r, hidden: !r.hidden } : r)))
  }

  const metaColumns = [
    {
      field: 'title',
      header: 'Spaltenauswahl',
      body: (r) => (
        <Checkbox
          compact
          checked={!r.hidden}
          label={r.title}
          onChange={() => onToggleHidden(r.key)}
        />
      )
    }
  ] as Column[]

  const init = (reset: boolean = false) => {
    const raw = columns
      .filter((col) => !col.off)
      .map((col, idx) => {
        const key = buildColumnKey(col, idx)
        return {
          key,
          title: col.header,
          sortable: false,
          hidden:
            col.hidden &&
            (config?.columnOrder == null || config?.columnOrder.find((o) => o === key) == null)
        }
      })

    if (reset || config?.columnOrder == null || config?.columnOrder.length === 0) {
      return raw
    }

    const ordered = config.columnOrder.map((key) => raw.find((c) => c.key === key)).filter(Boolean)

    return ordered.concat(
      raw
        .filter((c1) => ordered.find((c2) => c2.key === c1.key) == null)
        .map((c) => ({ ...c, hidden: true }))
    )
  }

  const [rows, setRows] = useState(init)
  const [fixed, setFixed] = useState(config?.stickyColumsLeft || 0)
  const [striped, setStriped] = useState(config?.striped ?? stripedDefault)
  const [borders, setBorders] = useState(config?.borders ?? bordersDefault)
  const [actionsPos, setActionsPos] = useState(config?.actionsPos ?? actionsPosDefault)

  const activeColumnCount = useMemo(
    () => Math.round(rows.filter((r) => !r.hidden).length / 2),
    [rows]
  )

  const onResetGlobals = () => {
    setStriped(stripedDefault)
    setBorders(bordersDefault)
    setActionsPos(actionsPosDefault)
  }

  const onReset = () => {
    setConfig({
      columnOrder: init(true)
        .filter((row) => !row.hidden)
        .map((row) => row.key),
      stickyColumsLeft: stickyColumsLeftDefault || 0,
      striped: null,
      borders: null,
      actionsPos: null,
      hash
    })
    onClose()
  }
  const onOk = () => {
    setConfig({
      columnOrder: rows.filter((row) => !row.hidden).map((row) => row.key),
      stickyColumsLeft: fixed == 0 ? null : fixed,
      striped: striped === stripedDefault ? null : striped,
      borders: borders === bordersDefault ? null : borders,
      actionsPos: actionsPos === actionsPosDefault ? null : actionsPos,
      hash
    })
    onClose()
  }

  const onChangeFix = (e, value) => {
    setFixed(value)
  }

  useEffect(() => {
    if (fixed > 0 && fixed > activeColumnCount) {
      setFixed(0)
    }
  }, [activeColumnCount, fixed])

  const actions = (
    <>
      <ResetButton onClick={onReset} />
      <ApplyButton onClick={onOk} />
      <CancelButton onClick={onClose} />
    </>
  )

  return (
    <DialogEx
      title={
        <div>
          Tabelleneigenschaften
          {hasState ? null : (
            <Typography variant="subtitle2" fontStyle="italic" marginTop={-1} color="GrayText">
              temporär, wird nicht gespeichert
            </Typography>
          )}
        </div>
      }
      open={open}
      onClose={onClose}
      actions={actions}
      maxWidth="md"
      fullWidth
      height="400px"
    >
      <Grid container direction="row">
        <Grid size={{xs:12,sm:8}}>
          <DataTable
            name="DataTableConfigTable"
            columns={metaColumns}
            value={rows}
            onReorderRows={setRows}
            dense
          />
        </Grid>
        <Grid
          className={classes.sticky}
          size={{xs:12,sm:4}}
          height="100%"
          spacing={1}
          padding={2}
          direction="column"
          container
        >
          <Grid>
            <FormField label={`${fixed} Spalten fixieren`} fullWidth>
              <Slider
                style={{ marginTop: 12 }}
                min={0}
                max={activeColumnCount}
                value={fixed}
                onChange={onChangeFix}
                marks
              />
            </FormField>
          </Grid>
          <Grid>
            <Divider orientation="horizontal">Einstellungen</Divider>
          </Grid>
          <Grid marginLeft="4px">
            <Switch
              size="small"
              checked={striped}
              onChange={() => setStriped(!striped)}
              name="stripes"
              label="Zeilen gestreift"
            />
          </Grid>
          <Grid marginLeft="4px">
            <Switch
              size="small"
              checked={borders}
              onChange={() => setBorders(!borders)}
              label="Zeilen mit Rahmen"
              name="border"
            />
          </Grid>
          <Grid marginLeft="4px">
            <Switch
              size="small"
              checked={actionsPos === 'left'}
              onChange={() => setActionsPos(actionsPos === 'left' ? 'right' : 'left')}
              label="Aktionen links statt rechts"
              name="actionpos"
            />
          </Grid>
          <Grid container justifyContent="center">
            <Button label="Globale Vorgaben" size="small" onClick={onResetGlobals} color="error" />
          </Grid>
        </Grid>
      </Grid>
    </DialogEx>
  )
}
