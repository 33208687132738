import { format, formatDistance, formatDistanceToNow, isValid, Locale, parse } from 'date-fns'
import localeDE from 'date-fns/locale/de'
import localeEN from 'date-fns/locale/en-US'
import { errorLog } from './logging'
import { isString } from './utils'

export const resolveDateFnsLocal = (locale: string): Locale => {
  switch (locale?.substring(0, 2).toLowerCase()) {
    case 'en':
      return localeEN
    case 'de':
    default:
      return localeDE
  }
}

const dateGlobalsDE = {
  code: 'de',

  dateFormat: 'dd.MM.yyyy',
  dateTimeFormat: 'dd.MM.yyyy HH:mm',
  dateTimeSecFormat: 'dd.MM.yyyy HH:mm:ss',
  dateTimeMillisecFormat: 'dd.MM.yyyy HH:mm:ss.SSS',
  timeFormat: 'HH:MM',

  unlimited: 'unbegrenzt',
  until: 'bis',

  datePlaceholder: 'tt.mm.jjjj',

  durationStrings: ['Jahre', 'Monate', 'Wochen', 'Tage', 'Stunden', 'Minuten', 'Sekunden']
}

const dateGlobalsEN = {
  code: 'en',

  dateFormat: 'MM/dd/yyyy',
  dateTimeFormat: 'MM/dd/yyyy HH:mm',
  dateTimeSecFormat: 'MM/dd/yyyy HH:mm:ss',
  dateTimeMillisecFormat: 'MM/dd/yyyy HH:mm:ss.SSS',
  timeFormat: 'HH:MM',

  unlimited: 'unlimited',
  until: 'until',

  datePlaceholder: 'mm/dd/yyyy',

  durationStrings: ['years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds']
}

let globalDateLocaleInfos = dateGlobalsDE
let globalDateFnsLocale = localeDE

export const setGlobalDateFnsLocale = (dateFnsLocale: Locale) => {
  globalDateFnsLocale = dateFnsLocale
  switch (dateFnsLocale.code.substring(0, 2)) {
    case 'en':
      globalDateLocaleInfos = dateGlobalsEN
      break
    case 'de':
      globalDateLocaleInfos = dateGlobalsDE
      break
    default:
      globalDateLocaleInfos = dateGlobalsDE
      console.error('locale not supported', dateFnsLocale.code)
  }
}

export const getCurrentLocale = (): string => globalDateLocaleInfos.code

export const getCurrentLocaleDateFormat = () => globalDateLocaleInfos.dateFormat

export const getCurrentLocaleDatePlaceholder = () => globalDateLocaleInfos.datePlaceholder

export const isDate = (d) => d instanceof Date && !Number.isNaN(d.getTime())

export const toDate = (ds): Date | null => {
  if (isString(ds)) {
    const time = Date.parse(ds)
    const x = Number.isNaN(time) ? null : new Date(time)
    return x
  }
  return isDate(ds) ? ds : null
}

export const formatDateTime = (ds, unlimited?: boolean) => {
  const d = toDate(ds)
  return (
    (d && format(d, globalDateLocaleInfos.dateTimeFormat)) ||
    (unlimited ? globalDateLocaleInfos.unlimited : null)
  )
}

export const formatDateTimeSecond = (ds, unlimited?: boolean) => {
  const d = toDate(ds)
  return (
    (d && format(d, globalDateLocaleInfos.dateTimeSecFormat)) ||
    (unlimited ? globalDateLocaleInfos.unlimited : null)
  )
}

export const formatDateTimeMillisecond = (ds, unlimited?: boolean) => {
  const d = toDate(ds)
  return (
    (d && format(d, globalDateLocaleInfos.dateTimeMillisecFormat)) ||
    (unlimited ? globalDateLocaleInfos.unlimited : null)
  )
}
export const formatDate = (ds: string | Date | null, unlimited?: boolean): string => {
  const d = toDate(ds)
  return (
    (d && format(d, globalDateLocaleInfos.dateFormat)) ||
    (unlimited ? globalDateLocaleInfos.unlimited : null)
  )
}

export const formatTime = (ds) => {
  const d = toDate(ds)
  return (d && format(d, globalDateLocaleInfos.timeFormat)) || ''
}

export function formatDateTimeRange(from, to) {
  const fs = formatDateTime(from)
  const ts = formatDateTime(to, true)
  if (fs) {
    return `${fs} ${globalDateLocaleInfos.until} ${ts}`
  }
  if (ts) {
    return `${globalDateLocaleInfos.until} ${ts}`
  }
  return ''
}

export function formatDateRange(from, to) {
  const fs = formatDate(from)
  const ts = formatDate(to)
  if (fs) {
    if (ts) {
      return `${fs} ${globalDateLocaleInfos.until} ${ts}`
    }
    return `${fs} ${globalDateLocaleInfos.until} ${globalDateLocaleInfos.unlimited}`
  }
  if (ts) {
    return `${globalDateLocaleInfos.until} ${ts}`
  }
  return ''
}

export const formatDuration = (from: Date, to: Date) =>
  isDate(from) && isDate(to) ? formatDistance(from, to, { locale: globalDateFnsLocale }) : null

export const formatDurationToNow = (date: Date) =>
  isDate(date) ? formatDistanceToNow(date, { locale: globalDateFnsLocale }) : null

function dd(v) {
  return v ? `0${v}`.slice(-2) : ''
}

export const formatISODate = (d) =>
  isDate(d) ? `${d.getFullYear()}-${dd(d.getMonth() + 1)}-${dd(d.getDate())}` : ''

const ISO_8601 = /^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2}(.\d{3})?(Z|[+-]\d{4})?)?$/

export function transformDates(data) {
  if (Array.isArray(data)) {
    data.forEach((o) => transformDates(o))
  } else {
    Object.keys(data).forEach((k) => {
      const field = data[k]
      if (field !== null)
        switch (typeof field) {
          case 'string':
            if (ISO_8601.test(field)) {
              data[k] = new Date(Date.parse(field))
            }
            break
          case 'object':
            transformDates(field)
            break
          default:
            break
        }
    })
  }
}

export const addDays = (date: Date, days: number): Date => {
  var result = new Date(date)
  result.setDate(result.getDate() + days)
  return result
}

const tryParseDate = (text, format, refDate) => {
  const s = text.replace(/^(\d+)\.(\d+)\.(\d\d)$/, '$1.$2.20$3')
  if (s.match(/^\d+\.\d+\.\d+$/)) {
    return parse(s, format || 'dd.MM.yyyy', refDate || new Date())
  }
  if (s.match(/^\d+\d?\.\d+\d?$/)) {
    return parse(s, format || 'dd.MM', refDate || new Date())
  }
  if (s.match(/^\d\d$/)) {
    const day = Number(s)
    const rdate = refDate ? new Date(refDate) : new Date()
    if (day < rdate.getDate()) {
      rdate.setDate(1)
      rdate.setMonth(rdate.getMonth() + 1)
      rdate.setDate(day)
      return rdate
    } else {
      rdate.setDate(day)
      return rdate
    }
  }
  if (s.match(/^\d\d\d\d$/)) {
    return parse(s, format || 'ddMM', refDate || new Date())
  }
  if (s.match(/^\d\d\d\d\d\d$/)) {
    return parse(s, format || 'ddMMyy', refDate || new Date())
  }
  if (s.match(/^\d\d\d\d\d\d\d\d$/)) {
    return parse(s, format || 'ddMMyyyy', refDate || new Date())
  }
  if (s.match(/^[+-](\d+)$/)) {
    return addDays(new Date(), Number(s))
  }
  return null
}

export const parseDateVariants = (text: string, props = null): Date | null | false => {
  const { refDate, format } = props || {}
  if (text) {
    if (text === '#') {
      return new Date()
    }
    try {
      const date = tryParseDate(text, format, refDate)
      if (date != null && isValid(date)) {
        return date
      }
      return false
    } catch (error) {
      errorLog('Failed to parse date', text, error)
      return false
    }
  }
  return null
}

/**
 * Peüfe, ob Datum kleiner als Referenz
 * @param date Datum
 * @param referenceDate Vergleichsdatum
 * @param orequal true, wenn <=, sonst <
 * @returns true, wenn beides gültige Datum und date < referenceDate
 */
export const isDateLower = (
  date: Date | string,
  referenceDate: Date | string,
  orequal: boolean = false
): boolean => {
  const a = toDate(date)
  const b = toDate(referenceDate)
  return (
    a != null && b != null && (a.getTime() < b.getTime() || (orequal && a.getTime() == b.getTime()))
  )
}

export const compareDates = (date1: Date | string | null, date2: Date | string | null): number => {
  const d1 = toDate(date1)
  const d2 = toDate(date2)
  if (d1 == null) {
    return d2 == null ? 0 : -1
  }
  if (d2 == null) {
    return 1
  }
  return d1.getTime() - d2.getTime()
}

export const trimDate0000 = (date: Date | string | null) => {
  const rd = toDate(date)
  if (rd != null) {
    rd.setHours(0)
    rd.setMinutes(0)
    rd.setSeconds(0)
    rd.setMilliseconds(0)
  }
  return rd
}

export const getDateToday = () => {
  const date = new Date()
  return trimDate0000(date)
}

export const getDateTomorrow = () => {
  const date = getDateToday()
  date.setDate(date.getDate() + 1)
  return date
}
