import { debugLog } from '@utils/logging'
import { GlobalDialogContext } from '@utils/ui/DialogAnker'
import { SnackbarProvider } from 'notistack'
import {
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Path } from 'typescript'
import { v4 as uuidv4 } from 'uuid'
import { AppContext } from './AppContext'
import { AppRouteCtx, AppRouteCtxType } from './AppRouteCtx'
import { AppSwitchCtx, AppSwitchCtxType, AppSwitchState } from './AppSwitchCtx'
import { ErrorBoundary } from './ErrorBoundary'

export interface AppSwitchProps {
  appTitle?: string
  maxCacheAge?: number
  minCacheAge?: number
  children?: any
}

export const AppSwitch = ({ appTitle, maxCacheAge, minCacheAge, children }: AppSwitchProps) => {
  const navigate = useNavigate()
  const location = useLocation()

  const ref = useRef<any>(undefined)

  const { setCurrentRouteKey } = useContext(GlobalDialogContext)

  const { session } = useContext(AppContext)

  const [state, setState] = useState<AppSwitchState>(() => ({
    current: null,
    cache: new Map<string, AppRouteCtxType>(),
    hookCache: new Map(),
    all: [],
    appTitle: appTitle || window.document.title,
    maxCacheAge: maxCacheAge || 300000,
    minCacheAge: minCacheAge || 20000,
    maxCacheKeepAliveCount: 10,
    session // used as invalidate trigger
  }))

  const replaceHistory = useCallback(
    (replaceFnc) => {
      const oldloc = location.pathname
      const newloc = replaceFnc(oldloc)
      if (newloc == null || oldloc === newloc) {
        return
      }
      const c = state.cache.get(oldloc)
      debugLog('APPROUTE-REPLACE', oldloc, newloc)

      if (c != null) {
        state.cache.delete(oldloc)
        state.cache.set(newloc, c)
      }

      navigate(newloc, { replace: true })
    },
    [navigate, location, state.cache]
  )

  const replaceSearch = useCallback(
    (search) => {
      navigate(
        {
          pathname: location.pathname,
          search
        },
        { replace: true }
      )
    },
    [navigate, location]
  )

  const pushHistory = useCallback((url: Path) => navigate(url), [navigate])

  const invalidateHistory = useCallback(
    (url) => {
      debugLog('APPROUTE-INVALIDATE', url)
      const c = state.cache.get(url)
      if (c != null) {
        state.cache.delete(url)
      }
    },
    [state.cache]
  )

  const registerDirtyHook = useCallback(
    (dirty) => {
      const unregister = (hooks, hid) => {
        hooks.delete(hid)
      }
      if (state.current != null) {
        // debugLog('APPROUTE-TOGGLEDIRTY', state.current, dirty)
        let hooks = state.hookCache.get(state.current)
        if (hooks == null) {
          hooks = new Map()
          state.hookCache.set(state.current, hooks)
        }
        const hid = uuidv4()
        hooks.set(hid, dirty)
        return () => unregister(hooks, hid)
      }
      return () => {}
    },
    [state]
  )

  const checkDirtyHook = useCallback(
    (key) => {
      const hooks = state.hookCache.get(key)
      const dirty = hooks && Array.from(hooks.values()).find((h) => h)
      debugLog('APPROUTE-CHECKHOOK', key, dirty)
      return dirty
    },
    [state.hookCache]
  )

  const checkDirtyHookAll = useCallback(() => {
    return Array.from(state.hookCache.values()).find((hooks) =>
      Array.from(hooks.values()).find((h) => h)
    )
  }, [state.hookCache])

  const setWindowTitle = useCallback(
    (title) => {
      if (title && title.length) {
        window.document.title = `${title} - ${state.appTitle}`
      } else {
        window.document.title = state.appTitle
      }
    },
    [state.appTitle]
  )

  useImperativeHandle(
    ref,
    // @ts-ignore
    () => ({
      getState: () => state,
      replaceHistory,
      replaceSearch,
      pushHistory,
      invalidateHistory,
      registerDirtyHook,
      checkDirtyHook,
      setWindowTitle,
      checkDirtyHookAll
    }),
    [
      replaceHistory,
      replaceSearch,
      pushHistory,
      invalidateHistory,
      registerDirtyHook,
      checkDirtyHook,
      setWindowTitle,
      checkDirtyHookAll,
      state
    ]
  )

  const ctx = useMemo<AppSwitchCtxType>(
    () => ({
      getState: () => ref.current && ref.current.getState(),
      setRoutes: (routes: any) => ref.current && setState((old) => ({ ...old, all: routes })),
      replaceHistory: (replaceFnc) => ref.current && ref.current.replaceHistory(replaceFnc),
      replaceSearch: (search: string) => ref.current && ref.current.replaceSearch(search),
      pushHistory: (url: string) => ref.current && ref.current.pushHistory(url),
      registerDirtyHook: (hook) => ref.current && ref.current.registerDirtyHook(hook),
      invalidateHistory: (url) => ref.current && ref.current.invalidateHistory(url),
      checkDirtyHook: (key) => ref.current && ref.current.checkDirtyHook(key),
      setWindowTitle: (title) => ref.current && ref.current.setWindowTitle(title),
      currentRouteKey: state.current
    }),
    [state]
  )

  useEffect(() => {
    if (state.session !== session) {
      debugLog('APPROUTE-RESET')
      setState((last) => ({
        ...last,
        cache: new Map(),
        all: [],
        session
      }))
    }
  }, [state.session, session])

  useEffect(() => {
    const handler = (ev) => {
      // @ts-ignore
      if (ref.current && ref.current.checkDirtyHookAll()) {
        ev.preventDefault()
        ev.returnValue = 'Es gibt noch ungespeicherte Inhalte!'
        return ev.returnValue
      }
      return undefined
    }
    window.addEventListener('beforeunload', handler)
    return () => {
      window.removeEventListener('beforeunload', handler)
      setCurrentRouteKey(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AppSwitchCtx.Provider value={ctx}>
      {children}
      {state.all.map((c) => (
        <AppRouteCtx.Provider value={c} key={c.key}>
          <span id={c.id} key={c.key} style={c.style} data-name={c.name} data-visible={c.visible}>
            <SnackbarProvider maxSnack={3}>
              <ErrorBoundary>{c.body}</ErrorBoundary>
            </SnackbarProvider>
          </span>
        </AppRouteCtx.Provider>
      ))}
    </AppSwitchCtx.Provider>
  )
}
