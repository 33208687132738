import { CropSquareRounded, Done, DragHandle, SquareRounded } from '@mui/icons-material'
import { Checkbox, Radio, TableBody, TableCell, TableRow } from '@mui/material'
import { AppContext } from '@utils/ui/App/AppContext'
import { IconButton } from '@utils/ui/Buttons/IconButton'
import { Permission, PermissionUtil } from '@utils/ui/Permission'
import { getFirstOfType } from '@utils/ui/uiutils'
import { aidOf, safeExecute } from '@utils/utils'
import clsx from 'clsx'
import React, { MouseEvent, useContext, useEffect, useRef } from 'react'
import isEqual from 'react-fast-compare'
import { ActiveCellType, Column, ColumnType } from './DataTable'
import { formatValue } from './DataTableUtils'
import { EditCell } from './EditCell'

const renderValue = (val: any, type: ColumnType) => {
  if (typeof val === 'boolean' || type === 'boolean') {
    return val ? <Done fontSize="small" /> : null
  }
  return formatValue(val, type)
}

export type RowClassName<T> = string | ((row: T, index: number) => string)
export type RowStyle<T> = React.CSSProperties | ((row: T, index: number) => React.CSSProperties)

export interface DataTableAction<T = any> {
  icon: string | React.ReactNode
  role?: string | string[]
  tooltip?: string
  direct?: boolean // TODO doof...
  // TODO: increase typedef quality
  check?: (data: T) => number
  getLink?: (data: T) => string
  handler?: (data: T) => void
  onClick?: (data: T) => () => void
}

export interface DataTableBodyProps<T = any> {
  bodyRef: any
  name?: string
  tableUID: string
  classes: any
  columns: Column<T>[]
  onSelectRowClick: (event: any) => any
  data: any[]
  offset: number
  focustype?: 'none' | 'row' | 'cell'
  checkSelected: (row: T) => any
  stickyHints: any
  selectMode?: 'none' | 'multi' | 'single'
  selectCol?: boolean
  onRowClick?: (event: MouseEvent<HTMLTableRowElement>) => void
  onRowDoubleClick?: (event: MouseEvent<HTMLTableRowElement>) => void
  actions?: DataTableAction<T>[]
  actionCol: boolean
  hover?: boolean
  onKeyDown?: (event?: any) => any
  rowClassName?: RowClassName<T>
  rowStyleFn: (row: T, index: number) => React.CSSProperties
  emptyMessage?: React.ReactNode
  groupBy?: any
  onReorderRows?: (idx1: number, idx2: number) => void
  activeCell: ActiveCellType
  getSelected: () => Set<T>
  setActiveCell: (args: (old: ActiveCellType) => ActiveCellType) => void
  borders: boolean
}

export const DataTableBody = <T extends any = any>({
  bodyRef,
  name,
  tableUID,
  classes,
  columns,
  selectMode,
  checkSelected,
  onSelectRowClick,
  data,
  offset,
  onRowClick,
  onRowDoubleClick,
  focustype,
  actions,
  onKeyDown,
  rowClassName,
  rowStyleFn,
  emptyMessage,
  stickyHints,
  groupBy,
  selectCol = false,
  hover = false,
  onReorderRows,
  actionCol,
  activeCell,
  setActiveCell,
  getSelected,
  borders
}: DataTableBodyProps<T>) => {
  // @ts-ignore
  const { checkUserRole } = useContext(AppContext) || {}

  const rowCacheRef = useRef(new Map())
  const paramsCacheRef = useRef({})
  const cacheCountRef = useRef(0)

  const colCount = columns.length + (actions?.length ? 1 : 0)

  const buildActionButton = (action, row) => {
    if (!action) {
      return null
    }
    if (typeof action === 'function') {
      return action(row)
    }

    if (checkUserRole && !checkUserRole(action.role)) {
      return null
    }
    const perm = PermissionUtil.check(action.check && action.check(row))
    if (perm === Permission.HIDDEN) {
      return null
    }

    if (action.getLink) {
      const linkTo = action.getLink(row)
      return (
        linkTo && (
          <IconButton
            key={action.icon}
            name={action.tooltip}
            icon={action.icon}
            size="tiny"
            style={{ margin: '-4px 0' }}
            tooltip={action.tooltip}
            to={linkTo}
            disabled={perm === Permission.DISABLED}
          />
        )
      )
    }
    return (
      <IconButton
        key={action.icon}
        name={action.tooltip}
        size="tiny"
        style={{ margin: '-4px 0' }}
        icon={action.icon}
        tooltip={action.tooltip}
        onClick={
          action.onClick ||
          (action.handler && (action.direct ? () => action.handler(row) : action.handler(row)))
        }
        disabled={perm === Permission.DISABLED}
      />
    )
  }

  /**
   * Render the action column with action buttons if available.
   *
   * @param {object} row The current row
   */
  const renderActionColumn = (row) => {
    if (!actionCol || !actions || !actions.length) {
      return null
    }

    return (
      <TableCell key="actionColumn" data-name="actcol" className={clsx(stickyHints.actionColTD)}>
        <div className={classes.actionColumn}>
          {actions.map((action) => buildActionButton(action, row)).filter((btn) => btn != null)}
        </div>
      </TableCell>
    )
  }

  const renderSelectColumn = (isItemSelected, labelId, index) => {
    if (selectCol && selectMode) {
      if (selectMode === 'multi') {
        return (
          <TableCell
            key="selectCol"
            data-name="selcol"
            // padding="checkbox"
            align="center"
            className={clsx(stickyHints.stickyClassTD(index), classes.stickySpecialColTD)}
          >
            <Checkbox
              checked={isItemSelected}
              checkedIcon={<SquareRounded />}
              icon={<CropSquareRounded />}
              onChange={onSelectRowClick}
              // @ts-ignore
              inputProps={{ 'aria-labelledby': labelId, 'data-selbox': true }}
              style={{ margin: -8 }}
            />
          </TableCell>
        )
      }

      if (selectMode === 'single') {
        return (
          <TableCell
            key="selectCol"
            data-name="selcol"
            // padding="checkbox"
            className={clsx(stickyHints.stickyClassTD(index), classes.stickySpecialColTD)}
          >
            <Radio
              name={tableUID}
              value={index}
              checked={isItemSelected}
              onClick={onSelectRowClick}
              // @ts-ignore
              inputProps={{ 'aria-labelledby': labelId, 'data-selbox': true }}
              style={{ margin: -8 }}
            />
          </TableCell>
        )
      }
    }

    return null
  }

  const onMouseEnter = (e) => {
    const node = getFirstOfType(e.target, 'tr')
    if (node) {
      node.setAttribute('draggable', true)
      node.setAttribute('user-select', 'none')
    }
  }

  const onMouseLeave = (e) => {
    const node = getFirstOfType(e.target, 'tr')
    if (node) {
      node.removeAttribute('draggable')
      node.removeAttribute('user-select')
    }
  }

  const renderColumn = (
    column: Column<T>,
    rowIdx: number,
    idx: number,
    row: any,
    span: number,
    isItemSelected: boolean,
    isActiveRow: boolean,
    rowStyle: any,
    tabIndex: number | undefined
  ) => {
    if (column.key == '::reorder') {
      return (
        <TableCell
          key="dragCol"
          data-name="dragcol"
          padding="none"
          style={{ ...rowStyle, textDecorationLine: 'none', paddingLeft: 4, opacity: 1 }}
          className={clsx(stickyHints.stickyClassTD(idx))}
        >
          <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} title="Zeile verschieben...">
            <DragHandle fontSize="small" style={{ cursor: 'ns-resize' }} />
          </div>
        </TableCell>
      )
    }
    if (column.key == '::select') {
      const labelId = `enhanced-table-checkbox-${idx}`
      return renderSelectColumn(isItemSelected, labelId, idx)
    }
    if (column.key == '::action') {
      return (
        <TableCell
          key="actionCol"
          data-name="actcol"
          padding="none"
          style={{
            ...rowStyle,
            textDecorationLine: 'none',
            paddingLeft: 4,
            paddingRight: 4,
            opacity: 1
          }}
          className={clsx(stickyHints.stickyClassTD(idx))}
        >
          <div className={clsx(classes.actionColumn)}>
            {actions.map((action) => buildActionButton(action, row)).filter((btn) => btn != null)}
          </div>
        </TableCell>
      )
    }
    if (column.key == '::filler') {
      return <TableCell key={column.key} data-name="fillcol" padding="none" />
    }

    let body: React.ReactNode
    if (column.visible == null || column.visible(row)) {
      if (column.body != null) {
        const cellId = `${tableUID}-${rowIdx}-${idx}`
        const render = (edit: boolean) =>
          column.body(row, { column, id: cellId, edit, getSelected })

        if (isActiveRow && activeCell.col === idx) {
          body = (
            <EditCell
              key={column.key}
              id={cellId}
              render={render}
              active={activeCell.edit}
              setActive={(edit) => setActiveCell((old) => ({ ...old, edit }))}
              type={column.type}
            />
          )
        } else {
          body = render(false)
        }
      } else {
        const val = column.valueGetter(row)
        if (Array.isArray(val)) {
          body = val.map((v: any, i: number) => <div key={i}>{renderValue(v, column.type)}</div>)
        } else {
          body = renderValue(val, column.type)
        }
      }
      if (column.decorator) {
        body = column.decorator(row, body)
      }
    }

    return (
      <TableCell
        id={`${tableUID}-${rowIdx}-${idx}-td`}
        key={column.key}
        data-name={column.key}
        col-idx={idx}
        tabIndex={tabIndex}
        className={clsx(stickyHints.stickyClassTD(idx))}
        align={column.align}
        style={{
          ...rowStyle,
          verticalAlign: column.valign,
          backgroundColor: column.backgroundColor ?? rowStyle?.backgroundColor,
          opacity: column.opacity,
          textAlign: column.align,
          whiteSpace: column.wrapMode,
          padding: column.cellPadding,
          borderWidth: column.cellPadding === 0 && !borders ? 0 : ''
        }}
        title={typeof column.tooltip === 'function' ? column.tooltip(row) : column.tooltip}
        colSpan={span}
      >
        {body}
      </TableCell>
    )
  }

  const renderRow = (row, index, rowKey, rowStyle) => {
    const key = rowKey && typeof rowKey !== 'object' ? rowKey : index

    if (row.__grouper && groupBy) {
      return (
        <TableRow
          key={key}
          hover={hover}
          className={clsx(classes.tablerow, classes.groupByTRgrouper)}
          // style={rowStyle}
          // onClick={onRowClick}
          // onDoubleClick={onRowDoubleClick}
          // role="checkbox"
          // aria-checked={isItemSelected}
          // tabIndex={rowtab ? 0 : null}
          // selected={isItemSelected}
          onKeyDown={onKeyDown}
          data-idx={index}
          data-grouper
        >
          {renderColumn(groupBy, index, 0, row, colCount, false, false, null, null)}
        </TableRow>
      )
    }

    const isItemSelected = checkSelected(row)

    const className = clsx(
      classes.tablerow,
      focustype !== 'none' && classes.tablerowHover,
      typeof rowClassName === 'function' ? rowClassName(row, index) : rowClassName,
      groupBy && classes.groupByTRnormal
    )

    // debugLog(tableUID, 'RENDER-ROW', rowKey)

    let span = undefined
    const cellIndex = focustype === 'cell' ? 0 : null

    return (
      <TableRow
        id={`${tableUID}-${index}-tr`}
        key={key}
        hover={hover}
        className={className}
        // style={rowStyle}
        onClick={onRowClick}
        onDoubleClick={onRowDoubleClick}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={focustype === 'row' ? 0 : null}
        selected={isItemSelected}
        onKeyDown={onKeyDown}
        data-idx={index}
      >
        {columns
          .map((column, idx) => {
            if (span > 0) {
              span--
              return null
            }
            const s = column.span && column.span(row)
            span = s > 0 ? Math.min(s, columns.length - idx) : undefined
            return renderColumn(
              column,
              index,
              idx,
              row,
              span,
              isItemSelected,
              activeCell.row === index,
              rowStyle,
              cellIndex
            )
          })
          .filter(Boolean)}
        {renderActionColumn(row)}
      </TableRow>
    )
  }

  const globalParams = {
    // data,
    classes,
    columns,
    focustype,
    selectMode,
    selectCol,
    // checkSelected, => nicht, da rowweise gefragt wird
    onSelectRowClick,
    onRowClick,
    onRowDoubleClick,
    onKeyDown,
    actions,
    hover,
    checkUserRole,
    tableUID
  }

  if (!isEqual(paramsCacheRef.current, globalParams)) {
    if (data.length > 0) {
      cacheCountRef.current += 1
      if (cacheCountRef.current > 10) {
        console.warn(`REPEATED TABLE-REDRAW - check columns dependencies for table ${name}!`)
      }
    }
    paramsCacheRef.current = globalParams
    rowCacheRef.current.clear()
  } else {
    cacheCountRef.current = 0
  }

  const dragRef = useRef<any>(undefined)
  const lastRowRef = useRef<any>(undefined)
  const lastRowClassRef = useRef<any>(undefined)

  useEffect(() => {
    const handleDragStart = (e) => {
      const row = getFirstOfType(e.target, 'tr')
      dragRef.current = row
      lastRowRef.current = null
      lastRowClassRef.current = null
    }

    const handleDragOver = (e) => {
      const row = getFirstOfType(e.target, 'tr')
      if (row == null || !bodyRef.current.contains(row) || dragRef.current == null) {
        return false
      }
      if (e.preventDefault) {
        e.preventDefault() // Necessary. Allows us to drop.
      }
      e.dataTransfer.dropEffect = 'move' // See the section on the DataTransfer object.

      if (lastRowRef.current != null) {
        const elem = lastRowRef.current
        const box = elem.getBoundingClientRect()
        const fb = e.pageY - box.top > elem.offsetHeight / 2
        if (fb) {
          // lastRowRef.current.style.borderBottom = '2.4px solid ' + blueGrey[200]
          // lastRowRef.current.style.borderTop = null
          lastRowRef.current.className = lastRowClassRef.current + ' ' + classes.dndBottom
        } else {
          // lastRowRef.current.style.borderTop = '2.4px solid ' + blueGrey[200]
          // lastRowRef.current.style.borderBottom = null
          lastRowRef.current.className = lastRowClassRef.current + ' ' + classes.dndTop
        }
      }

      return false
    }

    const handleDragEnter = (e) => {
      const row = getFirstOfType(e.target, 'tr')
      if (
        row != null &&
        row !== dragRef.current &&
        lastRowRef.current !== row &&
        dragRef.current != null
      ) {
        if (lastRowRef.current) {
          // lastRowRef.current.style.borderTop = null
          // lastRowRef.current.style.borderBottom = null
          lastRowRef.current.className = lastRowClassRef.current
        }
        lastRowRef.current = row
        lastRowClassRef.current = row.className
      }
    }

    const handleDragLeave = (e) => {
      const row = getFirstOfType(e.target, 'tr')
      if (
        row != null &&
        row !== dragRef.current &&
        lastRowRef.current !== row &&
        dragRef.current != null
      ) {
        if (lastRowRef.current) {
          // lastRowRef.current.style.borderTop = null
          // lastRowRef.current.style.borderBottom = null
          lastRowRef.current.className = lastRowClassRef.current
        }
        lastRowRef.current = row
        lastRowClassRef.current = row.className
      }
    }

    const handleDrop = (e) => {
      if (lastRowRef.current) {
        const idx1 = dragRef.current.getAttribute('data-idx')
        const idx2 = lastRowRef.current.getAttribute('data-idx')
        const box = lastRowRef.current.getBoundingClientRect()
        const fb = e.pageY - box.top > lastRowRef.current.offsetHeight / 2
        if (onReorderRows != null) {
          safeExecute(() => {
            onReorderRows(Number(idx1), Number(idx2) + (fb ? 1 : 0))
          })
        }
        dragRef.current = null
        // lastRowRef.current.style.borderTop = null
        // lastRowRef.current.style.borderBottom = null
        lastRowRef.current.className = lastRowClassRef.current
        lastRowRef.current = null
        lastRowClassRef.current = null
      } else {
        dragRef.current = null
      }
    }

    const handleDragEnd = () => {
      if (lastRowRef.current) {
        lastRowRef.current.className = lastRowClassRef.current
        lastRowRef.current = null
        lastRowClassRef.current = null
      }
      dragRef.current = null
    }

    const body = bodyRef.current
    body.addEventListener('dragstart', handleDragStart, false)
    body.addEventListener('dragenter', handleDragEnter, false)
    body.addEventListener('dragover', handleDragOver, false)
    body.addEventListener('dragleave', handleDragLeave, false)
    body.addEventListener('drop', handleDrop, false)
    body.addEventListener('dragend', handleDragEnd, false)
    return () => {
      body.removeEventListener('dragstart', handleDragStart, false)
      body.removeEventListener('dragenter', handleDragEnter, false)
      body.removeEventListener('dragover', handleDragOver, false)
      body.removeEventListener('dragleave', handleDragLeave, false)
      body.removeEventListener('drop', handleDrop, false)
      body.removeEventListener('dragend', handleDragEnd, false)
    }
  }, [bodyRef, classes.dndBottom, classes.dndTop, onReorderRows])

  return (
    <TableBody ref={bodyRef}>
      {data.map((row, i) => {
        const index = offset + i
        const selectedRow = checkSelected(row)
        const activeRow = activeCell.row === index
        const activeCol = activeRow ? activeCell.col : null
        const activeEdit = activeRow ? activeCell.edit : false
        const rowKey = aidOf(row)
        const rowStyle = rowStyleFn(row, index)
        const last = rowCacheRef.current.get(rowKey)
        const params = {
          index,
          row,
          selectedRow,
          activeRow,
          activeCol,
          activeEdit,
          rowClassName,
          rowStyle,
          borders
        }
        if (last == null || !isEqual(params, last.params)) {
          // debugLog('RENDER-ROW', rowKey)
          const body = renderRow(row, index, rowKey, rowStyle)
          rowCacheRef.current.set(rowKey, {
            params,
            body
          })
          return body
        }
        return last.body
      })}
      {data.length === 0 && emptyMessage && (
        <TableRow key="emptyRow">
          <TableCell colSpan={colCount} data-name="emptycol">
            {emptyMessage}
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  )
}
