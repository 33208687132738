import { Grid2 as Grid } from '@mui/material'
import { api } from '@one/api'
import { NumberCell } from '@one/components/common/NumberCell'
import { KassenPreisBasisField } from '@one/components/VkPreis/VkPreisPflege/fields/KassenPreisBasisField'
import { VkPreisBasisField } from '@one/components/VkPreis/VkPreisPflege/fields/VkPreisBasisField'
import { VkPreisEdit } from '@one/components/VkPreis/VkPreisPflege/model/VkPreisTypes'
import { HkmEnum } from '@one/enums/HkmEnum'
import {
  VkPreisBasisTyp,
  VkPreisChangeJson,
  VkPreiseMasseBearbeitenEintragJson,
  VkPreisGruppeJson,
  VkPreisListeBlattChangeJson,
  VkPreisListePreiseActionJson,
  VkPreisQuelle
} from '@one/typings/apiTypings'
import { useModelMgr } from '@utils/modelmgr'
import { CancelButton } from '@utils/ui/Buttons/CancelButton'
import { PerformButton } from '@utils/ui/Buttons/PerformButton'
import { Column, DataTable } from '@utils/ui/DataTable/DataTable'
import { DialogEx } from '@utils/ui/DialogEx'
import { AutocompleteEx } from '@utils/ui/fields/AutocompleteEx'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { Medal } from '@utils/ui/fields/Medal'
import { SelectEnumField } from '@utils/ui/fields/SelectEnumField'
import { StatePlane } from '@utils/ui/planes/StatePlane'
import { arrayItemReplace, dataFromEvent, nextKey, safeArray } from '@utils/utils'
import { useCallback, useMemo } from 'react'
import isEqual from 'react-fast-compare'
import { VkPreiseMasseBearbeitenKopfJsonEx } from './model/VkPreisMassenKopfModel'

interface VkPreisListeBlattChangeEdit extends VkPreisListeBlattChangeJson {
  preise: VkPreisEdit[]
}

type EditState = {
  preisblatt: VkPreisListeBlattChangeEdit
  artikelListe?: VkPreiseMasseBearbeitenEintragJson[]
}

export interface VkPeisMassenAenderDialogProps {
  kopf: VkPreiseMasseBearbeitenKopfJsonEx
  onClose: (fn?: () => void) => (data: any) => void
  onComplete: (updated?: any) => void
  selected?: VkPreiseMasseBearbeitenEintragJson[]
  visible?: boolean
}

const initPreisRow = (pg: VkPreisGruppeJson): VkPreisEdit =>
  ({
    key: nextKey(),
    preisGruppeId: pg.id,
    preisGruppeName: pg.name,
    preisGruppeBez: pg.bezeichnung,
    preis: null,
    basisTyp: null,
    brutto: pg.brutto,
    kasse: pg.kasse,
    spanne: null,
    aufschlag: null,
    staffelmenge: 0
  }) as VkPreisEdit

export const VkPeisMassenAenderDialog = ({
  kopf,
  onClose,
  onComplete,
  selected = [],
  visible = false
}: VkPeisMassenAenderDialogProps) => {
  const { forEinzelhandel } = kopf

  const { model, updModel, isChanged, save, uiLock } = useModelMgr<
    EditState,
    EditState,
    EditState,
    VkPreisListePreiseActionJson
  >({
    id: 'neu',
    api,
    title: 'VK-Preis Massenänderung',
    rest: 'vkpreisliste',
    restCreate: null,
    restSave: 'executeaction/preis',
    init: () =>
      ({
        preisblatt: {
          standortId: kopf.standortId,
          preise: kopf.preisgruppen.map(initPreisRow)
        }
      }) as EditState,
    validate: (model) => {
      const errors = model.preisblatt.preise
        .map((p) => {
          switch (p.basisTyp) {
            case VkPreisBasisTyp.MANUELL:
              if (p.preis == null && !forEinzelhandel) {
                return 'Preisgruppe ' + p.preisGruppeName + ': Preis muss gesetzt sein'
              }
              break
            case VkPreisBasisTyp.KALK_EK:
            case VkPreisBasisTyp.STAFFEL:
            case VkPreisBasisTyp.LISTENPREIS:
              if (p.spanne == null && p.aufschlag == null) {
                return (
                  'Preisgruppe ' + p.preisGruppeName + ': Spanne oder Aufschlag muss gesetzt sein'
                )
              }
              break
            default:
              return null
          }
        })
        .filter(Boolean)
      return errors
    },
    onSave: (rs) => onClose(onComplete)(rs.artikelListe),
    editMutator: (data) => ({
      ...data,
      preisblatt: { preise: [] }
    }),
    saveMutator: (data) => ({
      listeId: Number(kopf.id),
      standortId: kopf.standortId,
      eintraege: selected.map((s) => s.id),
      change: {
        abgeschlossen: data.preisblatt.abgeschlossen,
        kalkulationsvorschlagId: data.preisblatt.kalkulationsvorschlagId,
        rundungsregelId: data.preisblatt.rundungsregelId,
        referenzPreisgruppeId: data.preisblatt.referenzPreisgruppeId,
        staffelmenge: data.preisblatt.staffelmenge,
        quelle: data.preisblatt.quelle,
        festpreis: data.preisblatt.festpreis,
        preise: data.preisblatt.preise.map(
          (preis): VkPreisChangeJson => ({
            preisGruppeId: preis.preisGruppeId,
            staffelmenge: preis.staffelmenge,
            aufschlag: preis.aufschlag,
            basisTyp: forEinzelhandel ? VkPreisBasisTyp.MANUELL : preis.basisTyp,
            preis: preis.preis,
            spanne: preis.spanne
          })
        )
      }
    })
  })

  const tablePreise = forEinzelhandel
    ? safeArray(model.preisblatt.preise)
        .filter((preis) => preis.brutto && !preis.kasse)
        .map((preis) => ({
          ...preis,
          basisTyp: VkPreisBasisTyp.MANUELL
        }))
    : safeArray(model.preisblatt.preise)

  const readonly = model.preisblatt.kalkulationsvorschlagId != null

  const setPreisValue = useCallback(
    (row: VkPreisEdit, field: string, value: any) => {
      updModel((old) => {
        const edt = { ...row, [field]: value }

        switch (field) {
          case 'spanne':
            edt.aufschlag = null
            edt.preis = null
            break
          case 'aufschlag':
            edt.spanne = null
            edt.preis = null
            break
          case 'preis':
            edt.spanne = null
            edt.aufschlag = null
            break
          case 'basisTyp':
            if (edt.basisTyp === VkPreisBasisTyp.MANUELL) {
              edt.spanne = null
              edt.aufschlag = null
            } else {
              edt.preis = null
            }
            break
          default:
            // eslint-disable-next-line no-console
            console.error('Unexpected field', field)
        }

        if (isEqual(row, edt)) {
          return old
        }

        let next = {
          ...old,
          preisblatt: {
            ...old.preisblatt,
            preise: old.preisblatt.preise.map((r) => {
              if (r.preisGruppeId === row.preisGruppeId) {
                return edt
              }
              if (old.preisblatt.festpreis && r.brutto) {
                const rr: VkPreisEdit = {
                  ...r,
                  preis: edt.preis
                }
                return rr
              }
              return r
            })
          }
        }
        if (forEinzelhandel) {
          next = syncZKasse(next, edt)
        }
        return next
      })
    },
    [forEinzelhandel, updModel]
  )

  const setKalkulationsVorschlag = useCallback(
    (e) => {
      const kalkulationsvorschlagId = dataFromEvent(e).value
      let preise = model.preisblatt.preise || []

      if (kalkulationsvorschlagId != null) {
        const kalkulationsvorschlag = kopf.kalkulationsvorschlaegeMap.get(kalkulationsvorschlagId)
        if (kalkulationsvorschlag) {
          preise = kopf.preisgruppen.map(initPreisRow)
          kalkulationsvorschlag.preise.forEach((kr) => {
            const idx = preise.findIndex(
              (p) => p.preisGruppeId === kr.preisGruppeId && p.staffelmenge === kr.staffelmenge
            )
            if (idx !== -1) {
              preise[idx] = {
                ...preise[idx],
                aufschlag: kr.aufschlag,
                basisTyp: kr.basisTyp,
                preis: kr.preis,
                spanne: kr.spanne
                // basis: kr.basis,
                // brutto: kr.brutto,
                // kasse: kr.kasse,
                // etikettpreis: kr.etikettpreis,
              }
            }
          })
        }
      }

      updModel((old) => ({
        ...old,
        preisblatt: {
          ...old.preisblatt,
          kalkulationsvorschlagId: kalkulationsvorschlagId,
          preise
        }
      }))
    },
    [kopf.kalkulationsvorschlaegeMap, kopf.preisgruppen, model.preisblatt.preise, updModel]
  )

  const setRundungsregel = useCallback(
    (e) => {
      updModel((old) => ({
        ...old,
        preisblatt: {
          ...old.preisblatt,
          rundungsregelId: e.target.value
        }
      }))
    },
    [updModel]
  )

  const setQuelle = useCallback(
    (e) => {
      updModel((old) => ({
        ...old,
        preisblatt: {
          ...old.preisblatt,
          quelle: dataFromEvent(e).value
        }
      }))
    },
    [updModel]
  )

  const setReferenzPreisGruppeId = useCallback(
    (e) => {
      const referenzPreisgruppeId = dataFromEvent(e).value
      updModel((old) => {
        let next: EditState = {
          ...old,
          preisblatt: {
            ...old.preisblatt,
            referenzPreisgruppeId
          }
        }
        if (forEinzelhandel) {
          next = syncZKasse(next, null)
        }
        return next
      })
    },
    [forEinzelhandel, updModel]
  )

  const toggleFestpreis = useCallback(() => {
    updModel((old) => {
      const refPreis = old.preisblatt.preise.find(
        (p) => p.preisGruppeId === old.preisblatt.referenzPreisgruppeId
      )
      return {
        ...old,
        preisblatt: {
          ...old.preisblatt,
          festpreis: old.preisblatt.festpreis === false ? null : !old.preisblatt.festpreis,
          preise: old.preisblatt.festpreis
            ? old.preisblatt.preise
            : old.preisblatt.preise.map((p) => (p.brutto ? { ...p, preis: refPreis?.preis } : p))
        }
      } as EditState
    })
  }, [updModel])

  const topContent = useMemo(
    () => (
      <Grid container direction="row" spacing={2}>
        <Grid size={{ xs: 6 }}>
          <AutocompleteEx
            label="Rundungsregel"
            name="rundungsregelId"
            value={model.preisblatt.rundungsregelId}
            options={kopf.rundungsregeln}
            onChange={setRundungsregel}
            optionLabel="name"
            optionValue="id"
            emptyText="Unverändert"
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 6 }}>
          {forEinzelhandel ? (
            <SelectEnumField
              label="Quelle"
              name="quelle"
              value={model.preisblatt.quelle}
              onChange={setQuelle}
              emptyText="Unverändert"
              enumType={HkmEnum.VkPreisQuelle}
              noClear
              fullWidth
            />
          ) : (
            <AutocompleteEx
              label="Kalkulationsvorschlag"
              name="kalkulationsVorschlagId"
              value={model.preisblatt.kalkulationsvorschlagId}
              options={kopf.kalkulationsvorschlaege}
              onChange={setKalkulationsVorschlag}
              emptyText="Unverändert"
              optionLabel="name"
              optionValue="id"
              fullWidth
            />
          )}
        </Grid>
        {forEinzelhandel && (
          <>
            <Grid size={{ xs: 6 }}></Grid>
            <Grid size={{ xs: 6 }} marginTop={-3} marginBottom={-1}>
              <Checkbox
                label="Festpreis"
                size="small"
                checked={model.preisblatt.festpreis}
                onChange={() => toggleFestpreis()}
                indeterminate
              />
            </Grid>
          </>
        )}
      </Grid>
    ),
    [
      forEinzelhandel,
      kopf.kalkulationsvorschlaege,
      kopf.rundungsregeln,
      model.preisblatt.festpreis,
      model.preisblatt.kalkulationsvorschlagId,
      model.preisblatt.quelle,
      model.preisblatt.rundungsregelId,
      setKalkulationsVorschlag,
      setQuelle,
      setRundungsregel,
      toggleFestpreis
    ]
  )

  const columns = useMemo<Column<VkPreisEdit>[]>(
    () =>
      [
        {
          field: 'preisGruppeName',
          sortable: false,
          header: 'Preisgruppe',
          width: '8rem'
        },
        {
          field: 'preisGruppeBez',
          sortable: false,
          header: 'Bezeichnung',
          width: '16rem'
        },
        {
          field: 'brutto',
          sortable: false,
          header: 'Brutto',
          align: 'center',
          width: '2rem',
          type: 'boolean',
          off: forEinzelhandel,
          body: (row: any) => (row.brutto ? <Medal text="Brutto" /> : null)
        },
        {
          field: 'zkasse',
          sortable: false,
          header: 'ZKasse',
          align: 'center',
          width: '2rem',
          off: !forEinzelhandel,
          body: (row) => (
            <Checkbox
              compact
              checked={row.preisGruppeId === model.preisblatt.referenzPreisgruppeId}
              onChange={() => setReferenzPreisGruppeId({ value: row.preisGruppeId })}
              disabled={readonly || (!forEinzelhandel && row.basisTyp !== VkPreisBasisTyp.MANUELL)}
            />
          )
        },
        {
          field: 'basisTyp',
          sortable: false,
          header: 'Basis',
          width: '10rem',
          off: forEinzelhandel,
          body: (row: any) =>
            row.kasse ? (
              <KassenPreisBasisField
                referenzPreisgruppeId={model.preisblatt.referenzPreisgruppeId}
                onChange={(e) =>
                  updModel((old) => ({
                    ...old,
                    preisblatt: {
                      ...old.preisblatt,
                      referenzPreisgruppeId: e.target.value,
                      preise: arrayItemReplace(
                        old.preisblatt.preise,
                        (r) => r.preisGruppeId === row.preisGruppeId,
                        {
                          ...row,
                          basisTyp: e.target.value == null ? null : VkPreisBasisTyp.PREISGRUPPE
                        }
                      )
                    }
                  }))
                }
                preisgruppen={kopf.preisgruppen}
                emptyText="Unverändert"
              />
            ) : (
              <VkPreisBasisField
                name="basisTyp"
                model={row}
                basisPreisgruppeName={
                  kopf.basisPreisgruppe && row.preisGruppeId !== kopf.basisPreisgruppe.id
                    ? kopf.basisPreisgruppe.name
                    : null
                }
                onChange={setPreisValue}
                readonly={readonly}
                emptyText="Unverändert"
                forcePreise
              />
            )
        },
        {
          field: 'preis',
          sortable: false,
          header: 'VK-Preis',
          align: 'right',
          width: '12rem',
          body: (row, { column }) => (
            <NumberCell
              model={row}
              field={column.field as string}
              onChange={setPreisValue}
              readonly={
                readonly ||
                (forEinzelhandel
                  ? row.basisTyp !== VkPreisBasisTyp.MANUELL ||
                    model.preisblatt.quelle === VkPreisQuelle.LISTUNG
                  : row.basisTyp !== VkPreisBasisTyp.MANUELL)
              }
            />
          )
        },
        {
          field: 'spanne',
          sortable: false,
          header: 'Spanne %',
          align: 'right',
          width: '8rem',
          body: (row, { column }) => (
            <NumberCell
              model={row}
              field={column.field as string}
              onChange={setPreisValue}
              allowNegative
              max={100}
              readonly={
                readonly ||
                row.basisTyp == null ||
                (!forEinzelhandel && row.basisTyp === VkPreisBasisTyp.MANUELL) ||
                model.preisblatt.quelle === VkPreisQuelle.LISTUNG ||
                row.kasse
              }
            />
          )
        },
        {
          field: 'aufschlag',
          sortable: false,
          header: 'Aufschlag %',
          align: 'right',
          width: '8rem',
          off: forEinzelhandel,
          body: (row, { column }) => (
            <NumberCell
              model={row}
              field={column.field as string}
              onChange={setPreisValue}
              allowNegative
              readonly={
                readonly ||
                row.basisTyp == null ||
                row.basisTyp === VkPreisBasisTyp.MANUELL ||
                row.kasse
              }
            />
          )
        }
      ] as Column<VkPreisEdit>[],
    [
      forEinzelhandel,
      kopf.basisPreisgruppe,
      kopf.preisgruppen,
      model.preisblatt.festpreis,
      model.preisblatt.quelle,
      model.preisblatt.referenzPreisgruppeId,
      readonly,
      setPreisValue,
      setReferenzPreisGruppeId,
      updModel
    ]
  )

  const actions = (
    <>
      <PerformButton
        onClickVoid={save}
        disabled={!isChanged}
        label={`Für ${selected.length} Einträge anwenden`}
      />
      <CancelButton onClick={onClose()} />
    </>
  )

  return (
    <DialogEx
      open={visible}
      onClose={onClose()}
      maxWidth="md"
      fullWidth
      height="700px"
      title={`VK-Preise für ${selected.length} Artikel`}
      topContent={topContent}
      actions={actions}
    >
      <StatePlane uiLock={uiLock}>
        <DataTable
          name="VkPeisMassenAenderTable"
          columns={columns}
          value={tablePreise}
          dense
          selectMode="none"
          focustype="none"
        />
      </StatePlane>
    </DialogEx>
  )
}

const syncZKasse = (old: EditState, edt: VkPreisEdit) => {
  let next = old
  const refPreis = old.preisblatt.preise.find(
    (r) => r.preisGruppeId === old.preisblatt.referenzPreisgruppeId
  )
  if (refPreis && refPreis != edt) {
    next = {
      ...old,
      preisblatt: {
        ...old.preisblatt,
        preise: old.preisblatt.preise.map((r) => {
          if (r.kasse) {
            const rr: VkPreisEdit = {
              ...r,
              preis: refPreis.preis
            }
            return rr
          }
          return r
        })
      }
    }
  }
  return next
}
