import { Grid2 as Grid } from '@mui/material'
import { LieferantField } from '@one/components/Lieferant/LieferantField'
import { HkmEnum } from '@one/enums/HkmEnum'
import { LieferantAboSearcherLieferantAboCriteriaJson } from '@one/typings/apiTypings'
import { ChangeFunction } from '@utils/formstate'
import { SearchButton } from '@utils/ui/Buttons/SearchButton'
import { CardEx } from '@utils/ui/CardEx'
import { ShortCutHandler } from '@utils/ui/ShortCutHandler'
import { TooltipWrapper } from '@utils/ui/TooltipWrapper'
import { SelectEnumField } from '@utils/ui/fields/SelectEnumField'
import { TextField } from '@utils/ui/fields/TextField'

export interface LieferantAboUebersichtParamsProps {
  value: LieferantAboSearcherLieferantAboCriteriaJson
  onSearch: VoidFunction
  onChange: ChangeFunction<LieferantAboSearcherLieferantAboCriteriaJson>
}

export const LieferantAboUebersichtParams = ({
  value,
  onSearch,
  onChange
}: LieferantAboUebersichtParamsProps) => {
  const shortcuts = {
    Enter: onSearch
  }

  const tooltip =
    value.aenderungsTyp && 'Kann nicht zusammen mit Lieferant-Änderungstyp genutzt werden'

  return (
    <ShortCutHandler shortcuts={shortcuts}>
      <CardEx
        title="Lieferantenabopflege"
        backLink
        collapsiable
        bottomActions={<SearchButton onClick={onSearch} />}
      >
        <Grid container spacing={2}>
          <Grid size={{ xs: 6, sm: 2 }}>
            <TooltipWrapper title={tooltip}>
              <TextField
                fullWidth
                label="Abo-Name"
                name="name"
                value={value.name}
                onChange={onChange}
                disabled={Boolean(tooltip)}
              />
            </TooltipWrapper>
          </Grid>
          <Grid size={{ xs: 6, sm: 4 }}>
            <TooltipWrapper title={tooltip}>
              <LieferantField
                fullWidth
                label="Lieferant"
                name="lieferant"
                value={value.lieferant}
                onChange={onChange}
                disabled={Boolean(tooltip)}
                modus="alle"
              />
            </TooltipWrapper>
          </Grid>
          <Grid size={{ xs: 6, sm: 2 }}>
            <TooltipWrapper title={tooltip}>
              <SelectEnumField
                fullWidth
                name="abonniertStatus"
                label="Abo-Status"
                value={value.abonniertStatus}
                onChange={onChange}
                enumType={HkmEnum.LieferantAbonniertStatus}
                disabled={Boolean(tooltip)}
              />
            </TooltipWrapper>
          </Grid>
          <Grid size={{ xs: 6, sm: 3 }}>
            <SelectEnumField
              fullWidth
              name="aenderungsTyp"
              label="Lieferant-Änderungstyp"
              value={value.aenderungsTyp}
              onChange={onChange}
              enumType={HkmEnum.LieferantAenderungTyp}
            />
          </Grid>
        </Grid>
      </CardEx>
    </ShortCutHandler>
  )
}
