import { Grid2 as Grid } from '@mui/material'
import { api } from '@one/api'
import { HkmEnum } from '@one/enums/HkmEnum'
import { JobSchedulerInfo } from '@one/typings/apiTypings'
import { useApiCaller } from '@utils/apicaller'
import { formatDateTime } from '@utils/dateutils'
import { useEnums } from '@utils/enums'
import { Button } from '@utils/ui/Buttons/Button'
import { StaticField } from '@utils/ui/fields/StaticField'
import { useCallback, useEffect, useRef, useState } from 'react'
import { AdminViewPanel } from './AdminViewPanel'

export type AVImportSchedulerProps = {
  zoomed: boolean
  setZoomed: () => void
}

export const AVImportScheduler = ({ zoomed, setZoomed }: AVImportSchedulerProps) => {
  const [apiCall, apiBusy] = useApiCaller(api)

  const [infos, setInfos] = useState<JobSchedulerInfo>(null)

  const { et } = useEnums()

  const apiBusyRef = useRef(false)
  apiBusyRef.current = apiBusy

  const startScheduler = () => {
    setInfos(null)
    apiCall<JobSchedulerInfo>({
      method: 'GET',
      rest: 'import/startScheduler',
      onSuccess: (data) => setInfos(data)
    })
  }

  const startNowScheduler = () => {
    setInfos(null)
    apiCall({
      method: 'GET',
      rest: 'import/forceUpdateArtikelSelektion',
      onSuccess: (data) => setInfos(data)
    })
  }
  const stopScheduler = () => {
    setInfos(null)
    apiCall({
      method: 'GET',
      rest: 'import/stopScheduler',
      onSuccess: (data) => setInfos(data)
    })
  }

  const checkScheduler = useCallback(() => {
    if (apiBusyRef.current) {
      return
    }
    apiCall({
      method: 'GET',
      rest: 'import/statusScheduler',
      onSuccess: (data) => setInfos(data)
    })
  }, [apiCall])

  useEffect(() => {
    checkScheduler()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // useEffect(() => {
  //   if (visible) {
  //     const timeout = setInterval(checkScheduler, 5000)
  //     return () => clearTimeout(timeout)
  //   }
  //   return undefined
  // }, [checkScheduler, visible])

  return (
    <AdminViewPanel
      // zoomed={zoomed}
      // setZoomed={setZoomed}
      title="Import Scheduler"
      subtitle="Listungsänderungen aus PIM, ZART und Allianz verarbeiten"
      actions={[
        <Button key="start" label="Start" onClick={startScheduler} disabled={apiBusy} />,
        <Button key="stop" label="Stop" onClick={stopScheduler} disabled={apiBusy} />,
        <Button
          key="run"
          label="Ausführen"
          tooltip="Der Job wird sofort ausgeführt, danach läuft er wieder im angegebenen Interval"
          onClick={startNowScheduler}
          disabled={apiBusy}
          color="error"
        />,
        <Button key="check" label="Aktualisieren" onClick={checkScheduler} disabled={apiBusy} />
      ]}
    >
      <Grid container spacing={1}>
        <Grid size={{xs:6}}>
          <StaticField
            label="Status"
            value={et(HkmEnum.JobSchedulerStatus, infos?.status)}
            fullWidth
          />
        </Grid>
        <Grid size={{xs:6}}>
          <StaticField label="Interval (min)" value={infos?.updateInterval} fullWidth />
        </Grid>
        <Grid size={{xs:6}}>
          <StaticField label="Letzter Start" value={formatDateTime(infos?.lastStart)} fullWidth />
        </Grid>
        <Grid size={{xs:6}}>
          <StaticField label="Nächster Start" value={formatDateTime(infos?.nextStart)} fullWidth />
        </Grid>
        <Grid size={{xs:12}}>
          <StaticField label="Info" value={infos?.statusInfo} fullWidth />
        </Grid>
      </Grid>
    </AdminViewPanel>
  )
}
