import {
  CropSquareRounded,
  IndeterminateCheckBox,
  SquareRounded,
  WidgetsOutlined
} from '@mui/icons-material'
import { Checkbox, LinearProgress, TableCell, TableHead, TableRow } from '@mui/material'
import { toString } from '@utils/utils'
import clsx from 'clsx'
import { useCallback, useMemo, useRef } from 'react'
import { Column, ColumnFilterTrigger } from './DataTable'
import { DataTableColumnHead } from './DataTableColumnHead'

export interface DataTableHeaderProps<T = any> {
  headRef: any
  tableUID: string
  classes: any
  columns: Column<T>[]
  numSelected: number
  onRequestSort: (e: any, property: any) => void
  onSelectAllClick: (e: any) => void
  rowCount: number
  actionCol: boolean
  stickyHints: any
  orderBy?: any[]
  selectMode?: 'none' | 'multi' | 'single'
  columnFilterTrigger?: ColumnFilterTrigger<T>
  selectCol?: boolean
  stickyHeader?: boolean
  loading?: boolean
  onContextMenu?: (e: any) => void | null
  filterRows?: (mc: number) => T[]
}

const checkBoxProps = { 'aria-label': 'select all desserts' }

export const DataTableHeader = ({
  headRef,
  tableUID,
  classes,
  columns,
  stickyHints,
  selectMode,
  onSelectAllClick,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
  actionCol,
  columnFilterTrigger,
  selectCol = true,
  stickyHeader = false,
  loading = false,
  onContextMenu = null,
  filterRows
}: DataTableHeaderProps) => {
  const headRowRef = useRef<HTMLTableRowElement>(undefined)

  const createSortHandler = useCallback(
    (property) => (event) => {
      onRequestSort(event, property)
    },
    [onRequestSort]
  )

  const colClassName = useMemo(
    () =>
      clsx(
        classes.colHeader,
        stickyHeader && 'MuiPaper-root',
        stickyHeader && classes.stickyheader
      ),
    [classes.colHeader, classes.stickyheader, stickyHeader]
  )

  const actionColClassName = useMemo(
    () =>
      clsx(
        classes.actionColTH,
        classes.colHeader,
        stickyHints.header,
        stickyHints.actionColTH,
        classes.actionHeader
      ),
    [
      classes.actionColTH,
      classes.actionHeader,
      classes.colHeader,
      stickyHints.actionColTH,
      stickyHints.header
    ]
  )

  const colCount = useMemo(
    () => columns.length + (actionCol ? 1 : 0) + (selectCol ? 1 : 0),
    [actionCol, selectCol, columns.length]
  )

  const loadingIndicator = useMemo(() => {
    return (
      loading && (
        <TableRow key="loading" className={classes.loading}>
          <TableCell colSpan={colCount} style={{ top: headRowRef.current?.offsetHeight }}>
            <div style={{ width: '100%', position: 'absolute', top: 1 }}>
              <LinearProgress />
            </div>
          </TableCell>
        </TableRow>
      )
    )
  }, [loading, colCount, classes])

  const renderColHeader = useCallback(
    (column, index) => {
      if (column.key == '::reorder') {
        return (
          <TableCell
            key={column.key}
            padding="none"
            className={clsx(colClassName, stickyHints.stickyClassTH(index), classes.headerCol)}
            style={{ width: '2em' }}
          />
        )
      }
      if (column.key == '::select') {
        return (
          <TableCell
            key={column.key}
            data-name="select-all"
            padding="checkbox"
            className={clsx(colClassName, stickyHints.stickyClassTH(index), classes.headerCol)}
          >
            {selectMode === 'multi' && (
              <Checkbox
                style={{ margin: -8 }}
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={checkBoxProps}
                indeterminateIcon={<IndeterminateCheckBox />}
                checkedIcon={<SquareRounded />}
                icon={<CropSquareRounded />}
              />
            )}
          </TableCell>
        )
      }
      if (column.key == '::action') {
        return (
          <TableCell
            key={column.key}
            className={clsx(
              colClassName,
              stickyHints.stickyClassTH(index),
              classes.headerCol,
              classes.actionHeader
            )}
          >
            <WidgetsOutlined fontSize="small" />
          </TableCell>
        )
      }
      if (column.key == '::filler') {
        return (
          <TableCell
            key={column.key}
            className={clsx(colClassName, stickyHints.stickyClassTH(index), classes.headerCol)}
            padding="none"
            style={{ width: column.width }}
          />
        )
      }

      const key = column.key || column.field || index
      const skey = toString(key)
      const orderByCol = orderBy.find((o) => o.key === key) || {}
      let align
      if (column.align) {
        align = column.align
      } else {
        switch (column.type) {
          case 'number':
          case 'money2':
            align = 'right'
            break
          default:
            align = 'left'
            break
        }
      }

      return (
        <TableCell
          key={skey}
          style={{
            width: column.width,
            backgroundColor:
              (columnFilterTrigger?.isFilter(column) && '') || column.backgroundColor,
            opacity: column.opacity
          }}
          align={align}
          padding={column.disablePadding ? 'none' : 'normal'}
          sortDirection={orderByCol.dir}
          className={clsx(
            colClassName,
            stickyHints.stickyClassTH(index),
            classes.headerCol,
            columnFilterTrigger &&
              ((align === 'right' && classes.colHeadFilterSpaceLeft) ||
                (align === 'center' && classes.colHeadFilterSpaceCenter) ||
                classes.colHeadFilterSpaceRight),
            columnFilterTrigger?.isFilter(column) && classes.colHeadFilterActive
          )}
        >
          <DataTableColumnHead
            title={column.headerTip}
            column={column}
            sortHandler={createSortHandler}
            orderBy={orderByCol}
            classes={classes}
            columnFilterTrigger={columnFilterTrigger}
            filterRows={filterRows}
            align={align}
          />
        </TableCell>
      )
    },
    [
      classes,
      colClassName,
      columnFilterTrigger,
      createSortHandler,
      filterRows,
      numSelected,
      onSelectAllClick,
      orderBy,
      rowCount,
      selectMode,
      stickyHints
    ]
  )

  // debugLog(tableUID, 'HEAD-RENDER')

  return (
    <TableHead ref={headRef} onContextMenu={onContextMenu}>
      <TableRow key="header" ref={headRowRef}>
        {columns.map((column, index) => renderColHeader(column, index))}
        {actionCol && (
          <TableCell key="action-col" className={actionColClassName}>
            <WidgetsOutlined fontSize="small" />
          </TableCell>
        )}
      </TableRow>
      {loadingIndicator}
    </TableHead>
  )
}
