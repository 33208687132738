export const Constraints = {
  "AboArtikel": {
    "aenderungenAnzahl": {
      "notNull": true
    },
    "aenderungenIds": {
      "minSize": 0,
      "maxSize": 4000
    },
    "attributWarnungenGeprueft": {
      "notNull": true
    },
    "lfnrVkPreis": {
      "notNull": true
    },
    "validateItemIds": {
      "minSize": 0,
      "maxSize": 4000
    },
    "vkPreiseVersion": {
      "notNull": true
    },
    "zusatzAenderungenAnzahl": {
      "notNull": true
    },
    "zusatzAenderungenIds": {
      "minSize": 0,
      "maxSize": 4000
    }
  },
  "AboArtikelChangeItem": {
    "label": {
      "minSize": 0,
      "maxSize": 100
    },
    "newString": {
      "minSize": 0,
      "maxSize": 4000
    },
    "oldString": {
      "minSize": 0,
      "maxSize": 4000
    },
    "path": {
      "minSize": 0,
      "maxSize": 4000
    },
    "zusatz": {
      "notNull": true
    }
  },
  "AboArtikelLieferant": {
    "lfnrEkListenPreis": {
      "notNull": true
    },
    "lfnrEkPreis": {
      "notNull": true
    }
  },
  "AboEbene": {
    "name": {
      "minSize": 0,
      "maxSize": 50
    }
  },
  "AboEbeneArtikel": {
    "vkPreiseVersion": {
      "notNull": true
    }
  },
  "Allianz": {
    "bemerkung": {
      "minSize": 0,
      "maxSize": 2048
    },
    "name": {
      "minSize": 1,
      "maxSize": 100,
      "notNull": true
    }
  },
  "AllianzDatenBereitstellung": {
    "aenArtikelCount": {
      "notNull": true
    },
    "aenEkKonditionenRabattgruppeCount": {
      "notNull": true
    },
    "aenLieferantenCount": {
      "notNull": true
    },
    "artikelQwErrorCount": {
      "notNull": true
    },
    "delArtikelCount": {
      "notNull": true
    },
    "delEkKonditionenRabattgruppeCount": {
      "notNull": true
    },
    "delLieferantenCount": {
      "notNull": true
    },
    "neuArtikelCount": {
      "notNull": true
    },
    "neuEkKonditionenListeCount": {
      "notNull": true
    },
    "neuEkKonditionenRabattgruppeCount": {
      "notNull": true
    },
    "neuLieferantenCount": {
      "notNull": true
    },
    "qwArtikel": {
      "notNull": true
    },
    "qwArtikelCount": {
      "notNull": true
    },
    "qwLieferantenCount": {
      "notNull": true
    }
  },
  "AllianzDatenBereitstellungAenderungArtikel": {
    "aktiv": {
      "notNull": true
    }
  },
  "AllianzDatenBereitstellungAenderungLieferant": {
    "aktiv": {
      "notNull": true
    }
  },
  "AllianzLieferantBereitgestellt": {
    "isBereitgestellt": {
      "notNull": true
    }
  },
  "AllianzLieferantBereitstellung": {
    "isBereitgestellt": {
      "notNull": true
    }
  },
  "AllianzQuellenWechselArtikel": {
    "isBearbeitet": {
      "notNull": true
    }
  },
  "AllianzQuellenWechselLieferant": {
    "isBearbeitet": {
      "notNull": true
    }
  },
  "ArtikelAbo": {
    "bemerkung": {
      "minSize": 0,
      "maxSize": 2048
    },
    "eanGruppe": {
      "notNull": true
    },
    "name": {
      "minSize": 1,
      "maxSize": 100,
      "notNull": true
    }
  },
  "ArtikelAenderung": {
    "akt": {
      "notNull": true
    },
    "ekPreiseGeaendert": {
      "notNull": true
    },
    "preisaenderungenGetrennt": {
      "notNull": true
    },
    "status": {
      "notNull": true
    },
    "typ": {
      "notNull": true
    },
    "vkPreiseGeaendert": {
      "notNull": true
    }
  },
  "ArtikelAenderungAuslistung": {
    "auslistung": {
      "notNull": true
    }
  },
  "ArtikelAenderungEskalation": {
    "typ": {
      "notNull": true
    }
  },
  "ArtikelDatenpoolUebernahme": {
    "hageNummer": {
      "minSize": 0,
      "maxSize": 10
    }
  },
  "ArtikelEkPreis": {
    "gueltigVon": {
      "notNull": true
    }
  },
  "ArtikelMengeneinheit": {
    "ean": {
      "minSize": 0,
      "maxSize": 30
    },
    "me": {
      "notNull": true
    },
    "parent": {
      "notNull": true
    },
    "typ": {
      "notNull": true
    }
  },
  "ArtikelPlakat": {
    "layout": {
      "minSize": 0,
      "maxSize": 255
    }
  },
  "ArtikelPlakatText": {
    "tag": {
      "minSize": 0,
      "maxSize": 255
    },
    "text": {
      "minSize": 0,
      "maxSize": 255
    },
    "zusatzText": {
      "minSize": 0,
      "maxSize": 255
    }
  },
  "ArtikelSelektionEinstellung": {
    "name": {
      "minSize": 0,
      "maxSize": 255
    }
  },
  "ArtikelSelektionEintrag": {
    "eintragNichtMehrSelektiert": {
      "notNull": true
    }
  },
  "ArtikelVKPreis": {
    "pg": {
      "minSize": 0,
      "maxSize": 255
    }
  },
  "ArtikelVKPreise": {
    "grundPreisEinheit": {
      "minSize": 0,
      "maxSize": 255
    },
    "steuerKonto": {
      "minSize": 0,
      "maxSize": 255
    },
    "vkPreiseinheit": {
      "minSize": 0,
      "maxSize": 255
    },
    "waehrung": {
      "minSize": 0,
      "maxSize": 255
    }
  },
  "ArtikelVerteilungDefinition": {
    "aktiv": {
      "notNull": true
    },
    "name": {
      "minSize": 1,
      "maxSize": 100,
      "notNull": true
    },
    "preisVerteilungEinstellung": {
      "notNull": true
    }
  },
  "AsyncTask": {
    "aktCount": {
      "notNull": true
    },
    "progressInfo": {
      "minSize": 0,
      "maxSize": 500
    },
    "start": {
      "notNull": true
    },
    "status": {
      "notNull": true
    },
    "totalCount": {
      "notNull": true
    }
  },
  "Benutzer": {
    "login": {
      "minSize": 1,
      "maxSize": 40,
      "notNull": true
    },
    "name": {
      "minSize": 1,
      "maxSize": 100,
      "notNull": true
    }
  },
  "BulkString": {
    "value": {
      "minSize": 0,
      "maxSize": 255
    }
  },
  "DataLakeRecordError": {
    "artikel": {
      "minSize": 0,
      "maxSize": 35
    },
    "gln": {
      "minSize": 0,
      "maxSize": 35
    },
    "standort": {
      "minSize": 0,
      "maxSize": 35
    },
    "typ": {
      "notNull": true
    }
  },
  "DataLakeRecordItem": {
    "typ": {
      "notNull": true
    }
  },
  "DataLakeSystemInput": {
    "groupId": {
      "minSize": 0,
      "maxSize": 255
    },
    "host": {
      "minSize": 0,
      "maxSize": 255
    },
    "password": {
      "minSize": 0,
      "maxSize": 255
    },
    "securityProtocol": {
      "minSize": 0,
      "maxSize": 255
    },
    "topic": {
      "minSize": 0,
      "maxSize": 255
    },
    "topicError": {
      "minSize": 0,
      "maxSize": 255
    },
    "user": {
      "minSize": 0,
      "maxSize": 255
    }
  },
  "DatenpoolSystemNeon": {
    "serverLink": {
      "minSize": 0,
      "maxSize": 255
    },
    "token": {
      "minSize": 0,
      "maxSize": 255
    }
  },
  "DzArtStueli": {
    "hagenummer": {
      "minSize": 0,
      "maxSize": 30
    }
  },
  "DzArtikelArt": {
    "bez": {
      "minSize": 0,
      "maxSize": 100
    },
    "kurzBez": {
      "minSize": 0,
      "maxSize": 10
    },
    "name": {
      "minSize": 0,
      "maxSize": 100
    }
  },
  "DzArtikelLieferant": {
    "akt": {
      "notNull": true
    },
    "angebotstext": {
      "minSize": 0,
      "maxSize": 4000
    },
    "gefKomm": {
      "minSize": 0,
      "maxSize": 255
    },
    "industrieArtikelNummer": {
      "minSize": 0,
      "maxSize": 60
    },
    "isAusgelistet": {
      "notNull": true
    },
    "isGeloescht": {
      "notNull": true
    },
    "isGesperrt": {
      "notNull": true
    }
  },
  "DzArtikelVersion": {
    "betriebsTyp": {
      "notNull": true
    },
    "bez1": {
      "minSize": 0,
      "maxSize": 255
    },
    "bez2": {
      "minSize": 0,
      "maxSize": 255
    },
    "bez3": {
      "minSize": 0,
      "maxSize": 255
    },
    "bez4": {
      "minSize": 0,
      "maxSize": 255
    },
    "bontext": {
      "minSize": 0,
      "maxSize": 80
    },
    "btNummer": {
      "minSize": 1,
      "maxSize": 100
    },
    "btOrgNummer": {
      "minSize": 0,
      "maxSize": 100
    },
    "hageNummer": {
      "minSize": 0,
      "maxSize": 20
    },
    "matchcode": {
      "minSize": 0,
      "maxSize": 60
    },
    "pfandBTArtikelNr": {
      "minSize": 0,
      "maxSize": 255
    },
    "pflichttextCO2Kostenaufteilungsgesetz": {
      "maxSize": 255
    },
    "preisrolle": {
      "minSize": 0,
      "maxSize": 255
    },
    "selektionDz": {
      "minSize": 0,
      "maxSize": 60
    },
    "vorlaeuferArtikelNr": {
      "minSize": 0,
      "maxSize": 255
    },
    "vorlaueferArtikel": {
      "minSize": 0,
      "maxSize": 255
    },
    "warengruppeBDBNummer": {
      "minSize": 0,
      "maxSize": 20
    }
  },
  "DzBetriebstyp": {
    "bezeichnung": {
      "minSize": 0,
      "maxSize": 255
    },
    "vorlauftext": {
      "minSize": 0,
      "maxSize": 255
    }
  },
  "DzBetriebstypNummer": {
    "bisArtikel": {
      "minSize": 0,
      "maxSize": 255
    },
    "vonArtikel": {
      "minSize": 0,
      "maxSize": 255
    }
  },
  "DzBtArtikel": {
    "betriebsTyp": {
      "notNull": true
    },
    "btNummer": {
      "minSize": 1,
      "maxSize": 100
    },
    "eigenlistung": {
      "notNull": true
    },
    "hageNummer": {
      "minSize": 0,
      "maxSize": 100
    },
    "zeBtNummer": {
      "minSize": 0,
      "maxSize": 100
    }
  },
  "DzKalkulationsvorschlag": {
    "bezeichnung": {
      "minSize": 0,
      "maxSize": 60
    },
    "name": {
      "minSize": 1,
      "maxSize": 50,
      "notNull": true
    }
  },
  "DzLieferant": {
    "branche": {
      "minSize": 0,
      "maxSize": 200
    },
    "client": {
      "minSize": 0,
      "maxSize": 5
    },
    "edi": {
      "notNull": true
    },
    "eigenlistung": {
      "notNull": true
    },
    "fhEinheit": {
      "minSize": 0,
      "maxSize": 10
    },
    "gruppe": {
      "minSize": 0,
      "maxSize": 10
    },
    "handelsCode": {
      "minSize": 0,
      "maxSize": 10
    },
    "iln": {
      "minSize": 0,
      "maxSize": 15
    },
    "lieferantArt": {
      "notNull": true
    },
    "lieferbedingung": {
      "minSize": 0,
      "maxSize": 255
    },
    "nummer": {
      "minSize": 0,
      "maxSize": 255
    },
    "sprache": {
      "minSize": 0,
      "maxSize": 10
    },
    "steuernummer": {
      "minSize": 0,
      "maxSize": 20
    },
    "suchbegriff": {
      "minSize": 0,
      "maxSize": 50
    },
    "unsereKundennummer": {
      "minSize": 0,
      "maxSize": 20
    },
    "ustIDNummer": {
      "minSize": 0,
      "maxSize": 20
    },
    "vorlauf": {
      "minSize": 0,
      "maxSize": 10
    },
    "waehrung": {
      "minSize": 0,
      "maxSize": 10
    },
    "zahlungsZiel": {
      "minSize": 0,
      "maxSize": 10
    }
  },
  "DzLieferantAdresse": {
    "anrede": {
      "minSize": 0,
      "maxSize": 80
    },
    "email": {
      "minSize": 0,
      "maxSize": 80
    },
    "fax": {
      "minSize": 0,
      "maxSize": 50
    },
    "gln": {
      "minSize": 0,
      "maxSize": 15
    },
    "internet": {
      "minSize": 0,
      "maxSize": 80
    },
    "land": {
      "minSize": 0,
      "maxSize": 10
    },
    "name1": {
      "minSize": 0,
      "maxSize": 100
    },
    "name2": {
      "minSize": 0,
      "maxSize": 100
    },
    "name3": {
      "minSize": 0,
      "maxSize": 100
    },
    "ort": {
      "minSize": 0,
      "maxSize": 50
    },
    "ortsteil": {
      "minSize": 0,
      "maxSize": 50
    },
    "plz": {
      "minSize": 0,
      "maxSize": 20
    },
    "postfach": {
      "minSize": 0,
      "maxSize": 20
    },
    "postfachOrt": {
      "minSize": 0,
      "maxSize": 50
    },
    "postfachPlz": {
      "minSize": 0,
      "maxSize": 10
    },
    "strasse": {
      "minSize": 0,
      "maxSize": 100
    },
    "tel1": {
      "minSize": 0,
      "maxSize": 50
    },
    "tel2": {
      "minSize": 0,
      "maxSize": 50
    }
  },
  "DzMapEntryArtikelArt": {
    "extRef": {
      "minSize": 0,
      "maxSize": 10
    }
  },
  "DzMapEntryMengenEinheit": {
    "extRef": {
      "minSize": 0,
      "maxSize": 10
    }
  },
  "DzMapEntryPreisgruppe": {
    "extRef": {
      "minSize": 0,
      "maxSize": 10
    }
  },
  "DzMapEntrySteuer": {
    "extRef": {
      "minSize": 0,
      "maxSize": 10
    }
  },
  "DzMapEntryWarengruppe": {
    "extRef": {
      "minSize": 0,
      "maxSize": 10
    }
  },
  "DzMapperEbeneError": {
    "extRef": {
      "minSize": 0,
      "maxSize": 255
    }
  },
  "DzMapperEinstellung": {
    "name": {
      "minSize": 0,
      "maxSize": 255
    },
    "pimDatenmodell": {
      "notNull": true
    }
  },
  "DzPreisgruppe": {
    "bezeichnung": {
      "minSize": 0,
      "maxSize": 300
    },
    "extRef": {
      "minSize": 0,
      "maxSize": 30
    },
    "name": {
      "minSize": 0,
      "maxSize": 10
    }
  },
  "DzPreismenge": {
    "bezeichnung": {
      "minSize": 0,
      "maxSize": 60
    },
    "name": {
      "minSize": 0,
      "maxSize": 30
    }
  },
  "DzRundungsregel": {
    "bezeichnung": {
      "minSize": 0,
      "maxSize": 50
    },
    "ganzeCent": {
      "notNull": true
    },
    "name": {
      "minSize": 1,
      "maxSize": 50,
      "notNull": true
    }
  },
  "DzStandort": {
    "name": {
      "minSize": 1,
      "maxSize": 30,
      "notNull": true
    },
    "nr": {
      "notNull": true
    }
  },
  "DzSteuer": {
    "bezeichnung": {
      "minSize": 0,
      "maxSize": 60
    },
    "name": {
      "minSize": 0,
      "maxSize": 10
    }
  },
  "DzWarengruppe": {
    "bezeichnung": {
      "minSize": 0,
      "maxSize": 100
    },
    "nummer": {
      "minSize": 1,
      "maxSize": 10,
      "notNull": true
    },
    "suchbegriff": {
      "minSize": 0,
      "maxSize": 100
    }
  },
  "EkKonditionenDefinition": {
    "bezRabattProz1": {
      "minSize": 0,
      "maxSize": 50
    },
    "bezRabattProz2": {
      "minSize": 0,
      "maxSize": 50
    },
    "bezRabattProz3": {
      "minSize": 0,
      "maxSize": 50
    },
    "bezRabattProz4": {
      "minSize": 0,
      "maxSize": 50
    },
    "bezRabattProz5": {
      "minSize": 0,
      "maxSize": 50
    },
    "bezZuschlAbs1": {
      "minSize": 0,
      "maxSize": 50
    },
    "bezZuschlAbs2": {
      "minSize": 0,
      "maxSize": 50
    },
    "bezZuschlAbs3": {
      "minSize": 0,
      "maxSize": 50
    },
    "bezZuschlAbs4": {
      "minSize": 0,
      "maxSize": 50
    },
    "bezZuschlAbs5": {
      "minSize": 0,
      "maxSize": 50
    },
    "kurzRabattProz1": {
      "minSize": 0,
      "maxSize": 10
    },
    "kurzRabattProz2": {
      "minSize": 0,
      "maxSize": 10
    },
    "kurzRabattProz3": {
      "minSize": 0,
      "maxSize": 10
    },
    "kurzRabattProz4": {
      "minSize": 0,
      "maxSize": 10
    },
    "kurzRabattProz5": {
      "minSize": 0,
      "maxSize": 10
    },
    "kurzZuschlAbs1": {
      "minSize": 0,
      "maxSize": 10
    },
    "kurzZuschlAbs2": {
      "minSize": 0,
      "maxSize": 10
    },
    "kurzZuschlAbs3": {
      "minSize": 0,
      "maxSize": 10
    },
    "kurzZuschlAbs4": {
      "minSize": 0,
      "maxSize": 10
    },
    "kurzZuschlAbs5": {
      "minSize": 0,
      "maxSize": 10
    }
  },
  "EkKonditionenListe": {
    "bemerkung": {
      "minSize": 0,
      "maxSize": 255
    },
    "gueltigVon": {
      "notNull": true
    },
    "name": {
      "minSize": 0,
      "maxSize": 100
    },
    "typ": {
      "notNull": true
    }
  },
  "EkKonditionenListeEintrag": {
    "gueltigVon": {
      "notNull": true
    }
  },
  "EkKonditionenListenImportEintrag": {
    "gueltigVon": {
      "notNull": true
    }
  },
  "EkKonditionenRabattgruppeWert": {
    "rabattgruppe": {
      "notNull": true
    },
    "staffelmenge": {
      "notNull": true
    }
  },
  "EkKonditionenRabattgruppenListe": {
    "gueltigVon": {
      "notNull": true
    }
  },
  "EkKonditionenRabattgruppenListeEintrag": {
    "staffelmenge": {
      "notNull": true
    }
  },
  "EkKonditionenStaffelWert": {
    "staffelmenge": {
      "notNull": true
    }
  },
  "EkKonditionenWert": {
    "hauptstaffel": {
      "notNull": true
    },
    "staffelmenge": {
      "notNull": true
    }
  },
  "EkListenPreisListe": {
    "bemerkung": {
      "minSize": 0,
      "maxSize": 255
    },
    "gueltigVon": {
      "notNull": true
    },
    "name": {
      "minSize": 0,
      "maxSize": 100
    },
    "typ": {
      "notNull": true
    }
  },
  "EkListenPreisListeEintrag": {
    "gueltigVon": {
      "notNull": true
    }
  },
  "EkPreisListeImport": {
    "bemerkung": {
      "minSize": 0,
      "maxSize": 255
    },
    "data": {
      "notNull": true
    },
    "dateigroesse": {
      "notNull": true
    },
    "dateiname": {
      "minSize": 0,
      "maxSize": 256,
      "notNull": true
    },
    "name": {
      "minSize": 0,
      "maxSize": 100
    },
    "status": {
      "notNull": true
    },
    "type": {
      "notNull": true
    }
  },
  "EkPreisListeImportDefinitionMapping": {
    "defExtern": {
      "minSize": 1,
      "maxSize": 100,
      "notNull": true
    },
    "defIntern": {
      "minSize": 1,
      "maxSize": 100,
      "notNull": true
    }
  },
  "EkPreisListeImportDefinitionStep": {
    "freigabe": {
      "notNull": true
    },
    "name": {
      "minSize": 1,
      "maxSize": 100,
      "notNull": true
    },
    "status": {
      "notNull": true
    }
  },
  "EkPreisListeImportDefinitionStepLabels": {
    "labelsType": {
      "notNull": true
    }
  },
  "EkPreisListeImportNichtGefundenArtikel": {
    "artikelNr": {
      "maxSize": 255
    },
    "ean": {
      "maxSize": 255
    },
    "ian": {
      "maxSize": 255
    }
  },
  "EkPreisListeImportStepIANDuplikate": {
    "ean": {
      "maxSize": 255
    },
    "ian": {
      "maxSize": 255
    }
  },
  "EkPreisListeImportStepPeMeUnterschied": {
    "ausDateiMe": {
      "maxSize": 20
    },
    "staffelmenge": {
      "notNull": true
    }
  },
  "EkPreisListeImportZeileIgnoriert": {
    "isAutoIgnoriert": {
      "notNull": true
    },
    "isIgnoriert": {
      "notNull": true
    },
    "status": {
      "notNull": true
    },
    "zeile": {
      "notNull": true
    }
  },
  "EkStaffelgruppe": {
    "bezeichnung": {
      "minSize": 0,
      "maxSize": 50
    },
    "name": {
      "minSize": 1,
      "maxSize": 50,
      "notNull": true
    }
  },
  "EosRecordError": {
    "artikel": {
      "minSize": 0,
      "maxSize": 35
    },
    "gln": {
      "minSize": 0,
      "maxSize": 35
    },
    "lieferant": {
      "minSize": 0,
      "maxSize": 35
    },
    "standort": {
      "minSize": 0,
      "maxSize": 35
    },
    "typ": {
      "notNull": true
    }
  },
  "EosRecordItem": {
    "typ": {
      "notNull": true
    }
  },
  "EosSystemInput": {
    "groupId": {
      "minSize": 0,
      "maxSize": 255
    },
    "host": {
      "minSize": 0,
      "maxSize": 255
    },
    "password": {
      "minSize": 0,
      "maxSize": 255
    },
    "securityProtocol": {
      "minSize": 0,
      "maxSize": 255
    },
    "topic": {
      "minSize": 0,
      "maxSize": 255
    },
    "topicError": {
      "minSize": 0,
      "maxSize": 255
    },
    "user": {
      "minSize": 0,
      "maxSize": 255
    }
  },
  "ErpEbene": {
    "erpEbeneId": {
      "minSize": 0,
      "maxSize": 255
    }
  },
  "ErpEbeneImport": {
    "isLast": {
      "notNull": true
    },
    "nummer": {
      "notNull": true
    }
  },
  "ErpImportData": {
    "sequenzNummer": {
      "notNull": true
    },
    "subLast": {
      "notNull": true
    },
    "subSequenzNummer": {
      "notNull": true
    },
    "subTyp": {
      "minSize": 0,
      "maxSize": 255
    }
  },
  "ErrorMessage": {
    "hint": {
      "minSize": 0,
      "maxSize": 500
    },
    "message": {
      "minSize": 0,
      "maxSize": 4000
    },
    "messageId": {
      "minSize": 0,
      "maxSize": 100
    },
    "stackTrace": {
      "minSize": 0,
      "maxSize": 4000
    }
  },
  "EtikettenDruckKopf": {
    "etikettenDruckStatus": {
      "notNull": true
    }
  },
  "EtikettenDruckSystem": {
    "groupId": {
      "minSize": 0,
      "maxSize": 255
    },
    "host": {
      "minSize": 0,
      "maxSize": 255
    },
    "password": {
      "minSize": 0,
      "maxSize": 255
    },
    "securityProtocol": {
      "minSize": 0,
      "maxSize": 255
    },
    "topic": {
      "minSize": 0,
      "maxSize": 255
    },
    "topicError": {
      "minSize": 0,
      "maxSize": 255
    },
    "user": {
      "minSize": 0,
      "maxSize": 255
    }
  },
  "ExportDataToErp": {
    "exportErrorMessage": {
      "minSize": 0,
      "maxSize": 1024
    },
    "exportStart": {
      "notNull": true
    },
    "exportStatus": {
      "notNull": true
    }
  },
  "ExportDataToErpFile": {
    "exportFileTyp": {
      "notNull": true
    },
    "exportedArtCount": {
      "notNull": true
    },
    "exportedArtikelArtCount": {
      "notNull": true
    },
    "exportedBetriebstypCount": {
      "notNull": true
    },
    "exportedEkKonditionenCount": {
      "notNull": true
    },
    "exportedEkPreiseCount": {
      "notNull": true
    },
    "exportedEkRabattgruppeCount": {
      "notNull": true
    },
    "exportedEkRabattgruppeVersionCount": {
      "notNull": true
    },
    "exportedEkStaffelgruppeCount": {
      "notNull": true
    },
    "exportedKalkulationsvorschlagCount": {
      "notNull": true
    },
    "exportedLiefCount": {
      "notNull": true
    },
    "exportedMengeneinheitCount": {
      "notNull": true
    },
    "exportedPreisgruppeCount": {
      "notNull": true
    },
    "exportedPreismengeCount": {
      "notNull": true
    },
    "exportedRundungsregelCount": {
      "notNull": true
    },
    "exportedStandortArtCount": {
      "notNull": true
    },
    "exportedStandortArtEkCount": {
      "notNull": true
    },
    "exportedStandortArtVkCount": {
      "notNull": true
    },
    "exportedStandortArtVkSperreCount": {
      "notNull": true
    },
    "exportedStandortCount": {
      "notNull": true
    },
    "exportedSteuerCount": {
      "notNull": true
    },
    "exportedVkPreiseCount": {
      "notNull": true
    },
    "exportedWarengruppeCount": {
      "notNull": true
    },
    "fileName": {
      "maxSize": 255
    }
  },
  "ExportDataToErpStep": {
    "exportStart": {
      "notNull": true
    },
    "exportStatus": {
      "notNull": true
    },
    "nextExportId": {
      "notNull": true
    }
  },
  "GesellschafterFirma": {
    "gln": {
      "minSize": 1,
      "maxSize": 20,
      "notNull": true
    },
    "name": {
      "minSize": 1,
      "maxSize": 50,
      "notNull": true
    },
    "nr": {
      "minSize": 1,
      "maxSize": 10,
      "notNull": true
    }
  },
  "GesellschafterOrganisation": {
    "gln": {
      "minSize": 0,
      "maxSize": 20
    },
    "name": {
      "minSize": 1,
      "maxSize": 50,
      "notNull": true
    },
    "nr": {
      "minSize": 0,
      "maxSize": 10
    }
  },
  "HagebauZentrale": {
    "bevorzugterHauptlieferantILN": {
      "minSize": 0,
      "maxSize": 15
    },
    "connectILN": {
      "minSize": 0,
      "maxSize": 15
    },
    "name": {
      "minSize": 0,
      "maxSize": 50
    }
  },
  "HauptlieferantPflegeArtikel": {
    "altNichtBevorzugterHauptLieferant": {
      "notNull": true
    },
    "nichtBevorzugterHauptLieferant": {
      "notNull": true
    }
  },
  "HkmQueryTempFindSeArtikel": {
    "btNummer": {
      "maxSize": 255
    },
    "ean": {
      "maxSize": 255
    },
    "ian": {
      "maxSize": 255
    }
  },
  "HkmQueryTempString": {
    "id": {
      "minSize": 0,
      "maxSize": 255
    }
  },
  "ImportDataFromErpArtikelEkKonditionen": {
    "artikelErpId": {
      "minSize": 0,
      "maxSize": 255
    },
    "lieferantErpId": {
      "minSize": 0,
      "maxSize": 255
    }
  },
  "ImportDataFromErpArtikelEkPreis": {
    "artikelErpId": {
      "minSize": 0,
      "maxSize": 255
    },
    "lieferantErpId": {
      "minSize": 0,
      "maxSize": 255
    }
  },
  "ImportDataFromErpVkPreise": {
    "artikelErpId": {
      "minSize": 0,
      "maxSize": 255
    }
  },
  "ImportExternJobModel": {
    "name": {
      "minSize": 1,
      "maxSize": 255,
      "notNull": true
    }
  },
  "ImportExternJobModelExecutionFile": {
    "fileName": {
      "minSize": 0,
      "maxSize": 1024
    },
    "originalFileName": {
      "minSize": 0,
      "maxSize": 1024
    }
  },
  "ImportExternJobSchedulerModel": {
    "name": {
      "minSize": 1,
      "maxSize": 255,
      "notNull": true
    }
  },
  "Label": {
    "bezeichnung": {
      "minSize": 0,
      "maxSize": 64
    },
    "kurz": {
      "minSize": 1,
      "maxSize": 10,
      "notNull": true
    },
    "labelGruppe": {
      "notNull": true
    }
  },
  "LabelZuordnung": {
    "zuordnungen": {
      "notNull": true
    }
  },
  "LieferantAbo": {
    "bemerkung": {
      "minSize": 0,
      "maxSize": 2048
    },
    "name": {
      "minSize": 0,
      "maxSize": 100
    }
  },
  "LieferantAenderung": {
    "akt": {
      "notNull": true
    },
    "status": {
      "notNull": true
    },
    "typ": {
      "notNull": true
    }
  },
  "LieferantSystemEsb": {
    "importDir": {
      "minSize": 0,
      "maxSize": 255
    },
    "importedDir": {
      "minSize": 0,
      "maxSize": 255
    }
  },
  "LieferantSystemZart": {
    "importDir": {
      "minSize": 0,
      "maxSize": 255
    },
    "importedDir": {
      "minSize": 0,
      "maxSize": 255
    }
  },
  "LieferantenVerteilungDefinition": {
    "aktiv": {
      "notNull": true
    },
    "name": {
      "minSize": 1,
      "maxSize": 100,
      "notNull": true
    }
  },
  "LoginSession": {
    "loginDate": {
      "notNull": true
    },
    "remoteAdr": {
      "minSize": 0,
      "maxSize": 50
    }
  },
  "Mengeneinheit": {
    "bez": {
      "minSize": 0,
      "maxSize": 100
    },
    "kurzBez": {
      "minSize": 0,
      "maxSize": 100
    },
    "name": {
      "minSize": 1,
      "maxSize": 100,
      "notNull": true
    }
  },
  "MigrationArtikelAboImport": {
    "data": {
      "notNull": true
    }
  },
  "MigrationArtikelAboImportArtikel": {
    "btNummer": {
      "minSize": 1,
      "maxSize": 100
    },
    "eigenlistungDuplikat": {
      "notNull": true
    },
    "warengruppeNeu": {
      "minSize": 0,
      "maxSize": 10
    }
  },
  "MigrationArtikelEosDatenErzeugen": {
    "eigenpflegeFachhandel": {
      "notNull": true
    }
  },
  "MigrationArtikelEosDatenErzeugenLieferant": {
    "artikelAnz": {
      "notNull": true
    },
    "artikelAnzProhibis": {
      "notNull": true
    }
  },
  "ModelProperty": {
    "key": {
      "minSize": 0,
      "maxSize": 100
    },
    "stringValue": {
      "minSize": 0,
      "maxSize": 500
    }
  },
  "Nummernkreis": {
    "ende": {
      "notNull": true
    },
    "lueckenFuellen": {
      "notNull": true
    },
    "prefix": {
      "minSize": 0,
      "maxSize": 10
    },
    "start": {
      "notNull": true
    },
    "typ": {
      "notNull": true
    }
  },
  "PimArtikel": {
    "hageNummer": {
      "minSize": 0,
      "maxSize": 15,
      "notNull": true
    },
    "suEbene": {
      "notNull": true
    }
  },
  "PimArtikelKontext": {
    "invalid": {
      "notNull": true
    },
    "pimKontext": {
      "notNull": true
    }
  },
  "PimArtikelLieferant": {
    "index": {
      "minSize": 0,
      "maxSize": 50,
      "notNull": true
    },
    "pimArtikel": {
      "notNull": true
    },
    "zeLieferant": {
      "notNull": true
    }
  },
  "PimArtikelLieferantEkPreis": {
    "akt": {
      "notNull": true
    },
    "gueltigAb": {
      "notNull": true
    },
    "index": {
      "minSize": 0,
      "maxSize": 100,
      "notNull": true
    },
    "pimArtikel": {
      "notNull": true
    },
    "pimArtikelLieferant": {
      "notNull": true
    },
    "pimImportDataHash": {
      "minSize": 0,
      "maxSize": 80
    },
    "preis": {
      "notNull": true
    },
    "preisBasis": {
      "notNull": true
    },
    "preisMenge": {
      "notNull": true
    },
    "preisMengeneinheit": {
      "notNull": true
    },
    "rabattgruppe": {
      "maxSize": 255
    },
    "type": {
      "notNull": true
    },
    "waehrung": {
      "notNull": true
    },
    "zeLieferant": {
      "notNull": true
    }
  },
  "PimArtikelVersion": {
    "artikelArt": {
      "notNull": true
    },
    "basisWarengruppe": {
      "maxSize": 255
    },
    "bdbWarengruppe": {
      "minSize": 0,
      "maxSize": 10
    },
    "farbgruppe": {
      "maxSize": 255
    },
    "gtin": {
      "minSize": 0,
      "maxSize": 35
    },
    "hageNummer": {
      "minSize": 0,
      "maxSize": 15,
      "notNull": true
    },
    "hagebauLogistikEinkaufMengeneinheit": {
      "maxSize": 255
    },
    "hagebauLogistikLagerkostenMengeneinheit": {
      "maxSize": 255
    },
    "hagebauLogistikProduktmanager": {
      "maxSize": 255
    },
    "hagebauLogistikWarenausgangHandlingskategorie": {
      "maxSize": 255
    },
    "hagebauLogistikWarenausgangMengeneinheit": {
      "maxSize": 255
    },
    "hagebauLogistikWareneingangHandlingskategorie": {
      "maxSize": 255
    },
    "intrastatMengeneinheit": {
      "maxSize": 255
    },
    "intrastatRegion": {
      "maxSize": 255
    },
    "materialgruppe": {
      "maxSize": 255
    },
    "nachfolger": {
      "maxSize": 255
    },
    "pimArtikel": {
      "notNull": true
    },
    "pimImportDataHash": {
      "maxSize": 255
    },
    "pimProdukt": {
      "notNull": true
    },
    "produkttyp": {
      "maxSize": 255
    },
    "ursprungsland": {
      "maxSize": 255
    },
    "vkSperreAb": {
      "maxSize": 255
    },
    "vkSperreBis": {
      "maxSize": 255
    },
    "vorlaeufer": {
      "maxSize": 255
    }
  },
  "PimArtikelVersionCompliance": {
    "adrKlasse": {
      "maxSize": 255
    },
    "befoerderungskategorie": {
      "maxSize": 255
    },
    "ceKennzeichen": {
      "maxSize": 255
    },
    "enVKVModellbezeichnung": {
      "maxSize": 255
    },
    "enVKVSkalaEnergieeffizienzklasse": {
      "maxSize": 255
    },
    "energieeffizienzklasse": {
      "maxSize": 255
    },
    "gefahrgutADRNummer": {
      "maxSize": 255
    },
    "gefahrgutSondervorschrift": {
      "maxSize": 255
    },
    "gefahrgutUNNummer": {
      "maxSize": 255
    },
    "gefahrgutVerpackungsgruppe": {
      "maxSize": 255
    },
    "gefahrnummer": {
      "maxSize": 255
    },
    "gefahrstoffeinstufung": {
      "maxSize": 2000
    },
    "gefahrzettel": {
      "maxSize": 255
    },
    "haertegradStyroporsteine": {
      "maxSize": 255
    },
    "inverkehrbringerHausnummerPostfachnummer": {
      "maxSize": 255
    },
    "inverkehrbringerLand": {
      "maxSize": 255
    },
    "inverkehrbringerName": {
      "maxSize": 255
    },
    "inverkehrbringerPLZ": {
      "maxSize": 255
    },
    "inverkehrbringerStrassePostfach": {
      "maxSize": 255
    },
    "lagerklasse": {
      "maxSize": 255
    },
    "meldepflichtigerAusgangstoffMengeneinheit": {
      "maxSize": 255
    },
    "pflichttextCO2Kostenaufteilungsgesetz": {
      "maxSize": 255
    },
    "pimArtikelVersion": {
      "notNull": true
    },
    "prodSGHerstelleradresse": {
      "maxSize": 255
    },
    "produktidentifikatoren": {
      "maxSize": 255
    },
    "qngReadyZertifikatsnummer": {
      "maxSize": 255
    },
    "relevanterRohstoffNachEUDR": {
      "maxSize": 255
    },
    "scipNummer": {
      "maxSize": 255
    },
    "signalwort": {
      "maxSize": 255
    },
    "textilkomponenteBezeichnung": {
      "maxSize": 255
    },
    "tunnelbeschraenkungscode": {
      "maxSize": 255
    },
    "umweltgefaehrdungsklasse": {
      "maxSize": 255
    },
    "wassergefaehrdungsklasse": {
      "maxSize": 255
    },
    "zulaessigeGesamtmengeJeBefoerderungseinheit": {
      "maxSize": 255
    }
  },
  "PimArtikelVersionComplianceAbfallschluessel": {
    "abfallschluessel": {
      "maxSize": 255
    },
    "pimArtikelVersionCompliance": {
      "notNull": true
    }
  },
  "PimArtikelVersionComplianceGefahrenhinweis": {
    "gefahrenhinweis": {
      "maxSize": 255
    },
    "pimArtikelVersionCompliance": {
      "notNull": true
    }
  },
  "PimArtikelVersionComplianceGefahrenpiktogramm": {
    "gefahrenpiktogramm": {
      "maxSize": 255
    },
    "pimArtikelVersionCompliance": {
      "notNull": true
    }
  },
  "PimArtikelVersionComplianceGefahrzettelNebengefahr": {
    "gefahrzettelNebengefahr": {
      "maxSize": 255
    },
    "pimArtikelVersionCompliance": {
      "notNull": true
    }
  },
  "PimArtikelVersionComplianceKlassifizierungscodeADR": {
    "klassifizierungscodeADR": {
      "maxSize": 255
    },
    "pimArtikelVersionCompliance": {
      "notNull": true
    }
  },
  "PimArtikelVersionComplianceKonsistenz": {
    "konsistenz": {
      "maxSize": 255
    },
    "pimArtikelVersionCompliance": {
      "notNull": true
    }
  },
  "PimArtikelVersionComplianceLand": {
    "pimArtikelVersionCompliance": {
      "notNull": true
    },
    "pimLand": {
      "notNull": true
    }
  },
  "PimArtikelVersionComplianceNetzanschlussTyp": {
    "netzanschlussTyp": {
      "maxSize": 255
    },
    "pimArtikelVersionCompliance": {
      "notNull": true
    }
  },
  "PimArtikelVersionComplianceProdSGProduktkennzeichnung": {
    "pimArtikelVersionCompliance": {
      "notNull": true
    },
    "prodSGProduktkennzeichnung": {
      "maxSize": 255
    }
  },
  "PimArtikelVersionComplianceProdSGWarnhinweis": {
    "prodSGWarnhinweis": {
      "maxSize": 255
    }
  },
  "PimArtikelVersionComplianceSicherheitshinweis": {
    "sicherheitshinweis": {
      "maxSize": 255
    }
  },
  "PimArtikelVersionComplianceSprache": {
    "pimArtikelVersionCompliance": {
      "notNull": true
    },
    "pimSprache": {
      "notNull": true
    },
    "zusaetzlicheAngabenBefoerderungspapier": {
      "maxSize": 255
    }
  },
  "PimArtikelVersionComplianceVerpackungsart": {
    "pimArtikelVersionCompliance": {
      "notNull": true
    },
    "verpackungsart": {
      "maxSize": 255
    }
  },
  "PimArtikelVersionComplianceWEEEKategorie": {
    "pimArtikelVersionCompliance": {
      "notNull": true
    },
    "weeeKategorie": {
      "maxSize": 255
    }
  },
  "PimArtikelVersionDisplay": {
    "hageNummer": {
      "minSize": 0,
      "maxSize": 15
    },
    "pimArtikelVersion": {
      "notNull": true
    }
  },
  "PimArtikelVersionFliese": {
    "angebotstext1": {
      "maxSize": 255
    },
    "angebotstext2": {
      "maxSize": 255
    },
    "design": {
      "maxSize": 255
    },
    "einsatzbereich": {
      "maxSize": 255
    },
    "farbe": {
      "maxSize": 255
    },
    "farbfamilie": {
      "maxSize": 255
    },
    "formatgruppe": {
      "maxSize": 255
    },
    "kategorie": {
      "maxSize": 255
    },
    "material": {
      "maxSize": 255
    },
    "nassbereich": {
      "maxSize": 255
    },
    "oberflaeche": {
      "maxSize": 255
    },
    "oberflaechenveredelung": {
      "maxSize": 255
    },
    "pimArtikelVersion": {
      "notNull": true
    },
    "rutschfestigkeit": {
      "maxSize": 255
    },
    "serienname": {
      "maxSize": 255
    },
    "verdraengungsraum": {
      "maxSize": 255
    }
  },
  "PimArtikelVersionFlieseSprache": {
    "oberkategorie": {
      "maxSize": 255
    },
    "pimArtikelVersionFliese": {
      "notNull": true
    },
    "pimSprache": {
      "notNull": true
    }
  },
  "PimArtikelVersionGtin": {
    "geloescht": {
      "notNull": true
    },
    "gtin": {
      "minSize": 0,
      "maxSize": 35,
      "notNull": true
    },
    "pimArtikelVersion": {
      "notNull": true
    }
  },
  "PimArtikelVersionKontext": {
    "abweichendeMwStEndkundeB2C": {
      "maxSize": 255
    },
    "bontext": {
      "minSize": 0,
      "maxSize": 80
    },
    "btArtikelNr": {
      "minSize": 0,
      "maxSize": 10
    },
    "btWarengruppe": {
      "maxSize": 255
    },
    "focus": {
      "maxSize": 255
    },
    "invalid": {
      "notNull": true
    },
    "matchcode": {
      "minSize": 0,
      "maxSize": 60
    },
    "pimArtikelVersion": {
      "notNull": true
    },
    "pimKontext": {
      "notNull": true
    },
    "preisrolle": {
      "maxSize": 255
    },
    "retourenfristOnlineBestellung": {
      "maxSize": 255
    },
    "standort": {
      "maxSize": 255
    },
    "topfgroesse": {
      "maxSize": 255
    },
    "versandkostenklasse": {
      "maxSize": 255
    },
    "vkSperreAb": {
      "maxSize": 255
    },
    "vkSperreBis": {
      "maxSize": 255
    },
    "vkSperreGrund": {
      "maxSize": 255
    }
  },
  "PimArtikelVersionKontextCrossChannelVarianteOnline": {
    "crossChannelVarianteOnline": {
      "maxSize": 255
    },
    "pimArtikelVersionKontext": {
      "notNull": true
    }
  },
  "PimArtikelVersionKontextPflanze": {
    "ausfuehrung": {
      "maxSize": 255
    },
    "blattfarbe": {
      "maxSize": 255
    },
    "bluetenfarbe": {
      "maxSize": 255
    },
    "bluetenmerkmal": {
      "maxSize": 255
    },
    "botanischeBezeichnungArt": {
      "maxSize": 255
    },
    "botanischeBezeichnungGattung": {
      "maxSize": 255
    },
    "botanischeBezeichnungSorte": {
      "maxSize": 255
    },
    "deutscheBezeichnung": {
      "maxSize": 255
    },
    "lebenszyklus": {
      "maxSize": 255
    },
    "pimArtikelVersionKontext": {
      "notNull": true
    },
    "qualitaet": {
      "maxSize": 255
    },
    "wuchsform": {
      "maxSize": 255
    }
  },
  "PimArtikelVersionKontextSprache": {
    "artikelbeschreibung": {
      "maxSize": 255
    },
    "pimArtikelVersionKontext": {
      "notNull": true
    },
    "pimSprache": {
      "notNull": true
    }
  },
  "PimArtikelVersionLand": {
    "pimArtikelVersion": {
      "notNull": true
    },
    "pimLand": {
      "notNull": true
    },
    "steuerindikation": {
      "minSize": 0,
      "maxSize": 10
    }
  },
  "PimArtikelVersionLieferant": {
    "ekSperreAb": {
      "maxSize": 255
    },
    "ekSperreBis": {
      "maxSize": 255
    },
    "fscClaim": {
      "maxSize": 255
    },
    "herstellerartikelnummer": {
      "maxSize": 255
    },
    "ian": {
      "minSize": 0,
      "maxSize": 60
    },
    "name1": {
      "maxSize": 255
    },
    "name2": {
      "maxSize": 255
    },
    "pefcClaim": {
      "maxSize": 255
    },
    "pimArtikelVersion": {
      "notNull": true
    },
    "sourcingOffice": {
      "minSize": 0,
      "maxSize": 2
    },
    "teilsortiment": {
      "maxSize": 255
    },
    "unterWarengruppe": {
      "maxSize": 255
    },
    "warengruppe": {
      "maxSize": 255
    },
    "zeLieferant": {
      "notNull": true
    }
  },
  "PimArtikelVersionLieferantFliese": {
    "pimArtikelVersionLieferant": {
      "notNull": true
    },
    "preisgruppe": {
      "maxSize": 255
    },
    "segment": {
      "maxSize": 255
    },
    "werkskategorie": {
      "maxSize": 255
    }
  },
  "PimArtikelVersionLieferantKontext": {
    "alternativeArtikelNummer": {
      "maxSize": 255
    },
    "biozidZulassungsnummer": {
      "maxSize": 255
    },
    "biozidZulassungsnummerZulassungsende": {
      "maxSize": 255
    },
    "ekSperreAb": {
      "maxSize": 255
    },
    "ekSperreBis": {
      "maxSize": 255
    },
    "ekSperreGrund": {
      "maxSize": 255
    },
    "incoterms": {
      "maxSize": 255
    },
    "incotermsAbgangshafen": {
      "maxSize": 255
    },
    "incotermsEmpfangshafen": {
      "maxSize": 255
    },
    "listungsstatus": {
      "maxSize": 255
    },
    "pflanzenschutzregistrierNummer": {
      "maxSize": 255
    },
    "pflanzenschutzregistrierNummerZulassungsende": {
      "maxSize": 255
    },
    "pimArtikelVersionLieferant": {
      "notNull": true
    },
    "pimKontext": {
      "notNull": true
    },
    "retourenWiederverwertungskennzeichen": {
      "maxSize": 255
    },
    "retourenziel": {
      "maxSize": 255
    },
    "sortimentsKennzeichenHagebauLogistik": {
      "maxSize": 255
    },
    "steuerungTransportDienstleister": {
      "maxSize": 255
    },
    "versandsystemEndkunde": {
      "maxSize": 255
    },
    "versandwegEndkunde": {
      "maxSize": 255
    },
    "warenruecksendungLieferantenretoure": {
      "maxSize": 255
    }
  },
  "PimArtikelVersionLieferantLand": {
    "pimLand": {
      "notNull": true
    }
  },
  "PimArtikelVersionLieferantSprache": {
    "artikelBez": {
      "minSize": 0,
      "maxSize": 80
    },
    "pimArtikelVersionLieferant": {
      "notNull": true
    },
    "pimSprache": {
      "notNull": true
    }
  },
  "PimArtikelVersionLieferantVertriebsweg": {
    "pimArtikelVersionLieferant": {
      "notNull": true
    },
    "vertriebsweg": {
      "maxSize": 255
    }
  },
  "PimArtikelVersionMengeneinheit": {
    "gtin": {
      "maxSize": 255
    },
    "pimArtikelVersion": {
      "notNull": true
    },
    "pimMengeneinheit": {
      "notNull": true
    }
  },
  "PimArtikelVersionMengeneinheitGtin": {
    "geloescht": {
      "notNull": true
    },
    "gtin": {
      "minSize": 0,
      "maxSize": 35,
      "notNull": true
    },
    "pimArtikelVersionMengeneinheit": {
      "notNull": true
    }
  },
  "PimArtikelVersionPackstueck": {
    "inhalt": {
      "maxSize": 2000
    },
    "pimArtikelVersion": {
      "notNull": true
    }
  },
  "PimArtikelVersionPflanze": {
    "ausfuehrung": {
      "maxSize": 255
    },
    "blattfarbe": {
      "maxSize": 255
    },
    "bluetenfarbe": {
      "maxSize": 255
    },
    "bluetenmerkmal": {
      "maxSize": 255
    },
    "botanischeBezeichnungArt": {
      "maxSize": 255
    },
    "botanischeBezeichnungGattung": {
      "maxSize": 255
    },
    "botanischeBezeichnungSorte": {
      "maxSize": 255
    },
    "deutscheBezeichnung": {
      "maxSize": 255
    },
    "lebenszyklus": {
      "maxSize": 255
    },
    "pimArtikelVersion": {
      "notNull": true
    },
    "qualitaet": {
      "maxSize": 255
    },
    "standort": {
      "maxSize": 255
    },
    "topfgroesse": {
      "maxSize": 255
    },
    "wuchsform": {
      "maxSize": 255
    }
  },
  "PimArtikelVersionRegalplatzierung": {
    "pimArtikelVersion": {
      "notNull": true
    },
    "regalplatzierung": {
      "maxSize": 255
    }
  },
  "PimArtikelVersionSprache": {
    "etikett1": {
      "minSize": 0,
      "maxSize": 40
    },
    "etikett2": {
      "minSize": 0,
      "maxSize": 40
    },
    "farbe": {
      "maxSize": 255
    },
    "lieferschein1": {
      "minSize": 0,
      "maxSize": 40
    },
    "lieferschein2": {
      "minSize": 0,
      "maxSize": 40
    },
    "lieferschein3": {
      "minSize": 0,
      "maxSize": 40
    },
    "lieferschein4": {
      "minSize": 0,
      "maxSize": 40
    },
    "lieferumfang": {
      "maxSize": 255
    },
    "material": {
      "maxSize": 255
    },
    "pimArtikelVersion": {
      "notNull": true
    },
    "pimSprache": {
      "notNull": true
    },
    "plakattext1": {
      "minSize": 0,
      "maxSize": 255
    },
    "plakattext2": {
      "minSize": 0,
      "maxSize": 255
    },
    "plakattext3": {
      "minSize": 0,
      "maxSize": 255
    },
    "plakattext4": {
      "minSize": 0,
      "maxSize": 255
    },
    "plakattext5": {
      "minSize": 0,
      "maxSize": 255
    },
    "plakattext6": {
      "minSize": 0,
      "maxSize": 255
    },
    "plakattext7": {
      "minSize": 0,
      "maxSize": 255
    },
    "plakattext8": {
      "minSize": 0,
      "maxSize": 255
    },
    "produktDimension1": {
      "maxSize": 255
    },
    "produktDimension2": {
      "maxSize": 255
    },
    "produktDimension3": {
      "maxSize": 255
    },
    "produktreferenz": {
      "maxSize": 255
    },
    "sellingpoint1": {
      "minSize": 0,
      "maxSize": 255
    },
    "sellingpoint2": {
      "minSize": 0,
      "maxSize": 255
    },
    "sellingpoint3": {
      "minSize": 0,
      "maxSize": 255
    },
    "sellingpoint4": {
      "minSize": 0,
      "maxSize": 255
    },
    "sellingpoint5": {
      "minSize": 0,
      "maxSize": 255
    }
  },
  "PimArtikelVkPreis": {
    "akt": {
      "notNull": true
    },
    "bruttoNetto": {
      "maxSize": 255
    },
    "grundpreisMasseinheit": {
      "maxSize": 255
    },
    "gueltigAb": {
      "notNull": true
    },
    "index": {
      "minSize": 0,
      "maxSize": 100,
      "notNull": true
    },
    "pimArtikel": {
      "notNull": true
    },
    "pimImportDataHash": {
      "minSize": 0,
      "maxSize": 80
    },
    "pimKontext": {
      "notNull": true
    },
    "preisBasis": {
      "notNull": true
    },
    "preisMenge": {
      "notNull": true
    },
    "preisMengeneinheit": {
      "notNull": true
    },
    "waehrung": {
      "notNull": true
    }
  },
  "PimKontext": {
    "kontext": {
      "notNull": true
    }
  },
  "PimMappingZart": {
    "pim": {
      "minSize": 0,
      "maxSize": 50
    },
    "typ": {
      "notNull": true
    },
    "zart": {
      "minSize": 0,
      "maxSize": 50,
      "notNull": true
    }
  },
  "PimProdukt": {
    "hageNummer": {
      "minSize": 0,
      "maxSize": 15
    },
    "suEbene": {
      "notNull": true
    }
  },
  "PimProduktVersion": {
    "hageNummer": {
      "minSize": 0,
      "maxSize": 15
    },
    "marke": {
      "minSize": 0,
      "maxSize": 40
    },
    "pimProdukt": {
      "notNull": true
    },
    "statistischeWarennummer": {
      "minSize": 0,
      "maxSize": 11
    },
    "suEbene": {
      "notNull": true
    },
    "vertriebskanal": {
      "maxSize": 255
    },
    "zolltarifnummer": {
      "minSize": 0,
      "maxSize": 50
    }
  },
  "PimProduktVersionLieferant": {
    "pimProduktVersion": {
      "notNull": true
    },
    "zeLieferant": {
      "notNull": true
    }
  },
  "PimProduktVersionSprache": {
    "pimProduktVersion": {
      "notNull": true
    },
    "pimSprache": {
      "notNull": true
    },
    "primaerklassifikation": {
      "maxSize": 255
    }
  },
  "PimRecord": {
    "pimSystem": {
      "notNull": true
    }
  },
  "PimRecordError": {
    "gtin": {
      "minSize": 0,
      "maxSize": 35
    },
    "hageNummer": {
      "minSize": 0,
      "maxSize": 15
    },
    "lfnr": {
      "minSize": 0,
      "maxSize": 15
    },
    "pimSystem": {
      "notNull": true
    },
    "typ": {
      "notNull": true
    }
  },
  "PimSystemPim": {
    "groupId": {
      "minSize": 0,
      "maxSize": 255
    },
    "host": {
      "minSize": 0,
      "maxSize": 255
    },
    "password": {
      "minSize": 0,
      "maxSize": 255
    },
    "securityProtocol": {
      "minSize": 0,
      "maxSize": 255
    },
    "topic": {
      "minSize": 0,
      "maxSize": 255
    },
    "topicError": {
      "minSize": 0,
      "maxSize": 255
    },
    "user": {
      "minSize": 0,
      "maxSize": 255
    }
  },
  "PimSystemZart": {
    "importDir": {
      "minSize": 0,
      "maxSize": 255
    },
    "importedDir": {
      "minSize": 0,
      "maxSize": 255
    }
  },
  "PimWertSprache": {
    "beschreibung": {
      "minSize": 0,
      "maxSize": 255
    },
    "bez": {
      "minSize": 0,
      "maxSize": 100,
      "notNull": true
    },
    "kurzBez": {
      "minSize": 0,
      "maxSize": 100
    }
  },
  "PreisEbeneVerteilungPerNr": {
    "zielNummer": {
      "notNull": true
    }
  },
  "PreisVorgangEintrag": {
    "ekPreis": {
      "notNull": true
    },
    "seArtikel": {
      "notNull": true
    },
    "vkPreis": {
      "notNull": true
    }
  },
  "Protokoll": {
    "info": {
      "minSize": 0,
      "maxSize": 200
    }
  },
  "ProtokollEintrag": {
    "fragment": {
      "minSize": 0,
      "maxSize": 32767
    },
    "fragment_small": {
      "minSize": 0,
      "maxSize": 4000
    },
    "message": {
      "minSize": 0,
      "maxSize": 4000
    },
    "messageId": {
      "minSize": 0,
      "maxSize": 100
    },
    "reference": {
      "minSize": 0,
      "maxSize": 255
    },
    "stackTrace": {
      "minSize": 0,
      "maxSize": 4000
    }
  },
  "SeArtikel": {
    "betriebsTyp": {
      "notNull": true
    }
  },
  "SeArtikelEkKonditionen": {
    "gueltigVon": {
      "notNull": true
    }
  },
  "SeArtikelEkPreis": {
    "seMengenEinheit": {
      "notNull": true
    }
  },
  "SeArtikelMengeneinheit": {
    "ean": {
      "minSize": 0,
      "maxSize": 30
    },
    "nurAmLieferanten": {
      "notNull": true
    },
    "typ": {
      "notNull": true
    }
  },
  "SeArtikelPreisEbene": {
    "nichtBevorzugterHauptLieferant": {
      "notNull": true
    }
  },
  "SeArtikelVersion": {
    "aliasNr": {
      "maxSize": 10
    },
    "nichtBevorzugterHauptLieferant": {
      "notNull": true
    }
  },
  "SeArtikelVersionEAN": {
    "ean": {
      "minSize": 0,
      "maxSize": 30,
      "notNull": true
    },
    "zuordnung": {
      "notNull": true
    }
  },
  "SeArtikelVersionKontextPimDaten": {
    "bontext": {
      "minSize": 0,
      "maxSize": 80
    },
    "btArtikelNr": {
      "minSize": 0,
      "maxSize": 10
    },
    "focus": {
      "maxSize": 255
    },
    "matchcode": {
      "minSize": 0,
      "maxSize": 60
    }
  },
  "SeArtikelVersionLieferantKontextPimDaten": {
    "listungsstatus": {
      "minSize": 0,
      "maxSize": 20
    }
  },
  "SeArtikelVersionLieferantPimDaten": {
    "ian": {
      "minSize": 0,
      "maxSize": 60
    }
  },
  "SeArtikelVersionPimDaten": {
    "intrastatRegion": {
      "maxSize": 255
    },
    "lieferschein1": {
      "minSize": 0,
      "maxSize": 40
    },
    "lieferschein2": {
      "minSize": 0,
      "maxSize": 40
    },
    "lieferschein3": {
      "minSize": 0,
      "maxSize": 40
    },
    "lieferschein4": {
      "minSize": 0,
      "maxSize": 40
    },
    "statistischeWarennummer": {
      "minSize": 0,
      "maxSize": 11
    },
    "ursprungsland": {
      "maxSize": 255
    }
  },
  "SeArtikelVersionPimDatenGtin": {
    "geloescht": {
      "notNull": true
    },
    "gtin": {
      "minSize": 0,
      "maxSize": 35,
      "notNull": true
    }
  },
  "SeArtikelVersionPimMengeneinheitGtin": {
    "geloescht": {
      "notNull": true
    },
    "gtin": {
      "minSize": 0,
      "maxSize": 35,
      "notNull": true
    }
  },
  "SeArtikelVkPreisBlatt": {
    "gueltigVon": {
      "notNull": true
    }
  },
  "SeArtikelVkSperre": {
    "vkSperreAb": {
      "maxSize": 255
    },
    "vkSperreBis": {
      "maxSize": 255
    },
    "vkSperreGrund": {
      "maxSize": 255
    }
  },
  "SeEANGruppe": {
    "bezeichnung": {
      "minSize": 0,
      "maxSize": 50
    },
    "name": {
      "minSize": 1,
      "maxSize": 50,
      "notNull": true
    }
  },
  "SeEkKonditionenRabattgruppe": {
    "bezeichnung": {
      "minSize": 0,
      "maxSize": 30
    },
    "name": {
      "minSize": 1,
      "maxSize": 8,
      "notNull": true
    },
    "seLieferant": {
      "notNull": true
    },
    "suchName": {
      "maxSize": 255
    }
  },
  "SeEkKonditionenRabattgruppeVersion": {
    "gueltigVon": {
      "notNull": true
    },
    "kopf": {
      "notNull": true
    },
    "seLieferant": {
      "notNull": true
    }
  },
  "SeSortiment": {
    "kontext": {
      "notNull": true
    }
  },
  "SeSortimentArtikel": {
    "artikel": {
      "notNull": true
    },
    "kontext": {
      "notNull": true
    },
    "sortiment": {
      "notNull": true
    },
    "timestamp": {
      "notNull": true
    }
  },
  "SeSortimentArtikelAnforderung": {
    "artikel": {
      "notNull": true
    },
    "kontext": {
      "notNull": true
    },
    "lieferant": {
      "notNull": true
    },
    "referenz": {
      "maxSize": 100
    },
    "stammEbene": {
      "notNull": true
    },
    "standort": {
      "notNull": true
    },
    "status": {
      "notNull": true
    }
  },
  "SeSortimentEintrag": {
    "artikelListeLastSyncHash": {
      "minSize": 0,
      "maxSize": 255
    }
  },
  "SeSparte": {
    "bezeichnung": {
      "minSize": 0,
      "maxSize": 30
    },
    "extRef": {
      "minSize": 0,
      "maxSize": 30
    },
    "nr": {
      "minSize": 1,
      "maxSize": 6,
      "notNull": true
    }
  },
  "SeStandort": {
    "status": {
      "notNull": true
    }
  },
  "SeStandortArtikel": {
    "kontext": {
      "notNull": true
    },
    "status": {
      "notNull": true
    }
  },
  "SeStandortArtikelEkKonditionen": {
    "gueltigVon": {
      "notNull": true
    }
  },
  "SeStandortArtikelRegalplatz": {
    "etikettentyp": {
      "maxSize": 255
    },
    "gln": {
      "maxSize": 255
    },
    "hageNr": {
      "maxSize": 255
    },
    "lagerbereich": {
      "maxSize": 255
    },
    "lagerort": {
      "maxSize": 255
    },
    "lagerplatz": {
      "maxSize": 255
    },
    "lagerplatztyp": {
      "maxSize": 255
    },
    "referenz": {
      "maxSize": 100
    },
    "sequenzNr": {
      "maxSize": 255
    }
  },
  "SeStandortArtikelVkPreisblatt": {
    "gueltigVon": {
      "notNull": true
    }
  },
  "SeStandortArtikelVkSperre": {
    "vkSperreGrund": {
      "maxSize": 255
    }
  },
  "SeStandortGruppe": {
    "kontext": {
      "notNull": true
    },
    "name": {
      "minSize": 1,
      "maxSize": 200,
      "notNull": true
    },
    "nr": {
      "minSize": 1,
      "maxSize": 255,
      "notNull": true
    },
    "status": {
      "notNull": true
    }
  },
  "SeWarengruppeMappingBDBERP": {
    "bdbWarengruppe": {
      "maxSize": 255
    }
  },
  "SeZufuhrMulti": {
    "bezeichnung": {
      "minSize": 0,
      "maxSize": 50
    },
    "name": {
      "minSize": 1,
      "maxSize": 50,
      "notNull": true
    }
  },
  "SeZufuhrMultiPreisgruppe": {
    "zufuhrMulti": {
      "notNull": true
    }
  },
  "StammEbene": {
    "aboAenderungenAutomatischUebernehmen": {
      "notNull": true
    },
    "einzlhandelAusPIM": {
      "notNull": true
    },
    "gln": {
      "minSize": 0,
      "maxSize": 20
    },
    "hagebauZentrale": {
      "notNull": true
    },
    "land": {
      "maxSize": 10,
      "notNull": true
    },
    "name": {
      "minSize": 0,
      "maxSize": 50
    },
    "nr": {
      "minSize": 0,
      "maxSize": 20
    },
    "pimDatenmodell": {
      "notNull": true
    },
    "preisaenderungenGetrenntBearbeiten": {
      "notNull": true
    }
  },
  "SuchArtikel": {
    "artikelArt": {
      "minSize": 0,
      "maxSize": 255
    },
    "betriebsTyp": {
      "notNull": true
    },
    "bez1": {
      "minSize": 0,
      "maxSize": 255
    },
    "bez2": {
      "minSize": 0,
      "maxSize": 255
    },
    "bez3": {
      "minSize": 0,
      "maxSize": 255
    },
    "bez4": {
      "minSize": 0,
      "maxSize": 255
    },
    "bontext": {
      "minSize": 0,
      "maxSize": 80
    },
    "btNummer": {
      "minSize": 0,
      "maxSize": 30
    },
    "btOrgNummer": {
      "minSize": 0,
      "maxSize": 30
    },
    "btWarengruppe": {
      "minSize": 0,
      "maxSize": 20
    },
    "ean": {
      "minSize": 0,
      "maxSize": 30
    },
    "etikett1": {
      "minSize": 0,
      "maxSize": 255
    },
    "etikett2": {
      "minSize": 0,
      "maxSize": 255
    },
    "hageNummer": {
      "minSize": 0,
      "maxSize": 15
    },
    "industrieArtikelNummer": {
      "minSize": 0,
      "maxSize": 60
    },
    "isEigenlistung": {
      "notNull": true
    },
    "matchcode": {
      "minSize": 0,
      "maxSize": 60
    },
    "pfandBTArtikelNr": {
      "minSize": 0,
      "maxSize": 255
    },
    "suchbez": {
      "minSize": 0,
      "maxSize": 1020
    },
    "vertriebsWeg": {
      "notNull": true
    },
    "vorlaueferArtikel": {
      "minSize": 0,
      "maxSize": 255
    },
    "wgBDB": {
      "minSize": 0,
      "maxSize": 20
    }
  },
  "SuchArtikelEAN": {
    "ean": {
      "minSize": 1,
      "maxSize": 30,
      "notNull": true
    }
  },
  "SuchArtikelKontext": {
    "bontext": {
      "minSize": 0,
      "maxSize": 80
    },
    "btArtikelNr": {
      "minSize": 0,
      "maxSize": 10
    },
    "btWarengruppe": {
      "minSize": 0,
      "maxSize": 20
    },
    "isKeinHauptlieferant": {
      "notNull": true
    },
    "isKeinLieferantNutzbar": {
      "notNull": true
    },
    "kontext": {
      "notNull": true
    },
    "matchcode": {
      "minSize": 0,
      "maxSize": 60
    },
    "nichtBevorzugterHauptLieferant": {
      "notNull": true
    }
  },
  "SuchArtikelLieferant": {
    "ekMengeneinheit": {
      "minSize": 0,
      "maxSize": 100
    },
    "gefKomm": {
      "minSize": 0,
      "maxSize": 255
    },
    "industrieArtikelNummer": {
      "minSize": 0,
      "maxSize": 60
    },
    "isAusgelistet": {
      "notNull": true
    },
    "isEigenpflege": {
      "notNull": true
    },
    "isGeloescht": {
      "notNull": true
    },
    "isGesperrt": {
      "notNull": true
    },
    "isHauptLieferant": {
      "notNull": true
    },
    "nichtBevorzugterHauptLieferant": {
      "notNull": true
    },
    "wgBDB": {
      "minSize": 0,
      "maxSize": 20
    }
  },
  "SuchEbene": {
    "typ": {
      "notNull": true
    }
  },
  "SuchLieferant": {
    "anrede": {
      "minSize": 0,
      "maxSize": 80
    },
    "client": {
      "minSize": 0,
      "maxSize": 5
    },
    "edi": {
      "notNull": true
    },
    "eigenlistung": {
      "notNull": true
    },
    "email": {
      "minSize": 0,
      "maxSize": 80
    },
    "fax": {
      "minSize": 0,
      "maxSize": 50
    },
    "gln": {
      "minSize": 0,
      "maxSize": 15
    },
    "gruppe": {
      "minSize": 0,
      "maxSize": 10
    },
    "iln": {
      "minSize": 0,
      "maxSize": 15
    },
    "land": {
      "minSize": 0,
      "maxSize": 10
    },
    "name1": {
      "minSize": 0,
      "maxSize": 100
    },
    "name2": {
      "minSize": 0,
      "maxSize": 100
    },
    "nummer": {
      "minSize": 0,
      "maxSize": 8,
      "notNull": true
    },
    "ort": {
      "minSize": 0,
      "maxSize": 50
    },
    "ortsteil": {
      "minSize": 0,
      "maxSize": 50
    },
    "plz": {
      "minSize": 0,
      "maxSize": 20
    },
    "postfach": {
      "minSize": 0,
      "maxSize": 20
    },
    "postfachOrt": {
      "minSize": 0,
      "maxSize": 50
    },
    "postfachPlz": {
      "minSize": 0,
      "maxSize": 10
    },
    "sprache": {
      "minSize": 0,
      "maxSize": 10
    },
    "strasse": {
      "minSize": 0,
      "maxSize": 100
    },
    "suchbegriff": {
      "minSize": 0,
      "maxSize": 50
    },
    "tel1": {
      "minSize": 0,
      "maxSize": 50
    },
    "tel2": {
      "minSize": 0,
      "maxSize": 50
    },
    "typ": {
      "notNull": true
    }
  },
  "TMLieferantNummer": {
    "nummer": {
      "notNull": true
    }
  },
  "Umsatzsteuer": {
    "land": {
      "minSize": 0,
      "maxSize": 10
    },
    "steuerkey": {
      "maxSize": 255
    }
  },
  "UpdateArtikelSelektionJobModel": {
    "name": {
      "minSize": 1,
      "maxSize": 255,
      "notNull": true
    }
  },
  "Verteilung": {
    "verteilungStart": {
      "notNull": true
    }
  },
  "VkPreis": {
    "basisTyp": {
      "notNull": true
    },
    "staffelmenge": {
      "notNull": true
    }
  },
  "VkPreisListe": {
    "bemerkung": {
      "minSize": 0,
      "maxSize": 255
    },
    "gueltigVon": {
      "notNull": true
    },
    "name": {
      "minSize": 0,
      "maxSize": 100
    },
    "typ": {
      "notNull": true
    }
  },
  "Warengruppe": {
    "name": {
      "minSize": 0,
      "maxSize": 100
    },
    "nummer": {
      "minSize": 1,
      "maxSize": 10,
      "notNull": true
    },
    "suchbegriff": {
      "minSize": 0,
      "maxSize": 100
    }
  },
  "ZeArtStueli": {
    "hagenummer": {
      "minSize": 0,
      "maxSize": 30
    }
  },
  "ZeArtikel": {
    "hageNummer": {
      "minSize": 0,
      "maxSize": 10
    }
  },
  "ZeArtikelLieferant": {
    "akt": {
      "notNull": true
    },
    "angebotstext": {
      "minSize": 0,
      "maxSize": 255
    },
    "betriebsTyp": {
      "notNull": true
    },
    "gefKomm": {
      "minSize": 0,
      "maxSize": 255
    },
    "industrieArtikelNummer": {
      "minSize": 0,
      "maxSize": 40
    },
    "wgBDB": {
      "minSize": 0,
      "maxSize": 20
    }
  },
  "ZeArtikelLieferantEK": {
    "ekPreisEinheit": {
      "minSize": 0,
      "maxSize": 15
    },
    "ekRabattGruppe": {
      "minSize": 0,
      "maxSize": 255
    },
    "gueltigAb": {
      "notNull": true
    }
  },
  "ZeArtikelLieferantEKStaffel": {
    "einheit": {
      "minSize": 0,
      "maxSize": 255
    },
    "staffelArt": {
      "minSize": 0,
      "maxSize": 255
    }
  },
  "ZeArtikelVersion": {
    "betriebsTyp": {
      "notNull": true
    },
    "bez1": {
      "minSize": 0,
      "maxSize": 255
    },
    "bez1_ph": {
      "minSize": 0,
      "maxSize": 255
    },
    "bez2": {
      "minSize": 0,
      "maxSize": 255
    },
    "bez2_ph": {
      "minSize": 0,
      "maxSize": 255
    },
    "bez3_ph": {
      "minSize": 0,
      "maxSize": 255
    },
    "bez4_ph": {
      "minSize": 0,
      "maxSize": 255
    },
    "bontext": {
      "minSize": 0,
      "maxSize": 80
    },
    "btNummer": {
      "minSize": 0,
      "maxSize": 10
    },
    "btWarengruppe": {
      "minSize": 0,
      "maxSize": 10
    },
    "eudrKategorie": {
      "maxSize": 255
    },
    "hageNummer": {
      "minSize": 0,
      "maxSize": 10
    },
    "matchcode": {
      "minSize": 0,
      "maxSize": 60
    },
    "pfandBTArtikelNr": {
      "minSize": 0,
      "maxSize": 255
    },
    "pflichttextCO2Kostenaufteilungsgesetz": {
      "maxSize": 255
    },
    "preisrolle": {
      "maxSize": 255
    },
    "vertriebsWeg": {
      "notNull": true
    },
    "vlPaketinhaltEinheit": {
      "minSize": 0,
      "maxSize": 255
    },
    "vlPaketinhaltMengeEinheit": {
      "minSize": 0,
      "maxSize": 255
    },
    "vorlaueferArtikel": {
      "minSize": 0,
      "maxSize": 255
    },
    "zartArtikelArt": {
      "minSize": 0,
      "maxSize": 255
    }
  },
  "ZeBtArtikel": {
    "betriebsTyp": {
      "notNull": true
    },
    "btNummer": {
      "minSize": 0,
      "maxSize": 10
    },
    "hageNummer": {
      "minSize": 0,
      "maxSize": 10
    },
    "vertriebsWeg": {
      "notNull": true
    }
  },
  "ZeEAN": {
    "ean": {
      "minSize": 0,
      "maxSize": 30
    },
    "vkSperreZart": {
      "minSize": 0,
      "maxSize": 10
    }
  },
  "ZeLieferant": {
    "branche": {
      "minSize": 0,
      "maxSize": 200
    },
    "client": {
      "minSize": 0,
      "maxSize": 5
    },
    "edi": {
      "notNull": true
    },
    "eigenlistung": {
      "notNull": true
    },
    "fhEinheit": {
      "minSize": 0,
      "maxSize": 10
    },
    "gruppe": {
      "minSize": 0,
      "maxSize": 10
    },
    "handelsCode": {
      "minSize": 0,
      "maxSize": 10
    },
    "iln": {
      "minSize": 0,
      "maxSize": 15
    },
    "lieferantArt": {
      "notNull": true
    },
    "lieferbedingung": {
      "minSize": 0,
      "maxSize": 255
    },
    "nummer": {
      "minSize": 0,
      "maxSize": 8,
      "notNull": true
    },
    "sprache": {
      "minSize": 0,
      "maxSize": 10
    },
    "suchbegriff": {
      "minSize": 0,
      "maxSize": 50
    },
    "ustIDNummer": {
      "minSize": 0,
      "maxSize": 255
    },
    "vorlauf": {
      "minSize": 0,
      "maxSize": 10
    },
    "waehrung": {
      "minSize": 0,
      "maxSize": 10
    },
    "zahlungsZiel": {
      "minSize": 0,
      "maxSize": 10
    }
  },
  "ZeLieferantAdresse": {
    "anrede": {
      "minSize": 0,
      "maxSize": 80
    },
    "co": {
      "minSize": 0,
      "maxSize": 100
    },
    "email": {
      "minSize": 0,
      "maxSize": 80
    },
    "fax": {
      "minSize": 0,
      "maxSize": 50
    },
    "gln": {
      "minSize": 0,
      "maxSize": 15
    },
    "internet": {
      "minSize": 0,
      "maxSize": 80
    },
    "land": {
      "minSize": 0,
      "maxSize": 10
    },
    "name1": {
      "minSize": 0,
      "maxSize": 100
    },
    "name2": {
      "minSize": 0,
      "maxSize": 100
    },
    "name3": {
      "minSize": 0,
      "maxSize": 100
    },
    "ort": {
      "minSize": 0,
      "maxSize": 50
    },
    "ortsteil": {
      "minSize": 0,
      "maxSize": 50
    },
    "plz": {
      "minSize": 0,
      "maxSize": 20
    },
    "postfach": {
      "minSize": 0,
      "maxSize": 20
    },
    "postfachOrt": {
      "minSize": 0,
      "maxSize": 50
    },
    "postfachPlz": {
      "minSize": 0,
      "maxSize": 10
    },
    "strasse": {
      "minSize": 0,
      "maxSize": 100
    },
    "tel1": {
      "minSize": 0,
      "maxSize": 50
    },
    "tel2": {
      "minSize": 0,
      "maxSize": 50
    }
  }
} as const;
