import { api } from '@one/api'
import {
  ArtikelDatenpoolDisplayJson,
  ArtikelDatenpoolSearcherCriteriaJson
} from '@one/typings/apiTypings'
import { useSearcherState } from '@utils/searcher'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { ArtikelDatenPoolUebersichtParams } from './ArtikelDatenpoolUebersichtParams'
import { ArtikelDatenPoolUebersichtTable } from './ArtikelDatenpoolUebersichtTable'

export const ArtikelDatenPoolUebersichtView = () => {
  const { criteria, onCriteriaChange, result, search } = useSearcherState<
    ArtikelDatenpoolSearcherCriteriaJson,
    ArtikelDatenpoolDisplayJson
  >({
    api,
    url: '/artikeldatenpool',
    initialParams: {}
  })

  return (
    <Frame space>
      <FrameRow>
        <ArtikelDatenPoolUebersichtParams
          value={criteria}
          onSearch={search}
          onChange={onCriteriaChange}
        />
      </FrameRow>
      <FrameBody>
        <ArtikelDatenPoolUebersichtTable result={result} reload={search} />
      </FrameBody>
    </Frame>
  )
}
