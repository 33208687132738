import { Grid2 as Grid } from '@mui/material'
import { PimArtikelVersionPackstueckJson, SeArtikelJson } from '@one/typings/apiTypings'
import { ModelAction, onChangeWrapper, ValueChangeFn } from '@utils/modelmgr'
import { NumberField } from '@utils/ui/fields/NumberField'
import { RowForm } from '@utils/ui/fields/RowForm'
import { TextField } from '@utils/ui/fields/TextField'
import { aidOf } from '@utils/utils'
import React from 'react'
import { EigenlistungsArtikelUseCase } from './model/EigenlistungsArtikelUseCase'

export interface PimArtikelPackstueckeProps {
  packstuecke: PimArtikelVersionPackstueckJson[]
  onChange: ValueChangeFn<SeArtikelJson>
  dispatch: (action: ModelAction) => void
  errors: any
  readonly?: boolean
}

export const PimArtikelPackstuecke = ({
  packstuecke,
  dispatch,
  onChange,
  errors,
  readonly
}: PimArtikelPackstueckeProps) => {
  return (
    <RowForm
      addLabel="Packstück hinzufügen"
      onAdd={readonly ? null : () => dispatch({ type: EigenlistungsArtikelUseCase.ADDPACKSTUECK })}
      onRemove={
        readonly
          ? null
          : (packstueck) =>
              dispatch({ type: EigenlistungsArtikelUseCase.REMOVEPACKSTUECK, packstueck })
      }
      title="Packstücke"
      name="Packstuecke"
      rows={packstuecke}
      body={(packstueck) => (
        <PimArtikelPackstueckMemo
          packstueck={packstueck}
          onChange={onChangeWrapper(
            onChange,
            `pimArtikel.version.packstuecke.[__aid=${aidOf(packstueck)}]`
          )}
          readonly={readonly}
        />
      )}
    />
  )
}

export const PimArtikelPackstueckeMemo = React.memo(PimArtikelPackstuecke)

export interface PimArtikelPackstueckProps {
  packstueck: PimArtikelVersionPackstueckJson
  onChange: ValueChangeFn<SeArtikelJson>
  readonly?: boolean
}

const PimArtikelPackstueck = ({ packstueck, onChange, readonly }: PimArtikelPackstueckProps) => {
  return (
    <Grid container spacing={2}>
      <Grid size={{xs:12,sm:6,md:4}}>
        <NumberField
          label="Länge"
          value={packstueck.laenge}
          name="laenge"
          onChange={onChange}
          fraction={0}
          fullWidth
          disabled={readonly}
        />
      </Grid>
      <Grid size={{xs:12,sm:6,md:4}}>
        <NumberField
          label="Breite"
          value={packstueck.breite}
          name="breite"
          onChange={onChange}
          fraction={0}
          fullWidth
          disabled={readonly}
        />
      </Grid>
      <Grid size={{xs:12,sm:6,md:4}}>
        <NumberField
          label="Höhe"
          value={packstueck.hoehe}
          name="hoehe"
          onChange={onChange}
          fraction={0}
          fullWidth
          disabled={readonly}
        />
      </Grid>
      <Grid size={{xs:12,sm:6,md:4}}>
        <TextField
          label="Inhalt"
          value={packstueck.inhalt}
          name="inhalt"
          onChange={onChange}
          fullWidth
          disabled={readonly}
        />
      </Grid>
      <Grid size={{xs:12,sm:6,md:4}}>
        <NumberField
          label="Gewicht"
          value={packstueck.gewicht}
          name="gewicht"
          onChange={onChange}
          fraction={4}
          fullWidth
          disabled={readonly}
        />
      </Grid>
    </Grid>
  )
}

const PimArtikelPackstueckMemo = React.memo(PimArtikelPackstueck)
