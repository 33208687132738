import { Grid2 as Grid } from '@mui/material'
import { api } from '@one/api'
import { StandortField } from '@one/components/common/StandortField'
import {
  AllianzMitgliedBearbeitenJson,
  AllianzMitgliedDisplayJson,
  AllianzMitgliedJson
} from '@one/typings/apiTypings'
import { useModelMgr } from '@utils/modelmgr'
import { Button } from '@utils/ui/Buttons/Button'
import { CancelButton } from '@utils/ui/Buttons/CancelButton'
import { DialogEx } from '@utils/ui/DialogEx'
import { Frame, FrameBody } from '@utils/ui/Frame'
import { StaticField } from '@utils/ui/fields/StaticField'
import { StatePlane } from '@utils/ui/planes/StatePlane'
import { useCallback, useState } from 'react'
import { AllianzMitgliedField } from './AllianzMitgliedField'

export interface AllianzMitgliedAnlageDialogProps {
  allianzId: number
  mitglieder: AllianzMitgliedDisplayJson[]
  open: boolean
  onClose: (saved: boolean) => void
}

export const AllianzMitgliedAnlageDialog = ({
  allianzId,
  mitglieder,
  open,
  onClose
}: AllianzMitgliedAnlageDialogProps) => {
  const [firmaId, setFirmaId] = useState<string>()

  const validate = useCallback((model: AllianzMitgliedJson): any => {
    if (model.defaultStandort == null)
      return {
        defaultStandort: 'Ein Defaultstandort muss angegeben werden'
      }
    return undefined
  }, [])

  const { envelope, model, errors, save, uiLock, wait } = useModelMgr<
    AllianzMitgliedBearbeitenJson,
    AllianzMitgliedJson
  >({
    id: 'neu',
    api,
    restps: {
      allianzId: allianzId,
      gsId: firmaId
    },
    unwrapField: 'allianzMitglied',
    title: 'Allianz Gesellschafter',
    rest: firmaId == null ? null : 'allianz/mitglied',
    validate
  })

  const handleSave = useCallback(() => {
    save({ then: () => onClose(true) })
  }, [onClose, save])

  const actions = (
    <>
      <Button label="Speichern" onClick={handleSave} color="primary" disabled={!firmaId} />
      <CancelButton onClick={() => onClose(false)} />
    </>
  )

  return (
    <DialogEx
      open={open}
      onClose={() => onClose(false)}
      fullWidth
      title="Firma als neues Allianzmitglied auswählen"
      actions={actions}
      lockClose={wait}
      height="360px"
    >
      <StatePlane uiLock={uiLock}>
        <Frame>
          <FrameBody>
            <Grid container spacing={2} padding={1}>
              <Grid size={{xs:12}}>
                <AllianzMitgliedField mitglieder={mitglieder} onSelect={setFirmaId} />
              </Grid>
              {model.firma ? (
                <>
                  <Grid size={{xs:6}}>
                    <StaticField label="GLN" value={model.firma.gln} />
                  </Grid>
                  <Grid size={{xs:6}}>
                    <StaticField label="Nummer" value={model.firma.nr} />
                  </Grid>
                  <Grid size={{xs:12}}>
                    <StandortField
                      label="Defaultstandort"
                      required
                      options={envelope?.alleStandorte}
                      value={model.defaultStandort?.id}
                      error={errors.defaultStandort}
                      fullWidth
                      readonly={!envelope?.alleStandorte?.length}
                      emptyText={
                        envelope?.alleStandorte?.length > 0
                          ? 'Bitte wählen'
                          : 'Fehlt - Gesellschafter hat keine Standorte!'
                      }
                    />
                  </Grid>
                </>
              ) : null}
            </Grid>
          </FrameBody>
        </Frame>
      </StatePlane>
    </DialogEx>
  )
}
