import { NotInterested, QuestionMark, Report, ReportOffOutlined } from '@mui/icons-material'
import {
  ArtikelDefaultColumnsProps,
  useArtikelColumns
} from '@one/components/Artikel/ArtikelColumn'
import {
  ArtikelLabelByTypeZuordnungJson,
  EkPreisListeImportDefinitionStepLabelsType,
  EkPreisListImportAssistentStepNichtGetroffeneArtikelJson,
  LabelJson,
  PreisEbeneDisplayJson
} from '@one/typings/apiTypings'
import { useFormState } from '@utils/formstate'
import { ModelAction } from '@utils/modelmgr'
import { useStateEx } from '@utils/stateex'
import { AppContext } from '@utils/ui/App/AppContext'
import { Button } from '@utils/ui/Buttons/Button'
import { MenuButton, MenuButtonItem } from '@utils/ui/Buttons/MenuButton'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { FrameBody } from '@utils/ui/Frame'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { EkPreisImportUsecase } from './model/EkPreisImportUsecase'

export interface EkPreisImportPflegeStep3NichtGetroffeneArtikelProps {
  nichtGetroffenenArtikel?: EkPreisListImportAssistentStepNichtGetroffeneArtikelJson[]
  nichtGetroffenenArtikelChanges?: Map<number, boolean>
  allePreisEbenen: PreisEbeneDisplayJson[]
  dispatch: (action: ModelAction) => void
  allLabels: LabelJson[]
  stepLabelIds?: ArtikelLabelByTypeZuordnungJson[]
}

export const EkPreisImportPflegeStep3NichtGetroffeneArtikel = ({
  nichtGetroffenenArtikel,
  nichtGetroffenenArtikelChanges,
  allePreisEbenen,
  dispatch,
  allLabels,
  stepLabelIds
}: EkPreisImportPflegeStep3NichtGetroffeneArtikelProps) => {
  const appContext = useContext(AppContext)

  const [selected, setSelected, getSelected] = useStateEx(new Set())
  const [initList, setInitList] = useState(true)
  const [checkboxState, onCheckboxChange] = useFormState(() => {
    if (!stepLabelIds) {
      return {}
    }
    return stepLabelIds.reduce((list, byType) => {
      byType.labelIds
      byType.labelIds.forEach((id) => {
        list[id] = byType.type === EkPreisListeImportDefinitionStepLabelsType.DEFAULT_AUTOMATISCH
      })
      return list
    }, {})
  })

  const columnProps = useMemo<
    ArtikelDefaultColumnsProps<EkPreisListImportAssistentStepNichtGetroffeneArtikelJson>
  >(
    () => ({
      isGesellschafter: appContext.isGesellschafter,
      fieldWrapper: {
        wrapper: 'artikel'
      },
      addColumnToIndex: [
        {
          beforeField: 'artikel.hageNummer',
          column: {
            field: 'ok',
            header: 'Ignorieren?',
            align: 'center',
            body: (row) => {
              const val = nichtGetroffenenArtikelChanges?.get(row.id) ?? row.ok
              return (
                <Checkbox
                  size="small"
                  indeterminate={val == null}
                  compact
                  indeterminateIcon={<QuestionMark />}
                  icon={<Report color="error" />}
                  checkedIcon={<ReportOffOutlined />}
                  checked={val}
                  onChange={(e) => {
                    dispatch({
                      type: EkPreisImportUsecase.SET_NICHTGETROFFEN_OK,
                      id: row.id,
                      selected: getSelected(),
                      checked: e.target.checked
                    })
                    return false
                  }}
                />
              )
            }
          }
        },
        {
          beforeField: 'artikel.bez1',
          column: {
            field: 'keinePreispflege',
            header: 'KP',
            headerTip: 'Keine Preispflege',
            align: 'center',
            valueGetter: (row) => (row.keinePreispflege ? 'Keine Preispflege' : null),
            body: (row) => (row.keinePreispflege ? <NotInterested fontSize="small" /> : null)
          }
        },
        {
          beforeField: 'artikel.bez1',
          column: {
            field: 'ekPreis',
            header: 'EKPreis',
            type: 'money2'
          }
        },
        {
          beforeField: 'artikel.bez1',
          column: {
            header: 'Standort (Lagergruppe)',
            field: 'preisEbenenIdsMitEkPreise',
            valueGetter: (row) =>
              row.preisEbenenIdsMitEkPreise
                ?.map((id) => allePreisEbenen.find((pe) => pe.id === id))
                .filter(Boolean)
                .map((pe) => pe.nr)
          },
          body: (row) => (
            <span>
              {row.preisEbenenIdsMitEkPreise
                ?.map((id) => allePreisEbenen.find((pe) => pe.id === id))
                .filter(Boolean)
                .map((pe) => pe.nr)
                .join(', ')}
            </span>
          )
        }
      ]
    }),
    [
      allePreisEbenen,
      appContext.isGesellschafter,
      dispatch,
      nichtGetroffenenArtikelChanges,
      getSelected
    ]
  )

  const columns = useArtikelColumns(columnProps)

  const processedArtikels = useMemo(() => {
    const suppressionCount = {}
    const ignoredCount = {}
    const filteredArtikel =
      nichtGetroffenenArtikel?.filter((artikel) => {
        let isSuppressed = false
        if (artikel.artikel.labels) {
          artikel.artikel.labels.forEach((labelId) => {
            suppressionCount[labelId] = suppressionCount?.[labelId]
              ? suppressionCount[labelId] + 1
              : 1
            if (artikel.ok) {
              ignoredCount[labelId] = ignoredCount?.[labelId] ? ignoredCount[labelId] + 1 : 1
            }

            if (checkboxState[labelId]) {
              isSuppressed = true
            }
          })
        }

        return !isSuppressed
      }) ?? []

    return {
      suppressionCount,
      ignoredCount,
      filteredArtikel
    }
  }, [nichtGetroffenenArtikel, checkboxState])

  useEffect(() => {
    // dieser Effekt räumt die UI auf und setzt initial und automatisch die Checkbox der Labels, deren Artikel in Gänze ignoriert sind, auf true
    if (initList && processedArtikels.filteredArtikel.length) {
      setInitList(false)
      const { ignoredCount, suppressionCount } = processedArtikels

      const overwrites = {}
      Object.keys(ignoredCount).forEach((key) => {
        if (ignoredCount[key] === suppressionCount[key] && !checkboxState[key]) {
          overwrites[key] = true
        }
      })

      if (Object.keys(overwrites).length) {
        onCheckboxChange((old) => {
          return { ...old, ...overwrites }
        })
      }
    }
  }, [initList, processedArtikels, onCheckboxChange, checkboxState])

  const onHandleCheckboxToggle = useCallback(
    (label: LabelJson) => (e) => {
      const isSelected = !checkboxState[label.id]
      const matchingArtikels = nichtGetroffenenArtikel.filter((artikel) => {
        return artikel.artikel?.labels?.includes(label.id)
      })

      dispatch({
        type: EkPreisImportUsecase.SET_NICHTGETROFFEN_OK,
        selected: matchingArtikels,
        checked: isSelected
      })

      onCheckboxChange(e)
    },
    [onCheckboxChange, checkboxState, nichtGetroffenenArtikel, dispatch]
  )

  const ignoreLabelCheckbox = useMemo<MenuButtonItem[]>(() => {
    let checkBoxes = []
    stepLabelIds?.forEach((byType) => {
      checkBoxes = checkBoxes.concat(
        byType.labelIds.map((labelId) => {
          const label = allLabels.find((_label) => _label.id === labelId)
          return {
            id: labelId,
            forceEnabled: true,
            label: (
              <Checkbox
                checked={checkboxState[labelId]}
                onChange={onHandleCheckboxToggle(label)}
                name={`${labelId}`}
                label={
                  <span>
                    {label.bezeichnung} ({label.kurz}) -{' '}
                    {processedArtikels.suppressionCount[labelId] && (
                      <i>{processedArtikels.suppressionCount[labelId]} Artikel</i>
                    )}
                  </span>
                }
              />
            )
          }
        })
      )
    })

    return checkBoxes
  }, [stepLabelIds, allLabels, checkboxState, processedArtikels, onHandleCheckboxToggle])

  const bottomActions = (
    <>
      <MenuButton
        label="Ausblenden"
        items={ignoreLabelCheckbox}
        keepOpen
        disabled={ignoreLabelCheckbox.length === 0}
      />
      <Button
        label="Ignorieren"
        disabled={selected.size === 0}
        onClick={() =>
          dispatch({ type: EkPreisImportUsecase.SET_NICHTGETROFFEN_OK, selected, checked: true })
        }
      />
      <Button
        label="Nicht ignorieren"
        disabled={selected.size === 0}
        onClick={() =>
          dispatch({ type: EkPreisImportUsecase.SET_NICHTGETROFFEN_OK, selected, checked: false })
        }
      />
    </>
  )

  return (
    <FrameBody>
      <DataTableCard
        name="Step3NichtGetroffeneArtikel"
        localStateName="Step3NichtGetroffeneArtikel"
        title="Nicht getroffene Artikel"
        filterMode="both"
        paging
        selectMode="multi"
        selectCol
        columns={columns}
        value={processedArtikels.filteredArtikel}
        onSelect={setSelected}
        dense
        bottomActions={bottomActions}
      />
    </FrameBody>
  )
}
